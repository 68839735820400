export const block3jMock = {
    statement_id: '01ee9b2e-f246-181b-968b-d9639ed71071',
    status: {
        state: 'SUCCEEDED',
    },
    manifest: {
        format: 'JSON_ARRAY',
        schema: {
            column_count: 4,
            columns: [
                {
                    name: 'project_type',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 0,
                },
                {
                    name: 'total_leads',
                    type_text: 'BIGINT',
                    type_name: 'LONG',
                    position: 1,
                },
                {
                    name: 'qualified_leads',
                    type_text: 'BIGINT',
                    type_name: 'LONG',
                    position: 2,
                },
                {
                    name: 'percentage_qualified',
                    type_text: 'DOUBLE',
                    type_name: 'DOUBLE',
                    position: 3,
                },
            ],
        },
        total_chunk_count: 1,
        chunks: [
            {
                chunk_index: 0,
                row_offset: 0,
                row_count: 3,
            },
        ],
        total_row_count: 3,
        truncated: false,
    },
    result: {
        chunk_index: 0,
        row_offset: 0,
        row_count: 3,
        data_array: [
            ['Company', '271', '114', '0.42066420664206644'],
            ['Multi Family Home', '57', '25', '0.43859649122807015'],
            ['Single Home', '1708', '787', '0.4607728337236534'],
        ],
    },
};
