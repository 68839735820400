export const block2kMock = {
    statement_id: '01ee9b2e-ce09-1737-99fb-0b95497d0dfc',
    status: {
        state: 'SUCCEEDED',
    },
    manifest: {
        format: 'JSON_ARRAY',
        schema: {
            column_count: 5,
            columns: [
                {
                    name: 'month',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 0,
                },
                {
                    name: 'channel',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 1,
                },
                {
                    name: 'total_opp',
                    type_text: 'BIGINT',
                    type_name: 'LONG',
                    position: 2,
                },
                {
                    name: 'won_opp',
                    type_text: 'BIGINT',
                    type_name: 'LONG',
                    position: 3,
                },
                {
                    name: 'win_rate',
                    type_text: 'DOUBLE',
                    type_name: 'DOUBLE',
                    position: 4,
                },
            ],
        },
        total_chunk_count: 1,
        chunks: [
            {
                chunk_index: 0,
                row_offset: 0,
                row_count: 138,
            },
        ],
        total_row_count: 138,
        truncated: false,
    },
    result: {
        chunk_index: 0,
        row_offset: 0,
        row_count: 138,
        data_array: [
            ['2019-02', null, '2', '2', '1.0'],
            ['2019-09', 'Typeform Volvo', '3', '2', '0.6666666666666666'],
            ['2019-10', 'Typeform Volvo', '4', '3', '0.75'],
            ['2019-10', 'Phone', '1', '0', '0.0'],
            ['2019-11', 'Phone', '2', '1', '0.5'],
            ['2019-11', 'Typeform Volvo', '5', '2', '0.4'],
            ['2019-11', 'Typeform', '1', '0', '0.0'],
            ['2019-11', 'Direct', '1', '1', '1.0'],
            ['2019-12', 'Typeform Volvo', '10', '4', '0.4'],
            ['2019-12', 'Phone', '2', '2', '1.0'],
            ['2020-01', 'Typeform Volvo', '8', '4', '0.5'],
            ['2020-01', 'Phone', '2', '2', '1.0'],
            ['2020-01', 'Typeform', '1', '1', '1.0'],
            ['2020-02', 'Typeform Volvo', '2', '2', '1.0'],
            ['2020-06', 'Typeform Volvo', '8', '3', '0.375'],
            ['2020-07', 'Typeform Volvo', '15', '5', '0.3333333333333333'],
            ['2020-07', 'Direct', '5', '1', '0.2'],
            ['2020-07', 'Phone', '1', '1', '1.0'],
            ['2020-08', 'Typeform', '1', '0', '0.0'],
            ['2020-08', 'Typeform Volvo', '16', '9', '0.5625'],
            ['2020-09', 'Typeform Volvo', '19', '9', '0.47368421052631576'],
            ['2020-09', 'Phone', '1', '0', '0.0'],
            ['2020-09', 'Direct', '1', '1', '1.0'],
            ['2020-10', 'Typeform Volvo', '16', '7', '0.4375'],
            ['2020-10', 'Phone', '1', '1', '1.0'],
            ['2020-11', 'Typeform Volvo', '15', '4', '0.26666666666666666'],
            ['2020-12', 'Typeform Volvo', '10', '10', '1.0'],
            ['2020-12', 'Phone', '2', '0', '0.0'],
            ['2020-12', 'Email', '1', '0', '0.0'],
            ['2021-01', 'Typeform Volvo', '12', '8', '0.6666666666666666'],
            ['2021-01', 'Typeform', '1', '1', '1.0'],
            ['2021-02', 'Typeform Volvo', '17', '9', '0.5294117647058824'],
            ['2021-02', 'Typeform', '3', '1', '0.3333333333333333'],
            ['2021-02', 'Zeplug', '1', '1', '1.0'],
            ['2021-03', 'Typeform Volvo', '12', '7', '0.5833333333333334'],
            ['2021-03', 'Email', '1', '1', '1.0'],
            ['2021-04', 'Typeform Volvo', '15', '2', '0.13333333333333333'],
            ['2021-05', 'Typeform Volvo', '14', '6', '0.42857142857142855'],
            ['2021-05', 'Email', '1', '0', '0.0'],
            ['2021-06', 'Typeform Volvo', '7', '3', '0.42857142857142855'],
            ['2021-06', 'Email', '1', '1', '1.0'],
            ['2021-07', 'Typeform Volvo', '11', '6', '0.5454545454545454'],
            ['2021-07', 'Zeplug', '1', '0', '0.0'],
            ['2021-08', 'Typeform Volvo', '7', '2', '0.2857142857142857'],
            ['2021-08', 'Email', '2', '1', '0.5'],
            ['2021-09', 'Typeform Volvo', '12', '3', '0.25'],
            ['2021-09', 'Email', '1', '1', '1.0'],
            ['2021-09', 'Direct', '1', '1', '1.0'],
            ['2021-09', 'Phone', '3', '3', '1.0'],
            ['2021-10', 'Typeform Volvo', '20', '9', '0.45'],
            ['2021-10', 'Direct', '2', '1', '0.5'],
            ['2021-10', 'Email', '1', '1', '1.0'],
            ['2021-10', 'Phone', '2', '0', '0.0'],
            ['2021-11', 'Email', '4', '1', '0.25'],
            ['2021-11', 'Phone', '4', '2', '0.5'],
            ['2021-11', 'Typeform Volvo', '22', '9', '0.4090909090909091'],
            ['2021-11', 'Direct', '1', '0', '0.0'],
            ['2021-12', 'Typeform Volvo', '38', '15', '0.39473684210526316'],
            ['2022-01', 'Typeform Volvo', '24', '15', '0.625'],
            ['2022-01', 'Phone', '1', '0', '0.0'],
            ['2022-01', 'Volvo dealership Form', '3', '1', '0.3333333333333333'],
            ['2022-01', 'Email', '1', '1', '1.0'],
            ['2022-02', 'Typeform Volvo', '26', '12', '0.46153846153846156'],
            ['2022-02', 'Form Volvo Employee FR', '2', '1', '0.5'],
            ['2022-02', 'Volvo dealership Form', '7', '5', '0.7142857142857143'],
            ['2022-02', 'Phone', '3', '0', '0.0'],
            ['2022-03', 'Volvo dealership Form', '14', '6', '0.42857142857142855'],
            ['2022-03', 'Typeform Volvo', '29', '12', '0.41379310344827586'],
            ['2022-03', 'Phone', '1', '1', '1.0'],
            ['2022-03', 'Direct', '1', '1', '1.0'],
            ['2022-03', 'Email', '1', '1', '1.0'],
            ['2022-04', 'Volvo dealership Form', '31', '15', '0.4838709677419355'],
            ['2022-04', 'Typeform Volvo', '31', '10', '0.3225806451612903'],
            ['2022-04', 'Zeplug', '1', '0', '0.0'],
            ['2022-04', 'Form Volvo Employee FR', '1', '1', '1.0'],
            ['2022-04', 'Email', '1', '0', '0.0'],
            ['2022-05', 'Typeform Volvo', '41', '17', '0.4146341463414634'],
            ['2022-05', 'Volvo dealership Form', '23', '11', '0.4782608695652174'],
            ['2022-05', 'Email', '2', '1', '0.5'],
            ['2022-06', 'Volvo dealership Form', '30', '11', '0.36666666666666664'],
            ['2022-06', 'Typeform Volvo', '35', '12', '0.34285714285714286'],
            ['2022-06', 'Direct', '1', '0', '0.0'],
            ['2022-06', 'Phone', '2', '1', '0.5'],
            ['2022-07', 'Volvo dealership Form', '31', '12', '0.3870967741935484'],
            ['2022-07', 'Typeform Volvo', '34', '16', '0.47058823529411764'],
            ['2022-07', 'Email', '1', '1', '1.0'],
            ['2022-07', 'Direct', '1', '0', '0.0'],
            ['2022-07', 'Phone', '3', '1', '0.3333333333333333'],
            ['2022-07', 'Form Volvo Employee FR', '1', '0', '0.0'],
            ['2022-08', 'Volvo dealership Form', '24', '12', '0.5'],
            ['2022-08', 'Typeform Volvo', '24', '12', '0.5'],
            ['2022-09', 'Typeform Volvo', '18', '8', '0.4444444444444444'],
            ['2022-09', 'Volvo dealership Form', '42', '12', '0.2857142857142857'],
            ['2022-09', 'Zeplug', '1', '1', '1.0'],
            ['2022-09', 'Form Volvo Employee FR', '1', '1', '1.0'],
            ['2022-09', 'Email', '3', '1', '0.3333333333333333'],
            ['2022-10', 'Volvo dealership Form', '31', '11', '0.3548387096774194'],
            ['2022-10', 'Typeform Volvo', '20', '8', '0.4'],
            ['2022-10', 'Phone', '1', '0', '0.0'],
            ['2022-10', 'Direct', '1', '1', '1.0'],
            ['2022-11', 'Volvo dealership Form', '17', '5', '0.29411764705882354'],
            ['2022-11', 'Typeform Volvo', '8', '2', '0.25'],
            ['2022-12', 'Typeform Volvo', '18', '3', '0.16666666666666666'],
            ['2022-12', 'Volvo dealership Form', '21', '6', '0.2857142857142857'],
            ['2022-12', 'Email', '2', '1', '0.5'],
            ['2023-01', 'Typeform Volvo', '13', '6', '0.46153846153846156'],
            ['2023-01', 'Volvo dealership Form', '18', '5', '0.2777777777777778'],
            ['2023-01', 'Email', '1', '0', '0.0'],
            ['2023-02', 'Volvo dealership Form', '12', '5', '0.4166666666666667'],
            ['2023-02', 'Typeform Volvo', '11', '3', '0.2727272727272727'],
            ['2023-02', 'Zeplug', '1', '1', '1.0'],
            ['2023-03', 'Typeform Volvo', '7', '1', '0.14285714285714285'],
            ['2023-03', 'Volvo dealership Form', '12', '8', '0.6666666666666666'],
            ['2023-04', 'Volvo dealership Form', '12', '5', '0.4166666666666667'],
            ['2023-04', 'Typeform Volvo', '6', '0', '0.0'],
            ['2023-04', 'Email', '1', '1', '1.0'],
            ['2023-04', 'Phone', '1', '0', '0.0'],
            ['2023-05', 'Volvo dealership Form', '3', '2', '0.6666666666666666'],
            ['2023-05', 'Typeform Volvo', '2', '1', '0.5'],
            ['2023-05', 'Zeplug', '1', '0', '0.0'],
            ['2023-05', 'Email', '1', '0', '0.0'],
            ['2023-06', 'Volvo dealership Form', '8', '2', '0.25'],
            ['2023-06', 'Typeform Volvo', '4', '1', '0.25'],
            ['2023-06', 'Email', '1', '0', '0.0'],
            ['2023-07', 'Volvo dealership Form', '8', '5', '0.625'],
            ['2023-07', 'Typeform Volvo', '3', '1', '0.3333333333333333'],
            ['2023-08', 'Volvo dealership Form', '4', '2', '0.5'],
            ['2023-08', 'Typeform Volvo', '2', '2', '1.0'],
            ['2023-09', 'Typeform Volvo', '7', '1', '0.14285714285714285'],
            ['2023-09', 'Volvo dealership Form', '4', '1', '0.25'],
            ['2023-09', 'Email', '1', '1', '1.0'],
            ['2023-10', 'Volvo dealership Form', '8', '2', '0.25'],
            ['2023-10', 'Typeform Volvo', '6', '1', '0.16666666666666666'],
            ['2023-11', 'Volvo dealership Form', '4', '1', '0.25'],
            ['2023-11', 'Typeform Volvo', '2', '1', '0.5'],
            ['2023-11', 'Zeplug', '1', '1', '1.0'],
            ['2023-11', 'Typeform', '1', '1', '1.0'],
            ['2023-12', 'Volvo dealership Form', '2', '2', '1.0'],
        ],
    },
};
