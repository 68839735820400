import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { contributorsMock } from './mock/contributors';
import { block1aMock } from './mock/1-A';
import { block1bMock } from './mock/1-B';
import { block1cMock } from './mock/1-C';
import { block1dMock } from './mock/1-D';
import { block1eMock } from './mock/1-E';
import { block1fMock } from './mock/1-F';
import { block1gMock } from './mock/1-G';
import { block1hMock } from './mock/1-H';
import { block1iMock } from './mock/1-I';
import { block1jMock } from './mock/1-J';
import { block1kMock } from './mock/1-K';
import { block1lMock } from './mock/1-L';
import { block1mMock } from './mock/1-M';
import { block1nMock } from './mock/1-N';
import { block2iMock } from './mock/2-I';
import { block2jMock } from './mock/2-J';
import { block2kMock } from './mock/2-K';
import { block2lMock } from './mock/2-L';
import { block3iMock } from './mock/3-I';
import { block3jMock } from './mock/3-J';
import { block3kMock } from './mock/3-K';
import { block3lMock } from './mock/3-L';
import { block4iMock } from './mock/4-I';
import { block4jMock } from './mock/4-J';
import { block4kMock } from './mock/4-K';
import { block4lMock } from './mock/4-L';
import { block5lMock } from './mock/5-L';
import { block5jMock } from './mock/5-J';
import { countriesMock } from './mock/countries';
import { typesMock } from './mock/types';

export type BackendResponse = {
    statement_id: string;
    status: {
        state: string;
    };
    manifest: {
        format: string;
        schema: {
            column_count: number;
            columns: Array<{
                name: string;
                type_text: string;
                type_name: string;
                position: number;
            }>;
        };
        total_chunk_count: number;
        chunks: Array<{
            chunk_index: number;
            row_offset: number;
            row_count: number;
        }>;
        total_row_count: number;
        truncated: boolean;
    };
    result: {
        chunk_index: number;
        row_offset: number;
        row_count: number;
        data_array: Array<Array<string | null>>;
    };
    title?: string;
};

type QueryMap = {
    [key: string]: {
        id: string;
        block?: string;
        title: string;
    };
};

const queries: QueryMap = {
    countries: {
        id: '238c99ff-dbf2-44de-a62b-f7c89c25bf5d',
        title: 'countries',
    },
    types: {
        id: '110164fb-23e9-48a5-ab42-fb28e36c0513',
        title: 'types',
    },
    contributors: {
        id: 'b3b5660d-d2c4-4b4b-9830-a115a8d44e6a',
        title: 'contributors',
    },
    createdLeads: {
        id: '69f2de7e-0a42-4167-b297-25b1ecdbcb5e',
        title: 'Created Leads',
    },
    qualifiedLeads: {
        id: 'ae69094f-0939-463d-aa76-c9eeff44953e',
        title: 'Qualified Leads',
    },
    projectsWon: {
        id: 'af6d1fce-bdde-4bf8-a83c-c4945a8db27e',
        title: 'Projects Won',
    },
    finalisedInstallations: {
        id: 'a566ad4f-1e1f-497b-a25e-73b6138c8f63',
        title: 'Finalised Installations',
    },
    qualifiedLeadRate: {
        id: '0919a0ce-d390-45d0-9a18-f295d4f3748d',
        title: 'Qualified Lead Rate',
    },
    projectWinRate: {
        id: '022246ce-59ad-4de3-a919-2d3cc165d4d8',
        title: 'Project Win Rate',
    },
    quotationLeadtime: {
        id: '3c7fc89e-c415-434a-8d9c-30877b5414e3',
        title: 'Quotation Leadtime',
    },
    installationLeadtime: {
        id: '62cdfa9d-1a20-44d4-b47a-8412d42e9405',
        title: 'Installation Leadtime',
    },
    exportData: {
        id: 'f5b5a7d3-0d01-43c8-a49e-ef85be618c6e',
        title: 'export',
    },
};

interface QueryOptions {
    dates?: Date[];
    contributors?: string[];
    countries?: string[];
    types?: string[];
    groupBy?: string;
}

export class BackendApi {
    private userScope?: string;

    constructor(private readonly axiosConnector: AxiosInstance) {}

    async setUserScope(userScope: string) {
        this.userScope = userScope;
    }
    private authenticated(accessToken: string): AxiosRequestConfig {
        return {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        };
    }

    async monthlyEvolutionOfCreatedLeadLaurent(accessToken: string, options?: QueryOptions): Promise<BackendResponse> {
        try {
            const { id, block, title } = queries.monthlyEvolutionOfCreatedLead;

            const res = await this.axiosConnector.get(
                `proxy?block=${block}&queryId=${id}${this.mappingOptions(options, true, 'lead_status')}`,
                this.authenticated(accessToken),
            );

            res.data.title = block + ' - ' + title;

            return res.data;
        } catch (err) {
            console.error(err);
            return block1aMock;
            // throw err;
        }
    }

    async monthlyEvolutionOfCreatedLead(accessToken: string, options?: QueryOptions): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.monthlyEvolutionOfCreatedLead.block}&queryId=${queries.monthlyEvolutionOfCreatedLead.id}${this.mappingOptions(
                    options,
                    true,
                    'lead_status',
                )}`,
                this.authenticated(accessToken),
            );

            return res.data;
        } catch (err) {
            console.error(err);
            return block1aMock;
            // throw err;
        }
    }

    async totalCreatedLead(accessToken: string, options?: QueryOptions): Promise<BackendResponse> {
        try {
            const { id, block, title } = queries.totalCreatedLead;

            const res = await this.axiosConnector.get(
                `proxy?block=${block}&queryId=${id}${this.mappingOptions(options, false, 'lead_status')}`,
                this.authenticated(accessToken),
            );

            res.data.title = block + ' - ' + title;

            return res.data;
        } catch (err) {
            console.error(err);
            return block1bMock;
            // throw err;
        }
    }

    async monthlyEvolutionOfOpportunity(accessToken: string, options?: QueryOptions): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.monthlyEvolutionOfOpportunity.block}&queryId=${queries.monthlyEvolutionOfOpportunity.id}${this.mappingOptions(
                    options,
                    true,
                    'opportunity_status',
                )}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block1cMock;
            // throw err;
        }
    }

    async totalOpportunities(accessToken: string, options?: QueryOptions): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.totalOpportunities.block}&queryId=${queries.totalOpportunities.id}${this.mappingOptions(options, false, 'opportunity_status')}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block1dMock;
            // throw err;
        }
    }

    async monthlyEvolutionOfOrder(accessToken: string, options?: QueryOptions): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.monthlyEvolutionOfOrder.block}&queryId=${queries.monthlyEvolutionOfOrder.id}${this.mappingOptions(options, true, 'order_status')}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block1eMock;
            // throw err;
        }
    }

    async totalOrders(accessToken: string, options?: QueryOptions): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.totalOrders.block}&queryId=${queries.totalOrders.id}${this.mappingOptions(options, false, 'order_status')}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block1fMock;
            // throw err;
        }
    }

    async monthlyEvolutionOfFinalisedInstallations(
        accessToken: string,
        options?: QueryOptions,
    ): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.monthlyEvolutionOfFinalisedInstallations.block}&queryId=${queries.monthlyEvolutionOfFinalisedInstallations.id}${this.mappingOptions(options, true)}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block1gMock;
            // throw err;
        }
    }

    async totalFinalisedInstallations(accessToken: string, options?: QueryOptions): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.totalFinalisedInstallations.block}&queryId=${queries.totalFinalisedInstallations.id}${this.mappingOptions(options, false)}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block1hMock;
            // throw err;
        }
    }

    async monthlyEvolutionOfQualifiedLeadRate(accessToken: string, options?: QueryOptions): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.monthlyEvolutionOfQualifiedLeadRate.block}&queryId=${queries.monthlyEvolutionOfQualifiedLeadRate.id}${this.mappingOptions(options, true)}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block1iMock;
            // throw err;
        }
    }

    async monthlyEvolutionOfQualifiedLeadRateByChannel(
        accessToken: string,
        options?: QueryOptions,
    ): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.monthlyEvolutionOfQualifiedLeadRateByChannel.block}&queryId=${queries.monthlyEvolutionOfQualifiedLeadRateByChannel.id}${this.mappingOptions(
                    options,
                    true,
                )}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block2iMock;
            // throw err;
        }
    }

    async monthlyEvolutionOfQualifiedLeadRateByProject(
        accessToken: string,
        options?: QueryOptions,
    ): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.monthlyEvolutionOfQualifiedLeadRateByProject.block}&queryId=${queries.monthlyEvolutionOfQualifiedLeadRateByProject.id}${this.mappingOptions(
                    options,
                    true,
                )}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block3iMock;
            // throw err;
        }
    }

    async monthlyEvolutionOfQualifiedLeadRateByCountry(
        accessToken: string,
        options?: QueryOptions,
    ): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.monthlyEvolutionOfQualifiedLeadRateByCountry.id}&queryId=${queries.monthlyEvolutionOfQualifiedLeadRateByCountry.id}${this.mappingOptions(
                    options,
                    true,
                )}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block4iMock;
            // throw err;
        }
    }

    async qualifiedLeadRate(accessToken: string, options?: QueryOptions): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.qualifiedLeadRate.block}&queryId=${queries.qualifiedLeadRate.id}${this.mappingOptions(options, true)}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block1jMock;
            // throw err;
        }
    }

    async qualifiedLeadRateByCountry(accessToken: string, options?: QueryOptions): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.qualifiedLeadRateByCountry.block}&queryId=${queries.qualifiedLeadRateByCountry.id}${this.mappingOptions(options, true)}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block4jMock;
            // throw err;
        }
    }

    async qualifiedLeadRateByChannel(accessToken: string, options?: QueryOptions): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.qualifiedLeadRateByChannel.block}&queryId=${queries.qualifiedLeadRateByChannel.id}${this.mappingOptions(options)}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block2jMock;
            // throw err;
        }
    }

    async qualifiedLeadRateByContributor(accessToken: string, options?: QueryOptions): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.qualifiedLeadRateByContributor.id}&queryId=${queries.qualifiedLeadRateByContributor.id}${this.mappingOptions(options, true)}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block5jMock;
            // throw err;
        }
    }

    async qualifiedLeadRateByProject(accessToken: string, options?: QueryOptions): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.qualifiedLeadRateByProject.block}&queryId=${queries.qualifiedLeadRateByProject.id}${this.mappingOptions(options, true)}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block3jMock;
            // throw err;
        }
    }

    async monthlyEvolutionOfProjectWinRate(accessToken: string, options?: QueryOptions): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.monthlyEvolutionOfProjectWinRate.block}&queryId=${queries.monthlyEvolutionOfProjectWinRate.id}${this.mappingOptions(options, true)}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block1kMock;
            // throw err;
        }
    }

    async monthlyEvolutionOfProjectWinRateByChannel(
        accessToken: string,
        options?: QueryOptions,
    ): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.monthlyEvolutionOfProjectWinRateByChannel.block}&queryId=${queries.monthlyEvolutionOfProjectWinRateByChannel.id}${this.mappingOptions(options, true)}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block2kMock;
            // throw err;
        }
    }

    async monthlyEvolutionOfProjectWinRateByProject(
        accessToken: string,
        options?: QueryOptions,
    ): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.monthlyEvolutionOfProjectWinRateByProject.id}&queryId=${queries.monthlyEvolutionOfProjectWinRateByProject.id}${this.mappingOptions(options, true)}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block3kMock;
            // throw err;
        }
    }

    async monthlyEvolutionOfProjectWinRateByCountry(
        accessToken: string,
        options?: QueryOptions,
    ): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.monthlyEvolutionOfProjectWinRateByCountry.block}&queryId=${queries.monthlyEvolutionOfProjectWinRateByCountry.id}${this.mappingOptions(options, true)}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block4kMock;
            // throw err;
        }
    }

    async projectWinRate(accessToken: string, options?: QueryOptions): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.projectWinRate.block}&queryId=${queries.projectWinRate.id}${this.mappingOptions(options)}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block1lMock;
            // throw err;
        }
    }

    async projectWinRateByChannel(accessToken: string, options?: QueryOptions): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.projectWinRateByChannel.block}&queryId=${queries.projectWinRateByChannel.id}${this.mappingOptions(options)}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block2lMock;
            // throw err;
        }
    }

    async projectWinRateByProject(accessToken: string, options?: QueryOptions): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.projectWinRateByProject.block}&queryId=${queries.projectWinRateByProject.id}${this.mappingOptions(options)}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block3lMock;
            // throw err;
        }
    }

    async projectWinRateByCountry(accessToken: string, options?: QueryOptions): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.projectWinRateByCountry.block}&queryId=${queries.projectWinRateByCountry.id}${this.mappingOptions(options)}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block4lMock;
            // throw err;
        }
    }

    async projectWinRateByContributor(accessToken: string, options?: QueryOptions): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.projectWinRateByContributor.block}&queryId=${queries.projectWinRateByContributor.id}${this.mappingOptions(options)}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block5lMock;
            // throw err;
        }
    }

    async monthlyEvolutionOfQuotationLeadtime(accessToken: string, options?: QueryOptions): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.monthlyEvolutionOfQuotationLeadtime.block}&queryId=${queries.monthlyEvolutionOfQuotationLeadtime.id}${this.mappingOptions(options, true)}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block1mMock;
            // throw err;
        }
    }

    async monthlyEvolutionOfInstallationLeadtime(
        accessToken: string,
        options?: QueryOptions,
    ): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.monthlyEvolutionOfInstallationLeadtime.block}&queryId=${queries.monthlyEvolutionOfInstallationLeadtime.id}${this.mappingOptions(options, true)}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block1nMock;
            // throw err;
        }
    }

    async getCountries(accessToken: string): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.countries.block}&queryId=${queries.countries.id}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return countriesMock;
            // throw err;
        }
    }

    async getTypes(accessToken: string): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.types.block}&queryId=${queries.types.id}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return typesMock;
            // throw err;
        }
    }

    async getContributors(accessToken: string): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.contributors.block}&queryId=${queries.contributors.id}${this.mappingOptions()}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return contributorsMock;
            // throw err;
        }
    }

    async exportData(accessToken: string, options?: QueryOptions): Promise<BackendResponse> {
        try {
            const res = await this.axiosConnector.get(
                `proxy?block=${queries.exportData.block}&queryId=${queries.exportData.id}${this.mappingOptions(options)}`,
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            console.error(err);
            return block1aMock;
            // throw err;
        }
    }

    async getQuery(
        queryKey: string,
        block: string,
        accessToken: string,
        options?: QueryOptions,
        withGroupByMonth: boolean = false,
        groupByStatus?: string,
    ): Promise<BackendResponse> {
        try {
            const { id, title } = queries[queryKey];

            const res = await this.axiosConnector.get(
                `proxy?block=${block}&queryId=${id}${this.mappingOptions(options, withGroupByMonth, groupByStatus)}`,
                this.authenticated(accessToken),
            );

            res.data.title = `${title} ( ${block} )`;
            res.data.title = title;

            return res.data;
        } catch (err) {
            console.error(err);
            return block1aMock;
            // throw err;
        }
    }

    private mappingOptions(options?: QueryOptions, withGroupByMonth?: boolean, groupByStatus?: string): string {
        // annee - mois - jour pour la date `YYYY-mm-dd``
        // const initialWhere = this.userScope ? `&where[user_scope]=${this.userScope}` : '&where[user_scope]=Volvo';
        const initialWhere = this.userScope ? `&where[user_scope]=${this.userScope}` : '&where[user_scope]=Ford%20Pro';
        // const initialWhere = this.userScope ? `&where[user_scope]=${this.userScope}` : '&where[user_scope]=Volvo';
        // const initialWhere = '&where[user_scope]=Ford%20Pro';

        if (!options) {
            return initialWhere;
        }
        let where = [initialWhere];
        if (options.dates && options.dates.length > 1) {
            // removing timezone offset to convert to ISO String
            var
                tzoffset = (options.dates[0]).getTimezoneOffset() * 60000,
                startDate = new Date(options.dates[0].getTime() - tzoffset),
                endDate = new Date(options.dates[1].getTime() - tzoffset);

            where.push(`where[selected_period_start_date]=${startDate.toISOString().split('T')[0]}`);
            where.push(`where[selected_period_end_date]=${endDate.toISOString().split('T')[0]}`);
        }
        if (options.contributors && options.contributors.length > 0) {
            where.push(`where[selected_business_contributors]=${options.contributors.join(',')}`);
        }
        if (options.countries && options.countries.length > 0) {
            where.push(`where[selected_countries]=${options.countries.join(',')}`);
        }
        if (options.types && options.types.length > 0) {
            where.push(`where[selected_project_types]=${options.types.join(',')}`);
        }
        if (options.groupBy && options.groupBy !== 'status') {
            where.push(`groupBy[]=${options.groupBy}`);
        }
        if (options.groupBy === 'status' && groupByStatus) {
            where.push(`groupBy[]=${groupByStatus}`);
        }
        if (withGroupByMonth) {
            where.push(`groupBy[]=month`);
        }
        const allWhere = where.join('&');
        return allWhere;
    }
}
