import React from 'react';
import { GraphLayout } from '../../layout/GraphLayout';
import { CountryMonthlyEvolutionOfCreatedLeadByCountry } from 'src/ui/atoms/graphs/country-monthly-evolution-of-created-lead-by-country';
import { CountryTotalCreatedLeadByCountry } from 'src/ui/atoms/graphs/country-total-created-lead-by-country';
import { CountryMonthlyEvolutionOfOpportunityByCountry } from 'src/ui/atoms/graphs/country-monthly-evolution-of-opportunity-by-country';
import { CountryTotalOpportunityByCountry } from 'src/ui/atoms/graphs/country-total-opportunities-by-country';
import { CountryMonthlyEvolutionOfOrderByCountry } from 'src/ui/atoms/graphs/country-monthly-evolution-of-order-by-country';
import { CountryTotalOrdersByCountry } from 'src/ui/atoms/graphs/country-total-orders-by-country';
import { CountryMonthlyEvolutionOfFinalisedInstallationsByCountry } from 'src/ui/atoms/graphs/country-monthly-evolution-of-finalised-installations-by-country';
import { CountryTotalFinalisedInstallationsByCountry } from 'src/ui/atoms/graphs/country-total-finalised-installations-by-country';
import { CountryMonthlyEvolutionOfQualifiedLeadRateByCountry } from 'src/ui/atoms/graphs/country-monthly-evolution-of-qualified-lead-rate-by-country';
import { CountryQualifiedLeadRateByCountry } from 'src/ui/atoms/graphs/country-qualified-lead-rate-by-country';
import { CountryMonthlyEvolutionOfProjectWinRateByCountry } from 'src/ui/atoms/graphs/country-monthly-evolution-of-project-win-rate-by-country';
import { CountryProjectWinRateByCountry } from 'src/ui/atoms/graphs/country-project-win-rate-by-country';
import { CountryMonthlyEvolutionOfQuotationLeadtimeByCountry } from 'src/ui/atoms/graphs/country-monthly-evolution-of-quotation-leadtime-by-country';
import { CountryMonthlyEvolutionOfInstallationLeadtimeByCountry } from 'src/ui/atoms/graphs/country-monthly-evolution-of-installation-leadtime-by-country';

export function CountryGraphs() {
    return (
        <GraphLayout
            title="Country"
            graphs={[
                <CountryMonthlyEvolutionOfCreatedLeadByCountry />,
                <CountryTotalCreatedLeadByCountry />,
                <CountryMonthlyEvolutionOfOpportunityByCountry />,
                <CountryTotalOpportunityByCountry />,
                <CountryMonthlyEvolutionOfOrderByCountry />,
                <CountryTotalOrdersByCountry />,
                <CountryMonthlyEvolutionOfFinalisedInstallationsByCountry />,
                <CountryTotalFinalisedInstallationsByCountry />,
                <CountryMonthlyEvolutionOfQualifiedLeadRateByCountry />,
                <CountryQualifiedLeadRateByCountry />,
                <CountryMonthlyEvolutionOfProjectWinRateByCountry />,
                <CountryProjectWinRateByCountry />,
                <CountryMonthlyEvolutionOfQuotationLeadtimeByCountry />,
                <CountryMonthlyEvolutionOfInstallationLeadtimeByCountry />,
            ]}
        />
    );
}
