import React, { useEffect, useState, useContext } from 'react';
import { PageTemplate } from '../layout/PageTemplate';
import { Card, Stack, Typography } from '@mui/joy';
import TabsMenu from '../molecules/TabsMenu';
import { StatusGraphs } from '../molecules/graphs/status-graphs';
import { ChannelGraphs } from '../molecules/graphs/source-channel-graphs';
import { ProjectTypeGraphs } from '../molecules/graphs/project-type-graphs';
import { CountryGraphs } from '../molecules/graphs/country-graphs';
import Filters from '../molecules/Filters';
import { FiltersContext } from '../contexts/filters-context';

export function Overview() {
    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState('status');
    const { filtersState } = useContext(FiltersContext)!;

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <PageTemplate
            greyBackground
            pageTitle={
                <span>
                    Overview{' '}
                    <Typography level="h1" fontSize="xl">
                        by {filtersState.currentPage}
                    </Typography>
                </span>
            }
        >
            <Stack
                sx={{
                    minHeight: 'calc(100vh - 82px)',
                    padding: '21px 20px',
                    justifyContent: 'space-between',
                }}
                data-test-id="overview-page"
            >
                <Stack
                    sx={{
                        justifyContent: 'flex-start',
                    }}
                >
                    <TabsMenu setTab={setTab} />
                    <Card
                        sx={{
                            borderTopLeftRadius: 0,
                            border: 'none',
                        }}
                    >
                        <Filters />
                        {!loading && (
                            <>
                                {tab === 'status' && <StatusGraphs />}
                                {tab === 'channel' && <ChannelGraphs />}
                                {tab === 'project_type' && <ProjectTypeGraphs />}
                                {tab === 'country_name' && <CountryGraphs />}
                            </>
                        )}
                    </Card>
                </Stack>
            </Stack>
        </PageTemplate>
    );
}
