import { createContext, useContext, useState, useCallback } from 'react';

interface LoadingContextType {
    loadingCount: number;
    incrementLoading: () => void;
    decrementLoading: () => void;
}

const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

export function LoadingProvider(props: any) {
    const [loadingCount, setLoadingCount] = useState<number>(0);

    const incrementLoading = useCallback(() => {
        setLoadingCount((count) => count + 1);
    }, []);

    const decrementLoading = useCallback(() => {
        setLoadingCount((count) => Math.max(0, count - 1));
    }, []);

    const value = { loadingCount, incrementLoading, decrementLoading };

    return <LoadingContext.Provider value={value}>{props.children}</LoadingContext.Provider>;
}

export const useLoading = (): LoadingContextType => {
    const context = useContext(LoadingContext);
    return context!;
};
