import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    ArcElement,
    Tooltip,
    Legend,
    ChartOptions,
    ChartDataset,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { BackendResponse } from 'src/providers/data-source-backend';
import { allValuesDeduplicatedOf, positionOf } from 'src/utils/graph-utils';
import { backgroundColorForValue } from 'src/utils/color-utils';
import NoDataDisplay from './NoDataDisplay';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

const barGraphOptions = (
    name: string,
    info?: string,
    axisXLabel?: string,
    axisYLabel?: string,
    displayLegend?: boolean,
    horizontalBar?: boolean,
): ChartOptions<'bar'> => ({
    plugins: {
        title: {
            display: true,
            text: name,
        },
        legend: {
            display: true,
            title: {
                display: !!info,
                text: info,
            },
            // cannot hide labels with {$.plugin.legend.display} only, we have to hide it via a the arrow function below
            labels: displayLegend
                ? {}
                : {
                      generateLabels: () => [],
                  },
            position: 'top',
        },
    },
    responsive: true,
    indexAxis: horizontalBar ? 'y' : 'x',
    maintainAspectRatio: false,
    scales: {
        x: {
            title: {
                display: true,
                text: axisXLabel,
            },
            stacked: true,
        },
        y: {
            title: {
                display: true,
                text: axisYLabel,
            },
            stacked: true,
            ticks: {
                callback: function (value: any): string {
                    // truncate the labels only in this axis
                    const lbl = this.getLabelForValue(value as number);
                    if (lbl.length > 40) {
                        return `${lbl.substring(0, 40)}...`;
                    }
                    return lbl;
                },
            },
        },
    },
});

const graphDataBarGraph = ({
    data,
    axisX,
    axisXStack,
    axisXStackFilter = () => true,
    axisYValue,
    axisYValueFilter = () => true,
    axisYStack,
    axisYStackFilter = () => true,
    axisXSort,
}: {
    data: BackendResponse;
    axisX: string;
    axisXStack?: string;
    axisXStackFilter?: (value: string | null) => boolean;
    axisYValue: (data: (string | null)[][]) => number;
    axisYValueFilter?: (data: (string | null)[]) => boolean;
    axisYStack?: string;
    axisYStackFilter?: (value: string | null) => boolean;
    axisXSort?: (a: string, b: string) => number;
}): ChartDataset<any, any>[] => {
    const axisXPosition = positionOf(data, axisX);
    const axisXValues = allValuesDeduplicatedOf(data, axisX, axisXSort);

    const axisYHorizontalStackPosition = axisXStack ? positionOf(data, axisXStack) : -1;
    const axisYVerticalStackPosition = axisYStack ? positionOf(data, axisYStack) : -1;

    const stacked = axisXStack !== undefined || axisYStack !== undefined;

    const filter = (val: string | undefined | null, position: number) => (row: (string | null)[]) =>
        val === undefined || val === null || val === '' || position === -1 || row[position] === val;

    const axisYVerticalStackValues = axisYStack ? allValuesDeduplicatedOf(data, axisYStack) : [''];
    const axisYHorizontalStackValues = axisXStack ? allValuesDeduplicatedOf(data, axisXStack) : [''];

    const filteredAxisYVerticalStackValues = axisYVerticalStackValues.filter(axisXStackFilter);
    const filteredAxisYHorizontalStackValues = axisYHorizontalStackValues.filter(axisYStackFilter);

    const res = filteredAxisYHorizontalStackValues.flatMap((axisYHorizontalStackValue, idxAxisYHorizontalStack) =>
        filteredAxisYVerticalStackValues.map((axisYVerticalStackValue, idxAxisYVerticalStackValue) => {
            const labels = [];
            if (axisYHorizontalStackValue) labels.push(axisYHorizontalStackValue);
            if (axisYVerticalStackValue) labels.push(axisYVerticalStackValue);

            return {
                label: labels.join('-'),
                data: axisXValues.map((axisXValue) => {
                    const filteredData = data.result.data_array
                        .filter(filter(axisYHorizontalStackValue, axisYHorizontalStackPosition))
                        .filter(filter(axisXValue, axisXPosition))
                        .filter(filter(axisYVerticalStackValue, axisYVerticalStackPosition))
                        .filter(axisYValueFilter);
                    return axisYValue(filteredData);
                }),
                backgroundColor: stacked
                    ? backgroundColorForValue(
                          axisYVerticalStackValue && axisYVerticalStackValue !== ''
                              ? axisYVerticalStackValue
                              : axisYHorizontalStackValue,
                      )
                    : axisXValues.map((axisXValue) => backgroundColorForValue(axisXValue)),
                stack: axisYHorizontalStackValue ?? undefined,
            };
        }),
    );

    return res;
};

export function BarGraph(props: {
    info?: string;
    data: BackendResponse;
    title: string;
    axisXLabel?: string;
    axisYLabel?: string;
    axisX: string;
    axisXSort?: (a: string, b: string) => number;
    axisXStack?: string;
    axisXStackFilter?: (value: string | null) => boolean;
    axisYValue: (data: (string | null)[][]) => number;
    axisYValueFilter?: (data: (string | null)[]) => boolean;
    axisYStack?: string;
    axisYStackFilter?: (value: string | null) => boolean;
    displayLegend?: boolean;
    horizontalBar?: boolean;
}) {
    if (!props.data.result || !props.data.result.data_array) {
        return <NoDataDisplay />;
    }
    return (
        <>
            <Bar
                options={barGraphOptions(
                    props.title,
                    props.info,
                    props.axisXLabel,
                    props.axisYLabel,
                    props.displayLegend ?? true,
                    props.horizontalBar,
                )}
                data={{
                    labels: allValuesDeduplicatedOf(props.data, props.axisX, props.axisXSort),
                    datasets: graphDataBarGraph({
                        data: props.data,
                        axisX: props.axisX,
                        axisXStack: props.axisXStack,
                        axisXStackFilter: props.axisXStackFilter,
                        axisYValue: props.axisYValue,
                        axisYValueFilter: props.axisYValueFilter,
                        axisYStack: props.axisYStack,
                        axisYStackFilter: props.axisYStackFilter,
                        axisXSort: props.axisXSort,
                    }),
                }}
            />
        </>
    );
}
