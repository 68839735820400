import { Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { LoaderPage } from './pages/Loader';
import { Home } from './pages/Home';
import { type ComponentType } from 'react';
import { Overview } from './pages/Overview';
import { Contributor } from './pages/Contributor';

export const AuthenticationGuard = ({ Component }: { Component: ComponentType }) => {
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

    if (isLoading) {
        return <LoaderPage />;
    }

    if (!isAuthenticated) {
        loginWithRedirect();
        return;
    }

    return <Component />;
};

export function AppRoutes() {
    const { isLoading, error } = useAuth0();
    if (error) {
        console.log(error);
    }

    if (isLoading) {
        return <LoaderPage />;
    }

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/overview" element={<AuthenticationGuard Component={Overview} />} />
            <Route path="/contributor" element={<AuthenticationGuard Component={Contributor} />} />
        </Routes>
    );
}
