import { Typography, Snackbar } from '@mui/joy';
import { theme } from '../theme';

interface ToasterProps {
    text: string;
    color?: 'primary' | 'danger';
    open: boolean;
    setOpen: Function;
}

export default function Toaster({ text, color, open, setOpen }: ToasterProps) {
    return (
        <Snackbar variant="solid" color={color} open={open} onClose={() => setOpen(false)} autoHideDuration={3000}>
            <Typography sx={{ color: color === 'danger' ? theme.palette.neutral[50] : theme.palette.neutral[900] }}>
                {text}
            </Typography>
        </Snackbar>
    );
}
