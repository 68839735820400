export const block1dMock = {
    statement_id: '01ee99e2-da0e-199c-a3ed-84af4ece2d8d',
    status: {
        state: 'SUCCEEDED',
    },
    manifest: {
        format: 'JSON_ARRAY',
        schema: {
            column_count: 8,
            columns: [
                {
                    name: 'month',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 0,
                },
                {
                    name: 'created_date',
                    type_text: 'TIMESTAMP',
                    type_name: 'TIMESTAMP',
                    position: 1,
                },
                {
                    name: 'opportunity_status',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 2,
                },
                {
                    name: 'id',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 3,
                },
                {
                    name: 'channel',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 4,
                },
                {
                    name: 'country_name',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 5,
                },
                {
                    name: 'project_type',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 6,
                },
                {
                    name: 'business_contributor',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 7,
                },
            ],
        },
        total_chunk_count: 1,
        chunks: [
            {
                chunk_index: 0,
                row_offset: 0,
                row_count: 1302,
            },
        ],
        total_row_count: 1302,
        truncated: false,
    },
    result: {
        chunk_index: 0,
        row_offset: 0,
        row_count: 1302,
        data_array: [
            [
                'February 2019',
                '2019-02-05T15:00:38.000Z',
                'Waiting for Quote',
                '0060X00000ZidlMQAR',
                'Direct',
                'France',
                'Single Home',
                'ChargeGuru Business Services',
            ],
            [
                'February 2019',
                '2019-02-25T15:11:06.000Z',
                'Closed Won',
                '0060X00000ZjnAFQAZ',
                null,
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'February 2019',
                '2019-02-26T14:10:33.000Z',
                'Closed Won',
                '0060X00000ZjpNMQAZ',
                null,
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'September 2019',
                '2019-09-27T07:25:52.000Z',
                'Closed Won',
                '0060X00000ebXyVQAU',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'September 2019',
                '2019-09-27T07:37:32.000Z',
                'Closed Lost',
                '0060X00000ebY0kQAE',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'September 2019',
                '2019-09-30T07:04:15.000Z',
                'Closed Won',
                '0060X00000ebbqgQAA',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'October 2019',
                '2019-10-04T16:17:37.000Z',
                'Closed Won',
                '0060X00000ebpDEQAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'October 2019',
                '2019-10-11T17:18:40.000Z',
                'Closed Won',
                '0060X00000ec3kTQAQ',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'October 2019',
                '2019-10-17T07:55:25.000Z',
                'Closed Won',
                '0060X00000ecCsHQAU',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'October 2019',
                '2019-10-21T13:16:17.000Z',
                'Closed Lost',
                '0060X00000ecJZpQAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'October 2019',
                '2019-10-30T08:29:47.000Z',
                'Closed Lost',
                '0060X00000ecfahQAA',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'November 2019',
                '2019-11-04T09:17:25.000Z',
                'Closed Won',
                '0060X00000ecnVSQAY',
                'Phone',
                'France',
                'Single Home',
                "Volvo -  CAR'S SERVICES - ONET LE CHATEAU (RODEZ)",
            ],
            [
                'November 2019',
                '2019-11-04T17:41:07.000Z',
                'Closed Lost',
                '0060X00000ecpDAQAY',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'November 2019',
                '2019-11-08T21:17:49.000Z',
                'Closed Won',
                '0060X00000eczA6QAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'November 2019',
                '2019-11-13T08:59:16.000Z',
                'Closed Lost',
                '0060X00000ed5llQAA',
                'Typeform',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'November 2019',
                '2019-11-21T14:35:36.000Z',
                'Closed Lost',
                '0060X00000edOKMQA2',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'November 2019',
                '2019-11-25T08:22:59.000Z',
                'Closed Lost',
                '0060X00000edT40QAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'November 2019',
                '2019-11-26T16:05:01.000Z',
                'Closed Lost',
                '0060X00000edXN9QAM',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
            ],
            [
                'November 2019',
                '2019-11-28T08:20:07.000Z',
                'Closed Won',
                '0060X00000edb1BQAQ',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'November 2019',
                '2019-11-28T08:24:42.000Z',
                'Closed Won',
                '0060X00000edb1zQAA',
                'Direct',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'December 2019',
                '2019-12-02T09:56:34.000Z',
                'Closed Lost',
                '0060X00000edg5zQAA',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'December 2019',
                '2019-12-02T14:33:40.000Z',
                'Closed Won',
                '0060X00000edhACQAY',
                'Phone',
                'France',
                'Multi Family Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'December 2019',
                '2019-12-03T12:42:51.000Z',
                'Closed Won',
                '0060X00000edjOeQAI',
                'Phone',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'December 2019',
                '2019-12-05T15:02:47.000Z',
                'Closed Won',
                '0060X00000edqGRQAY',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'December 2019',
                '2019-12-09T12:00:33.000Z',
                'Closed Lost',
                '0060X00000edvWNQAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'December 2019',
                '2019-12-09T13:59:28.000Z',
                'Closed Won',
                '0060X00000edvweQAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'December 2019',
                '2019-12-10T14:13:45.000Z',
                'Closed Won',
                '0060X00000edyjWQAQ',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'December 2019',
                '2019-12-10T16:45:19.000Z',
                'Closed Lost',
                '0060X00000edzJbQAI',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'December 2019',
                '2019-12-11T12:03:29.000Z',
                'Closed Lost',
                '0060X00000ee14CQAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'December 2019',
                '2019-12-11T16:10:09.000Z',
                'Closed Won',
                '0060X00000ee1wqQAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'December 2019',
                '2019-12-12T13:46:46.000Z',
                'Closed Lost',
                '0060X00000ee45UQAQ',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO COLMAR - COLMAR',
            ],
            [
                'December 2019',
                '2019-12-20T10:03:55.000Z',
                'Closed Lost',
                '0060X00000eeJj4QAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES',
            ],
            [
                'January 2020',
                '2020-01-02T14:31:21.000Z',
                'Closed Lost',
                '0060X00000ie4W9QAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'January 2020',
                '2020-01-06T09:34:28.000Z',
                'Closed Lost',
                '0060X00000ie7y6QAA',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                "Volvo -  FIRST AUTOMOBILES - VILLENEUVE D'ASCQ",
            ],
            [
                'January 2020',
                '2020-01-09T09:19:00.000Z',
                'Closed Won',
                '0060X00000ieFriQAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'January 2020',
                '2020-01-13T08:31:06.000Z',
                'Closed Won',
                '0060X00000ieL4WQAU',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'January 2020',
                '2020-01-13T08:39:21.000Z',
                'Closed Won',
                '0060X00000ieL6hQAE',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'January 2020',
                '2020-01-14T13:14:07.000Z',
                'Closed Won',
                '0060X00000ieP0qQAE',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - VERSAILLES',
            ],
            [
                'January 2020',
                '2020-01-20T16:06:34.000Z',
                'Closed Lost',
                '0060X00000ieb8NQAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'January 2020',
                '2020-01-27T13:47:27.000Z',
                'Closed Won',
                '0060X00000iepFnQAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'January 2020',
                '2020-01-30T08:33:07.000Z',
                'Closed Won',
                '0060X00000iewAJQAY',
                'Typeform',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'January 2020',
                '2020-01-30T11:20:48.000Z',
                'Closed Won',
                '0060X00000iewrgQAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'January 2020',
                '2020-01-30T17:07:51.000Z',
                'Closed Lost',
                '0060X00000ieyElQAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'February 2020',
                '2020-02-05T08:13:19.000Z',
                'Closed Won',
                '0060X00000if9JUQAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES PAU - LESCAR',
            ],
            [
                'February 2020',
                '2020-02-11T12:26:44.000Z',
                'Closed Won',
                '0060X00000ifLfYQAU',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'June 2020',
                '2020-06-17T14:07:15.000Z',
                'Closed Won',
                '0060X00000ihVhKQAU',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'June 2020',
                '2020-06-17T16:31:15.000Z',
                'Closed Won',
                '0060X00000ihVuyQAE',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'June 2020',
                '2020-06-18T07:23:47.000Z',
                'Closed Lost',
                '0060X00000ihW95QAE',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PRESTIGE CARS - AUBIERE (CLERMONT FERRAND)',
            ],
            [
                'June 2020',
                '2020-06-25T09:53:28.000Z',
                'Closed Lost',
                '0060X00000ihd2bQAA',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT CHARTRES - NOGENT LE PHAYE (CHARTRES)',
            ],
            [
                'June 2020',
                '2020-06-25T10:06:58.000Z',
                'Closed Won',
                '0060X00000ihd3tQAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'June 2020',
                '2020-06-29T08:02:55.000Z',
                'Closed Lost',
                '0060X00000ihfR4QAI',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'June 2020',
                '2020-06-29T13:58:56.000Z',
                'Closed Lost',
                '0060X00000ihqy5QAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - BORGO',
            ],
            [
                'June 2020',
                '2020-06-30T08:27:07.000Z',
                'Closed Lost',
                '0060X00000ihrUTQAY',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  FELIX FAURE GRENOBLE - SEYSINNET - PARISET (GRENOBLE)',
            ],
            [
                'July 2020',
                '2020-07-02T14:40:22.000Z',
                'Closed Lost',
                '0060X00000ihvAgQAI',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'July 2020',
                '2020-07-03T07:16:04.000Z',
                'Closed Lost',
                '0060X00000ihvZGQAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'July 2020',
                '2020-07-03T13:11:01.000Z',
                'Closed Won',
                '0060X00000ihw4nQAA',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'July 2020',
                '2020-07-08T12:17:28.000Z',
                'Closed Lost',
                '0060X00000ihzYLQAY',
                'Direct',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'July 2020',
                '2020-07-08T12:19:18.000Z',
                'Closed Lost',
                '0060X00000ihzYHQAY',
                'Direct',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'July 2020',
                '2020-07-08T12:21:34.000Z',
                'Closed Lost',
                '0060X00000ihzYaQAI',
                'Direct',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'July 2020',
                '2020-07-09T06:44:39.000Z',
                'Closed Won',
                '0060X00000ii080QAA',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'July 2020',
                '2020-07-09T06:44:55.000Z',
                'Closed Won',
                '0060X00000ii08AQAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'July 2020',
                '2020-07-09T15:42:23.000Z',
                'Closed Lost',
                '0060X00000ii0vfQAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'July 2020',
                '2020-07-10T08:28:00.000Z',
                'Closed Lost',
                '0060X00000ii1XEQAY',
                'Typeform Volvo',
                'Belgium',
                'Company',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-13T15:31:04.000Z',
                'Closed Lost',
                '0060X00000ii3caQAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-17T07:36:14.000Z',
                'Closed Lost',
                '0060X00000ii8IbQAI',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'July 2020',
                '2020-07-17T07:36:19.000Z',
                'Closed Lost',
                '0060X00000ii8IgQAI',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'July 2020',
                '2020-07-17T14:41:42.000Z',
                'Closed Lost',
                '0060X00000ii8rJQAQ',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'July 2020',
                '2020-07-20T08:05:17.000Z',
                'Closed Won',
                '0060X00000iiAImQAM',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-20T08:30:46.000Z',
                'Standby',
                '0060X00000iiAKzQAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'July 2020',
                '2020-07-21T08:01:01.000Z',
                'Closed Won',
                '0060X00000iiBNZQA2',
                'Direct',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-27T07:42:43.000Z',
                'Closed Lost',
                '0060X00000mhp4JQAQ',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'July 2020',
                '2020-07-27T07:49:58.000Z',
                'Closed Lost',
                '0060X00000mhp4sQAA',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-27T09:18:13.000Z',
                'Closed Won',
                '0060X00000mhpCnQAI',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE',
            ],
            [
                'July 2020',
                '2020-07-28T13:08:27.000Z',
                'Closed Won',
                '0060X00000mhquWQAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'July 2020',
                '2020-07-29T11:48:09.000Z',
                'Closed Lost',
                '0060X00000mhruZQAQ',
                'Direct',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-30T06:50:05.000Z',
                'Waiting for Quote',
                '0060X00000mhsQlQAI',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'August 2020',
                '2020-08-03T13:07:46.000Z',
                'Closed Lost',
                '0060X00000mhv1pQAA',
                'Typeform',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2020',
                '2020-08-04T15:00:27.000Z',
                'Closed Won',
                '0060X00000mhwCqQAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2020',
                '2020-08-04T15:30:18.000Z',
                'Closed Lost',
                '0060X00000mhwFTQAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2020',
                '2020-08-04T16:07:56.000Z',
                'Closed Lost',
                '0060X00000mhwJaQAI',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'August 2020',
                '2020-08-05T08:33:11.000Z',
                'Closed Won',
                '0060X00000mhwZkQAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2020',
                '2020-08-05T08:45:37.000Z',
                'Closed Lost',
                '0060X00000mhwbsQAA',
                'Typeform Volvo',
                'Belgium',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2020',
                '2020-08-05T13:51:11.000Z',
                'Closed Won',
                '0060X00000mhx3aQAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2020',
                '2020-08-10T14:03:37.000Z',
                'Closed Won',
                '0060X00000mi05YQAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2020',
                '2020-08-14T09:08:38.000Z',
                'Closed Lost',
                '0060X00000mi2m9QAA',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'August 2020',
                '2020-08-17T06:40:18.000Z',
                'Closed Won',
                '0060X00000mi3ikQAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2020',
                '2020-08-17T15:45:10.000Z',
                'Closed Lost',
                '0060X00000mi4SGQAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2020',
                '2020-08-24T09:49:01.000Z',
                'Standby',
                '0060X00000miATwQAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'August 2020',
                '2020-08-27T07:12:39.000Z',
                'Closed Lost',
                '0060X00000miDTeQAM',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'August 2020',
                '2020-08-27T07:30:09.000Z',
                'Closed Won',
                '0060X00000miDUbQAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'August 2020',
                '2020-08-27T14:29:17.000Z',
                'Closed Lost',
                '0060X00000miEGCQA2',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2020',
                '2020-08-27T15:09:02.000Z',
                'Closed Won',
                '0060X00000miEKeQAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'August 2020',
                '2020-08-27T16:28:52.000Z',
                'Closed Won',
                '0060X00000miEQtQAM',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'August 2020',
                '2020-08-27T16:56:30.000Z',
                'Closed Won',
                '0060X00000miEW9QAM',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'September 2020',
                '2020-09-01T08:34:15.000Z',
                'Closed Lost',
                '0060X00000miHqqQAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'September 2020',
                '2020-09-01T13:36:05.000Z',
                'Closed Lost',
                '0060X00000miISXQA2',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2020',
                '2020-09-07T15:24:07.000Z',
                'Closed Lost',
                '0060X00000miO6XQAU',
                'Typeform Volvo',
                'Belgium',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2020',
                '2020-09-08T11:26:21.000Z',
                'Closed Won',
                '0060X00000miOxmQAE',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'September 2020',
                '2020-09-08T13:07:51.000Z',
                'Closed Lost',
                '0060X00000miP7IQAU',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'September 2020',
                '2020-09-10T17:32:25.000Z',
                'Closed Lost',
                '0060X00000miSjIQAU',
                'Phone',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'September 2020',
                '2020-09-10T18:11:15.000Z',
                'Closed Won',
                '0060X00000miSkaQAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2020',
                '2020-09-15T15:13:00.000Z',
                'Closed Won',
                '0060X00000miXxEQAU',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'September 2020',
                '2020-09-17T09:34:57.000Z',
                'Closed Lost',
                '0060X00000mia7fQAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'September 2020',
                '2020-09-21T07:01:50.000Z',
                'Closed Lost',
                '0066700000micdGAAQ',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'September 2020',
                '2020-09-22T08:49:17.000Z',
                'Closed Won',
                '0066700000mieG4AAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2020',
                '2020-09-22T13:01:52.000Z',
                'Closed Won',
                '0066700000miettAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2020',
                '2020-09-22T16:42:09.000Z',
                'Closed Lost',
                '0066700000mifL2AAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2020',
                '2020-09-23T16:25:00.000Z',
                'Closed Won',
                '0066700000migeRAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2020',
                '2020-09-24T06:52:46.000Z',
                'Closed Won',
                '0066700000migsOAAQ',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'September 2020',
                '2020-09-24T15:55:48.000Z',
                'Closed Won',
                '0066700000mii5gAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'September 2020',
                '2020-09-25T17:06:19.000Z',
                'Closed Won',
                '0066700000mikFsAAI',
                'Direct',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'September 2020',
                '2020-09-28T07:33:03.000Z',
                'Closed Won',
                '0066700000mimySAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2020',
                '2020-09-28T08:58:45.000Z',
                'Standby',
                '0066700000min72AAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - VERSAILLES',
            ],
            [
                'September 2020',
                '2020-09-28T13:28:48.000Z',
                'Closed Lost',
                '0066700000minlDAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'September 2020',
                '2020-09-29T10:06:56.000Z',
                'Closed Lost',
                '0066700000miosHAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2020',
                '2020-09-29T10:16:52.000Z',
                'Closed Lost',
                '0066700000miottAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'October 2020',
                '2020-10-02T07:52:11.000Z',
                'Closed Lost',
                '0066700000misYBAAY',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'October 2020',
                '2020-10-02T14:03:43.000Z',
                'Closed Lost',
                '0066700000mit7DAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2020',
                '2020-10-05T15:17:10.000Z',
                'Closed Won',
                '0066700000mivDnAAI',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'October 2020',
                '2020-10-06T07:39:09.000Z',
                'Closed Won',
                '0066700000mivjSAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'October 2020',
                '2020-10-06T12:07:31.000Z',
                'Closed Won',
                '0066700000miwEgAAI',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'October 2020',
                '2020-10-12T08:17:10.000Z',
                'Closed Lost',
                '0066700000mj457AAA',
                'Typeform Volvo',
                'Belgium',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'October 2020',
                '2020-10-13T08:33:13.000Z',
                'Closed Won',
                '0066700000mj64lAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'October 2020',
                '2020-10-13T16:51:06.000Z',
                'Closed Won',
                '0066700000mj7gJAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'October 2020',
                '2020-10-16T11:58:32.000Z',
                'Closed Lost',
                '0066700000mjPSuAAM',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'October 2020',
                '2020-10-19T09:59:49.000Z',
                'Closed Won',
                '0066700000mjSBwAAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'October 2020',
                '2020-10-21T08:58:56.000Z',
                'Closed Lost',
                '0066700000mjUwSAAU',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  FELIX FAURE AUTOMOBILES - VILLEFRANCHE-SUR-SAÔNE',
            ],
            [
                'October 2020',
                '2020-10-22T07:48:01.000Z',
                'Closed Lost',
                '0066700000mjXecAAE',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'October 2020',
                '2020-10-23T10:44:31.000Z',
                'Closed Lost',
                '0066700000mjaK8AAI',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'October 2020',
                '2020-10-27T08:48:32.000Z',
                'Closed Lost',
                '0066700000mje9JAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2020',
                '2020-10-27T14:49:59.000Z',
                'Closed Won',
                '0066700000mjeoLAAQ',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'October 2020',
                '2020-10-30T10:09:38.000Z',
                'Closed Won',
                '0066700000mji5UAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'October 2020',
                '2020-10-30T11:41:36.000Z',
                'Closed Lost',
                '0066700000mjiEmAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'November 2020',
                '2020-11-01T14:16:00.000Z',
                'Closed Won',
                '0066700000mjkHVAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'November 2020',
                '2020-11-06T09:47:28.000Z',
                'Closed Won',
                '0066700000mjqdqAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'November 2020',
                '2020-11-11T14:38:34.000Z',
                'Closed Lost',
                '0066700000mjvRGAAY',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'November 2020',
                '2020-11-12T08:14:01.000Z',
                'Closed Lost',
                '0066700000mjvt2AAA',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'November 2020',
                '2020-11-12T16:31:28.000Z',
                'Closed Lost',
                '0066700000mjyK0AAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'November 2020',
                '2020-11-17T09:23:39.000Z',
                'Closed Lost',
                '0066700000mk2WCAAY',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'November 2020',
                '2020-11-18T13:35:11.000Z',
                'Closed Lost',
                '0066700000mk5gTAAQ',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'November 2020',
                '2020-11-19T08:41:53.000Z',
                'Closed Won',
                '0066700000mk6WrAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2020',
                '2020-11-25T13:43:20.000Z',
                'Closed Lost',
                '0066700000mkE6UAAU',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'November 2020',
                '2020-11-25T14:09:13.000Z',
                'Closed Lost',
                '0066700000mkE9nAAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'November 2020',
                '2020-11-25T15:52:12.000Z',
                'Closed Lost',
                '0066700000mkELGAA2',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'November 2020',
                '2020-11-26T09:25:21.000Z',
                'Closed Won',
                '0066700000mkEuXAAU',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2020',
                '2020-11-30T08:51:26.000Z',
                'Closed Lost',
                '0066700000mkI1FAAU',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'November 2020',
                '2020-11-30T09:15:27.000Z',
                'Closed Lost',
                '0066700000mkI4FAAU',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'November 2020',
                '2020-11-30T14:35:42.000Z',
                'Closed Lost',
                '0066700000mkJ7qAAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2020',
                '2020-12-02T09:51:22.000Z',
                'Closed Won',
                '0066700000mkLqWAAU',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'December 2020',
                '2020-12-03T14:40:18.000Z',
                'Closed Won',
                '0066700000mkO0HAAU',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'December 2020',
                '2020-12-04T15:36:16.000Z',
                'Closed Won',
                '0066700000mkPZrAAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2020',
                '2020-12-07T15:34:08.000Z',
                'Closed Won',
                '0066700000mkRRMAA2',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'December 2020',
                '2020-12-09T17:03:08.000Z',
                'Standby',
                '0066700000mkU7NAAU',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'December 2020',
                '2020-12-11T16:46:55.000Z',
                'Closed Won',
                '0066700000mkWYvAAM',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'December 2020',
                '2020-12-16T10:57:12.000Z',
                'Closed Won',
                '0066700000mkbPeAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2020',
                '2020-12-17T12:58:20.000Z',
                'Closed Won',
                '0066700000mkdXQAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - REDON',
            ],
            [
                'December 2020',
                '2020-12-21T13:27:53.000Z',
                'Closed Won',
                '0066700000mkgZMAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2020',
                '2020-12-21T16:45:09.000Z',
                'Closed Lost',
                '0066700000mkgwyAAA',
                'Phone',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'December 2020',
                '2020-12-21T17:47:00.000Z',
                'Closed Lost',
                '0066700000mkhWSAAY',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'December 2020',
                '2020-12-21T19:50:38.000Z',
                'Closed Lost',
                '0066700000mki4KAAQ',
                'Email',
                'France',
                'Company',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'December 2020',
                '2020-12-29T14:59:20.000Z',
                'Closed Won',
                '0066700000mknTkAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'December 2020',
                '2020-12-29T18:03:04.000Z',
                'Closed Won',
                '0066700000mkndkAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'January 2021',
                '2021-01-04T08:44:53.000Z',
                'Closed Lost',
                '0066700000mkrQ8AAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'January 2021',
                '2021-01-04T16:30:24.000Z',
                'New',
                '0066700000mksMpAAI',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)',
            ],
            [
                'January 2021',
                '2021-01-05T10:55:31.000Z',
                'Closed Lost',
                '0066700000mksr2AAA',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT CHARTRES - NOGENT LE PHAYE (CHARTRES)',
            ],
            [
                'January 2021',
                '2021-01-05T11:11:45.000Z',
                'Closed Won',
                '0066700000mkstDAAQ',
                'Typeform',
                'France',
                'Single Home',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            [
                'January 2021',
                '2021-01-06T18:25:54.000Z',
                'Closed Won',
                '0066700000mkuAoAAI',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'January 2021',
                '2021-01-11T11:25:02.000Z',
                'Closed Won',
                '0066700000ml2d5AAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'January 2021',
                '2021-01-11T16:19:47.000Z',
                'Closed Lost',
                '0066700000ml3MLAAY',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'January 2021',
                '2021-01-11T17:08:56.000Z',
                'Closed Won',
                '0066700000ml3QqAAI',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'January 2021',
                '2021-01-18T18:26:51.000Z',
                'Closed Won',
                '0066700000mlF3sAAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'January 2021',
                '2021-01-19T07:24:54.000Z',
                'Closed Lost',
                '0066700000mlFOXAA2',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'January 2021',
                '2021-01-19T14:29:30.000Z',
                'Waiting for Quote',
                '0066700000mlGGtAAM',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'January 2021',
                '2021-01-21T08:29:41.000Z',
                'Closed Won',
                '0066700000mlI3bAAE',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'January 2021',
                '2021-01-25T09:52:09.000Z',
                'Closed Won',
                '0066700000mlKr2AAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'January 2021',
                '2021-01-28T18:38:47.000Z',
                'Closed Won',
                '0066700000mlPTdAAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            [
                'January 2021',
                '2021-01-29T08:17:37.000Z',
                'Closed Won',
                '0066700000mlWBXAA2',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - VERSAILLES',
            ],
            [
                'February 2021',
                '2021-02-02T08:09:15.000Z',
                'Closed Lost',
                '0066700000mlZ9hAAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'February 2021',
                '2021-02-03T10:28:41.000Z',
                'Closed Lost',
                '0066700000mlaLjAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'February 2021',
                '2021-02-03T18:58:54.000Z',
                'Closed Won',
                '0066700000mlb1LAAQ',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'February 2021',
                '2021-02-08T11:04:25.000Z',
                'Closed Won',
                '0066700000mlepXAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2021',
                '2021-02-08T17:57:13.000Z',
                'Closed Won',
                '0066700000mlfPsAAI',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'February 2021',
                '2021-02-08T18:47:35.000Z',
                'Closed Won',
                '0066700000mlfRAAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'February 2021',
                '2021-02-08T19:04:32.000Z',
                'Closed Won',
                '0066700000mlfRUAAY',
                'Typeform',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'February 2021',
                '2021-02-09T08:08:10.000Z',
                'Closed Lost',
                '0066700000mlfZxAAI',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'February 2021',
                '2021-02-10T15:07:26.000Z',
                'Closed Lost',
                '0066700000mlhRkAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'February 2021',
                '2021-02-10T16:31:51.000Z',
                'Standby',
                '0066700000mlhawAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'February 2021',
                '2021-02-11T10:18:16.000Z',
                'Closed Won',
                '0066700000mli1nAAA',
                'Zeplug',
                'France',
                'Single Home',
                'Volvo -  PRESTIGE CARS - AUBIERE (CLERMONT FERRAND)',
            ],
            [
                'February 2021',
                '2021-02-11T10:40:26.000Z',
                'Closed Won',
                '0066700000mli4hAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2021',
                '2021-02-12T08:28:00.000Z',
                'Closed Lost',
                '0066700000mliwQAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'February 2021',
                '2021-02-15T10:52:15.000Z',
                'Closed Lost',
                '0066700000mllLDAAY',
                'Typeform',
                'France',
                'Company',
                'Volvo -  DELHORBE AUTOMOBILES - SAINT MARTIN SUR LE PRE (CHALONS EN CHAMPAGNE)',
            ],
            [
                'February 2021',
                '2021-02-15T11:05:18.000Z',
                'Closed Lost',
                '0066700000mllMQAAY',
                'Typeform',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - SAINT MARTIN SUR LE PRE (CHALONS EN CHAMPAGNE)',
            ],
            [
                'February 2021',
                '2021-02-16T08:49:19.000Z',
                'Closed Lost',
                '0066700000mlmdfAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'February 2021',
                '2021-02-17T08:10:57.000Z',
                'Standby',
                '0066700000mlnrZAAQ',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'February 2021',
                '2021-02-17T15:14:13.000Z',
                'Closed Won',
                '0066700000mlocfAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'February 2021',
                '2021-02-22T08:29:46.000Z',
                'Closed Lost',
                '0066700000mlrv9AAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES',
            ],
            [
                'February 2021',
                '2021-02-22T09:45:28.000Z',
                'Closed Won',
                '0066700000mls5OAAQ',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'February 2021',
                '2021-02-22T10:04:57.000Z',
                'Closed Won',
                '0066700000mls70AAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2021',
                '2021-02-23T13:47:32.000Z',
                'Closed Won',
                '0066700000mluquAAA',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'February 2021',
                '2021-02-26T08:08:10.000Z',
                'Closed Lost',
                '0066700000mlyvkAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'March 2021',
                '2021-03-05T15:41:26.000Z',
                'Standby',
                '0066700000pt9eEAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2021',
                '2021-03-09T18:49:43.000Z',
                'Closed Won',
                '0066700000ptDAoAAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2021',
                '2021-03-11T13:54:57.000Z',
                'Closed Won',
                '0066700000ptFWZAA2',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'March 2021',
                '2021-03-15T09:11:37.000Z',
                'Closed Lost',
                '0066700000ptHiiAAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2021',
                '2021-03-15T09:23:23.000Z',
                'Closed Lost',
                '0066700000ptHk5AAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'March 2021',
                '2021-03-19T09:54:48.000Z',
                'Closed Won',
                '0066700000ptOELAA2',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'March 2021',
                '2021-03-19T14:07:41.000Z',
                'Closed Won',
                '0066700000ptOaMAAU',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'March 2021',
                '2021-03-22T13:20:07.000Z',
                'Closed Lost',
                '0066700000ptQQHAA2',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'March 2021',
                '2021-03-23T08:53:18.000Z',
                'Closed Lost',
                '0066700000ptRZAAA2',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'March 2021',
                '2021-03-24T08:07:52.000Z',
                'Closed Lost',
                '0066700000ptSYaAAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'March 2021',
                '2021-03-25T13:37:58.000Z',
                'Closed Won',
                '0066700000ptV40AAE',
                'Email',
                'France',
                'Single Home',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            [
                'March 2021',
                '2021-03-29T08:23:29.000Z',
                'Closed Won',
                '0066700000ptX6OAAU',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'March 2021',
                '2021-03-30T15:31:50.000Z',
                'Closed Won',
                '0066700000ptYgNAAU',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'March 2021',
                '2021-03-31T16:32:26.000Z',
                'Closed Won',
                '0066700000ptZcdAAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'April 2021',
                '2021-04-02T09:54:40.000Z',
                'Closed Lost',
                '0066700000ptb3OAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'April 2021',
                '2021-04-02T13:31:50.000Z',
                'Closed Lost',
                '0066700000ptbAAAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)',
            ],
            [
                'April 2021',
                '2021-04-06T06:55:50.000Z',
                'Closed Won',
                '0066700000ptcIRAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'April 2021',
                '2021-04-07T12:38:13.000Z',
                'Closed Lost',
                '0066700000pte1TAAQ',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'April 2021',
                '2021-04-07T13:13:21.000Z',
                'Quote sent • Negotiation',
                '0066700000pte4NAAQ',
                'Typeform',
                'France',
                'Company',
                'Volvo -  DELHORBE AUTOMOBILES - DIZY (EPERNAY)',
            ],
            [
                'April 2021',
                '2021-04-08T15:24:31.000Z',
                'Closed Lost',
                '0066700000ptfHmAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'April 2021',
                '2021-04-09T14:38:48.000Z',
                'Closed Lost',
                '0066700000ptg1fAAA',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'April 2021',
                '2021-04-13T16:22:31.000Z',
                'Standby',
                '0066700000ptk1aAAA',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'April 2021',
                '2021-04-14T13:48:03.000Z',
                'Closed Lost',
                '0066700000ptl3fAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'April 2021',
                '2021-04-14T16:03:29.000Z',
                'Closed Lost',
                '0066700000ptlHDAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'April 2021',
                '2021-04-14T16:42:25.000Z',
                'Closed Lost',
                '0066700000ptlJnAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'April 2021',
                '2021-04-16T07:39:09.000Z',
                'Standby',
                '0066700000ptmX0AAI',
                'Direct',
                'France',
                'Company',
                'Volvo car France',
            ],
            [
                'April 2021',
                '2021-04-16T07:39:37.000Z',
                'Standby',
                '0066700000ptmX5AAI',
                'Other',
                'France',
                'Company',
                'Volvo car France',
            ],
            [
                'April 2021',
                '2021-04-19T13:57:11.000Z',
                'Closed Lost',
                '0066700000ptp47AAA',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'April 2021',
                '2021-04-21T18:29:27.000Z',
                'Closed Lost',
                '0066700000ptsvmAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'April 2021',
                '2021-04-23T15:25:05.000Z',
                'Quote sent • Negotiation',
                '0066700000ptuqkAAA',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'April 2021',
                '2021-04-27T07:47:57.000Z',
                'Closed Won',
                '0066700000ptwuiAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'April 2021',
                '2021-04-28T20:15:36.000Z',
                'Closed Lost',
                '0066700000ptyvZAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'April 2021',
                '2021-04-29T16:30:19.000Z',
                'Closed Lost',
                '0066700000pu0RiAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'April 2021',
                '2021-04-30T13:45:04.000Z',
                'Closed Lost',
                '0066700000pu19YAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'May 2021',
                '2021-05-03T12:17:29.000Z',
                'Closed Lost',
                '0066700000pu27uAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS (11ème) - PARIS',
            ],
            [
                'May 2021',
                '2021-05-03T13:49:13.000Z',
                'Closed Lost',
                '0066700000pu2HzAAI',
                'Email',
                'France',
                'Company',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'May 2021',
                '2021-05-04T21:11:08.000Z',
                'Closed Won',
                '0066700000pu45HAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'May 2021',
                '2021-05-05T17:08:08.000Z',
                'Closed Won',
                '0066700000pu59yAAA',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'May 2021',
                '2021-05-07T06:43:32.000Z',
                'Closed Lost',
                '0066700000pu6LCAAY',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'May 2021',
                '2021-05-07T16:41:30.000Z',
                'Standby',
                '0066700000pu77fAAA',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'May 2021',
                '2021-05-10T06:39:21.000Z',
                'Closed Won',
                '0066700000pu7rTAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'May 2021',
                '2021-05-10T07:25:37.000Z',
                'Closed Lost',
                '0066700000pu7toAAA',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'May 2021',
                '2021-05-10T13:22:42.000Z',
                'Closed Won',
                '0066700000pu8S0AAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'May 2021',
                '2021-05-11T12:29:29.000Z',
                'Closed Won',
                '0066700000pu9QsAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2021',
                '2021-05-17T15:13:02.000Z',
                'Closed Won',
                '0066700000puEdhAAE',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'May 2021',
                '2021-05-20T07:19:30.000Z',
                'Closed Lost',
                '0066700000puH6IAAU',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'May 2021',
                '2021-05-20T09:20:30.000Z',
                'Closed Lost',
                '0066700000puHFjAAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - BORGO',
            ],
            [
                'May 2021',
                '2021-05-20T10:00:49.000Z',
                'Closed Lost',
                '0066700000puHJCAA2',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI',
            ],
            [
                'May 2021',
                '2021-05-20T10:04:35.000Z',
                'Closed Lost',
                '0066700000puHJgAAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI',
            ],
            [
                'May 2021',
                '2021-05-28T07:12:09.000Z',
                'Closed Lost',
                '0066700000puOuQAAU',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'June 2021',
                '2021-06-04T07:42:29.000Z',
                'Closed Lost',
                '0066700000puUAPAA2',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'June 2021',
                '2021-06-04T08:23:08.000Z',
                'Closed Won',
                '0066700000puUD9AAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2021',
                '2021-06-08T10:01:46.000Z',
                'Closed Lost',
                '0066700000puWouAAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VILLEFRANCHE-SUR-SAÔNE',
            ],
            [
                'June 2021',
                '2021-06-10T15:26:02.000Z',
                'Closed Won',
                '0066700000puZYNAA2',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2021',
                '2021-06-16T10:54:32.000Z',
                'Closed Won',
                '0066700000pueZjAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'June 2021',
                '2021-06-17T10:09:13.000Z',
                'Closed Lost',
                '0066700000pugdPAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'June 2021',
                '2021-06-17T16:12:07.000Z',
                'Closed Won',
                '0066700000puhGKAAY',
                'Email',
                'France',
                'Company',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'June 2021',
                '2021-06-30T09:16:49.000Z',
                'Closed Lost',
                '0066700000puqmcAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2021',
                '2021-07-01T13:44:10.000Z',
                'Closed Won',
                '0066700000pusS4AAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'July 2021',
                '2021-07-01T14:39:16.000Z',
                'Closed Lost',
                '0066700000pusmTAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2021',
                '2021-07-02T07:45:27.000Z',
                'Closed Lost',
                '0066700000put51AAA',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'July 2021',
                '2021-07-07T06:01:49.000Z',
                'Closed Lost',
                '0066700000puvj8AAA',
                'Zeplug',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'July 2021',
                '2021-07-13T09:23:00.000Z',
                'Closed Won',
                '0066700000puzzBAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'July 2021',
                '2021-07-15T07:32:55.000Z',
                'Closed Won',
                '0066700000pv1gRAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'July 2021',
                '2021-07-16T09:55:11.000Z',
                'Closed Lost',
                '0066700000pv3K8AAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES PAU - LESCAR',
            ],
            [
                'July 2021',
                '2021-07-19T09:20:19.000Z',
                'Closed Won',
                '0066700000pv4O6AAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'July 2021',
                '2021-07-21T08:19:42.000Z',
                'Closed Lost',
                '0066700000pv6RKAAY',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'July 2021',
                '2021-07-23T09:17:42.000Z',
                'Quote sent • Negotiation',
                '0066700000pv8jAAAQ',
                'Direct',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'July 2021',
                '2021-07-27T09:48:18.000Z',
                'Closed Won',
                '0066700000pvBTmAAM',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'July 2021',
                '2021-07-29T10:28:00.000Z',
                'Closed Won',
                '0066700000pvDUAAA2',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'July 2021',
                '2021-07-30T12:55:11.000Z',
                'Closed Lost',
                '0066700000pvEFPAA2',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'August 2021',
                '2021-08-09T08:34:55.000Z',
                'Closed Lost',
                '0066700000pvJyQAAU',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2021',
                '2021-08-11T16:45:22.000Z',
                'Closed Won',
                '0066700000pvLlrAAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2021',
                '2021-08-13T07:38:19.000Z',
                'Closed Won',
                '0066700000pvMr2AAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2021',
                '2021-08-13T16:12:33.000Z',
                'Closed Lost',
                '0066700000pvNsAAAU',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'August 2021',
                '2021-08-18T07:50:20.000Z',
                'Closed Won',
                '0066700000rvkF4AAI',
                'Email',
                'France',
                'Company',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'August 2021',
                '2021-08-19T12:11:49.000Z',
                'Closed Lost',
                '0066700000rvlFpAAI',
                'Email',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'August 2021',
                '2021-08-26T09:44:32.000Z',
                'Closed Lost',
                '0066700000rvpbvAAA',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'August 2021',
                '2021-08-31T08:51:48.000Z',
                'Closed Lost',
                '0066700000rvsnEAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2021',
                '2021-08-31T13:47:50.000Z',
                'Closed Lost',
                '0066700000rvtIMAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'September 2021',
                '2021-09-01T16:06:14.000Z',
                'Closed Lost',
                '0066700000rvuXbAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2021',
                '2021-09-13T07:53:47.000Z',
                'Closed Won',
                '0066700000rw2ptAAA',
                'Email',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2021',
                '2021-09-15T09:11:54.000Z',
                'Closed Lost',
                '0066700000rw6QvAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2021',
                '2021-09-15T11:52:59.000Z',
                'Closed Lost',
                '0066700000rw6s6AAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2021',
                '2021-09-15T12:07:39.000Z',
                'Closed Won',
                '0066700000rw6uHAAQ',
                'Direct',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2021',
                '2021-09-16T13:53:02.000Z',
                'Closed Lost',
                '0066700000rw80eAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'September 2021',
                '2021-09-20T17:15:27.000Z',
                'Closed Won',
                '0066700000rwAg2AAE',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'September 2021',
                '2021-09-21T12:02:37.000Z',
                'Closed Lost',
                '0066700000rwB9xAAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'September 2021',
                '2021-09-22T07:36:06.000Z',
                'Closed Lost',
                '0066700000rwCDqAAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2021',
                '2021-09-23T15:23:27.000Z',
                'Closed Lost',
                '0066700000rwEVmAAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI',
            ],
            [
                'September 2021',
                '2021-09-26T20:31:52.000Z',
                'Standby',
                '0066700000rwGqhAAE',
                'Phone',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'September 2021',
                '2021-09-26T21:07:51.000Z',
                'Closed Won',
                '0066700000rwGqmAAE',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'September 2021',
                '2021-09-27T07:05:56.000Z',
                'Closed Won',
                '0066700000rwGz0AAE',
                'Phone',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2021',
                '2021-09-28T08:50:21.000Z',
                'Closed Won',
                '0066700000rwIAUAA2',
                'Phone',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2021',
                '2021-09-28T14:39:45.000Z',
                'Closed Lost',
                '0066700000rwIcgAAE',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE',
            ],
            [
                'September 2021',
                '2021-09-30T08:12:05.000Z',
                'Closed Lost',
                '0066700000rwKBxAAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'September 2021',
                '2021-09-30T09:29:39.000Z',
                'Closed Won',
                '0066700000rwKMpAAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2021',
                '2021-09-30T12:16:59.000Z',
                'Closed Won',
                '0066700000rwKdlAAE',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'October 2021',
                '2021-10-04T06:45:28.000Z',
                'Closed Lost',
                '0066700000rwNUaAAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'October 2021',
                '2021-10-04T09:05:35.000Z',
                'Closed Won',
                '0066700000rwNjuAAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'October 2021',
                '2021-10-05T07:53:43.000Z',
                'Closed Lost',
                '0066700000rwOnsAAE',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'October 2021',
                '2021-10-05T08:03:03.000Z',
                'Closed Won',
                '0066700000rwOpAAAU',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'October 2021',
                '2021-10-12T07:44:47.000Z',
                'Closed Lost',
                '0066700000rwWm5AAE',
                'Direct',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'October 2021',
                '2021-10-12T14:22:39.000Z',
                'Closed Lost',
                '0066700000rwXPqAAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2021',
                '2021-10-12T16:39:58.000Z',
                'Closed Won',
                '0066700000rwXatAAE',
                'Direct',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2021',
                '2021-10-13T10:21:25.000Z',
                'Closed Won',
                '0066700000rwZFsAAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'October 2021',
                '2021-10-13T17:23:18.000Z',
                'Closed Lost',
                '0066700000rwZroAAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'October 2021',
                '2021-10-15T10:06:32.000Z',
                'Closed Lost',
                '0066700000rwb3DAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'October 2021',
                '2021-10-18T07:33:58.000Z',
                'Closed Won',
                '0066700000rwcWzAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            [
                'October 2021',
                '2021-10-18T16:49:41.000Z',
                'Closed Won',
                '0066700000rwdOXAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'October 2021',
                '2021-10-20T08:40:00.000Z',
                'Closed Won',
                '0066700000rwgWfAAI',
                'Email',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2021',
                '2021-10-21T14:55:56.000Z',
                'Closed Won',
                '0066700000rwiXaAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'October 2021',
                '2021-10-21T15:24:38.000Z',
                'Closed Lost',
                '0066700000rwia5AAA',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'October 2021',
                '2021-10-21T16:30:46.000Z',
                'Closed Lost',
                '0066700000rwif5AAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)',
            ],
            [
                'October 2021',
                '2021-10-22T15:15:04.000Z',
                'Closed Lost',
                '0066700000rwk8iAAA',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'October 2021',
                '2021-10-22T16:15:25.000Z',
                'Closed Won',
                '0066700000rwkD9AAI',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'October 2021',
                '2021-10-25T15:39:30.000Z',
                'Closed Won',
                '0066700000rwlZ2AAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2021',
                '2021-10-25T17:11:12.000Z',
                'Closed Lost',
                '0066700000rwldwAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'October 2021',
                '2021-10-27T15:06:46.000Z',
                'Standby',
                '0066700000rwnREAAY',
                'Phone',
                'France',
                'Company',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'October 2021',
                '2021-10-27T16:08:23.000Z',
                'Closed Lost',
                '0066700000rwnXCAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2021',
                '2021-10-28T13:48:32.000Z',
                'Closed Won',
                '0066700000rwoSOAAY',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'October 2021',
                '2021-10-29T14:15:34.000Z',
                'Closed Lost',
                '0066700000rwpLnAAI',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'October 2021',
                '2021-10-29T14:48:11.000Z',
                'Closed Lost',
                '0066700000rwpNtAAI',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'October 2021',
                '2021-10-29T15:31:58.000Z',
                'Closed Lost',
                '0066700000rwpQYAAY',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'November 2021',
                '2021-11-02T10:30:38.000Z',
                'Closed Lost',
                '0066700000rwrA3AAI',
                'Email',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2021',
                '2021-11-02T11:12:52.000Z',
                'Closed Won',
                '0066700000rwrFDAAY',
                'Phone',
                'France',
                'Company',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'November 2021',
                '2021-11-03T08:50:37.000Z',
                'Closed Won',
                '0066700000rwsaMAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'November 2021',
                '2021-11-03T10:21:00.000Z',
                'Closed Lost',
                '0066700000rwt0oAAA',
                'Email',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'November 2021',
                '2021-11-03T11:44:35.000Z',
                'Closed Lost',
                '0066700000rwtA0AAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - Malcorps Wavre',
            ],
            [
                'November 2021',
                '2021-11-03T14:52:13.000Z',
                'Closed Won',
                '0066700000rwtivAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'November 2021',
                '2021-11-04T11:14:08.000Z',
                'Quote sent • Negotiation',
                '0066700000rwuSFAAY',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'November 2021',
                '2021-11-04T13:45:25.000Z',
                'Closed Won',
                '0066700000rwufTAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'November 2021',
                '2021-11-04T16:46:59.000Z',
                'Closed Lost',
                '0066700000rwuyLAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2021',
                '2021-11-08T16:13:47.000Z',
                'Closed Lost',
                '0066700000rwxn5AAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'November 2021',
                '2021-11-08T16:39:40.000Z',
                'Closed Won',
                '0066700000rwxpQAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'November 2021',
                '2021-11-08T17:36:26.000Z',
                'Closed Lost',
                '0066700000rwxrqAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'November 2021',
                '2021-11-09T12:43:07.000Z',
                'Closed Lost',
                '0066700000rwynvAAA',
                'Direct',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'November 2021',
                '2021-11-10T14:42:55.000Z',
                'Closed Won',
                '0066700000rx0pNAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            [
                'November 2021',
                '2021-11-10T16:05:50.000Z',
                'Closed Won',
                '0066700000rx0xHAAQ',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'November 2021',
                '2021-11-10T16:29:36.000Z',
                'Closed Lost',
                '0066700000rx0yoAAA',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'November 2021',
                '2021-11-15T09:49:00.000Z',
                'Closed Lost',
                '0066700000rx3upAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'November 2021',
                '2021-11-15T18:37:06.000Z',
                'Standby',
                '0066700000rx4tkAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - BORGO',
            ],
            [
                'November 2021',
                '2021-11-16T09:06:57.000Z',
                'Closed Lost',
                '0066700000rx55vAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'November 2021',
                '2021-11-16T16:17:46.000Z',
                'Closed Won',
                '0066700000rx5n9AAA',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'November 2021',
                '2021-11-16T17:55:33.000Z',
                'Closed Lost',
                '0066700000rx5vNAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'November 2021',
                '2021-11-18T09:06:52.000Z',
                'Closed Won',
                '0066700000rxBmKAAU',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'November 2021',
                '2021-11-19T17:04:51.000Z',
                'Closed Lost',
                '0066700000rxK9iAAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'November 2021',
                '2021-11-22T17:32:11.000Z',
                'Closed Lost',
                '0066700000rxLs5AAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - LIMOGES',
            ],
            [
                'November 2021',
                '2021-11-22T17:41:04.000Z',
                'Closed Won',
                '0066700000rxLsoAAE',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'November 2021',
                '2021-11-23T08:17:53.000Z',
                'Closed Lost',
                '0066700000rxM6HAAU',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2021',
                '2021-11-23T10:13:52.000Z',
                'Closed Won',
                '0066700000rxMXDAA2',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'November 2021',
                '2021-11-24T09:08:42.000Z',
                'Closed Won',
                '0066700000rxNUuAAM',
                'Email',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2021',
                '2021-11-24T11:20:43.000Z',
                'Closed Lost',
                '0066700000rxNjuAAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'November 2021',
                '2021-11-25T09:14:40.000Z',
                'Closed Lost',
                '0066700000rxOayAAE',
                'Email',
                'France',
                'Single Home',
                'Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI',
            ],
            [
                'November 2021',
                '2021-11-26T08:34:47.000Z',
                'Closed Lost',
                '0066700000rxPkcAAE',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'November 2021',
                '2021-11-29T15:58:14.000Z',
                'Closed Lost',
                '0066700000rxRtjAAE',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'November 2021',
                '2021-11-29T16:39:12.000Z',
                'Closed Lost',
                '0066700000rxRx2AAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'December 2021',
                '2021-12-01T18:14:58.000Z',
                'Closed Won',
                '0066700000rxUf3AAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'December 2021',
                '2021-12-02T10:02:17.000Z',
                'Closed Won',
                '0066700000rxUuDAAU',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2021',
                '2021-12-02T17:47:01.000Z',
                'Closed Lost',
                '0066700000rxVRzAAM',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'December 2021',
                '2021-12-02T17:56:21.000Z',
                'Closed Lost',
                '0066700000rxVSEAA2',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'December 2021',
                '2021-12-03T15:59:43.000Z',
                'Closed Won',
                '0066700000rxW4NAAU',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'December 2021',
                '2021-12-04T10:03:51.000Z',
                'Closed Lost',
                '0066700000rxWHuAAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE SAINT ETIENNE - SAINT ETIENNE',
            ],
            [
                'December 2021',
                '2021-12-06T09:12:18.000Z',
                'Closed Lost',
                '0066700000rxWoPAAU',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'December 2021',
                '2021-12-06T09:25:18.000Z',
                'Closed Lost',
                '0066700000rxWpNAAU',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'December 2021',
                '2021-12-06T15:47:13.000Z',
                'Closed Lost',
                '0066700000rxXPVAA2',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'December 2021',
                '2021-12-07T12:05:22.000Z',
                'Closed Lost',
                '0066700000rxY5CAAU',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  J.D. AUTOMOBILES - SAINT GEREON (ANCENIS)',
            ],
            [
                'December 2021',
                '2021-12-08T13:59:11.000Z',
                'Closed Lost',
                '0066700000rxZ5CAAU',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'December 2021',
                '2021-12-09T14:32:20.000Z',
                'Closed Lost',
                '0066700000rxa29AAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - BUCHELAY (MANTES LA JOLIE)',
            ],
            [
                'December 2021',
                '2021-12-09T15:22:10.000Z',
                'Closed Lost',
                '0066700000rxa6pAAA',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI',
            ],
            [
                'December 2021',
                '2021-12-09T17:19:32.000Z',
                'Closed Won',
                '0066700000rxaGBAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2021',
                '2021-12-09T17:48:59.000Z',
                'Closed Won',
                '0066700000rxaHTAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'December 2021',
                '2021-12-10T14:56:55.000Z',
                'Closed Lost',
                '0066700000rxayrAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'December 2021',
                '2021-12-10T15:55:34.000Z',
                'Closed Won',
                '0066700000rxb2yAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'December 2021',
                '2021-12-10T16:20:58.000Z',
                'Closed Lost',
                '0066700000rxb5JAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2021',
                '2021-12-14T10:30:15.000Z',
                'Closed Won',
                '0066700000rxdlhAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2021',
                '2021-12-14T13:44:31.000Z',
                'Closed Won',
                '0066700000rxe3OAAQ',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'December 2021',
                '2021-12-14T13:57:11.000Z',
                'Closed Won',
                '0066700000rxe4lAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'December 2021',
                '2021-12-15T15:36:33.000Z',
                'Closed Lost',
                '0066700000rxfL0AAI',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'December 2021',
                '2021-12-16T18:09:28.000Z',
                'Closed Won',
                '0066700000rxgUrAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'December 2021',
                '2021-12-17T07:51:34.000Z',
                'Closed Lost',
                '0066700000rxgd0AAA',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'December 2021',
                '2021-12-20T09:41:30.000Z',
                'Closed Lost',
                '0066700000rxitKAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'December 2021',
                '2021-12-20T10:44:55.000Z',
                'Closed Lost',
                '0066700000rxizDAAQ',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'December 2021',
                '2021-12-20T16:58:10.000Z',
                'Closed Lost',
                '0066700000rxjpGAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'December 2021',
                '2021-12-21T16:54:20.000Z',
                'Closed Won',
                '0066700000rxl1EAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'December 2021',
                '2021-12-21T17:10:34.000Z',
                'Closed Won',
                '0066700000rxl2HAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'December 2021',
                '2021-12-22T09:56:17.000Z',
                'Closed Lost',
                '0066700000rxlMvAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'December 2021',
                '2021-12-24T14:53:24.000Z',
                'Closed Won',
                '0066700000rxmyqAAA',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'December 2021',
                '2021-12-24T15:30:01.000Z',
                'Closed Lost',
                '0066700000rxmzAAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'December 2021',
                '2021-12-24T15:48:40.000Z',
                'Closed Lost',
                '0066700000rxmzZAAQ',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'December 2021',
                '2021-12-28T08:20:58.000Z',
                'Closed Lost',
                '0066700000rxnlYAAQ',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'December 2021',
                '2021-12-28T15:08:56.000Z',
                'Closed Lost',
                '0066700000rxo4LAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI',
            ],
            [
                'December 2021',
                '2021-12-28T16:43:06.000Z',
                'Closed Lost',
                '0066700000rxo8dAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES',
            ],
            [
                'December 2021',
                '2021-12-29T19:32:10.000Z',
                'Closed Won',
                '0066700000rxof2AAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'December 2021',
                '2021-12-30T09:28:00.000Z',
                'Closed Won',
                '0066700000rxopbAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'January 2022',
                '2022-01-03T17:08:09.000Z',
                'Standby',
                '0066700000rxqCIAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'January 2022',
                '2022-01-03T17:57:58.000Z',
                'Closed Won',
                '0066700000rxqEEAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            [
                'January 2022',
                '2022-01-04T09:39:43.000Z',
                'Closed Lost',
                '0066700000rxqT9AAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'January 2022',
                '2022-01-04T18:12:21.000Z',
                'Closed Lost',
                '0066700000rxr0lAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)',
            ],
            [
                'January 2022',
                '2022-01-06T17:02:43.000Z',
                'Closed Won',
                '0066700000rxsHOAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE SAINT ETIENNE - SAINT ETIENNE',
            ],
            [
                'January 2022',
                '2022-01-07T09:51:18.000Z',
                'Closed Lost',
                '0066700000rxsXwAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'January 2022',
                '2022-01-07T18:01:35.000Z',
                'Standby',
                '0066700000rxt1IAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'January 2022',
                '2022-01-10T14:14:38.000Z',
                'Closed Won',
                '0066700000rxuIWAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PREMIUM AUTOMOBILES - AUXERRE',
            ],
            [
                'January 2022',
                '2022-01-11T11:30:50.000Z',
                'Closed Won',
                '0066700000rxvIcAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'January 2022',
                '2022-01-11T16:23:43.000Z',
                'Closed Won',
                '0066700000rxvlQAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'January 2022',
                '2022-01-14T08:26:01.000Z',
                'Closed Won',
                '0066700000ryEvGAAU',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'January 2022',
                '2022-01-14T08:38:55.000Z',
                'Closed Lost',
                '0066700000ryEw9AAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'January 2022',
                '2022-01-14T18:51:55.000Z',
                'Closed Won',
                '0066700000ryFayAAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'January 2022',
                '2022-01-17T09:32:25.000Z',
                'Closed Lost',
                '0066700000ryGXAAA2',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'January 2022',
                '2022-01-17T15:36:49.000Z',
                'Closed Lost',
                '0066700000ryH4sAAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'January 2022',
                '2022-01-17T16:17:57.000Z',
                'Closed Won',
                '0066700000ryH99AAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'January 2022',
                '2022-01-18T07:30:25.000Z',
                'Closed Won',
                '0066700000ryI7sAAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'January 2022',
                '2022-01-18T10:55:17.000Z',
                'Closed Won',
                '0066700000ryINRAA2',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'January 2022',
                '2022-01-18T16:36:11.000Z',
                'Standby',
                '0066700000ryIs2AAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'January 2022',
                '2022-01-19T20:33:36.000Z',
                'Closed Lost',
                '0066700000ryKfoAAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO COLMAR - COLMAR',
            ],
            [
                'January 2022',
                '2022-01-20T15:25:52.000Z',
                'Closed Won',
                '0066700000ryLeAAAU',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CARS PREMIUM - CARCASSONNE',
            ],
            [
                'January 2022',
                '2022-01-21T08:25:10.000Z',
                'Closed Lost',
                '0066700000ryM8iAAE',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'January 2022',
                '2022-01-21T14:04:35.000Z',
                'Closed Won',
                '0066700000ryfqjAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'January 2022',
                '2022-01-24T12:05:24.000Z',
                'Closed Lost',
                '0066700000ryh6GAAQ',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'January 2022',
                '2022-01-24T12:32:05.000Z',
                'Standby',
                '0066700000ryh95AAA',
                'Form Volvo Employee FR',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'January 2022',
                '2022-01-24T13:49:02.000Z',
                'Closed Won',
                '0066700000ryhF8AAI',
                'Email',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'January 2022',
                '2022-01-24T16:16:40.000Z',
                'Closed Won',
                '0066700000ryhWiAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'January 2022',
                '2022-01-25T16:36:20.000Z',
                'Closed Lost',
                '0066700000ryiVRAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'January 2022',
                '2022-01-26T14:38:27.000Z',
                'Closed Won',
                '0066700000ryjERAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'January 2022',
                '2022-01-27T10:14:47.000Z',
                'Closed Won',
                '0066700000ryjo6AAA',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'January 2022',
                '2022-01-27T11:15:37.000Z',
                'Closed Lost',
                '0066700000ryjx2AAA',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - CHAMPNIERS (ANGOULEME)',
            ],
            [
                'January 2022',
                '2022-01-27T14:10:55.000Z',
                'Closed Lost',
                '0066700000ryk9hAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'January 2022',
                '2022-01-27T16:03:30.000Z',
                'Closed Won',
                '0066700000rykaYAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'February 2022',
                '2022-02-01T12:26:36.000Z',
                'Closed Won',
                '0066700000rynwMAAQ',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE',
            ],
            [
                'February 2022',
                '2022-02-01T12:37:17.000Z',
                'Closed Lost',
                '0066700000rynxUAAQ',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'February 2022',
                '2022-02-01T12:42:22.000Z',
                'Closed Won',
                '0066700000ryny8AAA',
                'Form Volvo Employee FR',
                'France',
                'Single Home',
                'ChargeGuru Business Services',
            ],
            [
                'February 2022',
                '2022-02-01T12:45:47.000Z',
                'Closed Lost',
                '0066700000rynyNAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'February 2022',
                '2022-02-01T15:27:29.000Z',
                'Closed Lost',
                '0066700000ryoEzAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'February 2022',
                '2022-02-03T08:09:17.000Z',
                'Standby',
                '0066700000rypXJAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PREMIUM AUTOMOBILES - AUXERRE',
            ],
            [
                'February 2022',
                '2022-02-04T11:25:14.000Z',
                'Closed Won',
                '0066700000ryrGaAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'February 2022',
                '2022-02-04T17:56:17.000Z',
                'Closed Won',
                '0066700000ryrgnAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2022',
                '2022-02-06T08:57:24.000Z',
                'Closed Lost',
                '0066700000rytf8AAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'February 2022',
                '2022-02-07T15:18:12.000Z',
                'Closed Lost',
                '0066700000ryv1eAAA',
                'Form Volvo Employee FR',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'February 2022',
                '2022-02-08T15:55:35.000Z',
                'Closed Won',
                '0066700000rywavAAA',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'February 2022',
                '2022-02-11T14:59:09.000Z',
                'Closed Lost',
                '0066700000rz0C3AAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'February 2022',
                '2022-02-11T16:48:15.000Z',
                'Closed Lost',
                '0066700000rz0LGAAY',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PERICAUD AUTOMOBILES - LIMOGES',
            ],
            [
                'February 2022',
                '2022-02-14T13:34:17.000Z',
                'Closed Won',
                '0066700000rz1PFAAY',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'February 2022',
                '2022-02-14T13:42:10.000Z',
                'Closed Lost',
                '0066700000rz1QNAAY',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'February 2022',
                '2022-02-14T16:12:17.000Z',
                'Closed Lost',
                '0066700000rz1gLAAQ',
                'Phone',
                'France',
                'Company',
                'Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI',
            ],
            [
                'February 2022',
                '2022-02-14T17:54:57.000Z',
                'Closed Lost',
                '0066700000rz1mjAAA',
                'Phone',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2022',
                '2022-02-14T18:13:29.000Z',
                'Closed Lost',
                '0066700000rz1nbAAA',
                'Phone',
                'France',
                'Company',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'February 2022',
                '2022-02-15T09:45:18.000Z',
                'Closed Lost',
                '0066700000rz2nXAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'February 2022',
                '2022-02-15T17:28:48.000Z',
                'Closed Lost',
                '0066700000rz3cQAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2022',
                '2022-02-15T18:14:57.000Z',
                'Closed Won',
                '0066700000rz3eXAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'February 2022',
                '2022-02-16T11:03:45.000Z',
                'Closed Won',
                '0066700000rz4RTAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'February 2022',
                '2022-02-16T13:27:20.000Z',
                'Closed Won',
                '0066700000rz4f6AAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2022',
                '2022-02-16T16:15:46.000Z',
                'Standby',
                '0066700000rz5C5AAI',
                'Form Volvo Employee FR',
                'France',
                'Multi Family Home',
                'Volvo Car France',
            ],
            [
                'February 2022',
                '2022-02-17T10:54:13.000Z',
                'Closed Lost',
                '0066700000rz5lzAAA',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'February 2022',
                '2022-02-17T14:10:29.000Z',
                'Closed Won',
                '0066700000rz61iAAA',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'February 2022',
                '2022-02-17T16:26:31.000Z',
                'Closed Lost',
                '0066700000uHlCuAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2022',
                '2022-02-17T17:34:49.000Z',
                'Closed Lost',
                '0066700000uHlGhAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2022',
                '2022-02-18T07:22:30.000Z',
                'Standby',
                '0066700000uHlPoAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2022',
                '2022-02-18T08:47:00.000Z',
                'Closed Lost',
                '0066700000uHlSxAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'February 2022',
                '2022-02-21T07:52:12.000Z',
                'Closed Won',
                '0066700000uHmf5AAC',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'February 2022',
                '2022-02-21T09:38:55.000Z',
                'Standby',
                '0066700000uHmoRAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'February 2022',
                '2022-02-21T10:53:55.000Z',
                'Closed Won',
                '0066700000uHmwuAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'February 2022',
                '2022-02-23T08:21:58.000Z',
                'Closed Won',
                '0066700000uHovOAAS',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'February 2022',
                '2022-02-23T09:30:40.000Z',
                'Closed Lost',
                '0066700000uHp3NAAS',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'February 2022',
                '2022-02-23T10:43:58.000Z',
                'Closed Lost',
                '0066700000uHpDIAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'February 2022',
                '2022-02-24T09:09:19.000Z',
                'Closed Won',
                '0066700000uHqMQAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'February 2022',
                '2022-02-24T16:14:40.000Z',
                'Closed Won',
                '0066700000uHqwYAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'February 2022',
                '2022-02-25T07:56:08.000Z',
                'Closed Won',
                '0066700000uHrA6AAK',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'February 2022',
                '2022-02-28T09:01:03.000Z',
                'Closed Won',
                '0066700000uHsZgAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - SAINT MARTIN SUR LE PRE (CHALONS EN CHAMPAGNE)',
            ],
            [
                'February 2022',
                '2022-02-28T09:19:44.000Z',
                'Closed Won',
                '0066700000uHsbrAAC',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'February 2022',
                '2022-02-28T16:41:12.000Z',
                'Closed Lost',
                '0066700000uHtIgAAK',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'March 2022',
                '2022-03-01T13:20:39.000Z',
                'Closed Won',
                '0066700000uHu8NAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'March 2022',
                '2022-03-01T15:05:35.000Z',
                'Closed Won',
                '0066700000uHuHZAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'March 2022',
                '2022-03-02T10:29:49.000Z',
                'Closed Lost',
                '0066700000uHusuAAC',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'March 2022',
                '2022-03-02T14:15:38.000Z',
                'Closed Lost',
                '0066700000uHvGCAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'March 2022',
                '2022-03-04T15:04:07.000Z',
                'Closed Lost',
                '0066700000uHxwDAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'March 2022',
                '2022-03-05T09:54:13.000Z',
                'Closed Won',
                '0066700000uHyE2AAK',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'March 2022',
                '2022-03-07T08:03:23.000Z',
                'Closed Lost',
                '0066700000uI22MAAS',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PREMIUM AUTOMOBILES - BARBEREY SAINT SULPICE (TROYES)',
            ],
            [
                'March 2022',
                '2022-03-07T14:49:19.000Z',
                'Closed Won',
                '0066700000uI2pYAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'March 2022',
                '2022-03-09T15:39:03.000Z',
                'Closed Won',
                '0066700000uI53jAAC',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'March 2022',
                '2022-03-09T15:46:05.000Z',
                'Closed Won',
                '0066700000uI54DAAS',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'March 2022',
                '2022-03-10T07:53:54.000Z',
                'Closed Won',
                '0066700000uI5ONAA0',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'March 2022',
                '2022-03-11T08:34:14.000Z',
                'Closed Lost',
                '0066700000uI6YRAA0',
                'Volvo dealership Form',
                'France',
                'Company',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'March 2022',
                '2022-03-11T08:39:51.000Z',
                'Closed Lost',
                '0066700000uI6Z0AAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'March 2022',
                '2022-03-11T13:56:10.000Z',
                'Closed Won',
                '0066700000uI70zAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'March 2022',
                '2022-03-11T15:46:42.000Z',
                'Closed Lost',
                '0066700000uI79rAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'March 2022',
                '2022-03-11T16:36:59.000Z',
                'Closed Won',
                '0066700000uI7ClAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.P.S - BOURG EN BRESSE',
            ],
            [
                'March 2022',
                '2022-03-14T16:01:47.000Z',
                'Closed Won',
                '0066700000uI9FUAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            [
                'March 2022',
                '2022-03-15T14:20:12.000Z',
                'Closed Lost',
                '0066700000uIAJDAA4',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'March 2022',
                '2022-03-15T15:31:52.000Z',
                'Closed Lost',
                '0066700000uIARbAAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'March 2022',
                '2022-03-15T16:05:53.000Z',
                'Closed Lost',
                '0066700000uIAV9AAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2022',
                '2022-03-15T18:34:49.000Z',
                'Standby',
                '0066700000uIAdrAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2022',
                '2022-03-16T07:54:14.000Z',
                'Closed Lost',
                '0066700000uIB6FAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'March 2022',
                '2022-03-16T09:32:40.000Z',
                'Closed Lost',
                '0066700000uIBMhAAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2022',
                '2022-03-16T15:19:30.000Z',
                'Closed Won',
                '0066700000uICIqAAO',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'March 2022',
                '2022-03-16T18:05:49.000Z',
                'Closed Lost',
                '0066700000uICXvAAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'March 2022',
                '2022-03-16T18:13:52.000Z',
                'Closed Lost',
                '0066700000uICYFAA4',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'March 2022',
                '2022-03-16T18:23:52.000Z',
                'Closed Won',
                '0066700000uICYeAAO',
                'Direct',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - LIMOGES',
            ],
            [
                'March 2022',
                '2022-03-16T19:01:44.000Z',
                'Closed Lost',
                '0066700000uICaCAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'March 2022',
                '2022-03-21T08:07:34.000Z',
                'Standby',
                '0066700000uIFdBAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES',
            ],
            [
                'March 2022',
                '2022-03-21T08:20:52.000Z',
                'Closed Lost',
                '0066700000uIFeOAAW',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'March 2022',
                '2022-03-21T10:49:44.000Z',
                'Closed Lost',
                '0066700000uIFuhAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2022',
                '2022-03-21T15:59:11.000Z',
                'Closed Lost',
                '0066700000uIGkNAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'March 2022',
                '2022-03-22T07:52:40.000Z',
                'Closed Won',
                '0066700000uIH7lAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'March 2022',
                '2022-03-22T08:06:36.000Z',
                'Closed Lost',
                '0066700000uIH8tAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2022',
                '2022-03-22T09:53:37.000Z',
                'Closed Lost',
                '0066700000uIHKaAAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2022',
                '2022-03-22T10:29:32.000Z',
                'Closed Lost',
                '0066700000uIHPGAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'March 2022',
                '2022-03-22T14:45:13.000Z',
                'Closed Won',
                '0066700000uIHo1AAG',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2022',
                '2022-03-24T09:53:14.000Z',
                'Closed Won',
                '0066700000uIJnZAAW',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'March 2022',
                '2022-03-24T13:23:54.000Z',
                'Closed Lost',
                '0066700000uIK9eAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'March 2022',
                '2022-03-24T16:21:26.000Z',
                'Closed Won',
                '0066700000uIKPmAAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'March 2022',
                '2022-03-25T09:12:39.000Z',
                'Closed Lost',
                '0066700000uIKyrAAG',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'March 2022',
                '2022-03-25T16:26:34.000Z',
                'Closed Won',
                '0066700000uILX8AAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            [
                'March 2022',
                '2022-03-28T09:36:22.000Z',
                'Standby',
                '0066700000uIMcTAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'March 2022',
                '2022-03-29T16:26:05.000Z',
                'Closed Won',
                '0066700000uIOcPAAW',
                'Email',
                'France',
                'Company',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'March 2022',
                '2022-03-30T08:17:59.000Z',
                'Closed Won',
                '0066700000uIOvWAAW',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'March 2022',
                '2022-03-30T15:16:21.000Z',
                'Closed Won',
                '0066700000uIPb5AAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'March 2022',
                '2022-03-31T07:38:42.000Z',
                'Closed Lost',
                '0066700000uIPyTAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2022',
                '2022-03-31T10:12:49.000Z',
                'Closed Won',
                '0066700000uIQFAAA4',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'March 2022',
                '2022-03-31T12:15:45.000Z',
                'Closed Lost',
                '0066700000uIQOXAA4',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'April 2022',
                '2022-04-01T15:45:12.000Z',
                'Closed Lost',
                '0066700000uISD1AAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'April 2022',
                '2022-04-01T16:02:27.000Z',
                'Standby',
                '0066700000uISEJAA4',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'April 2022',
                '2022-04-04T15:02:38.000Z',
                'Closed Won',
                '0066700000uIU88AAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'April 2022',
                '2022-04-05T14:24:27.000Z',
                'Closed Lost',
                '0066700000uIV63AAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'April 2022',
                '2022-04-06T09:13:42.000Z',
                'Closed Lost',
                '0066700000uIVs2AAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'April 2022',
                '2022-04-06T10:29:06.000Z',
                'Closed Lost',
                '0066700000uIW0uAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'April 2022',
                '2022-04-06T17:09:59.000Z',
                'Closed Lost',
                '0066700000uIWmAAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'April 2022',
                '2022-04-07T10:20:03.000Z',
                'Closed Won',
                '0066700000uIXezAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'April 2022',
                '2022-04-07T13:42:27.000Z',
                'Closed Lost',
                '0066700000uIXwjAAG',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'April 2022',
                '2022-04-07T13:46:08.000Z',
                'Closed Lost',
                '0066700000uIXxIAAW',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'April 2022',
                '2022-04-08T05:47:52.000Z',
                'Closed Won',
                '0066700000uIYKgAAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'April 2022',
                '2022-04-08T08:42:58.000Z',
                'Closed Lost',
                '0066700000uIYTYAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'April 2022',
                '2022-04-08T13:33:25.000Z',
                'Closed Lost',
                '0066700000uIYw6AAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            [
                'April 2022',
                '2022-04-08T15:52:13.000Z',
                'Closed Won',
                '0066700000uIZ9oAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'April 2022',
                '2022-04-08T16:03:56.000Z',
                'Closed Lost',
                '0066700000uIZAXAA4',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)',
            ],
            [
                'April 2022',
                '2022-04-11T05:51:15.000Z',
                'Closed Lost',
                '0066700000uIaLcAAK',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'April 2022',
                '2022-04-11T05:52:38.000Z',
                'Closed Won',
                '0066700000uIaLhAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'April 2022',
                '2022-04-11T11:57:02.000Z',
                'Closed Lost',
                '0066700000uIaoTAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'April 2022',
                '2022-04-11T12:16:00.000Z',
                'Closed Lost',
                '0066700000uIapvAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'April 2022',
                '2022-04-12T07:31:05.000Z',
                'Closed Won',
                '0066700000uIbUyAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'April 2022',
                '2022-04-12T08:41:14.000Z',
                'Closed Won',
                '0066700000uIbcnAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'April 2022',
                '2022-04-12T09:04:10.000Z',
                'Closed Lost',
                '0066700000uIbfcAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'April 2022',
                '2022-04-13T07:31:23.000Z',
                'Closed Won',
                '0066700000uIcfsAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                "Volvo -  CAR'S SERVICES - ONET LE CHATEAU (RODEZ)",
            ],
            [
                'April 2022',
                '2022-04-13T12:00:37.000Z',
                'Closed Lost',
                '0066700000uId46AAC',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'April 2022',
                '2022-04-13T14:43:57.000Z',
                'Closed Lost',
                '0066700000uIdIrAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'April 2022',
                '2022-04-13T15:26:18.000Z',
                'Closed Won',
                '0066700000uIdNNAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'April 2022',
                '2022-04-13T16:06:29.000Z',
                'Closed Lost',
                '0066700000uIdPnAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PREMIUM AUTOMOBILES - BARBEREY SAINT SULPICE (TROYES)',
            ],
            [
                'April 2022',
                '2022-04-13T16:31:26.000Z',
                'Closed Lost',
                '0066700000uIdS3AAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'April 2022',
                '2022-04-14T10:10:54.000Z',
                'Closed Lost',
                '0066700000uIdwmAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'April 2022',
                '2022-04-14T10:40:27.000Z',
                'Closed Lost',
                '0066700000uIdzCAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'April 2022',
                '2022-04-15T08:37:42.000Z',
                'Closed Lost',
                '0066700000uIeu0AAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.P.S - BOURG EN BRESSE',
            ],
            [
                'April 2022',
                '2022-04-15T12:47:19.000Z',
                'Closed Lost',
                '0066700000uIf2TAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'April 2022',
                '2022-04-15T13:39:28.000Z',
                'Closed Won',
                '0066700000uIf4AAAS',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'April 2022',
                '2022-04-15T14:02:59.000Z',
                'Closed Won',
                '0066700000uIf5NAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'April 2022',
                '2022-04-15T14:22:51.000Z',
                'Closed Won',
                '0066700000uIf6GAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PRESTIGE CARS - TOULON SUR ALLIER (MOULIN)',
            ],
            [
                'April 2022',
                '2022-04-15T14:32:43.000Z',
                'Closed Won',
                '0066700000uIf6kAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'April 2022',
                '2022-04-15T15:00:49.000Z',
                'Closed Won',
                '0066700000uIf82AAC',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PERICAUD AUTOMOBILES - CHAMPNIERS (ANGOULEME)',
            ],
            [
                'April 2022',
                '2022-04-19T08:07:10.000Z',
                'Closed Won',
                '0066700000uIfuoAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'April 2022',
                '2022-04-19T08:43:49.000Z',
                'Closed Lost',
                '0066700000uIfxTAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'April 2022',
                '2022-04-19T11:49:41.000Z',
                'Closed Won',
                '0066700000uIgD7AAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'April 2022',
                '2022-04-19T13:06:18.000Z',
                'Closed Lost',
                '0066700000uIgI7AAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)',
            ],
            [
                'April 2022',
                '2022-04-19T15:08:16.000Z',
                'Closed Won',
                '0066700000uIgQuAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'April 2022',
                '2022-04-20T13:55:34.000Z',
                'Closed Won',
                '0066700000uIh9HAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'April 2022',
                '2022-04-21T08:22:49.000Z',
                'Closed Lost',
                '0066700000uIhjtAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'April 2022',
                '2022-04-21T08:57:47.000Z',
                'Closed Won',
                '0066700000uIhn7AAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'April 2022',
                '2022-04-21T15:34:50.000Z',
                'Closed Lost',
                '0066700000uIiM4AAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'April 2022',
                '2022-04-21T15:37:29.000Z',
                'Closed Lost',
                '0066700000uIiMdAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'April 2022',
                '2022-04-21T15:46:02.000Z',
                'Closed Lost',
                '0066700000uIiNCAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'April 2022',
                '2022-04-25T09:12:19.000Z',
                'Closed Won',
                '0066700000uIkDdAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'April 2022',
                '2022-04-25T12:12:49.000Z',
                'Closed Won',
                '0066700000uIkWxAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'April 2022',
                '2022-04-25T15:15:41.000Z',
                'Closed Lost',
                '0066700000uIkpBAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'April 2022',
                '2022-04-26T08:06:57.000Z',
                'Closed Lost',
                '0066700000uIlE1AAK',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'April 2022',
                '2022-04-26T08:09:24.000Z',
                'Closed Lost',
                '0066700000uIlE6AAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'April 2022',
                '2022-04-26T08:19:59.000Z',
                'Closed Lost',
                '0066700000uIlGHAA0',
                'Zeplug',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES',
            ],
            [
                'April 2022',
                '2022-04-26T09:23:28.000Z',
                'Closed Lost',
                '0066700000uIlMjAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'April 2022',
                '2022-04-26T16:16:28.000Z',
                'Closed Won',
                '0066700000uIm0iAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'April 2022',
                '2022-04-27T06:38:53.000Z',
                'Closed Lost',
                '0066700000uImA4AAK',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'April 2022',
                '2022-04-27T10:01:33.000Z',
                'Closed Won',
                '0066700000uImPTAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'April 2022',
                '2022-04-27T14:52:46.000Z',
                'Standby',
                '0066700000uImpSAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'April 2022',
                '2022-04-28T08:00:42.000Z',
                'Closed Won',
                '0066700000uInFfAAK',
                'Form Volvo Employee FR',
                'France',
                'Multi Family Home',
                'ChargeGuru Business Services',
            ],
            [
                'April 2022',
                '2022-04-28T08:43:24.000Z',
                'Closed Lost',
                '0066700000uInKKAA0',
                'Email',
                'France',
                'Single Home',
                'ABVV Chantilly',
            ],
            [
                'April 2022',
                '2022-04-28T13:57:22.000Z',
                'Closed Lost',
                '0066700000uInoFAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'April 2022',
                '2022-04-28T15:37:29.000Z',
                'Closed Lost',
                '0066700000uInvkAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'April 2022',
                '2022-04-28T15:40:56.000Z',
                'Closed Won',
                '0066700000uInwEAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'April 2022',
                '2022-04-29T07:51:18.000Z',
                'Closed Won',
                '0066700000uIo93AAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'April 2022',
                '2022-04-29T09:11:25.000Z',
                'Closed Lost',
                '0066700000uIoDeAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PRESTIGE CARS - AUBIERE (CLERMONT FERRAND)',
            ],
            [
                'April 2022',
                '2022-04-29T10:04:14.000Z',
                'Closed Lost',
                '0066700000uIoHRAA0',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  AMPLITUDE AUTOMOBILES - SAINTES',
            ],
            [
                'May 2022',
                '2022-05-02T09:29:04.000Z',
                'Closed Lost',
                '0066700000uIpuPAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-02T12:33:31.000Z',
                'Closed Lost',
                '0066700000uIq4tAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-02T13:00:06.000Z',
                'Closed Won',
                '0066700000uIq6pAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-02T16:12:10.000Z',
                'Closed Won',
                '0066700000uIqJKAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'May 2022',
                '2022-05-02T16:25:51.000Z',
                'Closed Won',
                '0066700000uIqKDAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-03T07:19:50.000Z',
                'Closed Lost',
                '0066700000uIqTtAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'May 2022',
                '2022-05-03T13:58:03.000Z',
                'Closed Won',
                '0066700000uIr41AAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'May 2022',
                '2022-05-03T15:32:42.000Z',
                'Closed Won',
                '0066700000uIrCFAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'May 2022',
                '2022-05-03T17:03:49.000Z',
                'Closed Won',
                '0066700000uIrGHAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VILLEFRANCHE-SUR-SAÔNE',
            ],
            [
                'May 2022',
                '2022-05-04T08:36:26.000Z',
                'Closed Won',
                '0066700000uIrXDAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-04T12:59:56.000Z',
                'Closed Won',
                '0066700000uIrsaAAC',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'May 2022',
                '2022-05-04T15:12:24.000Z',
                'Closed Lost',
                '0066700000uIs1XAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'May 2022',
                '2022-05-05T11:53:22.000Z',
                'Closed Lost',
                '0066700000uIsXNAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'May 2022',
                '2022-05-06T08:57:28.000Z',
                'Closed Lost',
                '0066700000uIt1rAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'May 2022',
                '2022-05-06T09:00:42.000Z',
                'Closed Lost',
                '0066700000uIt2BAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'May 2022',
                '2022-05-06T13:19:50.000Z',
                'Closed Won',
                '0066700000uItZtAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'May 2022',
                '2022-05-09T13:17:11.000Z',
                'Closed Lost',
                '0066700000uIujgAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'May 2022',
                '2022-05-09T13:53:09.000Z',
                'Closed Won',
                '0066700000uIun9AAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo - FIRST AUTOMOBILES - AULNOY LEZ VALENCIENNES',
            ],
            [
                'May 2022',
                '2022-05-09T15:48:40.000Z',
                'Closed Won',
                '0066700000uIuvhAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'May 2022',
                '2022-05-09T15:54:06.000Z',
                'Closed Lost',
                '0066700000uIuw1AAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-09T16:45:19.000Z',
                'Closed Lost',
                '0066700000uIuzjAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'May 2022',
                '2022-05-10T07:48:04.000Z',
                'Closed Won',
                '0066700000uIvTPAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'May 2022',
                '2022-05-10T07:49:50.000Z',
                'Closed Won',
                '0066700000uIvTjAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'May 2022',
                '2022-05-10T14:52:24.000Z',
                'Closed Lost',
                '0066700000uIw6OAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'May 2022',
                '2022-05-10T15:37:35.000Z',
                'Standby',
                '0066700000uIwAuAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'May 2022',
                '2022-05-10T16:43:30.000Z',
                'Closed Lost',
                '0066700000uIwEXAA0',
                'Email',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'May 2022',
                '2022-05-11T07:06:43.000Z',
                'Closed Lost',
                '0066700000uIwPpAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'May 2022',
                '2022-05-11T07:18:37.000Z',
                'Closed Lost',
                '0066700000uIwQnAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'May 2022',
                '2022-05-11T08:20:13.000Z',
                'Closed Lost',
                '0066700000uIwVxAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT CHARTRES - NOGENT LE PHAYE (CHARTRES)',
            ],
            [
                'May 2022',
                '2022-05-12T07:18:38.000Z',
                'Closed Lost',
                '0066700000uIyJ7AAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'May 2022',
                '2022-05-12T07:42:54.000Z',
                'Closed Won',
                '0066700000uIyL9AAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'May 2022',
                '2022-05-13T07:20:05.000Z',
                'Closed Won',
                '0066700000uIzLTAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'May 2022',
                '2022-05-13T07:22:55.000Z',
                'Closed Lost',
                '0066700000uIzLdAAK',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'May 2022',
                '2022-05-13T08:40:27.000Z',
                'Closed Lost',
                '0066700000uIzQTAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-13T13:37:13.000Z',
                'Closed Won',
                '0066700000uIzhyAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AUTO PREMIUM 08 - CHARLEVILLE MEZIERES',
            ],
            [
                'May 2022',
                '2022-05-13T13:45:38.000Z',
                'Closed Won',
                '0066700000uIzhzAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'May 2022',
                '2022-05-13T13:51:16.000Z',
                'Closed Lost',
                '0066700000uIziwAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'May 2022',
                '2022-05-16T08:40:12.000Z',
                'Standby',
                '0066700000uJ1EZAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'May 2022',
                '2022-05-16T09:17:13.000Z',
                'Closed Won',
                '0066700000uJ1JZAA0',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'May 2022',
                '2022-05-16T09:55:27.000Z',
                'Closed Lost',
                '0066700000uJ1NgAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'ABVV Chantilly',
            ],
            [
                'May 2022',
                '2022-05-16T13:24:15.000Z',
                'Closed Lost',
                '0066700000uJ1iFAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'May 2022',
                '2022-05-17T07:19:56.000Z',
                'Closed Won',
                '0066700000uJ2DhAAK',
                'Email',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'May 2022',
                '2022-05-18T05:48:09.000Z',
                'Closed Lost',
                '0066700000uJ389AAC',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'May 2022',
                '2022-05-18T09:14:49.000Z',
                'Closed Lost',
                '0066700000uJ3N5AAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            [
                'May 2022',
                '2022-05-18T15:34:39.000Z',
                'Closed Won',
                '0066700000uJ3tXAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-18T15:53:29.000Z',
                'Closed Won',
                '0066700000uJ3upAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'May 2022',
                '2022-05-18T16:03:07.000Z',
                'Closed Lost',
                '0066700000uJ3vJAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'May 2022',
                '2022-05-20T06:04:16.000Z',
                'Closed Lost',
                '0066700000uJ4ysAAC',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'May 2022',
                '2022-05-20T14:50:28.000Z',
                'Closed Lost',
                '0066700000uJ5X9AAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-23T07:15:03.000Z',
                'Closed Lost',
                '0066700000uJ6W5AAK',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'May 2022',
                '2022-05-23T08:14:24.000Z',
                'Closed Lost',
                '0066700000uJ6b0AAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-23T09:15:25.000Z',
                'Closed Lost',
                '0066700000uJ6iuAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'May 2022',
                '2022-05-23T09:39:45.000Z',
                'Standby',
                '0066700000uJ6mDAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'May 2022',
                '2022-05-24T07:45:49.000Z',
                'Closed Won',
                '0066700000uJ7j2AAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)',
            ],
            [
                'May 2022',
                '2022-05-24T08:14:57.000Z',
                'Standby',
                '0066700000uJ7mQAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'May 2022',
                '2022-05-24T08:15:44.000Z',
                'Closed Won',
                '0066700000uJ7mkAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-25T09:56:08.000Z',
                'Closed Lost',
                '0066700000uJ9HeAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'May 2022',
                '2022-05-25T13:29:56.000Z',
                'Closed Won',
                '0066700000uJ9Y1AAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'May 2022',
                '2022-05-25T14:24:08.000Z',
                'Closed Won',
                '0066700000uJ9crAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'May 2022',
                '2022-05-25T15:49:53.000Z',
                'Closed Lost',
                '0066700000uJ9jnAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'May 2022',
                '2022-05-27T09:33:13.000Z',
                'Closed Lost',
                '0066700000uJArdAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'May 2022',
                '2022-05-27T13:31:41.000Z',
                'Closed Won',
                '0066700000uJB81AAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'May 2022',
                '2022-05-30T07:07:53.000Z',
                'Closed Lost',
                '0066700000uJBnmAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-30T08:30:28.000Z',
                'Closed Lost',
                '0066700000uJBwjAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'May 2022',
                '2022-05-30T12:14:30.000Z',
                'Closed Lost',
                '0066700000uJCIpAAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'May 2022',
                '2022-05-30T13:07:39.000Z',
                'Closed Won',
                '0066700000uJCMhAAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'May 2022',
                '2022-05-31T07:16:39.000Z',
                'Closed Lost',
                '0066700000uJCsEAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'May 2022',
                '2022-05-31T08:13:40.000Z',
                'Closed Lost',
                '0066700000uJCyvAAG',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'May 2022',
                '2022-05-31T08:44:22.000Z',
                'Closed Won',
                '0066700000uJD2YAAW',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'May 2022',
                '2022-05-31T09:01:38.000Z',
                'Closed Won',
                '0066700000uJD4yAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'June 2022',
                '2022-06-01T08:00:26.000Z',
                'Closed Lost',
                '0066700000uJDy0AAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'June 2022',
                '2022-06-01T12:58:59.000Z',
                'Closed Lost',
                '0066700000uJEMCAA4',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2022',
                '2022-06-01T16:22:44.000Z',
                'Closed Won',
                '0066700000uJEcPAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'June 2022',
                '2022-06-01T16:28:38.000Z',
                'Closed Won',
                '0066700000uJEcZAAW',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'June 2022',
                '2022-06-01T16:39:58.000Z',
                'Closed Lost',
                '0066700000uJEctAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'June 2022',
                '2022-06-02T06:45:16.000Z',
                'Closed Won',
                '0066700000uJElHAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2022',
                '2022-06-02T08:01:33.000Z',
                'Closed Lost',
                '0066700000uJErFAAW',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'June 2022',
                '2022-06-02T08:27:35.000Z',
                'Closed Lost',
                '0066700000uJEtaAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2022',
                '2022-06-03T11:49:22.000Z',
                'Closed Lost',
                '0066700000uJFpKAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'June 2022',
                '2022-06-03T12:40:17.000Z',
                'Standby',
                '0066700000uJFr1AAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'June 2022',
                '2022-06-03T12:52:42.000Z',
                'Closed Lost',
                '0066700000uJFrfAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2022',
                '2022-06-06T08:35:57.000Z',
                'Closed Lost',
                '0066700000uJGc7AAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE JFC LISIEUX - LISIEUX',
            ],
            [
                'June 2022',
                '2022-06-08T14:18:48.000Z',
                'Closed Won',
                '0066700000uJJ1dAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2022',
                '2022-06-09T07:22:00.000Z',
                'Closed Won',
                '0066700000uJJhOAAW',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
            ],
            [
                'June 2022',
                '2022-06-09T11:37:38.000Z',
                'Closed Lost',
                '0066700000uJK8tAAG',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'June 2022',
                '2022-06-10T09:40:37.000Z',
                'Closed Lost',
                '0066700000uJKp4AAG',
                'Direct',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'June 2022',
                '2022-06-10T11:38:55.000Z',
                'Closed Won',
                '0066700000uJKw0AAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-13T08:01:33.000Z',
                'Closed Lost',
                '0066700000uJMDaAAO',
                'Volvo dealership Form',
                'France',
                'Company',
                'LARSON by Autosphere',
            ],
            [
                'June 2022',
                '2022-06-13T13:07:07.000Z',
                'Closed Lost',
                '0066700000uJMgSAAW',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'June 2022',
                '2022-06-13T14:17:47.000Z',
                'Closed Lost',
                '0066700000uJMmaAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-14T07:20:49.000Z',
                'Standby',
                '0066700000uJN5XAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'June 2022',
                '2022-06-14T10:11:43.000Z',
                'Closed Won',
                '0066700000uJNMgAAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'June 2022',
                '2022-06-15T09:09:31.000Z',
                'Closed Won',
                '0066700000uJOQ0AAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2022',
                '2022-06-15T12:37:58.000Z',
                'Closed Won',
                '0066700000uJOv3AAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'June 2022',
                '2022-06-16T07:19:47.000Z',
                'Closed Lost',
                '0066700000uJPQQAA4',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2022',
                '2022-06-16T08:42:31.000Z',
                'Standby',
                '0066700000uJPXHAA4',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2022',
                '2022-06-16T14:35:25.000Z',
                'Closed Lost',
                '0066700000uJPv9AAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'June 2022',
                '2022-06-17T07:23:22.000Z',
                'Closed Lost',
                '0066700000uJQEVAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'June 2022',
                '2022-06-17T08:59:03.000Z',
                'Standby',
                '0066700000uJQMUAA4',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'June 2022',
                '2022-06-17T10:22:23.000Z',
                'Closed Won',
                '0066700000uJQTGAA4',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'June 2022',
                '2022-06-17T14:12:04.000Z',
                'Closed Lost',
                '0066700000uJQgjAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)',
            ],
            [
                'June 2022',
                '2022-06-17T15:44:45.000Z',
                'Closed Won',
                '0066700000uJQodAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'June 2022',
                '2022-06-20T07:38:49.000Z',
                'Closed Lost',
                '0066700000uJRNYAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'June 2022',
                '2022-06-20T08:29:03.000Z',
                'Closed Lost',
                '0066700000uJRTgAAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS (11ème) - PARIS',
            ],
            [
                'June 2022',
                '2022-06-20T10:05:40.000Z',
                'Closed Lost',
                '0066700000uJRcZAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2022',
                '2022-06-20T12:20:47.000Z',
                'Closed Won',
                '0066700000uJRr4AAG',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'June 2022',
                '2022-06-20T12:31:20.000Z',
                'Closed Won',
                '0066700000uJRsWAAW',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PREMIUM AUTOMOBILES - BARBEREY SAINT SULPICE (TROYES)',
            ],
            [
                'June 2022',
                '2022-06-20T13:16:53.000Z',
                'Closed Lost',
                '0066700000uJRvkAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'June 2022',
                '2022-06-20T13:32:59.000Z',
                'Closed Lost',
                '0066700000uJRxCAAW',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'June 2022',
                '2022-06-20T14:55:06.000Z',
                'Closed Lost',
                '0066700000uJS3oAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Evreux - NORMANVILLE (EVREUX)',
            ],
            [
                'June 2022',
                '2022-06-21T07:18:47.000Z',
                'Closed Lost',
                '0066700000uJSIBAA4',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)',
            ],
            [
                'June 2022',
                '2022-06-21T08:28:46.000Z',
                'Closed Won',
                '0066700000uJSPHAA4',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'June 2022',
                '2022-06-21T10:04:32.000Z',
                'Closed Lost',
                '0066700000uJSZ7AAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'June 2022',
                '2022-06-22T07:17:35.000Z',
                'Closed Lost',
                '0066700000uJTNbAAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-22T07:59:09.000Z',
                'Closed Lost',
                '0066700000uJTQGAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'June 2022',
                '2022-06-22T11:57:48.000Z',
                'Standby',
                '0066700000uJThhAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'June 2022',
                '2022-06-23T07:54:52.000Z',
                'Waiting for Quote',
                '0066700000uJUEJAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'June 2022',
                '2022-06-23T11:41:25.000Z',
                'Standby',
                '0066700000uJUWwAAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'June 2022',
                '2022-06-23T15:59:18.000Z',
                'Closed Lost',
                '0066700000uJUqmAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'June 2022',
                '2022-06-24T09:53:02.000Z',
                'Closed Lost',
                '0066700000uJVFXAA4',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'June 2022',
                '2022-06-24T09:56:13.000Z',
                'Closed Lost',
                '0066700000uJVG6AAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'June 2022',
                '2022-06-24T11:07:13.000Z',
                'Quote sent • Negotiation',
                '0066700000uJVLBAA4',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'June 2022',
                '2022-06-24T12:23:25.000Z',
                'Closed Won',
                '0066700000uJVOZAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'June 2022',
                '2022-06-24T14:29:48.000Z',
                'Closed Won',
                '0066700000uJVXlAAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'June 2022',
                '2022-06-27T09:27:05.000Z',
                'Closed Won',
                '0066700000uJWvgAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'June 2022',
                '2022-06-27T09:28:17.000Z',
                'Closed Won',
                '0066700000uJWwOAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'June 2022',
                '2022-06-27T11:45:49.000Z',
                'Closed Lost',
                '0066700000uJXAkAAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'June 2022',
                '2022-06-27T12:31:45.000Z',
                'Closed Won',
                '0066700000uJXDyAAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'June 2022',
                '2022-06-27T13:49:28.000Z',
                'Closed Lost',
                '0066700000uJXL9AAO',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - ROISSY EN FRANCE',
            ],
            [
                'June 2022',
                '2022-06-27T14:23:40.000Z',
                'Closed Won',
                '0066700000uJXO8AAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2022',
                '2022-06-28T08:23:21.000Z',
                'Closed Lost',
                '0066700000uJXnkAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'June 2022',
                '2022-06-28T13:45:32.000Z',
                'Closed Lost',
                '0066700000uJYDAAA4',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'June 2022',
                '2022-06-28T14:45:13.000Z',
                'Closed Won',
                '0066700000uJYHHAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'June 2022',
                '2022-06-29T07:31:30.000Z',
                'Standby',
                '0066700000uKDwoAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'June 2022',
                '2022-06-29T07:48:30.000Z',
                'Closed Lost',
                '0066700000uKDyVAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'June 2022',
                '2022-06-29T12:36:05.000Z',
                'Closed Lost',
                '0066700000uKJHUAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'June 2022',
                '2022-06-29T14:29:52.000Z',
                'Closed Lost',
                '0066700000uKOOkAAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'June 2022',
                '2022-06-29T15:41:56.000Z',
                'Closed Lost',
                '0066700000uKR4TAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'June 2022',
                '2022-06-29T15:50:55.000Z',
                'Closed Lost',
                '0066700000uKRR8AAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VIENNE',
            ],
            [
                'June 2022',
                '2022-06-29T16:30:01.000Z',
                'Closed Lost',
                '0066700000uKSrFAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'June 2022',
                '2022-06-30T06:48:28.000Z',
                'Closed Lost',
                '0066700000uKXFhAAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'June 2022',
                '2022-06-30T08:03:42.000Z',
                'Closed Won',
                '0066700000uKXJoAAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2022',
                '2022-06-30T12:49:43.000Z',
                'Closed Lost',
                '0066700000uKXcvAAG',
                'Phone',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2022',
                '2022-06-30T15:03:26.000Z',
                'Closed Won',
                '0066700000uKXpGAAW',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'June 2022',
                '2022-06-30T15:14:07.000Z',
                'Closed Lost',
                '0066700000uKXq4AAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - EVERLINE - DENNEY (BELFORT)',
            ],
            [
                'June 2022',
                '2022-06-30T15:46:02.000Z',
                'Closed Won',
                '0066700000uKXsjAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'June 2022',
                '2022-06-30T16:28:09.000Z',
                'Closed Lost',
                '0066700000uKXviAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'July 2022',
                '2022-07-01T15:37:13.000Z',
                'Closed Lost',
                '0066700000uKaMuAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'July 2022',
                '2022-07-04T07:35:36.000Z',
                'Closed Lost',
                '0066700000uKauIAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'July 2022',
                '2022-07-04T08:18:53.000Z',
                'Closed Won',
                '0066700000uKaxMAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'July 2022',
                '2022-07-04T08:29:09.000Z',
                'Closed Won',
                '0066700000uKayZAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'July 2022',
                '2022-07-04T11:38:45.000Z',
                'Closed Lost',
                '0066700000uKbHbAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'July 2022',
                '2022-07-05T08:25:53.000Z',
                'Closed Lost',
                '0066700000uKbsIAAS',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'July 2022',
                '2022-07-05T13:00:22.000Z',
                'Closed Lost',
                '0066700000uKcGPAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'July 2022',
                '2022-07-05T13:54:35.000Z',
                'Closed Won',
                '0066700000uKcK7AAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'July 2022',
                '2022-07-06T09:19:41.000Z',
                'Closed Lost',
                '0066700000uKdJoAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'July 2022',
                '2022-07-06T11:52:00.000Z',
                'Closed Won',
                '0066700000uKdVVAA0',
                'Email',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2022',
                '2022-07-06T12:23:41.000Z',
                'Closed Won',
                '0066700000uKdXbAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'July 2022',
                '2022-07-06T13:08:38.000Z',
                'Closed Won',
                '0066700000uKda1AAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'July 2022',
                '2022-07-06T15:34:36.000Z',
                'Closed Won',
                '0066700000uKdkBAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AUTO PREMIUM 08 - CHARLEVILLE MEZIERES',
            ],
            [
                'July 2022',
                '2022-07-07T07:28:09.000Z',
                'Closed Lost',
                '0066700000uKdxFAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'July 2022',
                '2022-07-07T12:18:46.000Z',
                'Closed Lost',
                '0066700000uKem3AAC',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'July 2022',
                '2022-07-08T12:51:45.000Z',
                'Closed Lost',
                '0066700000uKqpoAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'July 2022',
                '2022-07-11T09:11:21.000Z',
                'Closed Lost',
                '0066700000uKteUAAS',
                'Direct',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'July 2022',
                '2022-07-11T12:35:26.000Z',
                'Closed Won',
                '0066700000uKttqAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            [
                'July 2022',
                '2022-07-11T13:00:41.000Z',
                'Closed Won',
                '0066700000uKtx9AAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'July 2022',
                '2022-07-11T13:37:29.000Z',
                'Standby',
                '0066700000uKu8MAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'July 2022',
                '2022-07-11T15:40:58.000Z',
                'Closed Lost',
                '0066700000uKy1KAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'July 2022',
                '2022-07-11T16:25:01.000Z',
                'Closed Lost',
                '0066700000uKy3zAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'July 2022',
                '2022-07-12T07:32:42.000Z',
                'Closed Won',
                '0066700000uKyExAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - BUCHELAY (MANTES LA JOLIE)',
            ],
            [
                'July 2022',
                '2022-07-12T16:00:51.000Z',
                'Closed Lost',
                '0066700000uL0qFAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'July 2022',
                '2022-07-13T08:23:11.000Z',
                'Closed Won',
                '0066700000uL189AAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'July 2022',
                '2022-07-13T12:38:15.000Z',
                'Closed Won',
                '0066700000uL1TEAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'July 2022',
                '2022-07-15T09:47:01.000Z',
                'Closed Won',
                '0066700000uL2ntAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'July 2022',
                '2022-07-15T10:35:54.000Z',
                'Closed Won',
                '0066700000uL37oAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'July 2022',
                '2022-07-15T12:43:14.000Z',
                'Closed Lost',
                '0066700000uL3HtAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            [
                'July 2022',
                '2022-07-18T07:36:32.000Z',
                'Closed Lost',
                '0066700000uL4MAAA0',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'July 2022',
                '2022-07-18T07:44:38.000Z',
                'Closed Lost',
                '0066700000uL4MBAA0',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'July 2022',
                '2022-07-18T08:36:29.000Z',
                'Closed Lost',
                '0066700000uL4QWAA0',
                'Form Volvo Employee FR',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2022',
                '2022-07-18T10:06:42.000Z',
                'Closed Lost',
                '0066700000uL4XDAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2022',
                '2022-07-18T12:10:59.000Z',
                'Closed Lost',
                '0066700000uL4h3AAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'July 2022',
                '2022-07-18T12:20:18.000Z',
                'Closed Won',
                '0066700000uL4hSAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2022',
                '2022-07-18T12:24:31.000Z',
                'Closed Lost',
                '0066700000uL4hmAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'July 2022',
                '2022-07-18T12:27:44.000Z',
                'Closed Lost',
                '0066700000uL4iGAAS',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'July 2022',
                '2022-07-18T12:36:40.000Z',
                'Closed Won',
                '0066700000uL4jJAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'July 2022',
                '2022-07-18T13:59:25.000Z',
                'Closed Lost',
                '0066700000uL4pHAAS',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'July 2022',
                '2022-07-18T16:33:24.000Z',
                'Closed Won',
                '0066700000uL50KAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'July 2022',
                '2022-07-19T07:37:37.000Z',
                'Closed Lost',
                '0066700000uL5ApAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'July 2022',
                '2022-07-19T07:56:04.000Z',
                'Closed Lost',
                '0066700000uL5C7AAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - SAINTES',
            ],
            [
                'July 2022',
                '2022-07-19T07:59:46.000Z',
                'Closed Won',
                '0066700000uL5CWAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'July 2022',
                '2022-07-19T12:29:50.000Z',
                'Closed Won',
                '0066700000uL5YwAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'July 2022',
                '2022-07-20T06:47:07.000Z',
                'Closed Lost',
                '0066700000uL64ZAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'July 2022',
                '2022-07-20T07:30:04.000Z',
                'Closed Won',
                '0066700000uL65qAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'July 2022',
                '2022-07-20T07:41:04.000Z',
                'Closed Lost',
                '0066700000uL66tAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'July 2022',
                '2022-07-20T09:05:33.000Z',
                'Closed Won',
                '0066700000uL6EYAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - EVERLINE - DENNEY (BELFORT)',
            ],
            [
                'July 2022',
                '2022-07-20T09:29:10.000Z',
                'Closed Lost',
                '0066700000uL6GZAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - NEUILLY SUR SEINE',
            ],
            [
                'July 2022',
                '2022-07-20T10:05:19.000Z',
                'Closed Lost',
                '0066700000uL6KMAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'July 2022',
                '2022-07-20T11:37:15.000Z',
                'Closed Lost',
                '0066700000uL6R3AAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'July 2022',
                '2022-07-20T12:15:42.000Z',
                'Closed Lost',
                '0066700000uL6TsAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'July 2022',
                '2022-07-20T12:18:41.000Z',
                'Closed Won',
                '0066700000uL6UCAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'July 2022',
                '2022-07-20T12:31:47.000Z',
                'Closed Lost',
                '0066700000uL6VKAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'July 2022',
                '2022-07-20T12:43:12.000Z',
                'Closed Won',
                '0066700000uL6WcAAK',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)',
            ],
            [
                'July 2022',
                '2022-07-21T07:24:50.000Z',
                'Closed Lost',
                '0066700000uL70AAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'July 2022',
                '2022-07-21T10:21:59.000Z',
                'Closed Won',
                '0066700000uL7FyAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'July 2022',
                '2022-07-22T12:46:09.000Z',
                'Waiting for Quote',
                '0066700000uL81AAAS',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'July 2022',
                '2022-07-22T13:06:36.000Z',
                'Closed Won',
                '0066700000uL81jAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'July 2022',
                '2022-07-22T15:32:10.000Z',
                'Closed Lost',
                '0066700000uL8B5AAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2022',
                '2022-07-25T07:48:19.000Z',
                'Closed Lost',
                '0066700000uL8jLAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'July 2022',
                '2022-07-25T09:06:06.000Z',
                'Closed Won',
                '0066700000uL8pxAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'July 2022',
                '2022-07-26T07:15:08.000Z',
                'Closed Lost',
                '0066700000uL9TxAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'July 2022',
                '2022-07-26T13:04:20.000Z',
                'Closed Won',
                '0066700000uL9q8AAC',
                'Phone',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2022',
                '2022-07-27T13:46:38.000Z',
                'Closed Lost',
                '0066700000uLAioAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'July 2022',
                '2022-07-28T07:45:11.000Z',
                'Closed Lost',
                '0066700000uLBFJAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'July 2022',
                '2022-07-28T08:33:08.000Z',
                'Closed Won',
                '0066700000uLBIhAAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'July 2022',
                '2022-07-29T07:39:55.000Z',
                'Closed Lost',
                '0066700000uLCMVAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'July 2022',
                '2022-07-29T08:14:52.000Z',
                'Closed Won',
                '0066700000uLCOlAAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'July 2022',
                '2022-07-29T09:00:11.000Z',
                'Closed Lost',
                '0066700000uLCS4AAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2022',
                '2022-07-29T10:53:28.000Z',
                'Closed Won',
                '0066700000uLCZoAAO',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'July 2022',
                '2022-07-29T14:13:58.000Z',
                'Closed Lost',
                '0066700000uLCjtAAG',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'July 2022',
                '2022-07-29T16:07:01.000Z',
                'Closed Lost',
                '0066700000uLCpDAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE JFC LISIEUX - LISIEUX',
            ],
            [
                'August 2022',
                '2022-08-01T15:25:58.000Z',
                'Closed Won',
                '0066700000uLEETAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'August 2022',
                '2022-08-02T09:30:25.000Z',
                'Closed Lost',
                '0066700000uLEdxAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - BRIVE LA GAILLARDE',
            ],
            [
                'August 2022',
                '2022-08-02T10:04:59.000Z',
                'Closed Won',
                '0066700000uLEgIAAW',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'August 2022',
                '2022-08-02T10:05:17.000Z',
                'Closed Won',
                '0066700000uLEgSAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'August 2022',
                '2022-08-02T13:11:35.000Z',
                'Closed Won',
                '0066700000uLEqDAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'August 2022',
                '2022-08-02T15:33:08.000Z',
                'Closed Won',
                '0066700000uLEz5AAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - SAINT AVIT (MONT DE MARSAN)',
            ],
            [
                'August 2022',
                '2022-08-03T08:19:23.000Z',
                'Closed Lost',
                '0066700000uLFC9AAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES',
            ],
            [
                'August 2022',
                '2022-08-03T15:17:53.000Z',
                'Closed Won',
                '0066700000uLFldAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'August 2022',
                '2022-08-03T15:26:25.000Z',
                'Closed Won',
                '0066700000uLFmCAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'August 2022',
                '2022-08-03T15:39:52.000Z',
                'Closed Lost',
                '0066700000uLFnZAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'August 2022',
                '2022-08-05T07:16:57.000Z',
                'Closed Won',
                '0066700000uLH8sAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'August 2022',
                '2022-08-05T11:42:37.000Z',
                'Closed Lost',
                '0066700000uLHLIAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'August 2022',
                '2022-08-05T11:56:32.000Z',
                'Closed Won',
                '0066700000uLHLmAAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'August 2022',
                '2022-08-05T12:25:06.000Z',
                'Closed Lost',
                '0066700000uLHMpAAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'August 2022',
                '2022-08-05T12:47:09.000Z',
                'Closed Won',
                '0066700000uLHOCAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'August 2022',
                '2022-08-08T12:28:44.000Z',
                'Closed Lost',
                '0066700000uLIBnAAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'August 2022',
                '2022-08-08T12:44:40.000Z',
                'Closed Won',
                '0066700000uLICvAAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'August 2022',
                '2022-08-08T13:03:00.000Z',
                'Closed Lost',
                '0066700000uLIDoAAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2022',
                '2022-08-08T14:40:03.000Z',
                'Closed Lost',
                '0066700000uLIJ8AAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'August 2022',
                '2022-08-08T17:11:36.000Z',
                'Closed Won',
                '0066700000uLIPfAAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'August 2022',
                '2022-08-09T07:17:53.000Z',
                'Closed Lost',
                '0066700000uLIUpAAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'August 2022',
                '2022-08-09T08:04:20.000Z',
                'Closed Won',
                '0066700000uLIXKAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'August 2022',
                '2022-08-09T15:26:05.000Z',
                'Closed Lost',
                '0066700000uLJIUAA4',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)',
            ],
            [
                'August 2022',
                '2022-08-10T13:57:53.000Z',
                'Closed Won',
                '0066700000uLJvbAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'August 2022',
                '2022-08-11T08:00:56.000Z',
                'Closed Lost',
                '0066700000uLKChAAO',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'August 2022',
                '2022-08-12T07:59:42.000Z',
                'Closed Lost',
                '0066700000uLKsJAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'August 2022',
                '2022-08-12T13:20:57.000Z',
                'Closed Won',
                '0066700000uLL8tAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'August 2022',
                '2022-08-16T12:29:06.000Z',
                'Closed Won',
                '0066700000uLN4XAAW',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'August 2022',
                '2022-08-16T12:44:40.000Z',
                'Closed Lost',
                '0066700000uLN5vAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'August 2022',
                '2022-08-17T13:15:32.000Z',
                'Closed Lost',
                '0066700000uLNk4AAG',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'August 2022',
                '2022-08-17T15:02:48.000Z',
                'Closed Lost',
                '0066700000uLNq7AAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'August 2022',
                '2022-08-19T08:13:09.000Z',
                'Closed Won',
                '0066700000uLOeqAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'August 2022',
                '2022-08-22T14:47:47.000Z',
                'Closed Lost',
                '0066700000uLQbkAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'August 2022',
                '2022-08-22T15:02:04.000Z',
                'Closed Won',
                '0066700000uLQcYAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'August 2022',
                '2022-08-22T17:35:00.000Z',
                'Closed Won',
                '0066700000uLQk8AAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'August 2022',
                '2022-08-23T10:33:37.000Z',
                'Standby',
                '0066700000uLRJ3AAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'August 2022',
                '2022-08-24T10:44:44.000Z',
                'Closed Won',
                '0066700000uLSLZAA4',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - SAINT MARTIN SUR LE PRE (CHALONS EN CHAMPAGNE)',
            ],
            [
                'August 2022',
                '2022-08-24T14:47:28.000Z',
                'Closed Lost',
                '0066700000uLSZRAA4',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'August 2022',
                '2022-08-25T07:06:46.000Z',
                'Closed Lost',
                '0066700000uLT5IAAW',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'August 2022',
                '2022-08-25T09:38:28.000Z',
                'Closed Lost',
                '0066700000uLTFSAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'August 2022',
                '2022-08-25T12:27:26.000Z',
                'Closed Lost',
                '0066700000uLTOZAA4',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'August 2022',
                '2022-08-25T14:15:28.000Z',
                'Closed Lost',
                '0066700000uLTUrAAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
            ],
            [
                'August 2022',
                '2022-08-26T07:21:29.000Z',
                'Closed Won',
                '0066700000uLTy3AAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'August 2022',
                '2022-08-29T07:16:49.000Z',
                'Standby',
                '0066700000uLVMxAAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2022',
                '2022-08-29T10:16:26.000Z',
                'Closed Won',
                '0066700000uLVXHAA4',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'August 2022',
                '2022-08-29T11:50:40.000Z',
                'Closed Won',
                '0066700000uLVcgAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'August 2022',
                '2022-08-29T12:19:18.000Z',
                'Closed Won',
                '0066700000uLVgEAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'ABVV Roissy en France',
            ],
            [
                'August 2022',
                '2022-08-29T13:33:47.000Z',
                'Closed Lost',
                '0066700000uLVjhAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
            ],
            [
                'August 2022',
                '2022-08-30T15:13:02.000Z',
                'Closed Lost',
                '0066700000uLWiLAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE SAINT ETIENNE - SAINT ETIENNE',
            ],
            [
                'August 2022',
                '2022-08-30T15:45:04.000Z',
                'Closed Lost',
                '0066700000uLWkRAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'August 2022',
                '2022-08-31T11:29:48.000Z',
                'Standby',
                '0066700000uLXSuAAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'September 2022',
                '2022-09-01T12:19:15.000Z',
                'Closed Lost',
                '0066700000uLYW3AAO',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'September 2022',
                '2022-09-01T13:29:39.000Z',
                'Closed Lost',
                '0066700000uLYayAAG',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'September 2022',
                '2022-09-01T13:58:52.000Z',
                'Closed Won',
                '0066700000uLYdTAAW',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'September 2022',
                '2022-09-01T14:28:08.000Z',
                'Closed Lost',
                '0066700000uLYfjAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'September 2022',
                '2022-09-01T15:25:07.000Z',
                'Closed Lost',
                '0066700000uLYjRAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'September 2022',
                '2022-09-02T08:18:54.000Z',
                'Closed Lost',
                '0066700000uLZDCAA4',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Tesla Store Lyon-Chemin des Gorges',
            ],
            [
                'September 2022',
                '2022-09-02T14:02:36.000Z',
                'Closed Won',
                '0066700000uLZj8AAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)',
            ],
            [
                'September 2022',
                '2022-09-05T07:11:30.000Z',
                'Closed Lost',
                '0066700000uLb5LAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'September 2022',
                '2022-09-05T07:36:17.000Z',
                'Closed Won',
                '0066700000uLb69AAC',
                'Zeplug',
                'France',
                'Company',
                'ChargeGuru Business Services',
            ],
            [
                'September 2022',
                '2022-09-05T07:48:48.000Z',
                'Closed Won',
                '0066700000uLb6dAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'September 2022',
                '2022-09-05T12:55:37.000Z',
                'Closed Won',
                '0066700000uLbTIAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            [
                'September 2022',
                '2022-09-05T13:05:09.000Z',
                'Standby',
                '0066700000uLbU1AAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2022',
                '2022-09-05T14:19:05.000Z',
                'Closed Lost',
                '0066700000uLbaEAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'September 2022',
                '2022-09-05T15:36:51.000Z',
                'Closed Won',
                '0066700000uLbefAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'September 2022',
                '2022-09-06T07:55:57.000Z',
                'Closed Won',
                '0066700000uLc6QAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'September 2022',
                '2022-09-06T08:29:26.000Z',
                'Closed Lost',
                '0066700000uLc8zAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2022',
                '2022-09-06T08:48:24.000Z',
                'Closed Lost',
                '0066700000uLcB5AAK',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'September 2022',
                '2022-09-06T12:31:23.000Z',
                'Closed Won',
                '0066700000uLcUCAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'September 2022',
                '2022-09-06T15:32:54.000Z',
                'Quote sent • Negotiation',
                '0066700000uLcjbAAC',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'September 2022',
                '2022-09-07T07:44:38.000Z',
                'Closed Lost',
                '0066700000uLdEFAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'September 2022',
                '2022-09-07T08:20:48.000Z',
                'Closed Lost',
                '0066700000uLdHxAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'September 2022',
                '2022-09-07T14:29:05.000Z',
                'Closed Lost',
                '0066700000uLdlxAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'September 2022',
                '2022-09-08T08:14:13.000Z',
                'Closed Won',
                '0066700000uLePjAAK',
                'Form Volvo Employee FR',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2022',
                '2022-09-08T13:18:48.000Z',
                'Closed Won',
                '0066700000uLellAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'September 2022',
                '2022-09-08T13:30:19.000Z',
                'Closed Lost',
                '0066700000uLemeAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'September 2022',
                '2022-09-08T16:55:11.000Z',
                'Closed Won',
                '0066700000uLf0qAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo Car France - Nanterre',
            ],
            [
                'September 2022',
                '2022-09-09T08:11:04.000Z',
                'Closed Lost',
                '0066700000uLfRSAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'September 2022',
                '2022-09-09T12:23:32.000Z',
                'Closed Lost',
                '0066700000uLfmVAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'September 2022',
                '2022-09-09T12:51:59.000Z',
                'Closed Lost',
                '0066700000uLfndAAC',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'September 2022',
                '2022-09-12T09:20:34.000Z',
                'Standby',
                '0066700000uLhyeAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'September 2022',
                '2022-09-12T16:59:57.000Z',
                'Closed Won',
                '0066700000uLiVEAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'September 2022',
                '2022-09-13T08:57:35.000Z',
                'Closed Won',
                '0066700000uLj61AAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'September 2022',
                '2022-09-13T14:50:59.000Z',
                'Closed Lost',
                '0066700000uLk7JAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'September 2022',
                '2022-09-13T16:19:08.000Z',
                'Closed Lost',
                '0066700000uLkGaAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'September 2022',
                '2022-09-14T07:37:58.000Z',
                'Closed Won',
                '0066700000uLlEQAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'September 2022',
                '2022-09-14T07:49:24.000Z',
                'Closed Lost',
                '0066700000uLlFTAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'September 2022',
                '2022-09-14T09:20:59.000Z',
                'Closed Lost',
                '0066700000uLlNcAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'September 2022',
                '2022-09-14T12:27:03.000Z',
                'Closed Lost',
                '0066700000uLlcJAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)',
            ],
            [
                'September 2022',
                '2022-09-14T12:29:59.000Z',
                'Closed Won',
                '0066700000uLlcnAAC',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'September 2022',
                '2022-09-15T07:56:55.000Z',
                'Closed Lost',
                '0066700000uLmQOAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'September 2022',
                '2022-09-15T11:55:23.000Z',
                'Closed Lost',
                '0066700000uLmokAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'September 2022',
                '2022-09-15T13:48:01.000Z',
                'Closed Lost',
                '0066700000uLmznAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2022',
                '2022-09-16T10:27:13.000Z',
                'Closed Won',
                '0066700000uLnrYAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'September 2022',
                '2022-09-16T15:08:01.000Z',
                'Closed Lost',
                '0066700000uLo6GAAS',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'September 2022',
                '2022-09-16T16:50:26.000Z',
                'Closed Lost',
                '0066700000uLoCTAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'September 2022',
                '2022-09-19T07:21:21.000Z',
                'Closed Lost',
                '0066700000uLpbUAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'September 2022',
                '2022-09-19T07:48:15.000Z',
                'Closed Won',
                '0066700000uLpd6AAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'September 2022',
                '2022-09-19T15:18:33.000Z',
                'Closed Won',
                '0066700000uLq7QAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'September 2022',
                '2022-09-20T07:55:17.000Z',
                'Standby',
                '0066700000uLqawAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'September 2022',
                '2022-09-20T10:00:22.000Z',
                'Closed Won',
                '0066700000uLqsWAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'September 2022',
                '2022-09-20T10:04:36.000Z',
                'Closed Lost',
                '0066700000uLqtUAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'September 2022',
                '2022-09-20T10:15:45.000Z',
                'Closed Lost',
                '0066700000uLquwAAC',
                'Email',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'September 2022',
                '2022-09-20T13:42:45.000Z',
                'Closed Won',
                '0066700000uLrV4AAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'September 2022',
                '2022-09-20T15:53:17.000Z',
                'Closed Lost',
                '0066700000uLrgRAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'September 2022',
                '2022-09-20T17:26:18.000Z',
                'Closed Lost',
                '0066700000uLs3RAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'September 2022',
                '2022-09-21T16:01:21.000Z',
                'Closed Lost',
                '0066700000uLtLUAA0',
                'Email',
                'France',
                'Single Home',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'September 2022',
                '2022-09-22T09:41:08.000Z',
                'Closed Lost',
                '0066700000uLu5IAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'September 2022',
                '2022-09-22T17:07:03.000Z',
                'Closed Lost',
                '0066700000uLv0JAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'September 2022',
                '2022-09-23T14:00:28.000Z',
                'Closed Lost',
                '0066700000uLvpgAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - ROISSY EN FRANCE',
            ],
            [
                'September 2022',
                '2022-09-26T07:30:57.000Z',
                'Closed Lost',
                '0066700000xKZouAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'September 2022',
                '2022-09-26T07:47:46.000Z',
                'Closed Lost',
                '0066700000xKZq2AAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'September 2022',
                '2022-09-26T08:52:14.000Z',
                'Closed Lost',
                '0066700000xKZypAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'September 2022',
                '2022-09-26T09:04:04.000Z',
                'Closed Won',
                '0066700000xKa0lAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'September 2022',
                '2022-09-26T10:04:42.000Z',
                'Closed Lost',
                '0066700000xKa8pAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'September 2022',
                '2022-09-28T08:16:43.000Z',
                'Closed Lost',
                '0066700000xKcoJAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'September 2022',
                '2022-09-29T07:28:07.000Z',
                'Closed Lost',
                '0066700000xKeLGAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'September 2022',
                '2022-09-29T09:07:00.000Z',
                'Closed Won',
                '0066700000xKeX7AAK',
                'Email',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'September 2022',
                '2022-09-30T08:29:35.000Z',
                'Closed Lost',
                '0066700000xKfvZAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'September 2022',
                '2022-09-30T16:12:16.000Z',
                'Closed Won',
                '0066700000xKgbuAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2022',
                '2022-10-02T15:11:11.000Z',
                'Closed Won',
                '0066700000xKiQ0AAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'October 2022',
                '2022-10-03T09:11:07.000Z',
                'Closed Won',
                '0066700000xKjCxAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'October 2022',
                '2022-10-03T09:48:16.000Z',
                'Closed Won',
                '0066700000xKjIvAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - SAINT MARTIN SUR LE PRE (CHALONS EN CHAMPAGNE)',
            ],
            [
                'October 2022',
                '2022-10-03T12:48:48.000Z',
                'Closed Lost',
                '0066700000xKjcgAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'October 2022',
                '2022-10-04T08:16:09.000Z',
                'Standby',
                '0066700000xKlGIAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2022',
                '2022-10-05T08:38:04.000Z',
                'Closed Lost',
                '0066700000xKnLnAAK',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'October 2022',
                '2022-10-05T12:56:49.000Z',
                'Closed Lost',
                '0066700000xKnu9AAC',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'October 2022',
                '2022-10-05T19:58:58.000Z',
                'Closed Won',
                '0066700000xKoJdAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'October 2022',
                '2022-10-06T09:23:27.000Z',
                'Closed Lost',
                '0066700000xKpIgAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'October 2022',
                '2022-10-06T09:43:35.000Z',
                'Closed Won',
                '0066700000xKpSWAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'October 2022',
                '2022-10-06T12:00:15.000Z',
                'Closed Lost',
                '0066700000xKpiFAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'October 2022',
                '2022-10-07T08:51:14.000Z',
                'Closed Lost',
                '0066700000xKqolAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'ABVV Roissy en France',
            ],
            [
                'October 2022',
                '2022-10-09T08:37:27.000Z',
                'Closed Lost',
                '0066700000xKsAiAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'October 2022',
                '2022-10-09T10:01:23.000Z',
                'Closed Won',
                '0066700000xKsBqAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'October 2022',
                '2022-10-10T08:25:40.000Z',
                'Closed Lost',
                '0066700000xKse6AAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'October 2022',
                '2022-10-10T09:00:21.000Z',
                'Closed Won',
                '0066700000xKsggAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'October 2022',
                '2022-10-11T13:45:59.000Z',
                'Closed Won',
                '0066700000xKuDcAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'October 2022',
                '2022-10-11T16:40:55.000Z',
                'Closed Lost',
                '0066700000xKvFtAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'October 2022',
                '2022-10-12T06:50:05.000Z',
                'Closed Lost',
                '0066700000xKvf8AAC',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'October 2022',
                '2022-10-12T09:09:27.000Z',
                'Closed Lost',
                '0066700000xKvlkAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'October 2022',
                '2022-10-12T09:41:49.000Z',
                'Closed Lost',
                '0066700000xKvncAAC',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'October 2022',
                '2022-10-12T10:29:56.000Z',
                'Closed Won',
                '0066700000xKvs2AAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'October 2022',
                '2022-10-12T10:55:43.000Z',
                'Closed Lost',
                '0066700000xKvuNAAS',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'October 2022',
                '2022-10-13T07:28:54.000Z',
                'Closed Lost',
                '0066700000xKxSzAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'October 2022',
                '2022-10-13T13:30:28.000Z',
                'Closed Won',
                '0066700000xKyQuAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'October 2022',
                '2022-10-14T15:10:48.000Z',
                'Closed Lost',
                '0066700000xL0J2AAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'October 2022',
                '2022-10-17T14:01:03.000Z',
                'Closed Won',
                '0066700000xL1yUAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'October 2022',
                '2022-10-17T14:19:03.000Z',
                'Closed Lost',
                '0066700000xL1zcAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'October 2022',
                '2022-10-17T16:21:20.000Z',
                'Closed Won',
                '0066700000xL28FAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'October 2022',
                '2022-10-18T08:59:44.000Z',
                'Closed Lost',
                '0066700000xL2mKAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
            ],
            [
                'October 2022',
                '2022-10-18T09:40:57.000Z',
                'Closed Lost',
                '0066700000xL2rUAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo - THIBAULT AUTOMOBILES - ALENCON',
            ],
            [
                'October 2022',
                '2022-10-18T12:15:44.000Z',
                'Closed Lost',
                '0066700000xL37SAAS',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'October 2022',
                '2022-10-18T12:25:32.000Z',
                'Waiting for Quote',
                '0066700000xL381AAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'October 2022',
                '2022-10-18T16:42:30.000Z',
                'Closed Won',
                '0066700000xL3VUAA0',
                'Direct',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2022',
                '2022-10-18T17:46:40.000Z',
                'Closed Won',
                '0066700000xL3WcAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2022',
                '2022-10-18T18:24:16.000Z',
                'Closed Lost',
                '0066700000xL3X1AAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'October 2022',
                '2022-10-19T09:19:13.000Z',
                'Closed Lost',
                '0066700000xL4DRAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'October 2022',
                '2022-10-19T14:01:32.000Z',
                'Closed Won',
                '0066700000xL4jrAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'October 2022',
                '2022-10-20T10:13:53.000Z',
                'Closed Lost',
                '0066700000xL5goAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
            ],
            [
                'October 2022',
                '2022-10-21T08:52:34.000Z',
                'Closed Lost',
                '0066700000xL6hYAAS',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'October 2022',
                '2022-10-21T13:50:20.000Z',
                'Closed Lost',
                '0066700000xL6yyAAC',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'October 2022',
                '2022-10-21T14:16:39.000Z',
                'Closed Won',
                '0066700000xL719AAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'October 2022',
                '2022-10-22T04:44:40.000Z',
                'Closed Lost',
                '0066700000xL7S0AAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - SAINT AVIT (MONT DE MARSAN)',
            ],
            [
                'October 2022',
                '2022-10-24T06:24:20.000Z',
                'Closed Lost',
                '0066700000xL8YOAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'October 2022',
                '2022-10-25T08:03:53.000Z',
                'Closed Lost',
                '0066700000xL9xrAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'October 2022',
                '2022-10-25T09:41:12.000Z',
                'Closed Lost',
                '0066700000xLA7wAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'October 2022',
                '2022-10-25T14:35:20.000Z',
                'Closed Lost',
                '0066700000xLAYYAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'October 2022',
                '2022-10-26T05:37:35.000Z',
                'Closed Won',
                '0066700000xLB1QAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'October 2022',
                '2022-10-26T15:58:46.000Z',
                'Closed Won',
                '0066700000xLCJLAA4',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'October 2022',
                '2022-10-27T15:07:59.000Z',
                'Closed Lost',
                '0066700000xLDIsAAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'October 2022',
                '2022-10-27T15:44:36.000Z',
                'Closed Won',
                '0066700000xLDLDAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'October 2022',
                '2022-10-28T16:28:21.000Z',
                'Closed Lost',
                '0066700000xLEQfAAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'October 2022',
                '2022-10-30T08:17:58.000Z',
                'Closed Won',
                '0066700000xLFOBAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'October 2022',
                '2022-10-31T09:40:02.000Z',
                'Closed Lost',
                '0066700000xLFvzAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'October 2022',
                '2022-10-31T14:54:58.000Z',
                'Closed Lost',
                '0066700000xLGdrAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'November 2022',
                '2022-11-02T07:18:35.000Z',
                'Closed Lost',
                '0066700000xLIPDAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - ROISSY EN FRANCE',
            ],
            [
                'November 2022',
                '2022-11-02T10:37:41.000Z',
                'Standby',
                '0066700000xLIfpAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'November 2022',
                '2022-11-02T10:42:18.000Z',
                'Closed Lost',
                '0066700000xLIgOAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'November 2022',
                '2022-11-02T14:32:12.000Z',
                'Closed Lost',
                '0066700000xLIz6AAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'November 2022',
                '2022-11-03T08:52:44.000Z',
                'Closed Lost',
                '0066700000xLJz2AAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'November 2022',
                '2022-11-04T08:45:44.000Z',
                'Closed Lost',
                '0066700000xLLPGAA4',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2022',
                '2022-11-07T09:33:45.000Z',
                'Closed Won',
                '0066700000xLNlnAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'November 2022',
                '2022-11-07T09:34:42.000Z',
                'Closed Won',
                '0066700000xLNlsAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES',
            ],
            [
                'November 2022',
                '2022-11-07T11:16:55.000Z',
                'Closed Lost',
                '0066700000xLNvYAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'November 2022',
                '2022-11-08T08:11:54.000Z',
                'Closed Won',
                '0066700000xLOo9AAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'November 2022',
                '2022-11-08T09:06:06.000Z',
                'Closed Won',
                '0066700000xLOsuAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'November 2022',
                '2022-11-08T10:13:19.000Z',
                'Closed Lost',
                '0066700000xLOz2AAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'November 2022',
                '2022-11-10T07:17:37.000Z',
                'Closed Lost',
                '0066700000xLXwEAAW',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'November 2022',
                '2022-11-10T17:18:51.000Z',
                'Closed Won',
                '0066700000xLYrqAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'November 2022',
                '2022-11-12T08:37:16.000Z',
                'Closed Lost',
                '0066700000xLaBlAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'November 2022',
                '2022-11-14T10:28:31.000Z',
                'Quote sent • Negotiation',
                '0066700000xLcksAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'November 2022',
                '2022-11-16T14:31:35.000Z',
                'Closed Won',
                '0066700000xLi51AAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'November 2022',
                '2022-11-17T10:20:18.000Z',
                'Quote sent • Negotiation',
                '0066700000xLjcQAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'November 2022',
                '2022-11-17T11:56:11.000Z',
                'Closed Lost',
                '0066700000xLjlmAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'November 2022',
                '2022-11-17T12:01:00.000Z',
                'Closed Lost',
                '0066700000xLjn4AAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'November 2022',
                '2022-11-18T13:15:13.000Z',
                'Closed Won',
                '0066700000xLoQEAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2022',
                '2022-11-20T15:13:14.000Z',
                'Closed Lost',
                '0066700000xLu5bAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'November 2022',
                '2022-11-20T16:22:15.000Z',
                'Closed Lost',
                '0066700000xLu60AAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'November 2022',
                '2022-11-25T09:09:45.000Z',
                'Closed Lost',
                '0066700000xLzrLAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'November 2022',
                '2022-11-25T11:01:55.000Z',
                'Closed Lost',
                '0066700000xM01VAAS',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'November 2022',
                '2022-11-25T11:21:05.000Z',
                'Closed Lost',
                '0066700000xM037AAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'November 2022',
                '2022-11-29T14:08:16.000Z',
                'Quote sent • Negotiation',
                '0066700000xM3MCAA0',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'November 2022',
                '2022-11-29T15:15:12.000Z',
                'Quote sent • Negotiation',
                '0066700000xM3T3AAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'November 2022',
                '2022-11-30T09:23:42.000Z',
                'Closed Lost',
                '0066700000xM4DuAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  CAR'S SERVICES - ONET LE CHATEAU (RODEZ)",
            ],
            [
                'November 2022',
                '2022-11-30T20:53:42.000Z',
                'Closed Lost',
                '0066700000xM4tUAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'December 2022',
                '2022-12-01T10:07:39.000Z',
                'Closed Lost',
                '0066700000xM5NFAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
            ],
            [
                'December 2022',
                '2022-12-01T12:27:34.000Z',
                'Closed Won',
                '0066700000xM5ZzAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'December 2022',
                '2022-12-01T16:59:10.000Z',
                'Closed Won',
                '0066700000xM5tLAAS',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'December 2022',
                '2022-12-01T17:51:48.000Z',
                'Closed Won',
                '0066700000xM5vvAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'December 2022',
                '2022-12-02T09:45:20.000Z',
                'Closed Lost',
                '0066700000xM6PMAA0',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'December 2022',
                '2022-12-02T10:51:02.000Z',
                'Closed Won',
                '0066700000xM6UIAA0',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'December 2022',
                '2022-12-02T14:13:25.000Z',
                'Closed Lost',
                '0066700000xM6hzAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'December 2022',
                '2022-12-02T20:32:52.000Z',
                'Closed Lost',
                '0066700000xM7ASAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'December 2022',
                '2022-12-04T11:14:27.000Z',
                'Closed Lost',
                '0066700000xM7zzAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'December 2022',
                '2022-12-06T13:35:25.000Z',
                'Closed Won',
                '0066700000xMAKnAAO',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'December 2022',
                '2022-12-06T14:42:32.000Z',
                'Closed Won',
                '0066700000xMAQ7AAO',
                'Email',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'December 2022',
                '2022-12-06T16:37:19.000Z',
                'Closed Lost',
                '0066700000xMAYpAAO',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'December 2022',
                '2022-12-07T09:17:54.000Z',
                'Closed Lost',
                '0066700000xMBLhAAO',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - ROISSY EN FRANCE',
            ],
            [
                'December 2022',
                '2022-12-08T08:44:24.000Z',
                'Closed Lost',
                '0066700000xMCNGAA4',
                'Email',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'December 2022',
                '2022-12-08T11:44:35.000Z',
                'Closed Lost',
                '0066700000xMCaeAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'December 2022',
                '2022-12-08T14:30:35.000Z',
                'Closed Lost',
                '0066700000xMCijAAG',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'December 2022',
                '2022-12-09T09:01:00.000Z',
                'Closed Won',
                '0066700000xMDIRAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'December 2022',
                '2022-12-09T10:59:36.000Z',
                'Closed Lost',
                '0066700000xMDU8AAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'December 2022',
                '2022-12-09T17:43:11.000Z',
                'Closed Lost',
                '0066700000xMECZAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'December 2022',
                '2022-12-12T13:24:42.000Z',
                'Closed Lost',
                '0066700000xMFwRAAW',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'December 2022',
                '2022-12-12T16:04:16.000Z',
                'Closed Lost',
                '0066700000xMG70AAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'December 2022',
                '2022-12-12T19:29:48.000Z',
                'Closed Lost',
                '0066700000xMGF9AAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'December 2022',
                '2022-12-14T09:54:32.000Z',
                'Closed Lost',
                '0066700000xMIjrAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'December 2022',
                '2022-12-14T10:18:52.000Z',
                'Closed Won',
                '0066700000xMIlsAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'December 2022',
                '2022-12-16T13:06:23.000Z',
                'Closed Lost',
                '0066700000xMLU7AAO',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
            ],
            [
                'December 2022',
                '2022-12-18T19:18:02.000Z',
                'Closed Lost',
                '0066700000xMNmJAAW',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'December 2022',
                '2022-12-19T10:22:26.000Z',
                'Closed Lost',
                '0066700000xMOSAAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'December 2022',
                '2022-12-19T11:13:28.000Z',
                'Closed Lost',
                '0066700000xMOYwAAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'December 2022',
                '2022-12-19T14:54:35.000Z',
                'Closed Lost',
                '0066700000xMOs2AAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'December 2022',
                '2022-12-19T15:19:36.000Z',
                'Waiting for Quote',
                '0066700000xMOwaAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'December 2022',
                '2022-12-19T17:06:44.000Z',
                'Closed Lost',
                '0066700000xMP4QAAW',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'December 2022',
                '2022-12-19T17:18:43.000Z',
                'Closed Lost',
                '0066700000xMP5IAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'December 2022',
                '2022-12-20T10:30:17.000Z',
                'Closed Won',
                '0066700000xMQ04AAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'December 2022',
                '2022-12-20T14:31:13.000Z',
                'Closed Lost',
                '0066700000xMQJ4AAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2022',
                '2022-12-21T22:59:08.000Z',
                'Closed Lost',
                '0066700000xMRgYAAW',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'December 2022',
                '2022-12-26T15:24:08.000Z',
                'Closed Lost',
                '0066700000xMUX1AAO',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE DROITE - LORMONT (BORDEAUX)',
            ],
            [
                'December 2022',
                '2022-12-27T13:31:13.000Z',
                'Closed Lost',
                '0066700000xMV8hAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'December 2022',
                '2022-12-27T17:15:56.000Z',
                'Closed Won',
                '0066700000xMVIgAAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'December 2022',
                '2022-12-28T10:50:32.000Z',
                'Closed Lost',
                '0066700000xMVsFAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'December 2022',
                '2022-12-28T19:14:47.000Z',
                'Standby',
                '0066700000xMWD3AAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'December 2022',
                '2022-12-28T19:48:46.000Z',
                'Closed Lost',
                '0066700000xMWDNAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'December 2022',
                '2022-12-29T15:38:59.000Z',
                'Closed Lost',
                '0066700000xMX9AAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)',
            ],
            [
                'December 2022',
                '2022-12-30T09:49:54.000Z',
                'Closed Lost',
                '0066700000xMXd5AAG',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'January 2023',
                '2023-01-02T11:44:21.000Z',
                'Waiting for Quote',
                '0066700000xMZBfAAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - DISTRE (SAUMUR)',
            ],
            [
                'January 2023',
                '2023-01-02T17:52:40.000Z',
                'Closed Won',
                '0066700000xMZQ6AAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'January 2023',
                '2023-01-03T12:52:12.000Z',
                'Closed Lost',
                '0066700000xMZzdAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'January 2023',
                '2023-01-03T14:43:23.000Z',
                'Closed Lost',
                '0066700000xMa4OAAS',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'January 2023',
                '2023-01-03T15:40:49.000Z',
                'Closed Lost',
                '0066700000xMa8aAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'January 2023',
                '2023-01-03T18:03:19.000Z',
                'Closed Lost',
                '0066700000xMaFMAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - DISTRE (SAUMUR)',
            ],
            [
                'January 2023',
                '2023-01-05T14:54:11.000Z',
                'Closed Lost',
                '0066700000xMcGmAAK',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'January 2023',
                '2023-01-05T16:29:49.000Z',
                'Closed Lost',
                '0066700000xMcN4AAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'January 2023',
                '2023-01-06T08:21:56.000Z',
                'Closed Lost',
                '0066700000xMcmOAAS',
                'Email',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'January 2023',
                '2023-01-09T17:52:42.000Z',
                'Closed Lost',
                '0066700000xMfH9AAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'January 2023',
                '2023-01-10T09:15:34.000Z',
                'Closed Lost',
                '0066700000xMfhqAAC',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'January 2023',
                '2023-01-11T09:33:05.000Z',
                'Standby',
                '0066700000xMgrXAAS',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'January 2023',
                '2023-01-12T11:54:40.000Z',
                'Closed Won',
                '0066700000xMiD6AAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'January 2023',
                '2023-01-13T13:37:32.000Z',
                'Closed Lost',
                '0066700000xxiTtAAI',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'January 2023',
                '2023-01-13T15:24:54.000Z',
                'Closed Won',
                '0066700000xxiaLAAQ',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'January 2023',
                '2023-01-14T18:09:16.000Z',
                'Closed Lost',
                '0066700000xxjFdAAI',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - ROISSY EN FRANCE',
            ],
            [
                'January 2023',
                '2023-01-16T10:08:49.000Z',
                'Closed Lost',
                '0066700000xxkJ6AAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo Car France',
            ],
            [
                'January 2023',
                '2023-01-16T14:28:48.000Z',
                'Closed Lost',
                '0066700000xxkdzAAA',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'January 2023',
                '2023-01-16T16:23:31.000Z',
                'Closed Lost',
                '0066700000xxknGAAQ',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'January 2023',
                '2023-01-17T13:52:47.000Z',
                'Closed Lost',
                '0066700000xxljrAAA',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'January 2023',
                '2023-01-18T13:20:03.000Z',
                'Closed Lost',
                '0066700000xxn67AAA',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'January 2023',
                '2023-01-18T14:20:25.000Z',
                'Closed Won',
                '0066700000xxn9kAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'January 2023',
                '2023-01-18T15:18:29.000Z',
                'Closed Won',
                '0066700000xxnUJAAY',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'January 2023',
                '2023-01-18T16:40:35.000Z',
                'Standby',
                '0066700000xxnaRAAQ',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'January 2023',
                '2023-01-20T09:05:26.000Z',
                'Closed Won',
                '0066700000xxpa4AAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'January 2023',
                '2023-01-20T10:45:32.000Z',
                'Closed Lost',
                '0066700000xxpjeAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'January 2023',
                '2023-01-20T13:10:36.000Z',
                'Closed Won',
                '0066700000xxprnAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'January 2023',
                '2023-01-20T15:16:48.000Z',
                'Closed Won',
                '0066700000xxpzTAAQ',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'January 2023',
                '2023-01-23T08:43:38.000Z',
                'Closed Lost',
                '0066700000xxrRmAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'January 2023',
                '2023-01-23T13:15:20.000Z',
                'Closed Lost',
                '0066700000xxroHAAQ',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'January 2023',
                '2023-01-25T16:49:29.000Z',
                'Closed Won',
                '0066700000xxulLAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VILLEFRANCHE-SUR-SAÔNE',
            ],
            [
                'January 2023',
                '2023-01-25T17:13:42.000Z',
                'Closed Lost',
                '0066700000xxumJAAQ',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'January 2023',
                '2023-01-26T16:04:56.000Z',
                'Closed Lost',
                '0066700000xxwIyAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            [
                'January 2023',
                '2023-01-27T13:08:44.000Z',
                'Standby',
                '0066700000xxxO0AAI',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  PERICAUD AUTOMOBILES - CHAMPNIERS (ANGOULEME)',
            ],
            [
                'January 2023',
                '2023-01-27T13:18:17.000Z',
                'Quote sent • Negotiation',
                '0066700000xxxOUAAY',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - CHAMPNIERS (ANGOULEME)',
            ],
            [
                'January 2023',
                '2023-01-27T13:32:18.000Z',
                'Standby',
                '0066700000xxxPDAAY',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - CHAMPNIERS (ANGOULEME)',
            ],
            [
                'January 2023',
                '2023-01-30T15:16:36.000Z',
                'Closed Won',
                '0066700000xy0Y8AAI',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'January 2023',
                '2023-01-30T16:42:06.000Z',
                'Closed Won',
                '0066700000xy0fTAAQ',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'January 2023',
                '2023-01-30T16:45:39.000Z',
                'Quote sent • Negotiation',
                '0066700000xy0fdAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE SAINT ETIENNE - SAINT ETIENNE',
            ],
            [
                'January 2023',
                '2023-01-31T14:39:07.000Z',
                'Standby',
                '0066700000xy1heAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - LIMOGES',
            ],
            [
                'February 2023',
                '2023-02-01T15:53:44.000Z',
                'Closed Lost',
                '0066700000xy3HBAAY',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'February 2023',
                '2023-02-02T09:01:13.000Z',
                'Standby',
                '0066700000xy3svAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'February 2023',
                '2023-02-02T10:52:10.000Z',
                'Closed Lost',
                '0066700000xy40pAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'February 2023',
                '2023-02-02T16:41:24.000Z',
                'Closed Lost',
                '0066700000xy4hZAAQ',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'February 2023',
                '2023-02-03T16:50:03.000Z',
                'Closed Lost',
                '0066700000xy5sdAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'February 2023',
                '2023-02-05T17:46:09.000Z',
                'Closed Lost',
                '0066700000xy6uVAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'February 2023',
                '2023-02-07T07:34:29.000Z',
                'Closed Won',
                '0066700000xy8PAAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'February 2023',
                '2023-02-07T09:23:34.000Z',
                'Closed Won',
                '0066700000xy8WuAAI',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'February 2023',
                '2023-02-07T09:47:10.000Z',
                'Closed Lost',
                '0066700000xy8YvAAI',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'February 2023',
                '2023-02-08T15:00:28.000Z',
                'Closed Lost',
                '0066700000xyA5gAAE',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'February 2023',
                '2023-02-09T16:00:06.000Z',
                'Closed Won',
                '0066700000xyBJjAAM',
                'Zeplug',
                'France',
                'Company',
                'ChargeGuru Business Services',
            ],
            [
                'February 2023',
                '2023-02-09T18:33:26.000Z',
                'Closed Won',
                '0066700000xyBQpAAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'February 2023',
                '2023-02-11T17:27:58.000Z',
                'Closed Won',
                '0066700000xyD88AAE',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'February 2023',
                '2023-02-12T17:39:29.000Z',
                'Closed Won',
                '0066700000xyDg3AAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'February 2023',
                '2023-02-13T12:34:25.000Z',
                'Closed Won',
                '0066700000xyEKXAA2',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'February 2023',
                '2023-02-14T15:07:21.000Z',
                'Closed Lost',
                '0066700000xyFiQAAU',
                'Volvo dealership Form',
                'France',
                'Company',
                'ELYPSE AUTO',
            ],
            [
                'February 2023',
                '2023-02-16T09:43:28.000Z',
                'Closed Lost',
                '0066700000xyHMHAA2',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            [
                'February 2023',
                '2023-02-17T11:51:44.000Z',
                'Closed Lost',
                '0066700000xyIWrAAM',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'February 2023',
                '2023-02-21T12:48:12.000Z',
                'Waiting for Quote',
                '0066700000xyOZSAA2',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'February 2023',
                '2023-02-21T13:09:22.000Z',
                'Closed Won',
                '0066700000xyOb4AAE',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'February 2023',
                '2023-02-21T15:10:01.000Z',
                'Closed Lost',
                '0066700000xyOkQAAU',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'February 2023',
                '2023-02-21T15:42:56.000Z',
                'Closed Lost',
                '0066700000xyOn5AAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)',
            ],
            [
                'February 2023',
                '2023-02-23T13:18:04.000Z',
                'Closed Lost',
                '0066700000xyRApAAM',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'February 2023',
                '2023-02-24T11:45:16.000Z',
                'Closed Lost',
                '0066700000xySECAA2',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'February 2023',
                '2023-02-27T11:27:40.000Z',
                'Closed Won',
                '0066700000xyWIfAAM',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'February 2023',
                '2023-02-27T16:48:35.000Z',
                'Closed Lost',
                '0066700000xyWnTAAU',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)',
            ],
            [
                'February 2023',
                '2023-02-27T20:34:54.000Z',
                'Waiting for Quote',
                '0066700000xyWuUAAU',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AUTO PREMIUM 08 - CHARLEVILLE MEZIERES',
            ],
            [
                'March 2023',
                '2023-03-01T07:19:13.000Z',
                'Closed Lost',
                '0066700000xyYTSAA2',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'March 2023',
                '2023-03-05T11:19:19.000Z',
                'Standby',
                '0066700000xyg3EAAQ',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'March 2023',
                '2023-03-08T14:43:24.000Z',
                'Closed Won',
                '0066700000xyku2AAA',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'March 2023',
                '2023-03-10T10:43:20.000Z',
                'Waiting for Quote',
                '0066700000xynAMAAY',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'March 2023',
                '2023-03-10T14:46:32.000Z',
                'Standby',
                '0066700000xynmoAAA',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'March 2023',
                '2023-03-10T16:30:40.000Z',
                'Closed Lost',
                '0066700000xynwQAAQ',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2023',
                '2023-03-13T08:10:19.000Z',
                'Closed Lost',
                '0066700000xyrHeAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2023',
                '2023-03-13T10:57:27.000Z',
                'Closed Won',
                '0066700000xyraCAAQ',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'March 2023',
                '2023-03-14T19:04:30.000Z',
                'Closed Lost',
                '0066700000xyuGFAAY',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'March 2023',
                '2023-03-15T13:47:28.000Z',
                'Closed Lost',
                '0066700000xyvRxAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'March 2023',
                '2023-03-16T20:58:50.000Z',
                'Closed Lost',
                '0066700000xyxtyAAA',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PRESTIGE CARS - TOULON SUR ALLIER (MOULIN)',
            ],
            [
                'March 2023',
                '2023-03-17T08:29:40.000Z',
                'Closed Won',
                '0066700000xyyIFAAY',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'March 2023',
                '2023-03-17T13:59:42.000Z',
                'Qualification',
                '0066700000xyyk0AAA',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'March 2023',
                '2023-03-21T11:24:06.000Z',
                'Closed Won',
                '0066700000xz2XjAAI',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'March 2023',
                '2023-03-21T18:21:02.000Z',
                'Closed Won',
                '0066700000xz38jAAA',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'March 2023',
                '2023-03-22T11:17:55.000Z',
                'Closed Won',
                '0066700000xz3wAAAQ',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'March 2023',
                '2023-03-24T19:52:22.000Z',
                'Closed Lost',
                '0066700000xz7DiAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - SAINT MARTIN SUR LE PRE (CHALONS EN CHAMPAGNE)',
            ],
            [
                'March 2023',
                '2023-03-27T12:46:38.000Z',
                'Closed Won',
                '0066700000xz9c7AAA',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'March 2023',
                '2023-03-27T14:36:02.000Z',
                'Closed Lost',
                '0066700000xz9mzAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'March 2023',
                '2023-03-27T15:25:21.000Z',
                'Closed Lost',
                '0066700000xz9qrAAA',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'March 2023',
                '2023-03-28T08:21:32.000Z',
                'Closed Lost',
                '0066700000xzAUIAA2',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'March 2023',
                '2023-03-28T14:33:30.000Z',
                'Closed Won',
                '0066700000xzB52AAE',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'March 2023',
                '2023-03-31T15:47:37.000Z',
                'Closed Won',
                '0066700000xzF0JAAU',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'April 2023',
                '2023-04-03T10:41:46.000Z',
                'Closed Lost',
                '0066700000xzH3eAAE',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'April 2023',
                '2023-04-03T18:35:13.000Z',
                'Quote sent • Negotiation',
                '0066700000xzHv7AAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'April 2023',
                '2023-04-04T14:06:24.000Z',
                'Closed Won',
                '0066700000xzJ8YAAU',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'April 2023',
                '2023-04-05T10:06:08.000Z',
                'Closed Won',
                '0066700000xzJzAAAU',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'April 2023',
                '2023-04-05T10:12:01.000Z',
                'Waiting for Quote',
                '0066700000xzJztAAE',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'April 2023',
                '2023-04-06T08:48:59.000Z',
                'Closed Won',
                '0066700000xzLfjAAE',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'April 2023',
                '2023-04-06T08:55:52.000Z',
                'Closed Lost',
                '0066700000xzLfaAAE',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'April 2023',
                '2023-04-07T08:01:58.000Z',
                'Closed Lost',
                '0066700000xzMYtAAM',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'April 2023',
                '2023-04-11T14:19:21.000Z',
                'Closed Lost',
                '0066700000xzPDQAA2',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'April 2023',
                '2023-04-12T14:16:50.000Z',
                'Closed Won',
                '0066700000xzQC2AAM',
                'Email',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'April 2023',
                '2023-04-12T16:11:32.000Z',
                'Closed Lost',
                '0066700000xzQJIAA2',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'April 2023',
                '2023-04-12T16:19:22.000Z',
                'Closed Lost',
                '0066700000xzQJmAAM',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'April 2023',
                '2023-04-14T18:06:51.000Z',
                'Qualification',
                '0066700000xzSTRAA2',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'April 2023',
                '2023-04-17T15:45:20.000Z',
                'Closed Lost',
                '0066700000xzUXlAAM',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'April 2023',
                '2023-04-17T20:10:07.000Z',
                'Closed Lost',
                '0066700000xzUerAAE',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NÖRDIK STAR AUTOS - CHALON SUR SAÔNE',
            ],
            [
                'April 2023',
                '2023-04-18T13:05:45.000Z',
                'Closed Won',
                '0066700000xzVafAAE',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'April 2023',
                '2023-04-21T08:21:57.000Z',
                'Closed Lost',
                '0066700000xzagpAAA',
                'Phone',
                'France',
                'Single Home',
                'Volvo - EVERLINE - DENNEY (BELFORT)',
            ],
            [
                'April 2023',
                '2023-04-22T07:31:41.000Z',
                'Waiting for Quote',
                '0066700000xzbsIAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'April 2023',
                '2023-04-23T15:10:14.000Z',
                'Closed Won',
                '0066700000xzckMAAQ',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'April 2023',
                '2023-04-24T15:16:07.000Z',
                'Closed Lost',
                '0066700000xzfFSAAY',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'April 2023',
                '2023-04-25T08:37:03.000Z',
                'Closed Lost',
                '0066700000xzg1RAAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'April 2023',
                '2023-04-25T19:04:19.000Z',
                'Standby',
                '0066700000xzhEdAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'April 2023',
                '2023-04-26T18:01:53.000Z',
                'Closed Lost',
                '0066700000xzjdbAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                "Volvo -  FIRST AUTOMOBILES - VILLENEUVE D'ASCQ",
            ],
            [
                'April 2023',
                '2023-04-27T16:36:17.000Z',
                'Closed Lost',
                '0066700000xzlIfAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'April 2023',
                '2023-04-28T13:36:39.000Z',
                'Closed Lost',
                '0066700000xzmqsAAA',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'May 2023',
                '2023-05-03T21:41:24.000Z',
                'Closed Won',
                '0066700000xzsswAAA',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NÖRDIK STAR AUTOS - CHALON SUR SAÔNE',
            ],
            [
                'May 2023',
                '2023-05-04T08:41:45.000Z',
                'Closed Lost',
                '0066700000xztn6AAA',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'May 2023',
                '2023-05-06T17:53:32.000Z',
                'Closed Won',
                '0066700000xzxZ0AAI',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'May 2023',
                '2023-05-09T08:40:29.000Z',
                'Closed Won',
                '0066700000xzzyxAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'May 2023',
                '2023-05-09T09:12:19.000Z',
                'Closed Lost',
                '0066700000y0034AAA',
                'Zeplug',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'May 2023',
                '2023-05-17T08:11:52.000Z',
                'Closed Lost',
                '0066700000y09PZAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'May 2023',
                '2023-05-26T15:41:38.000Z',
                'Closed Lost',
                '0066700000y0KX1AAM',
                'Email',
                'France',
                'Company',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'May 2023',
                '2023-05-31T14:07:53.000Z',
                'Quote sent • Negotiation',
                '0066700000y0OlkAAE',
                'Email',
                'France',
                'Company',
                'ChargeGuru',
            ],
            [
                'June 2023',
                '2023-06-01T08:02:47.000Z',
                'Closed Lost',
                '0066700000y0PjqAAE',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'June 2023',
                '2023-06-01T08:45:41.000Z',
                'Closed Lost',
                '0066700000y0PpYAAU',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2023',
                '2023-06-05T08:12:14.000Z',
                'Closed Lost',
                '0066700000y0SpbAAE',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'June 2023',
                '2023-06-06T08:03:13.000Z',
                'Closed Won',
                '0066700000y0UeuAAE',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'June 2023',
                '2023-06-08T16:06:36.000Z',
                'Closed Lost',
                '00667000010F125AAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'June 2023',
                '2023-06-09T12:06:49.000Z',
                'Closed Lost',
                '00667000010F1q5AAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'June 2023',
                '2023-06-09T18:26:25.000Z',
                'Quote sent • Negotiation',
                '00667000010F25PAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'June 2023',
                '2023-06-13T07:36:36.000Z',
                'Quote sent • Negotiation',
                '00667000010F4rCAAS',
                'Zeplug',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'June 2023',
                '2023-06-13T20:32:23.000Z',
                'Quote sent • Negotiation',
                '00667000010F5zqAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'June 2023',
                '2023-06-14T12:46:17.000Z',
                'Closed Lost',
                '00667000010F6tFAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'June 2023',
                '2023-06-15T08:00:46.000Z',
                'Quote sent • Negotiation',
                '00667000010F7i8AAC',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'June 2023',
                '2023-06-19T10:00:31.000Z',
                'Closed Lost',
                '00667000010FB3iAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'June 2023',
                '2023-06-19T16:40:21.000Z',
                'Closed Lost',
                '00667000010FBT2AAO',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'June 2023',
                '2023-06-22T10:51:03.000Z',
                'Standby',
                '00667000010FEYKAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'June 2023',
                '2023-06-23T08:50:08.000Z',
                'Closed Won',
                '00667000010FFPeAAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - LIMOGES',
            ],
            [
                'June 2023',
                '2023-06-23T09:28:27.000Z',
                'Quote sent • Negotiation',
                '00667000010FFSwAAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'June 2023',
                '2023-06-23T13:19:33.000Z',
                'Standby',
                '00667000010FFflAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'June 2023',
                '2023-06-25T15:13:01.000Z',
                'Closed Lost',
                '00667000010FGrQAAW',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'June 2023',
                '2023-06-26T10:28:45.000Z',
                'Closed Lost',
                '00667000010FHY5AAO',
                'Email',
                'France',
                'Single Home',
                'JMJ Automobiles',
            ],
            [
                'June 2023',
                '2023-06-30T20:58:32.000Z',
                'Closed Won',
                '00667000010FMprAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'July 2023',
                '2023-07-03T17:19:25.000Z',
                'Quote sent • Negotiation',
                '00667000010FQfcAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'July 2023',
                '2023-07-05T14:52:53.000Z',
                'Closed Lost',
                '00667000010FUVbAAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'July 2023',
                '2023-07-07T07:39:51.000Z',
                'Closed Won',
                '00667000010FY8zAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'July 2023',
                '2023-07-07T14:22:46.000Z',
                'Closed Lost',
                '00667000010FYbrAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'July 2023',
                '2023-07-12T14:22:43.000Z',
                'Closed Lost',
                '00667000010FdtbAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'July 2023',
                '2023-07-18T13:50:36.000Z',
                'Closed Won',
                '00667000010FjinAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'July 2023',
                '2023-07-18T14:10:39.000Z',
                'Closed Lost',
                '00667000010FjkyAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - CHAMPNIERS (ANGOULEME)',
            ],
            [
                'July 2023',
                '2023-07-20T16:12:36.000Z',
                'Closed Won',
                '00667000010FmUoAAK',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'July 2023',
                '2023-07-21T14:30:29.000Z',
                'Closed Won',
                '00667000010FnPGAA0',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'July 2023',
                '2023-07-24T18:01:51.000Z',
                'Closed Won',
                '00667000010FpscAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'July 2023',
                '2023-07-26T15:11:01.000Z',
                'Qualification',
                '00667000010FsJ6AAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'July 2023',
                '2023-07-26T17:03:40.000Z',
                'Closed Lost',
                '00667000010FsNNAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'July 2023',
                '2023-07-28T07:23:24.000Z',
                'Closed Won',
                '00667000010FtpmAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'August 2023',
                '2023-08-01T14:14:02.000Z',
                'Closed Won',
                '00667000010FxmnAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'August 2023',
                '2023-08-08T14:03:09.000Z',
                'Closed Lost',
                '00667000010SWN1AAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'August 2023',
                '2023-08-16T15:08:59.000Z',
                'Closed Lost',
                '00667000010Se0SAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'August 2023',
                '2023-08-24T18:00:23.000Z',
                'Quote sent • Negotiation',
                '00667000010SkEsAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'August 2023',
                '2023-08-25T10:28:23.000Z',
                'Closed Won',
                '00667000010SkavAAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'August 2023',
                '2023-08-30T07:33:10.000Z',
                'Closed Won',
                '00667000010SnbKAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2023',
                '2023-08-30T13:06:36.000Z',
                'Closed Won',
                '00667000010So05AAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'September 2023',
                '2023-09-01T08:13:25.000Z',
                'Closed Won',
                '00667000010SpDUAA0',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'September 2023',
                '2023-09-06T09:08:09.000Z',
                'Closed Lost',
                '00667000010StHSAA0',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'September 2023',
                '2023-09-06T11:30:12.000Z',
                'Closed Lost',
                '00667000010StwcAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'September 2023',
                '2023-09-06T13:14:52.000Z',
                'Quote sent • Negotiation',
                '00667000010SuA0AAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'September 2023',
                '2023-09-06T16:00:14.000Z',
                'Quote sent • Negotiation',
                '00667000010SuPoAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - EVERLINE - DENNEY (BELFORT)',
            ],
            [
                'September 2023',
                '2023-09-11T07:38:04.000Z',
                'Closed Lost',
                '00667000010SxPPAA0',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2023',
                '2023-09-11T19:15:11.000Z',
                'Quote sent • Negotiation',
                '00667000010SycOAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
            ],
            [
                'September 2023',
                '2023-09-12T14:32:54.000Z',
                'Quote sent • Negotiation',
                '00667000010SzUnAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'September 2023',
                '2023-09-12T14:57:41.000Z',
                'Standby',
                '00667000010SzYIAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'September 2023',
                '2023-09-13T16:50:41.000Z',
                'Standby',
                '00667000010T1BzAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'September 2023',
                '2023-09-17T12:55:32.000Z',
                'Quote sent • Negotiation',
                '00667000010T4KUAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'September 2023',
                '2023-09-18T09:31:40.000Z',
                'Closed Lost',
                '00667000010T4l6AAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2023',
                '2023-09-18T18:05:34.000Z',
                'Closed Won',
                '00667000010T5RzAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'September 2023',
                '2023-09-19T14:51:01.000Z',
                'Quote sent • Negotiation',
                '00667000010T6OSAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'September 2023',
                '2023-09-20T13:14:46.000Z',
                'Quote sent • Negotiation',
                '00667000010T7O6AAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'September 2023',
                '2023-09-21T14:15:51.000Z',
                'Closed Lost',
                '00667000010T8UtAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2023',
                '2023-09-22T08:42:42.000Z',
                'Closed Lost',
                '00667000010T9G9AAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'September 2023',
                '2023-09-26T10:00:50.000Z',
                'Quote sent • Negotiation',
                '00667000010TDB7AAO',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'September 2023',
                '2023-09-26T12:14:28.000Z',
                'Quote sent • Negotiation',
                '00667000010TDJ7AAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - REDON',
            ],
            [
                'September 2023',
                '2023-09-27T10:00:00.000Z',
                'Closed Won',
                '00667000010TEHTAA4',
                'Email',
                'France',
                'Single Home',
                'ABVV Chantilly',
            ],
            [
                'September 2023',
                '2023-09-27T12:34:29.000Z',
                'Waiting for Quote',
                '00667000010TEVGAA4',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'September 2023',
                '2023-09-28T15:08:23.000Z',
                'Closed Lost',
                '00667000010TFlxAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2023',
                '2023-09-28T19:18:08.000Z',
                'Closed Lost',
                '00667000010TFsoAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'October 2023',
                '2023-10-04T07:28:21.000Z',
                'Closed Lost',
                '00667000010TJcmAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'October 2023',
                '2023-10-06T11:04:50.000Z',
                'Closed Lost',
                '00667000010TLsxAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'October 2023',
                '2023-10-07T09:37:07.000Z',
                'Closed Lost',
                '00667000010TMLmAAO',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'October 2023',
                '2023-10-08T16:43:02.000Z',
                'Quote sent • Negotiation',
                '00667000010TMbaAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'October 2023',
                '2023-10-09T05:24:58.000Z',
                'Closed Lost',
                '00667000010TMeZAAW',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'October 2023',
                '2023-10-09T07:38:16.000Z',
                'Closed Lost',
                '00667000010TMhsAAG',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'October 2023',
                '2023-10-12T14:07:06.000Z',
                'Quote sent • Negotiation',
                '00667000010TPuOAAW',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'October 2023',
                '2023-10-14T12:22:09.000Z',
                'Quote sent • Negotiation',
                '00667000010TQrnAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'October 2023',
                '2023-10-16T07:17:35.000Z',
                'Qualification',
                '00667000010TRJHAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'October 2023',
                '2023-10-16T07:48:14.000Z',
                'Quote sent • Negotiation',
                '00667000010TRKKAA4',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'October 2023',
                '2023-10-16T13:42:37.000Z',
                'Closed Won',
                '00667000010TRsJAAW',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'October 2023',
                '2023-10-16T15:11:24.000Z',
                'Closed Lost',
                '00667000010TRy2AAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'October 2023',
                '2023-10-17T11:57:06.000Z',
                'Closed Lost',
                '00667000010TSe7AAG',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'October 2023',
                '2023-10-19T09:34:23.000Z',
                'Waiting for Quote',
                '00667000010TU2xAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'October 2023',
                '2023-10-19T11:45:13.000Z',
                'Quote sent • Negotiation',
                '00667000010TUBQAA4',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'October 2023',
                '2023-10-22T16:07:18.000Z',
                'Quote sent • Negotiation',
                '00667000010TVy6AAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'October 2023',
                '2023-10-22T16:27:13.000Z',
                'Quote sent • Negotiation',
                '00667000010TVyvAAG',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'October 2023',
                '2023-10-23T09:43:35.000Z',
                'Closed Won',
                '00667000010TWKMAA4',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'October 2023',
                '2023-10-24T08:21:41.000Z',
                'Closed Lost',
                '00667000010jtJ3AAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2023',
                '2023-10-24T08:50:10.000Z',
                'Closed Lost',
                '00667000010jtKqAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2023',
                '2023-10-25T12:50:51.000Z',
                'Quote sent • Negotiation',
                '00667000010jvXBAAY',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'October 2023',
                '2023-10-26T09:07:17.000Z',
                'Closed Won',
                '00667000010jwMtAAI',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - DISTRE (SAUMUR)',
            ],
            [
                'October 2023',
                '2023-10-27T10:47:36.000Z',
                'Waiting for Quote',
                '00667000010jxSOAAY',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2023',
                '2023-10-30T11:02:37.000Z',
                'Quote sent • Negotiation',
                '00667000010jya6AAA',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'October 2023',
                '2023-10-30T15:50:02.000Z',
                'Quote sent • Negotiation',
                '00667000010jz6yAAA',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2023',
                '2023-10-30T15:56:28.000Z',
                'Closed Lost',
                '00667000010jz7hAAA',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'October 2023',
                '2023-10-31T14:52:21.000Z',
                'Quote sent • Negotiation',
                '00667000010k0VgAAI',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'November 2023',
                '2023-11-03T10:52:34.000Z',
                'Closed Lost',
                '00667000010k8iPAAQ',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  CAR'S SERVICES - ONET LE CHATEAU (RODEZ)",
            ],
            [
                'November 2023',
                '2023-11-07T13:29:38.000Z',
                'Closed Lost',
                '00667000010kBdrAAE',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'November 2023',
                '2023-11-07T14:45:16.000Z',
                'Closed Lost',
                '00667000010kBnHAAU',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'November 2023',
                '2023-11-07T16:45:28.000Z',
                'Waiting for Quote',
                '00667000010kBzHAAU',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - ROISSY EN FRANCE',
            ],
            [
                'November 2023',
                '2023-11-08T13:42:39.000Z',
                'Closed Won',
                '00667000010ZdTyAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'November 2023',
                '2023-11-08T17:41:04.000Z',
                'Waiting for Quote',
                '00667000010ZdnmAAC',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'November 2023',
                '2023-11-13T07:58:09.000Z',
                'Quote sent • Negotiation',
                '00667000010Zg16AAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
            ],
            [
                'November 2023',
                '2023-11-13T09:21:36.000Z',
                'Quote sent • Negotiation',
                '00667000010Zg6GAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'November 2023',
                '2023-11-17T14:18:42.000Z',
                'Quote sent • Negotiation',
                '00667000010Zk3RAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'November 2023',
                '2023-11-20T09:36:27.000Z',
                'Waiting for Quote',
                '00667000010Zkx0AAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'ELYPSE AUTO',
            ],
            [
                'November 2023',
                '2023-11-20T09:37:01.000Z',
                'Quote sent • Negotiation',
                '00667000010ZkxFAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'November 2023',
                '2023-11-20T09:45:52.000Z',
                'Closed Won',
                '00667000010ZkxZAAS',
                'Zeplug',
                'France',
                'Company',
                'ChargeGuru Business Services',
            ],
            [
                'November 2023',
                '2023-11-20T17:22:08.000Z',
                'Quote sent • Negotiation',
                '00667000010ZlVtAAK',
                'Typeform',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'November 2023',
                '2023-11-21T09:08:15.000Z',
                'Quote sent • Negotiation',
                '00667000010ZljMAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2023',
                '2023-11-21T13:16:22.000Z',
                'Waiting for Quote',
                '00667000010Zm28AAC',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'November 2023',
                '2023-11-21T21:59:58.000Z',
                'Closed Lost',
                '00667000010ZmRpAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'November 2023',
                '2023-11-22T17:24:52.000Z',
                'Quote sent • Negotiation',
                '00667000010ZnFkAAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'November 2023',
                '2023-11-24T18:32:50.000Z',
                'Waiting for Quote',
                '00667000010ZoqtAAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'November 2023',
                '2023-11-25T05:08:16.000Z',
                'Quote sent • Negotiation',
                '00667000010ZovPAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'November 2023',
                '2023-11-27T10:27:37.000Z',
                'Quote sent • Negotiation',
                '00667000010Zpm4AAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'November 2023',
                '2023-11-27T11:37:31.000Z',
                'Quote sent • Negotiation',
                '00667000010Zpy4AAC',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS (11ème) - PARIS',
            ],
            [
                'November 2023',
                '2023-11-27T13:17:45.000Z',
                'Quote sent • Negotiation',
                '00667000010Zq5SAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'November 2023',
                '2023-11-27T13:49:34.000Z',
                'Waiting for Quote',
                '00667000010Zq87AAC',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'November 2023',
                '2023-11-28T09:03:01.000Z',
                'Waiting for Quote',
                '00667000010ZqdNAAS',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'November 2023',
                '2023-11-28T16:49:41.000Z',
                'Waiting for Quote',
                '00667000010ZrFlAAK',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'November 2023',
                '2023-11-29T09:33:23.000Z',
                'Quote sent • Negotiation',
                '00667000010ZrU2AAK',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2023',
                '2023-11-30T12:47:00.000Z',
                'Waiting for Quote',
                '00667000010ZsFCAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'November 2023',
                '2023-11-30T15:00:29.000Z',
                'Waiting for Quote',
                '00667000010ZsNQAA0',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'December 2023',
                '2023-12-01T14:22:34.000Z',
                'Quote sent • Negotiation',
                '00667000010Zt3YAAS',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2023',
                '2023-12-04T10:37:11.000Z',
                'Waiting for Quote',
                '006SZ000002d8HWYAY',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'December 2023',
                '2023-12-07T09:22:41.000Z',
                'Waiting for Quote',
                '006SZ000002r24BYAQ',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2023',
                '2023-12-07T10:05:35.000Z',
                'Quote sent • Negotiation',
                '006SZ000002r16YYAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2023',
                '2023-12-08T12:17:18.000Z',
                'Quote sent • Negotiation',
                '006SZ000002w4jdYAA',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'December 2023',
                '2023-12-11T14:08:14.000Z',
                'Waiting for Quote',
                '006SZ00000381usYAA',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'December 2023',
                '2023-12-11T15:00:39.000Z',
                'Quote sent • Negotiation',
                '006SZ00000384mJYAQ',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
        ],
    },
};
