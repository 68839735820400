import React from 'react';
import { Card, Stack, Typography, Button } from '@mui/joy';
import { theme } from '../theme';
import ChargeGuruLogoWhite from '../atoms/icons/ChargeGuruLogoWhite';
import { useAppAuth } from '../contexts/auth-context';
import { LoginButton } from '../molecules/LoginButton';
import { useNavigate } from 'react-router-dom';

export function Home() {
    const { isAuthenticated } = useAppAuth();
    const navigate = useNavigate();

    return (
        <Stack
            width="100vw"
            height="100vh"
            sx={{ background: theme.palette.secondary[500] }}
            alignItems={'center'}
            justifyContent={'center'}
        >
            <ChargeGuruLogoWhite size="lg" />
            <Card size="md" sx={{ width: '430px', marginTop: '10px' }}>
                <Typography level="title-lg" fontSize="xl">
                    Welcome to your reporting portal
                </Typography>

                <Typography>To access this page please connect to your ChargeGuru reporting account</Typography>

                {isAuthenticated ? (
                    <Button onClick={() => navigate('/overview')}>Go to portal</Button>
                ) : (
                    <LoginButton />
                )}
            </Card>
            <Stack direction="row" marginTop="10px">
                <Typography level="body-sm" sx={{ color: theme.palette.neutral[100] }}>
                    You don't have an account ? Please&nbsp;
                </Typography>
                <a href="mailto:contact@chargeguru.com">
                    <Typography level="title-sm" sx={{ color: theme.palette.neutral[100] }}>
                        &nbsp;Contact us
                    </Typography>
                </a>
            </Stack>
        </Stack>
    );
}
