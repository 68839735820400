import React, { useContext, useEffect, useState } from 'react';
import { BackendResponse } from 'src/providers/data-source-backend';
import { sortByMonth, sumAndPercent } from 'src/utils/graph-utils';
import { BackendContext } from 'src/ui/contexts/backend-context';
import { BarGraph } from 'src/ui/molecules/BarGraph';
import Loader from 'src/ui/molecules/Loader';
import { useAppAuth } from 'src/ui/contexts/auth-context';
import { FiltersContext } from 'src/ui/contexts/filters-context';
import { useLoading } from 'src/ui/contexts/loadingContext';
import { useLocation } from 'react-router-dom';
import getOptions from 'src/utils/getOptions';

export function StatusMonthlyEvolutionOfQualifiedLeadRate() {
    const backendApi = useContext(BackendContext)!;
    const [data, setData] = useState<BackendResponse | undefined>();
    const { getAccessTokenSilently } = useAppAuth();
    const { filtersState } = useContext(FiltersContext)!;
    const location = useLocation();
    const { incrementLoading, decrementLoading } = useLoading();

    useEffect(() => {
        const populate = async () => {
            incrementLoading();
            try {
                const options = getOptions(location.pathname, filtersState);
                const accessToken = await getAccessTokenSilently({ cacheMode: 'off' });
                setData(
                    await backendApi.getQuery(
                        'qualifiedLeadRate',
                        'I-I',
                        accessToken,
                        options,
                        true,
                    ),
                );
            } catch (error) {
                console.error(error);
            } finally {
                decrementLoading();
            }
        };

        populate();
    }, [filtersState, backendApi, getAccessTokenSilently, location.pathname]);

    return (
        <>
            {data && (
                <BarGraph
                    info="(number of leads qualified) / (number of leads qualified + unqualified)"
                    data={data}
                    title={`Monthly Evolution Of ${data.title}`}
                    axisXLabel="Month"
                    axisX="month"
                    axisXSort={sortByMonth}
                    axisYLabel={`${data.title} (%)`}
                    axisYValue={sumAndPercent(data, 'percentage_qualified')}
                    displayLegend={false}
                />
            )}
            {!data && <Loader />}
        </>
    );
}
