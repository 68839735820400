export const formatDate = (date: Date) => {
    let month = date.toLocaleString('en', { month: 'long' });
    month = (month.charAt(0).toUpperCase() + month.slice(1)).substring(0, 3);
    const day = date.toLocaleString('en', { day: 'numeric' });
    const year = date.toLocaleString('en', { year: 'numeric' }).substring(2, 4);
    const fullDate = `${month} ${day}, ${year}`;

    return fullDate;
};

export const formatDateShort = (date: Date) => {
    const day = `${date.getDate()}`.padStart(2, '0');
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const year = date.getFullYear();
    const fullDate = `${day}/${month}/${year}`;

    return fullDate;
};
