import React from 'react';
import '@fontsource/inter';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './router';
import { AuthenticationProvider } from './contexts/auth-context';
import { Config } from '..';
import { BackendProvider } from './contexts/backend-context';
import { BackendApi } from 'src/providers/data-source-backend';
import { FiltersProvider } from './contexts/filters-context';
import { FiltersReducer } from 'src/core/filters/service';
import { useAppAuth } from './contexts/auth-context';
import { LoadingProvider } from './contexts/loadingContext';

type AppProps = {
    backendApi: BackendApi;
    config: Config;
    filtersService: FiltersReducer;
};

const AuthenticatedApp = ({ backendApi, filtersService }: AppProps) => {
    const { userScope } = useAppAuth();
    backendApi.setUserScope(userScope);
    return (
        <BackendProvider backendApi={backendApi}>
            <FiltersProvider filtersReducer={filtersService}>
                <LoadingProvider>
                    <AppRoutes />
                </LoadingProvider>
            </FiltersProvider>
        </BackendProvider>
    );
};
export default function App(props: { backendApi: BackendApi; config: Config; filtersService: FiltersReducer }) {
    return (
        <BrowserRouter>
            <AuthenticationProvider {...props.config.auth}>
                <AuthenticatedApp {...props} />
            </AuthenticationProvider>
        </BrowserRouter>
    );
}
