import { createContext, PropsWithChildren, Dispatch } from 'react';
import { useAsyncReducer } from '../async-reducer';
import { FiltersAction, FiltersState, filtersInitialState, filtersReducer } from 'src/core/filters/service';

export const FiltersContext = createContext<{ filtersState: FiltersState; dispatch: Dispatch<FiltersAction> } | null>(
    null,
);

export function FiltersProvider(
    props: PropsWithChildren<{
        filtersReducer: ReturnType<typeof filtersReducer>;
    }>,
) {
    const [filtersState, dispatch] = useAsyncReducer(props.filtersReducer, filtersInitialState);

    return <FiltersContext.Provider value={{ filtersState, dispatch }}>{props.children}</FiltersContext.Provider>;
}
