export const block2jMock = {
    statement_id: '01ee99e1-83ae-13ff-9ca8-d7c180602030',
    status: {
        state: 'SUCCEEDED',
    },
    manifest: {
        format: 'JSON_ARRAY',
        schema: {
            column_count: 4,
            columns: [
                {
                    name: 'channel',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 0,
                },
                {
                    name: 'total_leads',
                    type_text: 'BIGINT',
                    type_name: 'LONG',
                    position: 1,
                },
                {
                    name: 'qualified_leads',
                    type_text: 'BIGINT',
                    type_name: 'LONG',
                    position: 2,
                },
                {
                    name: 'percentage_qualified',
                    type_text: 'DOUBLE',
                    type_name: 'DOUBLE',
                    position: 3,
                },
            ],
        },
        total_chunk_count: 1,
        chunks: [
            {
                chunk_index: 0,
                row_offset: 0,
                row_count: 10,
            },
        ],
        total_row_count: 10,
        truncated: false,
    },
    result: {
        chunk_index: 0,
        row_offset: 0,
        row_count: 10,
        data_array: [
            ['Direct', '12', '5', '0.4166666666666667'],
            ['Email', '24', '16', '0.6666666666666666'],
            ['Form Volvo Employee FR', '8', '8', '1.0'],
            ['Other', '1', '1', '1.0'],
            ['Phone', '28', '13', '0.4642857142857143'],
            ['Typeform Volvo', '925', '439', '0.4745945945945946'],
            ['Volkswagen Form', '1', '1', '1.0'],
            ['Volvo dealership Form', '1022', '432', '0.4227005870841487'],
            ['Website', '1', '0', '0.0'],
            ['Zeplug', '7', '5', '0.7142857142857143'],
        ],
    },
};
