import { FiltersState, GroupOfFilters } from 'src/core/filters/service';

const getOptions = (pathname: string, filtersState: FiltersState) => {
    const page = -1 !== pathname.indexOf('/contributor') ? 'contributorPage' : 'overviewPage';

    const options = (
        page === 'contributorPage' ? filtersState[page] : filtersState[page][filtersState.tab]
    ) as GroupOfFilters & { groupBy: string };
    if (page !== 'contributorPage') {
        options.groupBy = filtersState.tab;
    } else {
        options.groupBy = 'business_contributor';
    }

    return options;
};
export default getOptions;
