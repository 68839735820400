import { Button, DialogContent, DialogTitle, Modal, ModalClose, ModalDialog, Stack, Typography } from '@mui/joy';
import { useContext, useState } from 'react';
import { theme } from '../theme';
import xlsx from 'json-as-xlsx';
import Toaster from './Toaster';
import SelectDate from './form/SelectDate';
import { removeYearsToToday } from 'src/utils/removeDatesToToday';
import { BackendContext } from '../contexts/backend-context';
import { useAppAuth } from '../contexts/auth-context';

interface ExportModalProps {
    open: boolean;
    setOpen: Function;
}
export function ExportModal({ open, setOpen }: ExportModalProps) {
    const [selectedDates, setSelectedDates] = useState([new Date(removeYearsToToday(0), 0, 1), new Date()]);
    const backendApi = useContext(BackendContext)!;
    const { getAccessTokenSilently } = useAppAuth();
    const [snackbarInfos, setSnackbarInfos] = useState({
        open: false,
        error: false,
        message: '',
    });

    const exportData = async () => {
        try {
            const accessToken = await getAccessTokenSilently({ cacheMode: 'off' });
            const data = await backendApi.exportData(accessToken, { dates: selectedDates });
            const xlsxFile = [transformDataForXlsxFormat(data, 'data')];
            xlsx(xlsxFile, { fileName: `${new Date().toISOString()} - Export ChargeGuru` });
            setOpen(false);
            setSnackbarInfos({ open: true, error: false, message: 'File has been exported successfully' });
        } catch (err) {
            setOpen(false);
            setSnackbarInfos({ open: true, error: true, message: 'Erreur lors du téléchargement.' });
        }
    };

    const transformDataForXlsxFormat = (dataToChange: any, sheetName: string) => {
        const columns = dataToChange.manifest.schema.columns.map((column: any) => {
            return { label: column.name, value: column.name };
        });

        const datasToExport = dataToChange.result.data_array.map((line: any) => {
            const newLine: { [k: string]: any } = {};
            columns.forEach((column: { label: string; value: string }, index: number) => {
                newLine[column.value] = line[index];
            });
            return newLine;
        });
        return { columns, content: datasToExport, sheet: sheetName };
    };

    return (
        <>
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog variant="plain">
                    <ModalClose />
                    <DialogTitle sx={{ justifyContent: 'center' }}>
                        <Stack>
                            <Typography level="h2" textAlign={'center'}>
                                Export data
                            </Typography>
                            <Typography textAlign={'center'} sx={{ color: theme.palette.grey[500] }} level="body-md">
                                Select the period for which you wish to export the raw data
                            </Typography>
                        </Stack>
                    </DialogTitle>
                    <DialogContent>
                        <SelectDate isExport setSelectedDates={setSelectedDates} />
                        <Button onClick={() => exportData()} sx={{ fontWeight: 'bold' }}>
                            Export data
                        </Button>
                    </DialogContent>
                </ModalDialog>
            </Modal>
            <Toaster
                open={snackbarInfos.open}
                text={snackbarInfos.message}
                setOpen={(value: boolean) => setSnackbarInfos({ ...snackbarInfos, open: value })}
                color={snackbarInfos.error ? 'danger' : 'primary'}
            />
        </>
    );
}
