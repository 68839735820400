import { createContext, PropsWithChildren } from 'react';
import { BackendApi } from 'src/providers/data-source-backend';

export const BackendContext = createContext<BackendApi | null>(null);

export function BackendProvider(
    props: PropsWithChildren<{
        backendApi: BackendApi;
    }>,
) {
    return <BackendContext.Provider value={props.backendApi}>{props.children}</BackendContext.Provider>;
}
