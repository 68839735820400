const colors = [
    'rgb(82,239,153)',
    'rgb(253,63,190)',
    'rgb(44,245,43)',
    'rgb(134,37,147)',
    'rgb(33,167,8)',
    'rgb(206,125,239)',
    'rgb(157,234,25)',
    'rgb(188,28,250)',
    'rgb(188,224,145)',
    'rgb(106,55,71)',
    'rgb(121,227,249)',
    'rgb(253,43,49)',
    'rgb(35,94,49)',
    'rgb(231,124,159)',
    'rgb(36,164,117)',
    'rgb(158,25,77)',
    'rgb(5,157,197)',
    'rgb(194,87,32)',
    'rgb(213,208,250)',
    'rgb(48,64,141)',
    'rgb(211,203,4)',
    'rgb(49,71,237)',
    'rgb(242,169,102)',
    'rgb(95,128,218)',
    'rgb(134,151,100)',
    'rgb(154,123,141)',

    'rgb(136, 8, 8)',
    'rgb(170, 74, 68)',
    'rgb(238, 75, 43)',
    'rgb(165, 42, 42)',
    'rgb(128, 0, 32)',
    'rgb(110, 38, 14)',
    'rgb(204, 85, 0)',
    'rgb(233, 116, 81)',
    'rgb(112, 41, 99)',
    'rgb(210, 43, 43)',
    'rgb(196, 30, 58)',
    'rgb(215, 0, 64)',
    'rgb(222, 49, 99)',
    'rgb(210, 4, 45)',
    'rgb(149, 69, 53)',
    'rgb(129, 19, 49)',
    'rgb(248, 131, 121)',
    'rgb(129, 65, 65)',
    'rgb(220, 20, 60)',
    'rgb(139, 0, 0)',
    'rgb(123, 24, 24)',
    'rgb(154, 42, 42)',
    'rgb(192, 64, 0)',
    'rgb(128, 0, 0)',
    'rgb(152, 104, 104)',
    'rgb(119, 7, 55)',
    'rgb(255, 49, 49)',
    'rgb(74, 4, 4)',
    'rgb(250, 160, 160)',
    'rgb(236, 88, 0)',
    'rgb(227, 83, 53)',
    'rgb(169, 92, 104)',
    'rgb(227, 11, 92)',
    'rgb(255, 0, 0)',
    'rgb(165, 42, 42)',
    'rgb(145, 56, 49)',
    'rgb(255, 68, 51)',
    'rgb(149, 53, 83)',
    'rgb(194, 30, 86)',
    'rgb(224, 17, 95)',
    'rgb(128, 70, 27)',
    'rgb(250, 128, 114)',
    'rgb(255, 36, 0)',
    'rgb(250, 95, 85)',
    'rgb(227, 115, 94)',
    'rgb(124, 48, 48)',
    'rgb(99, 3, 48)',
    'rgb(164, 42, 4)',
    'rgb(227, 66, 52)',
    'rgb(114, 47, 55)',
    'rgb(255, 191, 0)',
    'rgb(251, 206, 177)',
    'rgb(242, 210, 189)',
    'rgb(255, 172, 28)',
    'rgb(205, 127, 50)',
    'rgb(218, 160, 109)',
    'rgb(204, 85, 0)',
    'rgb(233, 116, 81)',
    'rgb(227, 150, 62)',
    'rgb(242, 140, 40)',
    'rgb(210, 125, 45)',
    'rgb(184, 115, 51)',
    'rgb(255, 127, 80)',
    'rgb(248, 131, 121)',
    'rgb(139, 64, 0)',
    'rgb(250, 213, 165)',
    'rgb(228, 155, 15)',
    'rgb(255, 192, 0)',
    'rgb(218, 165, 32)',
    'rgb(255, 213, 128)',
    'rgb(192, 64, 0)',
    'rgb(244, 187, 68)',
    'rgb(255, 222, 173)',
    'rgb(255, 95, 31)',
    'rgb(204, 119, 34)',
    'rgb(255, 165, 0)',
    'rgb(250, 200, 152)',
    'rgb(255, 229, 180)',
    'rgb(236, 88, 0)',
    'rgb(248, 152, 128)',
    'rgb(227, 83, 53)',
    'rgb(255, 117, 24)',
    'rgb(255, 68, 51)',
    'rgb(255, 95, 21)',
    'rgb(250, 128, 114)',
    'rgb(255, 245, 238)',
    'rgb(160, 82, 45)',
    'rgb(250, 95, 85)',
    'rgb(240, 128, 0)',
    'rgb(227, 115, 94)',
    'rgb(255, 170, 51)',
    'rgb(234, 221, 202)',
    'rgb(255, 191, 0)',
    'rgb(251, 206, 177)',
    'rgb(245, 245, 220)',
    'rgb(225, 193, 110)',
    'rgb(255, 234, 0)',
    'rgb(253, 218, 13)',
    'rgb(255, 255, 143)',
    'rgb(223, 255, 0)',
    'rgb(228, 208, 10)',
    'rgb(255, 248, 220)',
    'rgb(255, 253, 208)',
    'rgb(139, 128, 0)',
    'rgb(250, 213, 165)',
    'rgb(194, 178, 128)',
    'rgb(238, 220, 130)',
    'rgb(228, 155, 15)',
    'rgb(255, 215, 0)',
    'rgb(255, 192, 0)',
    'rgb(218, 165, 32)',
    'rgb(252, 245, 95)',
    'rgb(255, 255, 240)',
    'rgb(248, 222, 126)',
    'rgb(240, 230, 140)',
    'rgb(250, 250, 51)',
    'rgb(251, 236, 93)',
    'rgb(244, 187, 68)',
    'rgb(255, 219, 88)',
    'rgb(250, 218, 94)',
    'rgb(255, 222, 173)',
    'rgb(236, 255, 220)',
    'rgb(255, 250, 160)',
    'rgb(255, 229, 180)',
    'rgb(201, 204, 63)',
    'rgb(180, 196, 36)',
    'rgb(147, 197, 114)',
    'rgb(244, 196, 48)',
    'rgb(243, 229, 171)',
    'rgb(196, 180, 84)',
    'rgb(245, 222, 179)',
    'rgb(255, 255, 0)',
    'rgb(255, 170, 51)',
    'rgb(0, 255, 255)',
    'rgb(127, 255, 212)',
    'rgb(69, 75, 27)',
    'rgb(8, 143, 143)',
    'rgb(170, 255, 0)',
    'rgb(95, 158, 160)',
    'rgb(9, 121, 105)',
    'rgb(175, 225, 175)',
    'rgb(223, 255, 0)',
    'rgb(228, 208, 10)',
    'rgb(0, 255, 255)',
    'rgb(2, 48, 32)',
    'rgb(125, 249, 255)',
    'rgb(80, 200, 120)',
    'rgb(95, 133, 117)',
    'rgb(79, 121, 66)',
    'rgb(34, 139, 34)',
    'rgb(124, 252, 0)',
    'rgb(0, 128, 0)',
    'rgb(53, 94, 59)',
    'rgb(0, 163, 108)',
    'rgb(42, 170, 138)',
    'rgb(76, 187, 23)',
    'rgb(144, 238, 144)',
    'rgb(50, 205, 50)',
    'rgb(71, 135, 120)',
    'rgb(11, 218, 81)',
    'rgb(152, 251, 152)',
    'rgb(138, 154, 91)',
    'rgb(15, 255, 80)',
    'rgb(236, 255, 220)',
    'rgb(128, 128, 0)',
    'rgb(193, 225, 193)',
    'rgb(201, 204, 63)',
    'rgb(180, 196, 36)',
    'rgb(147, 197, 114)',
    'rgb(150, 222, 209)',
    'rgb(138, 154, 91)',
    'rgb(46, 139, 87)',
    'rgb(159, 226, 191)',
    'rgb(0, 158, 96)',
    'rgb(0, 255, 127)',
    'rgb(0, 128, 128)',
    'rgb(64, 224, 208)',
    'rgb(196, 180, 84)',
    'rgb(64, 181, 173)',
    'rgb(64, 130, 109)',
    'rgb(0, 255, 255)',
    'rgb(240, 255, 255)',
    'rgb(137, 207, 240)',
    'rgb(0, 0, 255)',
    'rgb(115, 147, 179)',
    'rgb(8, 143, 143)',
    'rgb(0, 150, 255)',
    'rgb(95, 158, 160)',
    'rgb(0, 71, 171)',
    'rgb(100, 149, 237)',
    'rgb(0, 255, 255)',
    'rgb(0, 0, 139)',
    'rgb(111, 143, 175)',
    'rgb(20, 52, 164)',
    'rgb(125, 249, 255)',
    'rgb(96, 130, 182)',
    'rgb(0, 163, 108)',
    'rgb(63, 0, 255)',
    'rgb(93, 63, 211)',
    'rgb(173, 216, 230)',
    'rgb(25, 25, 112)',
    'rgb(0, 0, 128)',
    'rgb(31, 81, 255)',
    'rgb(167, 199, 231)',
    'rgb(204, 204, 255)',
    'rgb(182, 208, 226)',
    'rgb(150, 222, 209)',
    'rgb(65, 105, 225)',
    'rgb(15, 82, 186)',
    'rgb(159, 226, 191)',
    'rgb(135, 206, 235)',
    'rgb(70, 130, 180)',
    'rgb(0, 128, 128)',
    'rgb(64, 224, 208)',
    'rgb(4, 55, 242)',
    'rgb(64, 181, 173)',
    'rgb(8, 24, 168)',
    'rgb(0, 0, 0)',
    'rgb(54, 69, 79)',
    'rgb(2, 48, 32)',
    'rgb(48, 25, 52)',
    'rgb(52, 52, 52)',
    'rgb(27, 18, 18)',
    'rgb(40, 40, 43)',
    'rgb(25, 25, 112)',
    'rgb(53, 57, 53)',
    'rgb(237, 234, 222)',
    'rgb(245, 245, 220)',
    'rgb(249, 246, 238)',
    'rgb(255, 248, 220)',
    'rgb(255, 253, 208)',
    'rgb(240, 234, 214)',
    'rgb(255, 255, 240)',
    'rgb(233, 220, 201)',
    'rgb(255, 222, 173)',
    'rgb(250, 249, 246)',
    'rgb(252, 245, 229)',
    'rgb(255, 229, 180)',
    'rgb(226, 223, 210)',
    'rgb(255, 245, 238)',
    'rgb(243, 229, 171)',
    'rgb(255, 255, 255)',
    'rgb(234, 221, 202)',
    'rgb(225, 193, 110)',
    'rgb(205, 127, 50)',
    'rgb(165, 42, 42)',
    'rgb(218, 160, 109)',
    'rgb(128, 0, 32)',
    'rgb(233, 116, 81)',
    'rgb(110, 38, 14)',
    'rgb(193, 154, 107)',
    'rgb(149, 69, 53)',
    'rgb(123, 63, 0)',
    'rgb(210, 125, 45)',
    'rgb(111, 78, 55)',
    'rgb(131, 67, 51)',
    'rgb(184, 115, 51)',
    'rgb(129, 65, 65)',
    'rgb(92, 64, 51)',
    'rgb(139, 0, 0)',
    'rgb(152, 133, 88)',
    'rgb(194, 178, 128)',
    'rgb(193, 154, 107)',
    'rgb(229, 170, 112)',
    'rgb(154, 42, 42)',
    'rgb(150, 105, 25)',
    'rgb(240, 230, 140)',
    'rgb(196, 164, 132)',
    'rgb(192, 64, 0)',
    'rgb(128, 0, 0)',
    'rgb(150, 121, 105)',
    'rgb(242, 210, 189)',
    'rgb(204, 119, 34)',
    'rgb(128, 128, 0)',
    'rgb(74, 4, 4)',
    'rgb(169, 92, 104)',
    'rgb(165, 42, 42)',
    'rgb(145, 56, 49)',
    'rgb(128, 70, 27)',
    'rgb(139, 69, 19)',
    'rgb(194, 178, 128)',
    'rgb(160, 82, 45)',
    'rgb(210, 180, 140)',
    'rgb(72, 60, 50)',
    'rgb(124, 48, 48)',
    'rgb(245, 222, 179)',
    'rgb(114, 47, 55)',
    'rgb(178, 190, 181)',
    'rgb(115, 147, 179)',
    'rgb(54, 69, 79)',
    'rgb(169, 169, 169)',
    'rgb(96, 130, 182)',
    'rgb(128, 128, 128)',
    'rgb(129, 133, 137)',
    'rgb(211, 211, 211)',
    'rgb(137, 148, 153)',
    'rgb(229, 228, 226)',
    'rgb(138, 154, 91)',
    'rgb(192, 192, 192)',
    'rgb(112, 128, 144)',
    'rgb(132, 136, 132)',
    'rgb(113, 121, 126)',
    'rgb(159, 43, 104)',
    'rgb(242, 210, 189)',
    'rgb(222, 49, 99)',
    'rgb(129, 19, 49)',
    'rgb(255, 127, 80)',
    'rgb(248, 131, 121)',
    'rgb(220, 20, 60)',
    'rgb(170, 51, 106)',
    'rgb(201, 169, 166)',
    'rgb(255, 0, 255)',
    'rgb(255, 105, 180)',
    'rgb(255, 182, 193)',
    'rgb(255, 0, 255)',
    'rgb(243, 207, 198)',
    'rgb(119, 7, 55)',
    'rgb(255, 16, 240)',
    'rgb(218, 112, 214)',
    'rgb(248, 200, 220)',
    'rgb(250, 160, 160)',
    'rgb(255, 192, 203)',
    'rgb(248, 152, 128)',
    'rgb(103, 49, 71)',
    'rgb(169, 92, 104)',
    'rgb(128, 0, 128)',
    'rgb(227, 11, 92)',
    'rgb(149, 53, 83)',
    'rgb(243, 58, 106)',
    'rgb(224, 191, 184)',
    'rgb(194, 30, 86)',
    'rgb(224, 17, 95)',
    'rgb(250, 128, 114)',
    'rgb(255, 245, 238)',
    'rgb(216, 191, 216)',
    'rgb(227, 115, 131)',
    'rgb(159, 43, 104)',
    'rgb(191, 64, 191)',
    'rgb(128, 0, 32)',
    'rgb(112, 41, 99)',
    'rgb(170, 51, 106)',
    'rgb(48, 25, 52)',
    'rgb(72, 50, 72)',
    'rgb(93, 63, 211)',
    'rgb(230, 230, 250)',
    'rgb(203, 195, 227)',
    'rgb(207, 159, 255)',
    'rgb(170, 152, 169)',
    'rgb(224, 176, 255)',
    'rgb(145, 95, 109)',
    'rgb(119, 7, 55)',
    'rgb(218, 112, 214)',
    'rgb(195, 177, 225)',
    'rgb(204, 204, 255)',
    'rgb(103, 49, 71)',
    'rgb(169, 92, 104)',
    'rgb(128, 0, 128)',
    'rgb(81, 65, 79)',
    'rgb(149, 53, 83)',
    'rgb(216, 191, 216)',
    'rgb(99, 3, 48)',
    'rgb(127, 0, 255)',
    'rgb(114, 47, 55)',
    'rgb(189, 181, 213)',
].map((rgb) => rgb.replace('rgb(', '').replace(')', ''));

// export const backgroundColor = (idx: number): string => `rgba(${colors[Math.round(idx * colors.length / 2494498152)]}, 0.7)`;
export const backgroundColor = (idx: number): string => {
    // console.log(idx % colors.length);

    return `rgba(${colors[idx % colors.length]}, 0.7)`;
};

export const backgroundColorForValue = (value: string): string => {
    // console.log('color for "%s": "%d" "%s"', value, hashCode(value ?? ''), backgroundColor(hashCode(value ?? '')));

    return backgroundColor(hashCode(value ?? ''));
};

function hashCode(str: string): number {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
        let chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash);
}

// const hashCode = (str: string, seed: number = 0): number => {
//     let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
//     for (let i = 0, ch; i < str.length; i++) {
//         ch = str.charCodeAt(i);
//         h1 = Math.imul(h1 ^ ch, 2654435761);
//         h2 = Math.imul(h2 ^ ch, 1597334677);
//     }
//     h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
//     h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
//     h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
//     h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

//     return 4294967296 * (2097151 & h2) + (h1 >>> 0);
// };
