const CheckIcon = () => {
    return (
        <svg width="15" height="15" viewBox="0 -2 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.57386 7.56796C3.46503 7.57065 3.35678 7.5512 3.25568 7.5108C3.15459 7.4704 3.06275 7.40989 2.98574 7.33294L0.789537 5.13561C0.647663 4.98323 0.570434 4.78175 0.574099 4.57358C0.577765 4.36541 0.66204 4.16677 0.80919 4.01948C0.95634 3.87218 1.15489 3.78771 1.36306 3.78384C1.57123 3.77997 1.77278 3.857 1.92531 3.99872L3.57274 5.64502L8.08771 1.13567C8.24114 0.999312 8.44091 0.926794 8.64608 0.932983C8.85126 0.939172 9.0463 1.0236 9.19123 1.16896C9.33616 1.31432 9.42 1.50961 9.42558 1.7148C9.43116 1.91999 9.35805 2.11955 9.22123 2.27257L4.16087 7.33294C4.08615 7.40762 3.99742 7.46682 3.89978 7.50715C3.80214 7.54748 3.6975 7.56815 3.59186 7.56796H3.57386Z"
                fill="#1B2A50"
            />
        </svg>
    );
};

export default CheckIcon;
