const ProfileIcon = () => {
    return (
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="btn_users">
                <path
                    id="Trac&#195;&#169; 682"
                    d="M9.00527 1C9.99785 1.00109 10.9678 1.29642 11.7926 1.84866C12.6174 2.40091 13.2599 3.18527 13.639 4.10261C14.0181 5.01995 14.1167 6.02908 13.9224 7.00246C13.7281 7.97584 13.2496 8.86976 12.5473 9.57124C11.8451 10.2727 10.9506 10.7503 9.97705 10.9435C9.00346 11.1367 7.99443 11.037 7.0775 10.6569C6.16058 10.2768 5.37692 9.63342 4.82558 8.80805C4.27425 7.98267 3.97998 7.01236 3.97998 6.01978C3.97998 5.36011 4.11001 4.70691 4.36262 4.09752C4.61523 3.48814 4.98547 2.93451 5.45218 2.46831C5.9189 2.00211 6.47293 1.63247 7.08259 1.38053C7.69225 1.12859 8.3456 0.999279 9.00527 1V1Z"
                    stroke="#1B2A50"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <g id="Group 76">
                    <path
                        id="Trac&#195;&#169; 681"
                        d="M1.43652 19.0002C1.43652 16.8893 2.27508 14.8649 3.76773 13.3722C5.26037 11.8796 7.28483 11.041 9.39575 11.041"
                        stroke="#1B2A50"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Trac&#195;&#169; 738"
                        d="M16.5632 19.0002C16.5632 16.8893 15.7247 14.8649 14.232 13.3722C12.7394 11.8796 10.7149 11.041 8.604 11.041"
                        stroke="#1B2A50"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
        </svg>
    );
};

export default ProfileIcon;
