export const block2lMock = {
    statement_id: '01ee9b2e-d452-15a2-a07c-a58d2b490db1',
    status: {
        state: 'SUCCEEDED',
    },
    manifest: {
        format: 'JSON_ARRAY',
        schema: {
            column_count: 4,
            columns: [
                {
                    name: 'channel',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 0,
                },
                {
                    name: 'total_opp',
                    type_text: 'BIGINT',
                    type_name: 'LONG',
                    position: 1,
                },
                {
                    name: 'won_opp',
                    type_text: 'BIGINT',
                    type_name: 'LONG',
                    position: 2,
                },
                {
                    name: 'win_rate',
                    type_text: 'DOUBLE',
                    type_name: 'DOUBLE',
                    position: 3,
                },
            ],
        },
        total_chunk_count: 1,
        chunks: [
            {
                chunk_index: 0,
                row_offset: 0,
                row_count: 9,
            },
        ],
        total_row_count: 9,
        truncated: false,
    },
    result: {
        chunk_index: 0,
        row_offset: 0,
        row_count: 9,
        data_array: [
            [null, '2', '2', '1.0'],
            ['Direct', '15', '7', '0.4666666666666667'],
            ['Email', '28', '14', '0.5'],
            ['Form Volvo Employee FR', '5', '3', '0.6'],
            ['Phone', '33', '15', '0.45454545454545453'],
            ['Typeform', '8', '4', '0.5'],
            ['Typeform Volvo', '689', '288', '0.41799709724238027'],
            ['Volvo dealership Form', '369', '147', '0.3983739837398374'],
            ['Zeplug', '7', '4', '0.5714285714285714'],
        ],
    },
};
