import React from 'react';
import { GraphLayout } from '../../layout/GraphLayout';
import { ProjectTypeMonthlyEvolutionOfCreatedLeadByProjectType } from 'src/ui/atoms/graphs/project-type-monthly-evolution-of-created-lead-by-project-type';
import { ProjectTypeTotalCreatedLeadByProjectType } from 'src/ui/atoms/graphs/project-type-total-created-lead-by-project-type';
import { ProjectTypeMonthlyEvolutionOfOpportunityByProjectType } from 'src/ui/atoms/graphs/project-type-monthly-evolution-of-opportunity-by-project-type';
import { ProjectTypeTotalOpportunityByProjectType } from 'src/ui/atoms/graphs/project-type-total-opportunities-by-channel';
import { ProjectTypeMonthlyEvolutionOfOrderByProjectType } from 'src/ui/atoms/graphs/project-type-monthly-evolution-of-order-by-project-type';
import { ProjectTypeTotalOrdersByProjectType } from 'src/ui/atoms/graphs/project-type-total-orders-by-channel';
import { ProjectTypeMonthlyEvolutionOfFinalisedInstallationsByProjectType } from 'src/ui/atoms/graphs/project-type-monthly-evolution-of-finalised-installations-by-project-type';
import { ProjectTypeTotalFinalisedInstallationsByProjectType } from 'src/ui/atoms/graphs/project-type-total-finalised-installations-by-channel';
import { ProjectTypeMonthlyEvolutionOfQualifiedLeadRateByProjectType } from 'src/ui/atoms/graphs/project-type-monthly-evolution-of-qualified-lead-rate-by-project-type';
import { ProjectTypeQualifiedLeadRateByProjectType } from 'src/ui/atoms/graphs/project-type-qualified-lead-rate-by-project-type';
import { ProjectTypeMonthlyEvolutionOfProjectWinRateByProjectType } from 'src/ui/atoms/graphs/project-type-monthly-evolution-of-project-win-rate-by-project-type';
import { ProjectTypeProjectWinRateByProjectType } from 'src/ui/atoms/graphs/project-type-project-win-rate-by-project-type';
import { ProjectTypeMonthlyEvolutionOfQuotationLeadtimeByProjectType } from 'src/ui/atoms/graphs/project-type-monthly-evolution-of-quotation-leadtime-by-project-type';
import { ProjectTypeMonthlyEvolutionOfInstallationLeadtimeByProjectType } from 'src/ui/atoms/graphs/project-type-monthly-evolution-of-installation-leadtime-by-project-type';

export function ProjectTypeGraphs() {
    return (
        <GraphLayout
            title="Project Type"
            graphs={[
                <ProjectTypeMonthlyEvolutionOfCreatedLeadByProjectType />,
                <ProjectTypeTotalCreatedLeadByProjectType />,
                <ProjectTypeMonthlyEvolutionOfOpportunityByProjectType />,
                <ProjectTypeTotalOpportunityByProjectType />,
                <ProjectTypeMonthlyEvolutionOfOrderByProjectType />,
                <ProjectTypeTotalOrdersByProjectType />,
                <ProjectTypeMonthlyEvolutionOfFinalisedInstallationsByProjectType />,
                <ProjectTypeTotalFinalisedInstallationsByProjectType />,
                <ProjectTypeMonthlyEvolutionOfQualifiedLeadRateByProjectType />,
                <ProjectTypeQualifiedLeadRateByProjectType />,
                <ProjectTypeMonthlyEvolutionOfProjectWinRateByProjectType />,
                <ProjectTypeProjectWinRateByProjectType />,
                <ProjectTypeMonthlyEvolutionOfQuotationLeadtimeByProjectType />,
                <ProjectTypeMonthlyEvolutionOfInstallationLeadtimeByProjectType />,
            ]}
        />
    );
}
