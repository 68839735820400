export const typesMock = {
    statement_id: '01ee9e4f-4225-1d13-9be6-52be2792ab55',
    status: { state: 'SUCCEEDED' },
    manifest: {
        format: 'JSON_ARRAY',
        schema: { column_count: 1, columns: [{ name: 'name', type_text: 'STRING', type_name: 'STRING', position: 0 }] },
        total_chunk_count: 1,
        chunks: [{ chunk_index: 0, row_offset: 0, row_count: 3 }],
        total_row_count: 3,
        truncated: false,
    },
    result: {
        chunk_index: 0,
        row_offset: 0,
        row_count: 3,
        data_array: [
            ['Company', 'Company'],
            ['Multi Family Home', 'Multi Family Home'],
            ['Single Home', 'Single Home'],
        ],
    },
};
