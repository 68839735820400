import React, { useContext } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    ArcElement,
    Tooltip,
    Legend,
    ChartOptions,
    ChartDataset,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { BackendResponse } from 'src/providers/data-source-backend';
import { allValuesDeduplicatedOf, labelWithExtractCount, positionOf, sum } from 'src/utils/graph-utils';
import { backgroundColorForValue } from 'src/utils/color-utils';
import { Box, Typography } from '@mui/joy';
import { FiltersContext } from '../contexts/filters-context';
import { formatDateShort } from 'src/utils/formatDate';
import NoDataDisplay from './NoDataDisplay';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

const doughnutGraphOptions = (name: string, displayLegend: boolean = true): ChartOptions<'doughnut'> => ({
    plugins: {
        title: {
            display: true,
            text: name,
        },
        legend: {
            display: displayLegend,
        },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            display: false,
        },
        y: {
            display: false,
        },
    },
});

const graphDataForDoughnutGraph = (
    data: BackendResponse,
    colNameForCount: string,
    colNameForValue: string,
): ChartDataset<any, any>[] => {
    const countPosition = positionOf(data, colNameForCount);
    const valuePosition = positionOf(data, colNameForValue);
    const countValues = allValuesDeduplicatedOf(data, colNameForCount);
    return [
        {
            label: 'Record Count',
            data: countValues.map(
                (value) => (data.result.data_array.find((row) => row[countPosition] === value) ?? [])[valuePosition],
            ),
            backgroundColor: countValues.map((val) => backgroundColorForValue(val)),
        },
    ];
};

export function DoughnutGraph(props: {
    data: BackendResponse;
    title: string;
    axisYValue: string;
    axisXValue: string;
    displayLegend: boolean;
}) {
    const { filtersState } = useContext(FiltersContext)!;
    const from = formatDateShort(filtersState.overviewPage.status.dates[0]);
    const to = formatDateShort(filtersState.overviewPage.status.dates[1]);

    const titleWithDate = `${props.title} from ${from} to ${to}`;
    if (!props.data.result || !props.data.result.data_array) {
        return <NoDataDisplay />;
    }
    return (
        <Box position="relative" height="100%">
            <Doughnut
                options={doughnutGraphOptions(titleWithDate, props.displayLegend)}
                data={{
                    labels: allValuesDeduplicatedOf(props.data, props.axisYValue).map(
                        labelWithExtractCount(props.data, props.axisYValue, props.axisXValue),
                    ),
                    datasets: graphDataForDoughnutGraph(props.data, props.axisYValue, props.axisXValue),
                }}
            />
            <Box position="absolute" left="calc(100% / 2 - 25px)" top="calc(100% / 2 + 5px)">
                <Typography fontWeight="bold">
                    Total: {sum(props.data, props.axisXValue)(props.data.result.data_array)}
                </Typography>
            </Box>
        </Box>
    );
}
