import { Radio } from '@mui/joy';
import { ReactNode } from 'react';
import CheckedIcon from '../atoms/icons/RadioButtonIcon';
import UncheckedIcon from '../atoms/icons/UncheckedIcon';

interface InputProps {
    value: string | number | boolean;
    label: ReactNode;
    handleChange: Function;
    selectedValue: string | number | boolean;
}

export default function RadioButton({ value, label, handleChange, selectedValue }: InputProps) {
    return (
        <Radio
            sx={{
                borderRadius: '4px',
                borderColor: 'grey.200',
                height: '45px',
                padding: '12px',
            }}
            checked={selectedValue === value}
            onChange={() => handleChange(value)}
            value={value}
            name="radio-buttons"
            variant="soft"
            checkedIcon={<CheckedIcon />}
            uncheckedIcon={<UncheckedIcon />}
            label={label}
            key={`option-${value}`}
        />
    );
}
