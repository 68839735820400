export const block5jMock = {
    statement_id: '01eea0b2-d997-1c50-95f3-f9cbf3e6a8d7',
    status: { state: 'SUCCEEDED' },
    manifest: {
        format: 'JSON_ARRAY',
        schema: {
            column_count: 4,
            columns: [
                { name: 'business_contributor', type_text: 'STRING', type_name: 'STRING', position: 0 },
                { name: 'total_leads', type_text: 'BIGINT', type_name: 'LONG', position: 1 },
                { name: 'qualified_leads', type_text: 'BIGINT', type_name: 'LONG', position: 2 },
                { name: 'percentage_qualified', type_text: 'DOUBLE', type_name: 'DOUBLE', position: 3 },
            ],
        },
        total_chunk_count: 1,
        chunks: [{ chunk_index: 0, row_offset: 0, row_count: 133 }],
        total_row_count: 133,
        truncated: false,
    },
    result: {
        chunk_index: 0,
        row_offset: 0,
        row_count: 133,
        data_array: [
            ['ABVV Chantilly', '5', '3', '0.6'],
            ['ABVV Roissy en France', '2', '2', '1.0'],
            ['BMW MINI - SAINT-MERRI AMIENS - AMIENS', '1', '0', '0.0'],
            ['ChargeGuru', '1', '1', '1.0'],
            ['ChargeGuru Business Services', '1', '1', '1.0'],
            ['Concession Inconnue', '141', '125', '0.8865248226950354'],
            ['ELYPSE AUTO', '3', '2', '0.6666666666666666'],
            ['Groupe Saint Christophe', '1', '0', '0.0'],
            ['JMJ Automobiles', '1', '1', '1.0'],
            ['LARSON by Autosphere', '35', '13', '0.37142857142857144'],
            ['Tesla Store Lyon-Chemin des Gorges', '1', '1', '1.0'],
            ['Unknown Facilitator', '1', '1', '1.0'],
            ['Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY', '10', '4', '0.4'],
            ['Volvo -  A.B.V.V. AUTOMOBILES - EPINAY SUR SEINE', '1', '0', '0.0'],
            ['Volvo -  A.B.V.V. AUTOMOBILES - ROISSY EN FRANCE', '8', '6', '0.75'],
            ['Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE', '15', '5', '0.3333333333333333'],
            ['Volvo -  A.P.S - BOURG EN BRESSE', '5', '2', '0.4'],
            ['Volvo -  ACTENA AUTOMOBILES - BUCHELAY (MANTES LA JOLIE)', '2', '1', '0.5'],
            ['Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES', '7', '5', '0.7142857142857143'],
            ['Volvo -  ACTENA AUTOMOBILES - MAUREPAS', '9', '5', '0.5555555555555556'],
            ['Volvo -  ACTENA AUTOMOBILES - NEUILLY SUR SEINE', '3', '1', '0.3333333333333333'],
            ['Volvo -  ACTENA AUTOMOBILES - PARIS', '13', '4', '0.3076923076923077'],
            ['Volvo -  ACTENA AUTOMOBILES - PORT MARLY', '23', '11', '0.4782608695652174'],
            ['Volvo -  ACTENA AUTOMOBILES - VERSAILLES', '2', '0', '0.0'],
            ['Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE', '38', '14', '0.3684210526315789'],
            ['Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)', '10', '6', '0.6'],
            ['Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE', '8', '7', '0.875'],
            ['Volvo -  AMPLITUDE AUTOMOBILES - SAINTES', '4', '2', '0.5'],
            ['Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)', '16', '6', '0.375'],
            ['Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)', '11', '9', '0.8181818181818182'],
            ['Volvo -  AUTO PREMIUM 08 - CHARLEVILLE MEZIERES', '9', '4', '0.4444444444444444'],
            ['Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)', '28', '18', '0.6428571428571429'],
            ['Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT', '40', '20', '0.5'],
            ["Volvo -  CAR'S SERVICES - ONET LE CHATEAU (RODEZ)", '8', '3', '0.375'],
            ['Volvo -  CARAUTO SERVICES - GAP', '1', '0', '0.0'],
            ['Volvo -  CARBURY AUTOMOBILES - VALENCE', '16', '5', '0.3125'],
            ['Volvo -  CARS PREMIUM - CARCASSONNE', '1', '1', '1.0'],
            ['Volvo -  CARS PREMIUM - PERPIGNAN', '12', '4', '0.3333333333333333'],
            ['Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)', '8', '3', '0.375'],
            ['Volvo -  CAVALLARI AUTOMOBILES - NICE', '2', '0', '0.0'],
            ['Volvo -  CMM AUTOMOBILES - SAINTE CLOTILDE', '1', '0', '0.0'],
            ['Volvo -  COMMERES SAS - SEMEAC (TARBES)', '18', '8', '0.4444444444444444'],
            ['Volvo -  CSD MOTORS - BOE (AGEN)', '15', '9', '0.6'],
            ['Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)', '45', '8', '0.17777777777777778'],
            ['Volvo -  CYRNEA NORTH CARS - BORGO', '11', '1', '0.09090909090909091'],
            ['Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1', '27', '9', '0.3333333333333333'],
            ['Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)', '20', '14', '0.7'],
            ['Volvo -  DARMENDRAIL AUTOMOBILES - SAINT AVIT (MONT DE MARSAN)', '5', '2', '0.4'],
            ['Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)', '79', '21', '0.26582278481012656'],
            ['Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)', '17', '10', '0.5882352941176471'],
            ['Volvo -  DELHORBE AUTOMOBILES - DIZY (EPERNAY)', '1', '0', '0.0'],
            ['Volvo -  DELHORBE AUTOMOBILES - REIMS', '33', '9', '0.2727272727272727'],
            [
                'Volvo -  DELHORBE AUTOMOBILES - SAINT MARTIN SUR LE PRE (CHALONS EN CHAMPAGNE)',
                '7',
                '4',
                '0.5714285714285714',
            ],
            ['Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)', '16', '12', '0.75'],
            ['Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)', '21', '7', '0.3333333333333333'],
            ['Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN', '28', '12', '0.42857142857142855'],
            ['Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)', '36', '13', '0.3611111111111111'],
            ['Volvo -  ELYSEE EST AUTOS (11ème) - PARIS', '4', '2', '0.5'],
            ['Volvo -  ELYSEE EST AUTOS - CHENNEVIERES', '23', '13', '0.5652173913043478'],
            ['Volvo -  ESPACE NORD AUTOMOBILES - METZ', '41', '20', '0.4878048780487805'],
            ['Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)', '10', '4', '0.4'],
            ['Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)', '21', '12', '0.5714285714285714'],
            ['Volvo -  ESPACE SUD AUTOMOBILES - NIMES', '10', '6', '0.6'],
            ['Volvo -  EVERLINE - BESANÇON', '21', '7', '0.3333333333333333'],
            ['Volvo -  EVERLINE - EPINAL CEDEX', '14', '6', '0.42857142857142855'],
            ['Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)', '9', '2', '0.2222222222222222'],
            ['Volvo -  FELIX FAURE AUTOMOBILES - LYON', '14', '10', '0.7142857142857143'],
            ['Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX', '12', '8', '0.6666666666666666'],
            ['Volvo -  FELIX FAURE AUTOMOBILES - VIENNE', '1', '1', '1.0'],
            ['Volvo -  FELIX FAURE AUTOMOBILES - VILLEFRANCHE-SUR-SAÔNE', '3', '2', '0.6666666666666666'],
            ['Volvo -  FELIX FAURE SAINT ETIENNE - SAINT ETIENNE', '6', '3', '0.5'],
            ['Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)', '9', '3', '0.3333333333333333'],
            ["Volvo -  FIRST AUTOMOBILES - VILLENEUVE D'ASCQ", '2', '1', '0.5'],
            ['Volvo -  GENERAL AUTOMOBILE - GUERANDE', '18', '7', '0.3888888888888889'],
            ['Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)', '30', '12', '0.4'],
            ['Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN', '32', '12', '0.375'],
            ['Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI', '4', '2', '0.5'],
            ['Volvo -  GROUPE DUFFORT CHARTRES - NOGENT LE PHAYE (CHARTRES)', '2', '1', '0.5'],
            ['Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS', '9', '3', '0.3333333333333333'],
            ['Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE', '4', '1', '0.25'],
            ['Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS', '20', '8', '0.4'],
            ['Volvo -  GROUPE JFC LISIEUX - LISIEUX', '6', '2', '0.3333333333333333'],
            ['Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)', '29', '10', '0.3448275862068966'],
            ['Volvo -  Groupe JFC Evreux - NORMANVILLE (EVREUX)', '3', '1', '0.3333333333333333'],
            ['Volvo -  J.M. AUTOMOBILES - SAUVERNY (DIVONNE LES BAINS)', '1', '0', '0.0'],
            ['Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)', '7', '3', '0.42857142857142855'],
            ['Volvo -  MARTINIQUE AUTOMOBILES - LE LAMENTIN', '1', '0', '0.0'],
            ['Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)', '60', '28', '0.4666666666666667'],
            ['Volvo -  NÖRDIK STAR AUTOS - CHALON SUR SAÔNE', '4', '2', '0.5'],
            ['Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)', '47', '21', '0.44680851063829785'],
            ['Volvo -  PEMZEC AUTOMOBILES - REDON', '1', '1', '1.0'],
            ['Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)', '26', '14', '0.5384615384615384'],
            ['Volvo -  PEMZEC AUTOMOBILES 29 - BREST', '25', '13', '0.52'],
            ['Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER', '17', '10', '0.5882352941176471'],
            ['Volvo -  PERFORMAUTO - DECHY (DOUAI)', '17', '3', '0.17647058823529413'],
            ['Volvo -  PERFORMAUTO - LIEVIN', '39', '7', '0.1794871794871795'],
            ['Volvo -  PERICAUD AUTOMOBILES - BRIVE LA GAILLARDE', '1', '1', '1.0'],
            ['Volvo -  PERICAUD AUTOMOBILES - CHAMPNIERS (ANGOULEME)', '11', '6', '0.5454545454545454'],
            ['Volvo -  PERICAUD AUTOMOBILES - LIMOGES', '5', '4', '0.8'],
            ['Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)', '11', '7', '0.6363636363636364'],
            ['Volvo -  PREMIUM AUTOMOBILES - AUXERRE', '7', '2', '0.2857142857142857'],
            ['Volvo -  PREMIUM AUTOMOBILES - BARBEREY SAINT SULPICE (TROYES)', '9', '3', '0.3333333333333333'],
            ['Volvo -  PRESTIGE CARS - AUBIERE (CLERMONT FERRAND)', '6', '1', '0.16666666666666666'],
            ['Volvo -  PRESTIGE CARS - TOULON SUR ALLIER (MOULIN)', '2', '2', '1.0'],
            ['Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE', '15', '5', '0.3333333333333333'],
            ['Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)', '14', '5', '0.35714285714285715'],
            ['Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS', '33', '12', '0.36363636363636365'],
            ['Volvo -  SOMMAUTO - RIVERY LES AMIENS', '34', '13', '0.38235294117647056'],
            ['Volvo -  SP MOTORS - BOURGES', '17', '8', '0.47058823529411764'],
            ['Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE', '15', '6', '0.4'],
            ['Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)', '20', '11', '0.55'],
            ['Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS', '16', '5', '0.3125'],
            ['Volvo -  THIBAUT - LE HAVRE', '28', '11', '0.39285714285714285'],
            ['Volvo -  THIBAUT - ROUEN', '95', '35', '0.3684210526315789'],
            ['Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET', '14', '8', '0.5714285714285714'],
            ['Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS', '40', '20', '0.5'],
            ['Volvo -  VAL DE LOIRE AUTOMOBILES - DISTRE (SAUMUR)', '9', '3', '0.3333333333333333'],
            ['Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE', '20', '11', '0.55'],
            ['Volvo -  VK MOTORS - CALAIS', '30', '10', '0.3333333333333333'],
            ['Volvo -  VOLVO COLMAR - COLMAR', '2', '1', '0.5'],
            ["Volvo -  VOLVO CÔTE D'AZUR - FREJUS", '19', '5', '0.2631578947368421'],
            ['Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE DROITE - LORMONT (BORDEAUX)', '8', '1', '0.125'],
            ['Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)', '16', '6', '0.375'],
            ['Volvo -  VOLVO SIPA AUTOMOBILES PAU - LESCAR', '1', '0', '0.0'],
            ['Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)', '8', '2', '0.25'],
            ['Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)', '10', '6', '0.6'],
            ['Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)', '8', '3', '0.375'],
            ["Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE", '18', '14', '0.7777777777777778'],
            ['Volvo - EVERLINE - DENNEY (BELFORT)', '7', '4', '0.5714285714285714'],
            ['Volvo - FIRST AUTOMOBILES - AULNOY LEZ VALENCIENNES', '3', '1', '0.3333333333333333'],
            ['Volvo - THIBAULT AUTOMOBILES - ALENCON', '5', '1', '0.2'],
            ['Volvo Car France', '2', '2', '1.0'],
            ['Volvo Car France - Nanterre', '1', '1', '1.0'],
        ],
    },
};
