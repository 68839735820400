import { Tabs, TabList, Tab } from '@mui/joy';
import { theme } from '../theme';
import { useCallback, useContext, useEffect } from 'react';
import { FiltersContext } from '../contexts/filters-context';
import { filtersActions } from 'src/core/filters/service';

const elements = [
    { label: 'Status', value: 'status' },
    { label: 'Source channel', value: 'channel' },
    { label: 'Project type', value: 'project_type' },
    { label: 'Country', value: 'country_name' },
];

interface TabsMenuProps {
    setTab: Function;
}

export default function TabsMenu({ setTab }: TabsMenuProps) {
    const { dispatch, filtersState } = useContext(FiltersContext)!;
    const handleChange = useCallback(
        async (event: any, value: string | number | null) => {
            if (null === value || 'string' === typeof value) {
                return;
            }

            const newValue = elements[value].value as 'status' | 'channel' | 'project_type' | 'country_name';
            await dispatch(filtersActions.setTab(newValue));
            await dispatch(filtersActions.setCurrentPage(elements[value].label));
        },
        [dispatch],
    );

    useEffect(() => {
        setTab(filtersState.tab);
    }, [filtersState.tab, setTab]);

    return (
        <Tabs onChange={handleChange} value={elements.findIndex((e) => e.value === filtersState.tab)}>
            <TabList sx={{ backgroundColor: theme.palette.grey[100], boxShadow: 'none' }}>
                {elements.map((element) => (
                    <Tab
                        key={element.value}
                        variant="soft"
                        color="grey"
                        sx={{ height: '44px', width: '150px', borderTopLeftRadius: '3px', borderTopRightRadius: '3px' }}
                    >
                        {element.label}
                    </Tab>
                ))}
            </TabList>
        </Tabs>
    );
}
