export const countriesMock = {
    statement_id: '01ee9e4e-f7ff-1a79-b5f8-a07aee82b1b9',
    status: { state: 'SUCCEEDED' },
    manifest: {
        format: 'JSON_ARRAY',
        schema: {
            column_count: 1,
            columns: [{ name: 'country_name', type_text: 'STRING', type_name: 'STRING', position: 0 }],
        },
        total_chunk_count: 1,
        chunks: [{ chunk_index: 0, row_offset: 0, row_count: 7 }],
        total_row_count: 7,
        truncated: false,
    },
    result: {
        chunk_index: 0,
        row_offset: 0,
        row_count: 7,
        data_array: [
            ['Belgium', 'BE'],
            ['France', 'FR'],
            ['Germany', 'DE'],
            ['Italy', 'IT'],
            ['Portugal', 'PO'],
            ['Spain', 'ES'],
            ['United Kingdom', 'UK'],
        ],
    },
};
