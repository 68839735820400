export const block1aMock = {
    statement_id: '01ee99e2-b28e-199f-9dd5-7d3e27619f41',
    status: {
        state: 'SUCCEEDED',
    },
    manifest: {
        format: 'JSON_ARRAY',
        schema: {
            column_count: 8,
            columns: [
                {
                    name: 'month',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 0,
                },
                {
                    name: 'created_date',
                    type_text: 'TIMESTAMP',
                    type_name: 'TIMESTAMP',
                    position: 1,
                },
                {
                    name: 'id',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 2,
                },
                {
                    name: 'lead_status',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 3,
                },
                {
                    name: 'channel',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 4,
                },
                {
                    name: 'country_name',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 5,
                },
                {
                    name: 'project_type',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 6,
                },
                {
                    name: 'business_contributor',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 7,
                },
            ],
        },
        total_chunk_count: 1,
        chunks: [
            {
                chunk_index: 0,
                row_offset: 0,
                row_count: 3101,
            },
        ],
        total_row_count: 3101,
        truncated: false,
    },
    result: {
        chunk_index: 0,
        row_offset: 0,
        row_count: 3101,
        data_array: [
            [
                'September 2019',
                '2019-09-02T14:50:53.000Z',
                '00Q0X00000eYuG3UAK',
                'Qualified',
                'Email',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'September 2019',
                '2019-09-20T09:39:01.000Z',
                '00Q0X00000g819aUAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'September 2019',
                '2019-09-20T11:41:25.000Z',
                '00Q0X00000g81LcUAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'September 2019',
                '2019-09-24T17:07:02.000Z',
                '00Q0X00000g88MPUAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'September 2019',
                '2019-09-26T08:41:52.000Z',
                '00Q0X00000f592xUAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'September 2019',
                '2019-09-28T09:55:39.000Z',
                '00Q0X00000f5CbWUAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'October 2019',
                '2019-10-02T07:54:23.000Z',
                '00Q0X00000f5JZeUAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'October 2019',
                '2019-10-03T16:27:11.000Z',
                '00Q0X00000f5MzrUAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE',
            ],
            [
                'October 2019',
                '2019-10-11T12:03:25.000Z',
                '00Q0X00000f5fSwUAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'October 2019',
                '2019-10-11T12:42:48.000Z',
                '00Q0X00000f5fVgUAI',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                "Volvo -  CAR'S SERVICES - ONET LE CHATEAU (RODEZ)",
            ],
            [
                'October 2019',
                '2019-10-14T09:09:32.000Z',
                '00Q0X00000f5kmxUAA',
                'Qualified',
                'Typeform',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'October 2019',
                '2019-10-18T09:27:03.000Z',
                '00Q0X00000f60XyUAI',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'October 2019',
                '2019-10-23T15:34:49.000Z',
                '00Q0X00000f6ENKUA2',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'October 2019',
                '2019-10-26T12:15:09.000Z',
                '00Q0X00000f6Li7UAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'October 2019',
                '2019-10-29T13:57:29.000Z',
                '00Q0X00000f6T9ZUAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'November 2019',
                '2019-11-02T16:49:01.000Z',
                '00Q0X00000hDJnZUAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'November 2019',
                '2019-11-03T13:14:54.000Z',
                '00Q0X00000hDLTsUAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'November 2019',
                '2019-11-15T16:33:30.000Z',
                '00Q0X00000hDv2rUAC',
                'Unqualified',
                'Phone',
                'France',
                'Single Home',
                "Volvo -  FIRST AUTOMOBILES - VILLENEUVE D'ASCQ",
            ],
            [
                'November 2019',
                '2019-11-15T23:04:37.000Z',
                '00Q0X00000hDwXTUA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'November 2019',
                '2019-11-21T10:34:01.000Z',
                '00Q0X00000hEAppUAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'November 2019',
                '2019-11-25T09:14:47.000Z',
                '00Q0X00000hEKasUAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'November 2019',
                '2019-11-28T10:53:31.000Z',
                '00Q0X00000hEbzMUAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'November 2019',
                '2019-11-30T19:55:51.000Z',
                '00Q0X00000hEj2DUAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS (11ème) - PARIS',
            ],
            [
                'December 2019',
                '2019-12-02T12:07:35.000Z',
                '00Q0X00000hEmMqUAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                "Volvo -  FIRST AUTOMOBILES - VILLENEUVE D'ASCQ",
            ],
            [
                'December 2019',
                '2019-12-04T17:02:53.000Z',
                '00Q0X00000hEuBgUAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE JFC LISIEUX - LISIEUX',
            ],
            [
                'December 2019',
                '2019-12-05T07:45:56.000Z',
                '00Q0X00000hEwOEUA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'December 2019',
                '2019-12-06T00:37:16.000Z',
                '00Q0X00000hEzlJUAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO COLMAR - COLMAR',
            ],
            [
                'December 2019',
                '2019-12-06T10:41:01.000Z',
                '00Q0X00000hF0TzUAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'BMW MINI - SAINT-MERRI AMIENS - AMIENS',
            ],
            [
                'December 2019',
                '2019-12-06T15:59:07.000Z',
                '00Q0X00000hF15DUAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'December 2019',
                '2019-12-08T08:05:34.000Z',
                '00Q0X00000hF4wbUAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'December 2019',
                '2019-12-11T10:43:46.000Z',
                '00Q0X00000hFF71UAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'December 2019',
                '2019-12-11T11:28:31.000Z',
                '00Q0X00000hFFErUAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'December 2019',
                '2019-12-13T10:24:55.000Z',
                '00Q0X00000hFL3IUAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'December 2019',
                '2019-12-17T11:02:48.000Z',
                '00Q0X00000hFVFqUAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  FELIX FAURE SAINT ETIENNE - SAINT ETIENNE',
            ],
            [
                'December 2019',
                '2019-12-18T21:49:19.000Z',
                '00Q0X00000hFacIUAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            [
                'December 2019',
                '2019-12-19T21:29:43.000Z',
                '00Q0X00000hFelUUAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'December 2019',
                '2019-12-23T08:59:20.000Z',
                '00Q0X00000hFlnKUAS',
                'Unqualified',
                'Phone',
                'France',
                'Single Home',
                "Volvo -  FIRST AUTOMOBILES - VILLENEUVE D'ASCQ",
            ],
            [
                'January 2020',
                '2020-01-02T14:30:04.000Z',
                '00Q0X00000hG6CfUAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'January 2020',
                '2020-01-07T18:07:08.000Z',
                '00Q0X00000hGLToUAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'January 2020',
                '2020-01-09T11:19:22.000Z',
                '00Q0X00000hGRL1UAO',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'January 2020',
                '2020-01-09T11:25:24.000Z',
                '00Q0X00000hGRMdUAO',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'January 2020',
                '2020-01-09T15:45:46.000Z',
                '00Q0X00000hGS3uUAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'January 2020',
                '2020-01-14T16:29:23.000Z',
                '00Q0X00000hGi8JUAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'January 2020',
                '2020-01-16T16:42:37.000Z',
                '00Q0X00000hGox9UAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'January 2020',
                '2020-01-17T10:47:31.000Z',
                '00Q0X00000hGsW5UAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'January 2020',
                '2020-01-20T17:54:37.000Z',
                '00Q0X00000hH1q1UAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'January 2020',
                '2020-01-27T08:37:31.000Z',
                '00Q0X00000ihU5oUAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'January 2020',
                '2020-01-27T09:18:00.000Z',
                '00Q0X00000ihUBXUA2',
                'Qualified',
                'Phone',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'January 2020',
                '2020-01-27T21:59:39.000Z',
                '00Q0X00000ihXq5UAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'February 2020',
                '2020-02-01T14:21:14.000Z',
                '00Q0X00000ihnBuUAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES PAU - LESCAR',
            ],
            [
                'February 2020',
                '2020-02-14T12:59:44.000Z',
                '00Q0X00000iiUj4UAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'February 2020',
                '2020-02-14T17:11:38.000Z',
                '00Q0X00000iiVEkUAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO COLMAR - COLMAR',
            ],
            [
                'February 2020',
                '2020-02-24T12:03:28.000Z',
                '00Q0X00000ij25GUAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'February 2020',
                '2020-02-25T08:37:17.000Z',
                '00Q0X00000ij75rUAA',
                'Unqualified',
                'Typeform Volvo',
                'Belgium',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2020',
                '2020-02-25T11:38:15.000Z',
                '00Q0X00000ij7e1UAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'February 2020',
                '2020-02-25T13:42:47.000Z',
                '00Q0X00000ij7suUAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE JFC LISIEUX - LISIEUX',
            ],
            [
                'February 2020',
                '2020-02-26T09:37:21.000Z',
                '00Q0X00000ijAboUAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'February 2020',
                '2020-02-26T13:38:59.000Z',
                '00Q0X00000ijB8dUAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'February 2020',
                '2020-02-26T14:49:18.000Z',
                '00Q0X00000ijBJgUAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2020',
                '2020-03-04T14:06:31.000Z',
                '00Q0X00000ijUZ6UAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'March 2020',
                '2020-03-11T10:21:31.000Z',
                '00Q0X00000ijj2ZUAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'April 2020',
                '2020-04-03T22:31:25.000Z',
                '00Q0X00000ikL89UAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'April 2020',
                '2020-04-06T11:42:21.000Z',
                '00Q0X00000ikNfkUAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'April 2020',
                '2020-04-16T07:26:18.000Z',
                '00Q0X00000ikfcTUAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'April 2020',
                '2020-04-17T09:10:32.000Z',
                '00Q0X00000ikhmUUAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'April 2020',
                '2020-04-17T09:23:26.000Z',
                '00Q0X00000ikhnmUAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'April 2020',
                '2020-04-17T10:12:04.000Z',
                '00Q0X00000ikhs3UAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)',
            ],
            [
                'April 2020',
                '2020-04-17T11:02:17.000Z',
                '00Q0X00000ikhwPUAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)',
            ],
            [
                'April 2020',
                '2020-04-17T13:00:56.000Z',
                '00Q0X00000iki6IUAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'April 2020',
                '2020-04-17T15:57:33.000Z',
                '00Q0X00000ikiLfUAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'April 2020',
                '2020-04-17T17:04:27.000Z',
                '00Q0X00000ikiOtUAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'April 2020',
                '2020-04-18T08:21:28.000Z',
                '00Q0X00000ikkDjUAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'April 2020',
                '2020-04-18T08:24:35.000Z',
                '00Q0X00000ikkDtUAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)',
            ],
            [
                'April 2020',
                '2020-04-18T13:30:44.000Z',
                '00Q0X00000ikkNAUAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'April 2020',
                '2020-04-19T16:27:47.000Z',
                '00Q0X00000ikls4UAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'April 2020',
                '2020-04-20T13:27:29.000Z',
                '00Q0X00000ikoHJUAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'April 2020',
                '2020-04-20T19:36:32.000Z',
                '00Q0X00000ikpriUAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'April 2020',
                '2020-04-23T14:36:23.000Z',
                '00Q0X00000ikxWbUAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'April 2020',
                '2020-04-24T04:58:46.000Z',
                '00Q6700000pKzRNEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'April 2020',
                '2020-04-24T12:18:40.000Z',
                '00Q0X00000il1SVUAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'April 2020',
                '2020-04-26T21:56:03.000Z',
                '00Q0X00000il5z2UAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'May 2020',
                '2020-05-05T15:42:52.000Z',
                '00Q0X00000kO8HmUAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'May 2020',
                '2020-05-18T07:59:17.000Z',
                '00Q0X00000kOlftUAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'May 2020',
                '2020-05-19T13:48:57.000Z',
                '00Q0X00000kOqBgUAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'May 2020',
                '2020-05-20T09:56:00.000Z',
                '00Q0X00000kOtdxUAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'May 2020',
                '2020-05-20T10:02:20.000Z',
                '00Q0X00000kOtftUAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'May 2020',
                '2020-05-20T11:45:09.000Z',
                '00Q0X00000kOtxGUAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'May 2020',
                '2020-05-20T12:42:41.000Z',
                '00Q0X00000kOu6xUAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'May 2020',
                '2020-05-20T12:48:26.000Z',
                '00Q0X00000kOu7vUAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'May 2020',
                '2020-05-30T08:28:12.000Z',
                '00Q0X00000kPMFJUA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2020',
                '2020-05-30T08:39:01.000Z',
                '00Q0X00000kPMG7UAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2020',
                '2020-05-30T16:09:22.000Z',
                '00Q0X00000kPMbKUAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'June 2020',
                '2020-06-05T05:47:48.000Z',
                '00Q0X00000kPcOWUA0',
                'Unqualified',
                'Typeform Volvo',
                'Belgium',
                'Company',
                'Concession Inconnue',
            ],
            [
                'June 2020',
                '2020-06-05T14:47:52.000Z',
                '00Q0X00000kPdMkUAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'June 2020',
                '2020-06-09T14:32:47.000Z',
                '00Q0X00000kPmiOUAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'June 2020',
                '2020-06-15T13:36:29.000Z',
                '00Q0X00000kQ1I5UAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - BORGO',
            ],
            [
                'June 2020',
                '2020-06-16T11:49:22.000Z',
                '00Q0X00000kQ40RUAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT CHARTRES - NOGENT LE PHAYE (CHARTRES)',
            ],
            [
                'June 2020',
                '2020-06-17T14:48:27.000Z',
                '00Q0X00000kQ8BZUA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE',
            ],
            [
                'June 2020',
                '2020-06-18T12:45:11.000Z',
                '00Q0X00000kQAfaUAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'June 2020',
                '2020-06-19T21:28:09.000Z',
                '00Q0X00000kQGQWUA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2020',
                '2020-06-21T15:50:00.000Z',
                '00Q0X00000kQJbnUAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'June 2020',
                '2020-06-21T22:24:43.000Z',
                '00Q0X00000kQKgtUAG',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'June 2020',
                '2020-06-22T09:47:33.000Z',
                '00Q0X00000kQLIzUAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - NEUILLY SUR SEINE',
            ],
            [
                'June 2020',
                '2020-06-22T10:46:12.000Z',
                '00Q0X00000kQLREUA4',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'June 2020',
                '2020-06-22T20:02:04.000Z',
                '00Q0X00000kQNRZUA4',
                'Qualified',
                'Typeform',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2020',
                '2020-06-23T07:11:39.000Z',
                '00Q0X00000kQNs1UAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'June 2020',
                '2020-06-23T12:09:26.000Z',
                '00Q0X00000kQOcJUAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'June 2020',
                '2020-06-23T13:49:36.000Z',
                '00Q0X00000kQOoPUAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'June 2020',
                '2020-06-24T07:25:41.000Z',
                '00Q0X00000kQRH2UAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'June 2020',
                '2020-06-24T08:45:26.000Z',
                '00Q0X00000kQRRhUAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'June 2020',
                '2020-06-24T08:58:24.000Z',
                '00Q0X00000kQRTDUA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  AMPLITUDE AUTOMOBILES - SAINTES',
            ],
            [
                'June 2020',
                '2020-06-24T13:05:54.000Z',
                '00Q0X00000kQS0vUAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'June 2020',
                '2020-06-25T15:21:36.000Z',
                '00Q0X00000kQV2sUAG',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            [
                'June 2020',
                '2020-06-27T17:07:13.000Z',
                '00Q0X00000kQf00UAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2020',
                '2020-06-27T19:37:29.000Z',
                '00Q0X00000kQfwJUAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - BUCHELAY (MANTES LA JOLIE)',
            ],
            [
                'June 2020',
                '2020-06-29T08:47:39.000Z',
                '00Q0X00000kQhz5UAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2020',
                '2020-06-29T20:08:57.000Z',
                '00Q0X00000kQkSJUA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2020',
                '2020-06-29T20:10:40.000Z',
                '00Q0X00000kQkSTUA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'June 2020',
                '2020-06-30T08:57:54.000Z',
                '00Q0X00000kQkzFUAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'June 2020',
                '2020-06-30T13:30:04.000Z',
                '00Q0X00000kQljiUAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'June 2020',
                '2020-06-30T14:47:50.000Z',
                '00Q0X00000kQlvaUAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'July 2020',
                '2020-07-01T11:46:06.000Z',
                '00Q0X00000kQoiBUAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-01T14:31:22.000Z',
                '00Q0X00000kQoxhUAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'July 2020',
                '2020-07-01T22:14:16.000Z',
                '00Q0X00000kQqRnUAK',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'July 2020',
                '2020-07-02T07:28:23.000Z',
                '00Q0X00000kQqmuUAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'July 2020',
                '2020-07-02T08:12:25.000Z',
                '00Q0X00000kQqraUAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'July 2020',
                '2020-07-02T11:46:06.000Z',
                '00Q0X00000kQrHMUA0',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  PRESTIGE CARS - AUBIERE (CLERMONT FERRAND)',
            ],
            [
                'July 2020',
                '2020-07-02T11:48:48.000Z',
                '00Q0X00000kQrI0UAK',
                'Qualified',
                'Direct',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-02T11:55:33.000Z',
                '00Q0X00000kQrHXUA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'July 2020',
                '2020-07-02T15:50:42.000Z',
                '00Q0X00000kQs23UAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'July 2020',
                '2020-07-03T08:44:16.000Z',
                '00Q0X00000kQtp6UAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'July 2020',
                '2020-07-03T14:49:58.000Z',
                '00Q0X00000kQuSaUAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'July 2020',
                '2020-07-03T16:13:41.000Z',
                '00Q0X00000kQuaAUAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'July 2020',
                '2020-07-05T05:20:54.000Z',
                '00Q0X00000kQxeAUAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-05T06:12:08.000Z',
                '00Q0X00000kQxf8UAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-05T08:44:36.000Z',
                '00Q0X00000kQxjFUAS',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'July 2020',
                '2020-07-06T12:09:07.000Z',
                '00Q0X00000kQzwRUAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'July 2020',
                '2020-07-07T08:05:24.000Z',
                '00Q0X00000kR2ArUAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'July 2020',
                '2020-07-07T13:28:13.000Z',
                '00Q0X00000kR2dvUAC',
                'Qualified',
                'Direct',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'July 2020',
                '2020-07-08T09:19:10.000Z',
                '00Q0X00000kR590UAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'July 2020',
                '2020-07-08T11:51:14.000Z',
                '00Q0X00000kR5OXUA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'July 2020',
                '2020-07-08T12:10:37.000Z',
                '00Q0X00000kR5Q9UAK',
                'Qualified',
                'Direct',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'July 2020',
                '2020-07-08T12:10:48.000Z',
                '00Q0X00000kR5QEUA0',
                'Qualified',
                'Direct',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'July 2020',
                '2020-07-08T12:59:56.000Z',
                '00Q0X00000kR5XNUA0',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE',
            ],
            [
                'July 2020',
                '2020-07-10T15:37:00.000Z',
                '00Q0X00000kRBxPUAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-10T16:20:02.000Z',
                '00Q0X00000kRC0xUAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-10T16:22:36.000Z',
                '00Q0X00000kRC17UAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'July 2020',
                '2020-07-10T16:24:12.000Z',
                '00Q0X00000kRC1MUAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-10T16:26:24.000Z',
                '00Q0X00000kRC1RUAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - VERSAILLES',
            ],
            [
                'July 2020',
                '2020-07-10T16:28:32.000Z',
                '00Q0X00000kRC1gUAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-10T16:30:06.000Z',
                '00Q0X00000kRC1NUAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-10T16:31:47.000Z',
                '00Q0X00000kRC20UAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-10T16:33:20.000Z',
                '00Q0X00000kRC21UAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-10T16:35:39.000Z',
                '00Q0X00000kRC2PUAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-11T17:41:25.000Z',
                '00Q0X00000kRE3BUAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-12T01:26:12.000Z',
                '00Q0X00000kRFAmUAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-13T05:02:27.000Z',
                '00Q0X00000kRGspUAG',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'July 2020',
                '2020-07-13T12:31:16.000Z',
                '00Q0X00000kRHcMUAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-13T14:28:15.000Z',
                '00Q0X00000kRHpsUAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'July 2020',
                '2020-07-13T16:59:28.000Z',
                '00Q0X00000kRI6VUAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-13T20:12:06.000Z',
                '00Q0X00000kRJI3UAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'July 2020',
                '2020-07-13T20:15:04.000Z',
                '00Q0X00000kRJIIUA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-13T21:01:30.000Z',
                '00Q0X00000kRJM5UAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'July 2020',
                '2020-07-14T14:14:36.000Z',
                '00Q0X00000kRKriUAG',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'July 2020',
                '2020-07-14T15:17:46.000Z',
                '00Q0X00000kRKzGUAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-15T12:32:03.000Z',
                '00Q0X00000kROLqUAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  FELIX FAURE AUTOMOBILES - VILLEFRANCHE-SUR-SAÔNE',
            ],
            [
                'July 2020',
                '2020-07-15T13:34:30.000Z',
                '00Q0X00000kROWsUAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-15T16:43:47.000Z',
                '00Q0X00000kRPB5UAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-16T16:59:44.000Z',
                '00Q0X00000kRSCOUA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO COLMAR - COLMAR',
            ],
            [
                'July 2020',
                '2020-07-16T19:20:23.000Z',
                '00Q0X00000kRTMAUA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-17T07:34:58.000Z',
                '00Q0X00000kRTrgUAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'July 2020',
                '2020-07-17T09:56:15.000Z',
                '00Q0X00000kRU5wUAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'July 2020',
                '2020-07-17T11:18:37.000Z',
                '00Q0X00000kRUEPUA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'July 2020',
                '2020-07-17T13:27:01.000Z',
                '00Q0X00000kRUQQUA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO COLMAR - COLMAR',
            ],
            [
                'July 2020',
                '2020-07-17T19:42:11.000Z',
                '00Q0X00000kRWmEUAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-17T19:57:54.000Z',
                '00Q0X00000kRWmxUAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'July 2020',
                '2020-07-18T07:41:26.000Z',
                '00Q0X00000kRX86UAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'July 2020',
                '2020-07-19T06:46:06.000Z',
                '00Q0X00000kRYq4UAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'July 2020',
                '2020-07-19T19:28:14.000Z',
                '00Q0X00000kRaIwUAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-20T17:34:49.000Z',
                '00Q0X00000kRbnGUAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-23T08:30:30.000Z',
                '00Q0X00000kRifOUAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'July 2020',
                '2020-07-24T09:06:25.000Z',
                '00Q0X00000kRlVrUAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'July 2020',
                '2020-07-24T09:18:14.000Z',
                '00Q0X00000kRlXsUAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-24T09:22:14.000Z',
                '00Q0X00000kRlYWUA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'July 2020',
                '2020-07-24T10:09:16.000Z',
                '00Q0X00000kRleUUAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'July 2020',
                '2020-07-24T10:12:50.000Z',
                '00Q0X00000kRleyUAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  FELIX FAURE SAINT ETIENNE - SAINT ETIENNE',
            ],
            [
                'July 2020',
                '2020-07-24T10:18:10.000Z',
                '00Q0X00000kRlfNUAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'July 2020',
                '2020-07-24T10:32:08.000Z',
                '00Q0X00000kRlgGUAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'July 2020',
                '2020-07-24T10:48:52.000Z',
                '00Q6700000yPsZoEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - SAINT MARTIN SUR LE PRE (CHALONS EN CHAMPAGNE)',
            ],
            [
                'July 2020',
                '2020-07-24T11:01:37.000Z',
                '00Q0X00000kRligUAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'July 2020',
                '2020-07-24T11:19:35.000Z',
                '00Q0X00000kRlkDUAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-24T12:08:27.000Z',
                '00Q0X00000kRlpNUAS',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'July 2020',
                '2020-07-24T12:41:06.000Z',
                '00Q0X00000kRls2UAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'July 2020',
                '2020-07-24T12:44:04.000Z',
                '00Q0X00000kRlsWUAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'July 2020',
                '2020-07-24T13:34:18.000Z',
                '00Q0X00000kRlxMUAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'July 2020',
                '2020-07-24T13:57:00.000Z',
                '00Q0X00000kRlzrUAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'July 2020',
                '2020-07-24T15:23:49.000Z',
                '00Q0X00000kRm8EUAS',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES PAU - LESCAR',
            ],
            [
                'July 2020',
                '2020-07-24T17:56:43.000Z',
                '00Q0X00000kRmJgUAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'July 2020',
                '2020-07-24T18:15:38.000Z',
                '00Q0X00000kRn7eUAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-24T19:35:13.000Z',
                '00Q0X00000kRnLsUAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'July 2020',
                '2020-07-25T08:43:41.000Z',
                '00Q0X00000kRnq9UAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'July 2020',
                '2020-07-25T13:14:08.000Z',
                '00Q0X00000kRo4VUAS',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'July 2020',
                '2020-07-25T13:24:29.000Z',
                '00Q0X00000kRo4zUAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'July 2020',
                '2020-07-25T16:52:02.000Z',
                '00Q0X00000kRoDJUA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-25T18:12:57.000Z',
                '00Q0X00000kRowOUAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'July 2020',
                '2020-07-26T05:47:50.000Z',
                '00Q0X00000kRpR6UAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-26T10:01:40.000Z',
                '00Q0X00000kRpXYUA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'July 2020',
                '2020-07-26T18:59:45.000Z',
                '00Q0X00000kRqmeUAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-27T07:30:06.000Z',
                '00Q0X00000kRrBeUAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-27T09:08:04.000Z',
                '00Q0X00000kRrNuUAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'July 2020',
                '2020-07-27T09:16:25.000Z',
                '00Q0X00000kRrffUAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                "Volvo -  FIRST AUTOMOBILES - VILLENEUVE D'ASCQ",
            ],
            [
                'July 2020',
                '2020-07-27T11:48:59.000Z',
                '00Q0X00000kRrvnUAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-27T12:00:23.000Z',
                '00Q0X00000kRrxZUAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'July 2020',
                '2020-07-28T06:28:05.000Z',
                '00Q0X00000kRuArUAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PRESTIGE CARS - AUBIERE (CLERMONT FERRAND)',
            ],
            [
                'July 2020',
                '2020-07-28T11:22:42.000Z',
                '00Q0X00000kRuayUAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-28T14:45:48.000Z',
                '00Q0X00000kRv8HUAS',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'July 2020',
                '2020-07-29T09:52:34.000Z',
                '00Q0X00000kRxU1UAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'July 2020',
                '2020-07-29T11:46:34.000Z',
                '00Q0X00000kRxf9UAC',
                'Qualified',
                'Direct',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2020',
                '2020-07-29T15:38:24.000Z',
                '00Q0X00000kRy4SUAS',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'July 2020',
                '2020-07-31T06:40:30.000Z',
                '00Q0X00000kS2OhUAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PERICAUD AUTOMOBILES - LIMOGES',
            ],
            [
                'July 2020',
                '2020-07-31T15:43:31.000Z',
                '00Q0X00000kS3GuUAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'August 2020',
                '2020-08-01T14:52:54.000Z',
                '00Q0X00000kS4wjUAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE',
            ],
            [
                'August 2020',
                '2020-08-01T15:55:45.000Z',
                '00Q0X00000kS4z9UAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'August 2020',
                '2020-08-02T14:21:12.000Z',
                '00Q0X00000kS6QGUA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2020',
                '2020-08-04T21:14:43.000Z',
                '00Q0X00000kSCgKUAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'August 2020',
                '2020-08-07T03:32:33.000Z',
                '00Q0X00000kSHwJUAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'August 2020',
                '2020-08-08T11:25:00.000Z',
                '00Q0X00000lk38pUAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2020',
                '2020-08-10T17:13:34.000Z',
                '00Q0X00000lk79MUAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'August 2020',
                '2020-08-11T04:51:07.000Z',
                '00Q0X00000lk8yVUAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE DROITE - LORMONT (BORDEAUX)',
            ],
            [
                'August 2020',
                '2020-08-12T14:59:17.000Z',
                '00Q0X00000lkCf5UAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'August 2020',
                '2020-08-13T05:38:48.000Z',
                '00Q0X00000lkEhkUAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'August 2020',
                '2020-08-13T15:34:21.000Z',
                '00Q0X00000lkFWRUA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'August 2020',
                '2020-08-14T15:14:03.000Z',
                '00Q0X00000lkHz4UAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'August 2020',
                '2020-08-17T16:20:45.000Z',
                '00Q0X00000lkObzUAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2020',
                '2020-08-19T02:08:09.000Z',
                '00Q0X00000lkTHGUA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'August 2020',
                '2020-08-21T07:15:44.000Z',
                '00Q0X00000lkZDnUAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)',
            ],
            [
                'August 2020',
                '2020-08-21T07:16:16.000Z',
                '00Q0X00000lkZDsUAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)',
            ],
            [
                'August 2020',
                '2020-08-21T17:25:55.000Z',
                '00Q0X00000lka3jUAA',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  VOLVO COLMAR - COLMAR',
            ],
            [
                'August 2020',
                '2020-08-23T21:13:14.000Z',
                '00Q0X00000lkeYhUAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'August 2020',
                '2020-08-25T08:45:30.000Z',
                '00Q0X00000lki5CUAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'August 2020',
                '2020-08-25T09:39:36.000Z',
                '00Q0X00000lkiC8UAI',
                'Unqualified',
                'Zeplug',
                'France',
                'Single Home',
                'Volvo -  PRESTIGE CARS - AUBIERE (CLERMONT FERRAND)',
            ],
            [
                'August 2020',
                '2020-08-25T13:38:52.000Z',
                '00Q0X00000lkiYoUAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'August 2020',
                '2020-08-25T20:29:29.000Z',
                '00Q0X00000lkkBLUAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2020',
                '2020-08-26T07:44:28.000Z',
                '00Q0X00000lkkbwUAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'August 2020',
                '2020-08-26T21:19:40.000Z',
                '00Q0X00000lkn16UAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'August 2020',
                '2020-08-27T08:58:58.000Z',
                '00Q0X00000lknW6UAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'August 2020',
                '2020-08-28T09:53:38.000Z',
                '00Q0X00000lkqMdUAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'August 2020',
                '2020-08-28T11:06:01.000Z',
                '00Q0X00000lkqTeUAI',
                'Unqualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'August 2020',
                '2020-08-29T11:58:17.000Z',
                '00Q0X00000lksftUAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'August 2020',
                '2020-08-29T16:23:25.000Z',
                '00Q0X00000lkspjUAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2020',
                '2020-08-29T18:47:40.000Z',
                '00Q0X00000lktrHUAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'August 2020',
                '2020-08-30T20:47:49.000Z',
                '00Q0X00000lkvSuUAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'September 2020',
                '2020-09-02T13:09:47.000Z',
                '00Q0X00000ll479UAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'September 2020',
                '2020-09-03T14:06:41.000Z',
                '00Q0X00000ll76LUAQ',
                'Qualified',
                'Phone',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'September 2020',
                '2020-09-04T13:46:35.000Z',
                '00Q0X00000ll9a3UAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - REDON',
            ],
            [
                'September 2020',
                '2020-09-05T09:12:11.000Z',
                '00Q0X00000llBHnUAM',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'September 2020',
                '2020-09-05T10:23:03.000Z',
                '00Q0X00000llBL0UAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2020',
                '2020-09-06T13:39:47.000Z',
                '00Q0X00000llCtrUAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'September 2020',
                '2020-09-07T16:09:39.000Z',
                '00Q0X00000llFZcUAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'September 2020',
                '2020-09-08T06:06:34.000Z',
                '00Q0X00000llHHgUAM',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'September 2020',
                '2020-09-08T08:53:18.000Z',
                '00Q0X00000llHYtUAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'September 2020',
                '2020-09-08T09:35:41.000Z',
                '00Q0X00000llHg4UAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'September 2020',
                '2020-09-09T07:54:43.000Z',
                '00Q0X00000llKB4UAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'September 2020',
                '2020-09-09T09:00:32.000Z',
                '00Q0X00000llKHRUA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'September 2020',
                '2020-09-09T10:09:06.000Z',
                '00Q0X00000llKQVUA2',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'September 2020',
                '2020-09-10T08:25:15.000Z',
                '00Q0X00000llMyxUAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'September 2020',
                '2020-09-10T08:56:12.000Z',
                '00Q0X00000llN2GUAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES PAU - LESCAR',
            ],
            [
                'September 2020',
                '2020-09-10T19:51:27.000Z',
                '00Q0X00000llPIEUA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  J.D. AUTOMOBILES - SAINT GEREON (ANCENIS)',
            ],
            [
                'September 2020',
                '2020-09-11T06:51:46.000Z',
                '00Q0X00000llQ9pUAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'September 2020',
                '2020-09-11T08:29:26.000Z',
                '00Q0X00000llQIwUAM',
                'Unqualified',
                'Phone',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'September 2020',
                '2020-09-11T08:49:19.000Z',
                '00Q0X00000llQJSUA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'September 2020',
                '2020-09-11T15:42:06.000Z',
                '00Q0X00000llR5YUAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'September 2020',
                '2020-09-13T21:10:39.000Z',
                '00Q0X00000llVVbUAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PREMIUM AUTOMOBILES - BARBEREY SAINT SULPICE (TROYES)',
            ],
            [
                'September 2020',
                '2020-09-14T08:52:20.000Z',
                '00Q0X00000llW7rUAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'September 2020',
                '2020-09-14T10:30:37.000Z',
                '00Q0X00000llWS0UAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'September 2020',
                '2020-09-14T14:10:49.000Z',
                '00Q0X00000llX55UAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'September 2020',
                '2020-09-14T14:12:03.000Z',
                '00Q0X00000llX5ZUAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE DROITE - LORMONT (BORDEAUX)',
            ],
            [
                'September 2020',
                '2020-09-15T10:43:07.000Z',
                '00Q0X00000llZklUAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2020',
                '2020-09-15T21:28:11.000Z',
                '00Q0X00000llc84UAA',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'September 2020',
                '2020-09-16T05:55:58.000Z',
                '00Q0X00000llcLYUAY',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'September 2020',
                '2020-09-16T15:26:21.000Z',
                '00Q0X00000lldYnUAI',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'September 2020',
                '2020-09-16T19:31:48.000Z',
                '00Q0X00000llf6lUAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'September 2020',
                '2020-09-17T10:38:43.000Z',
                '00Q0X00000llgGdUAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - VERSAILLES',
            ],
            [
                'September 2020',
                '2020-09-18T15:46:32.000Z',
                '00Q6700000vEDuDEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'September 2020',
                '2020-09-19T12:30:06.000Z',
                '00Q0X00000lllpLUAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'September 2020',
                '2020-09-19T17:00:38.000Z',
                '00Q0X00000llm2yUAA',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE',
            ],
            [
                'September 2020',
                '2020-09-20T13:49:17.000Z',
                '00Q6700000llnX9EAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'September 2020',
                '2020-09-20T16:44:20.000Z',
                '00Q6700000llneAEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'September 2020',
                '2020-09-21T13:44:13.000Z',
                '00Q6700000llq7jEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'September 2020',
                '2020-09-21T17:30:10.000Z',
                '00Q6700000llqnUEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'September 2020',
                '2020-09-21T19:29:00.000Z',
                '00Q6700000lls5PEAQ',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'September 2020',
                '2020-09-22T08:16:39.000Z',
                '00Q6700000llsrsEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2020',
                '2020-09-22T09:01:44.000Z',
                '00Q6700000llszDEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'September 2020',
                '2020-09-22T14:49:09.000Z',
                '00Q6700000llu4xEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'September 2020',
                '2020-09-22T16:04:47.000Z',
                '00Q6700000lluLyEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'September 2020',
                '2020-09-23T05:53:18.000Z',
                '00Q6700000llwXhEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'September 2020',
                '2020-09-23T07:56:42.000Z',
                '00Q6700000llwikEAA',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'September 2020',
                '2020-09-23T08:20:52.000Z',
                '00Q6700000llwm8EAA',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'September 2020',
                '2020-09-23T12:55:33.000Z',
                '00Q6700000llxVgEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'September 2020',
                '2020-09-24T14:46:38.000Z',
                '00Q6700000lm1NAEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'September 2020',
                '2020-09-25T08:29:15.000Z',
                '00Q6700000lm3yYEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'September 2020',
                '2020-09-26T10:07:38.000Z',
                '00Q6700000lm72iEAA',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'September 2020',
                '2020-09-27T10:04:31.000Z',
                '00Q6700000lm95fEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2020',
                '2020-09-28T11:56:43.000Z',
                '00Q6700000lmBjPEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'September 2020',
                '2020-09-28T12:53:53.000Z',
                '00Q6700000lmBquEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'September 2020',
                '2020-09-28T13:12:10.000Z',
                '00Q6700000lmBucEAE',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'September 2020',
                '2020-09-30T13:32:32.000Z',
                '00Q6700000lmKvQEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'September 2020',
                '2020-09-30T19:20:49.000Z',
                '00Q6700000lmMulEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'October 2020',
                '2020-10-01T05:15:12.000Z',
                '00Q6700000lmNMpEAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'October 2020',
                '2020-10-01T15:40:28.000Z',
                '00Q6700000lmOh2EAE',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'October 2020',
                '2020-10-04T13:28:34.000Z',
                '00Q6700000lmY5vEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'October 2020',
                '2020-10-04T15:33:49.000Z',
                '00Q6700000lmYCNEA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'October 2020',
                '2020-10-04T19:22:28.000Z',
                '00Q6700000lmZNMEA2',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  J.M. AUTOMOBILES - SAUVERNY (DIVONNE LES BAINS)',
            ],
            [
                'October 2020',
                '2020-10-05T08:19:38.000Z',
                '00Q6700000lma1sEAA',
                'Unqualified',
                'Typeform Volvo',
                'Belgium',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'October 2020',
                '2020-10-05T10:01:52.000Z',
                '00Q6700000lmaGTEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE JFC LISIEUX - LISIEUX',
            ],
            [
                'October 2020',
                '2020-10-05T12:25:30.000Z',
                '00Q6700000lmaXAEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'October 2020',
                '2020-10-05T15:40:30.000Z',
                '00Q6700000lmb3iEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'October 2020',
                '2020-10-07T05:47:37.000Z',
                '00Q6700000lmh0OEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'October 2020',
                '2020-10-07T09:14:01.000Z',
                '00Q6700000lmhPxEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'October 2020',
                '2020-10-08T06:48:35.000Z',
                '00Q6700000lmkRxEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'October 2020',
                '2020-10-08T15:58:09.000Z',
                '00Q6700000lmlRgEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'October 2020',
                '2020-10-08T18:21:01.000Z',
                '00Q6700000lmmqXEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'October 2020',
                '2020-10-09T08:13:09.000Z',
                '00Q6700000lmngTEAQ',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - NEUILLY SUR SEINE',
            ],
            [
                'October 2020',
                '2020-10-09T08:25:02.000Z',
                '00Q6700000lmniMEAQ',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - NEUILLY SUR SEINE',
            ],
            [
                'October 2020',
                '2020-10-10T17:25:36.000Z',
                '00Q6700000lmr9fEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'October 2020',
                '2020-10-10T21:20:03.000Z',
                '00Q6700000lmsKyEAI',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'October 2020',
                '2020-10-11T06:52:58.000Z',
                '00Q6700000lmsX9EAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2020',
                '2020-10-11T11:43:09.000Z',
                '00Q6700000lmshiEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CAVALLARI AUTOMOBILES - NICE',
            ],
            [
                'October 2020',
                '2020-10-11T15:25:33.000Z',
                '00Q6700000lmstKEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'October 2020',
                '2020-10-11T15:58:30.000Z',
                '00Q6700000lmsvQEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'October 2020',
                '2020-10-12T09:13:14.000Z',
                '00Q6700000lmunVEAQ',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'October 2020',
                '2020-10-12T11:28:15.000Z',
                '00Q6700000lmv5iEAA',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE EST AUTOS (11ème) - PARIS',
            ],
            [
                'October 2020',
                '2020-10-13T13:13:56.000Z',
                '00Q6700000ln0Y7EAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'October 2020',
                '2020-10-13T13:43:56.000Z',
                '00Q6700000ln0cIEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'October 2020',
                '2020-10-13T19:20:32.000Z',
                '00Q6700000ln39jEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'October 2020',
                '2020-10-14T08:11:08.000Z',
                '00Q6700000ln3ppEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'October 2020',
                '2020-10-14T14:24:43.000Z',
                '00Q6700000ln4n4EAA',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - DISTRE (SAUMUR)',
            ],
            [
                'October 2020',
                '2020-10-14T21:25:06.000Z',
                '00Q6700000ln6rDEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'October 2020',
                '2020-10-15T08:31:06.000Z',
                '00Q6700000ln7TlEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)',
            ],
            [
                'October 2020',
                '2020-10-16T13:17:51.000Z',
                '00Q6700000lnBkiEAE',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'October 2020',
                '2020-10-16T16:04:16.000Z',
                '00Q6700000lnC7iEAE',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES',
            ],
            [
                'October 2020',
                '2020-10-17T07:57:19.000Z',
                '00Q6700000lnE6SEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'October 2020',
                '2020-10-17T17:14:52.000Z',
                '00Q6700000lnEkIEAU',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  PRESTIGE CARS - AUBIERE (CLERMONT FERRAND)',
            ],
            [
                'October 2020',
                '2020-10-18T10:04:12.000Z',
                '00Q6700000lnH5mEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'October 2020',
                '2020-10-18T20:25:28.000Z',
                '00Q6700000lnIk7EAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)',
            ],
            [
                'October 2020',
                '2020-10-19T10:08:14.000Z',
                '00Q6700000lnJkOEAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'October 2020',
                '2020-10-19T10:10:25.000Z',
                '00Q6700000lnJkiEAE',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'October 2020',
                '2020-10-20T14:04:06.000Z',
                '00Q6700000lnQmaEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2020',
                '2020-10-20T15:32:13.000Z',
                '00Q6700000lnR7NEAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - DIZY (EPERNAY)',
            ],
            [
                'October 2020',
                '2020-10-21T08:27:10.000Z',
                '00Q6700000lnTYgEAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI',
            ],
            [
                'October 2020',
                '2020-10-21T21:05:18.000Z',
                '00Q6700000lnWb2EAE',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'October 2020',
                '2020-10-22T07:17:50.000Z',
                '00Q6700000lnWyQEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'October 2020',
                '2020-10-22T10:04:52.000Z',
                '00Q6700000lnXJDEA2',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'October 2020',
                '2020-10-22T10:44:21.000Z',
                '00Q6700000lnXPVEA2',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE',
            ],
            [
                'October 2020',
                '2020-10-22T20:19:31.000Z',
                '00Q6700000lnZggEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'October 2020',
                '2020-10-24T13:35:45.000Z',
                '00Q6700000lnd8wEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT CHARTRES - NOGENT LE PHAYE (CHARTRES)',
            ],
            [
                'October 2020',
                '2020-10-24T18:01:25.000Z',
                '00Q6700000lndWuEAI',
                'Qualified',
                'Typeform',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2020',
                '2020-10-26T14:12:53.000Z',
                '00Q6700000lnhZgEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            [
                'October 2020',
                '2020-10-27T07:58:06.000Z',
                '00Q6700000lnjuREAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2020',
                '2020-10-28T08:50:03.000Z',
                '00Q6700000lnnCnEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'October 2020',
                '2020-10-30T10:16:23.000Z',
                '00Q6700000lnuCGEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'November 2020',
                '2020-11-01T15:36:59.000Z',
                '00Q6700000lnzY6EAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'November 2020',
                '2020-11-01T22:13:59.000Z',
                '00Q6700000lo10LEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'November 2020',
                '2020-11-02T13:38:51.000Z',
                '00Q6700000lo28ZEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'November 2020',
                '2020-11-02T17:06:52.000Z',
                '00Q6700000lo2YSEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'November 2020',
                '2020-11-03T12:20:43.000Z',
                '00Q6700000lo54kEAA',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - VERSAILLES',
            ],
            [
                'November 2020',
                '2020-11-03T15:27:47.000Z',
                '00Q6700000lo5xFEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - EPINAY SUR SEINE',
            ],
            [
                'November 2020',
                '2020-11-03T19:03:51.000Z',
                '00Q6700000lo6nIEAQ',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'November 2020',
                '2020-11-04T16:10:38.000Z',
                '00Q6700000lo9rNEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE DROITE - LORMONT (BORDEAUX)',
            ],
            [
                'November 2020',
                '2020-11-05T06:45:07.000Z',
                '00Q6700000loBsIEAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'November 2020',
                '2020-11-05T13:18:31.000Z',
                '00Q6700000loCV0EAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'November 2020',
                '2020-11-05T21:00:34.000Z',
                '00Q6700000nELuAEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'November 2020',
                '2020-11-06T16:50:50.000Z',
                '00Q6700000nENy8EAG',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'November 2020',
                '2020-11-07T08:48:15.000Z',
                '00Q6700000nEPxeEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'November 2020',
                '2020-11-07T10:33:55.000Z',
                '00Q6700000nEQ4LEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'November 2020',
                '2020-11-07T18:01:46.000Z',
                '00Q6700000nEQW0EAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - ROISSY EN FRANCE',
            ],
            [
                'November 2020',
                '2020-11-08T11:21:08.000Z',
                '00Q6700000nESH9EAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'November 2020',
                '2020-11-09T08:27:31.000Z',
                '00Q6700000nEU6MEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'November 2020',
                '2020-11-09T12:47:21.000Z',
                '00Q6700000nEUndEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'November 2020',
                '2020-11-09T18:14:56.000Z',
                '00Q6700000nEVdTEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2020',
                '2020-11-10T08:26:09.000Z',
                '00Q6700000nEXnWEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'November 2020',
                '2020-11-10T14:16:40.000Z',
                '00Q6700000nEYWbEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'November 2020',
                '2020-11-10T14:18:06.000Z',
                '00Q6700000nEYWqEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT CHARTRES - NOGENT LE PHAYE (CHARTRES)',
            ],
            [
                'November 2020',
                '2020-11-12T09:53:20.000Z',
                '00Q6700000nEfJdEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'November 2020',
                '2020-11-12T17:03:03.000Z',
                '00Q6700000nEgMXEA0',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES',
            ],
            [
                'November 2020',
                '2020-11-13T11:03:16.000Z',
                '00Q6700000nEk3AEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'November 2020',
                '2020-11-14T07:21:39.000Z',
                '00Q6700000nEmslEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'November 2020',
                '2020-11-16T16:08:32.000Z',
                '00Q6700000nEsj4EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'November 2020',
                '2020-11-17T08:37:25.000Z',
                '00Q6700000nEvBHEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'November 2020',
                '2020-11-20T12:14:18.000Z',
                '00Q6700000nF8vnEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'November 2020',
                '2020-11-21T09:49:48.000Z',
                '00Q6700000nFBXOEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2020',
                '2020-11-22T21:24:25.000Z',
                '00Q6700000nFFZFEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'November 2020',
                '2020-11-23T07:11:50.000Z',
                '00Q6700000nFFpNEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO COLMAR - COLMAR',
            ],
            [
                'November 2020',
                '2020-11-23T07:18:06.000Z',
                '00Q6700000nFFq1EAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'November 2020',
                '2020-11-23T11:37:40.000Z',
                '00Q6700000nFH6IEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2020',
                '2020-11-23T15:14:35.000Z',
                '00Q6700000nFHpkEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2020',
                '2020-11-24T10:02:43.000Z',
                '00Q6700000nFL5vEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'November 2020',
                '2020-11-25T22:21:58.000Z',
                '00Q6700000nFV9uEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'November 2020',
                '2020-11-26T09:24:16.000Z',
                '00Q6700000nFVenEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'November 2020',
                '2020-11-27T13:34:34.000Z',
                '00Q6700000nFZcREAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'November 2020',
                '2020-11-28T07:32:23.000Z',
                '00Q6700000nFbqREAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'December 2020',
                '2020-12-01T13:44:54.000Z',
                '00Q6700000nFl9PEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2020',
                '2020-12-07T15:36:41.000Z',
                '00Q6700000nG4mOEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'December 2020',
                '2020-12-07T22:54:25.000Z',
                '00Q6700000whguMEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'December 2020',
                '2020-12-08T10:59:13.000Z',
                '00Q6700000nG8Q0EAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AUTO PREMIUM 08 - CHARLEVILLE MEZIERES',
            ],
            [
                'December 2020',
                '2020-12-09T15:41:01.000Z',
                '00Q6700000nGD6sEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'December 2020',
                '2020-12-09T16:42:04.000Z',
                '00Q6700000nGDFuEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'December 2020',
                '2020-12-10T07:58:50.000Z',
                '00Q6700000nGGK9EAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'December 2020',
                '2020-12-10T15:25:35.000Z',
                '00Q6700000nGHTeEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'December 2020',
                '2020-12-11T17:43:45.000Z',
                '00Q6700000nGLufEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'December 2020',
                '2020-12-13T05:23:51.000Z',
                '00Q6700000nGRUkEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'December 2020',
                '2020-12-13T13:29:01.000Z',
                '00Q6700000nGRlkEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - REDON',
            ],
            [
                'December 2020',
                '2020-12-14T15:03:11.000Z',
                '00Q6700000nGVWsEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.P.S - BOURG EN BRESSE',
            ],
            [
                'December 2020',
                '2020-12-16T16:56:01.000Z',
                '00Q6700000nGgo9EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'December 2020',
                '2020-12-17T10:33:34.000Z',
                '00Q6700000nGjw5EAC',
                'Qualified',
                'Email',
                'France',
                'Company',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'December 2020',
                '2020-12-17T14:57:55.000Z',
                '00Q6700000nGkQoEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'December 2020',
                '2020-12-17T19:05:09.000Z',
                '00Q6700000nGlHeEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'December 2020',
                '2020-12-18T08:48:12.000Z',
                '00Q6700000nGnNMEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)',
            ],
            [
                'December 2020',
                '2020-12-18T13:01:41.000Z',
                '00Q67000010JjEOEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - EPINAY SUR SEINE',
            ],
            [
                'December 2020',
                '2020-12-21T09:20:48.000Z',
                '00Q6700000nGwMyEAK',
                'Qualified',
                'Phone',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'December 2020',
                '2020-12-21T09:22:43.000Z',
                '00Q6700000nGwN3EAK',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'December 2020',
                '2020-12-21T14:35:57.000Z',
                '00Q6700000nGx6NEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'December 2020',
                '2020-12-22T05:27:55.000Z',
                '00Q6700000nGzsKEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'December 2020',
                '2020-12-22T14:20:40.000Z',
                '00Q6700000nH0gmEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'December 2020',
                '2020-12-28T11:25:55.000Z',
                '00Q6700000nHG84EAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'December 2020',
                '2020-12-28T14:44:38.000Z',
                '00Q6700000nHGOUEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2020',
                '2020-12-29T15:07:58.000Z',
                '00Q6700000nHL7OEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'January 2021',
                '2021-01-04T14:55:17.000Z',
                '00Q6700000nHdlLEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'January 2021',
                '2021-01-04T15:30:29.000Z',
                '00Q6700000zfPoWEAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'January 2021',
                '2021-01-04T16:19:50.000Z',
                '00Q6700000nHdyFEAS',
                'Qualified',
                'Typeform',
                'France',
                'Single Home',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            [
                'January 2021',
                '2021-01-06T08:34:42.000Z',
                '00Q6700000nHlKIEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'January 2021',
                '2021-01-07T23:19:39.000Z',
                '00Q6700000nHtMvEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'January 2021',
                '2021-01-09T00:03:55.000Z',
                '00Q6700000nHxTOEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'January 2021',
                '2021-01-10T21:23:42.000Z',
                '00Q6700000nI3YBEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'January 2021',
                '2021-01-10T21:32:51.000Z',
                '00Q6700000nI3YeEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            [
                'January 2021',
                '2021-01-11T12:55:36.000Z',
                '00Q6700000nI50FEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'January 2021',
                '2021-01-11T13:53:02.000Z',
                '00Q6700000nI5AbEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'January 2021',
                '2021-01-11T15:46:25.000Z',
                '00Q6700000nI5T4EAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'January 2021',
                '2021-01-14T10:20:46.000Z',
                '00Q6700000nIMDfEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'January 2021',
                '2021-01-14T17:14:24.000Z',
                '00Q6700000nINBGEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'January 2021',
                '2021-01-15T14:28:10.000Z',
                '00Q6700000nIQyEEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'January 2021',
                '2021-01-16T13:39:02.000Z',
                '00Q6700000nIUZ8EAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'January 2021',
                '2021-01-17T07:36:43.000Z',
                '00Q6700000nIXH2EAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'January 2021',
                '2021-01-17T17:20:07.000Z',
                '00Q6700000oRsFFEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'January 2021',
                '2021-01-17T21:54:53.000Z',
                '00Q6700000oRuZoEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'January 2021',
                '2021-01-18T15:03:36.000Z',
                '00Q6700000oRwmfEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'January 2021',
                '2021-01-19T14:02:17.000Z',
                '00Q6700000oS8pYEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'January 2021',
                '2021-01-21T17:59:29.000Z',
                '00Q6700000oSIPLEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - VERSAILLES',
            ],
            [
                'January 2021',
                '2021-01-24T16:23:57.000Z',
                '00Q6700000oSTR5EAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'January 2021',
                '2021-01-24T18:01:28.000Z',
                '00Q6700000oSTYzEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'January 2021',
                '2021-01-26T07:38:35.000Z',
                '00Q6700000oSb5SEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'January 2021',
                '2021-01-26T15:25:53.000Z',
                '00Q6700000oScC1EAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'January 2021',
                '2021-01-26T22:42:55.000Z',
                '00Q6700000oSfsVEAS',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE',
            ],
            [
                'January 2021',
                '2021-01-27T08:12:45.000Z',
                '00Q6700000oSgsYEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'January 2021',
                '2021-01-27T09:45:18.000Z',
                '00Q6700000oShGZEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'January 2021',
                '2021-01-27T12:39:06.000Z',
                '00Q6700000oShehEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - BORGO',
            ],
            [
                'January 2021',
                '2021-01-27T14:42:00.000Z',
                '00Q6700000oShzbEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'January 2021',
                '2021-01-27T15:56:01.000Z',
                '00Q6700000oSiFHEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'January 2021',
                '2021-01-28T13:30:48.000Z',
                '00Q6700000oSmpcEAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE',
            ],
            [
                'February 2021',
                '2021-02-01T13:20:13.000Z',
                '00Q6700000oT3B1EAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'February 2021',
                '2021-02-02T05:55:18.000Z',
                '00Q6700000oT6t2EAC',
                'Qualified',
                'Typeform',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - SAINT MARTIN SUR LE PRE (CHALONS EN CHAMPAGNE)',
            ],
            [
                'February 2021',
                '2021-02-02T10:20:04.000Z',
                '00Q6700000oT7LVEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2021',
                '2021-02-03T10:35:55.000Z',
                '00Q6700000oTCwlEAG',
                'Qualified',
                'Zeplug',
                'France',
                'Single Home',
                'Volvo -  PRESTIGE CARS - AUBIERE (CLERMONT FERRAND)',
            ],
            [
                'February 2021',
                '2021-02-03T10:59:40.000Z',
                '00Q6700000oTD1kEAG',
                'Qualified',
                'Typeform',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'February 2021',
                '2021-02-03T13:05:49.000Z',
                '00Q6700000oTDHGEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'February 2021',
                '2021-02-03T19:20:28.000Z',
                '00Q6700000oTFMbEAO',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'February 2021',
                '2021-02-03T19:22:48.000Z',
                '00Q6700000oTGEyEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'February 2021',
                '2021-02-04T07:58:02.000Z',
                '00Q6700000oTH7VEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'February 2021',
                '2021-02-04T10:02:14.000Z',
                '00Q6700000oTHJwEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'February 2021',
                '2021-02-04T10:33:56.000Z',
                '00Q6700000oTHOXEA4',
                'Unqualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'February 2021',
                '2021-02-05T05:43:53.000Z',
                '00Q6700000oTLSPEA4',
                'Qualified',
                'Typeform',
                'France',
                'Company',
                'Volvo -  DELHORBE AUTOMOBILES - SAINT MARTIN SUR LE PRE (CHALONS EN CHAMPAGNE)',
            ],
            [
                'February 2021',
                '2021-02-05T08:49:19.000Z',
                '00Q6700000oTLdXEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'February 2021',
                '2021-02-06T15:13:48.000Z',
                '00Q6700000oTQ8HEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'February 2021',
                '2021-02-06T19:48:06.000Z',
                '00Q6700000oTSX9EAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'February 2021',
                '2021-02-07T09:42:20.000Z',
                '00Q6700000oTSyFEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)',
            ],
            [
                'February 2021',
                '2021-02-07T17:22:58.000Z',
                '00Q6700000oTTQxEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2021',
                '2021-02-07T21:33:10.000Z',
                '00Q6700000oTVmWEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'February 2021',
                '2021-02-08T17:26:00.000Z',
                '00Q6700000oTY9PEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'February 2021',
                '2021-02-08T18:17:03.000Z',
                '00Q6700000oTYHnEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2021',
                '2021-02-08T21:59:52.000Z',
                '00Q6700000oTb08EAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - NEUILLY SUR SEINE',
            ],
            [
                'February 2021',
                '2021-02-09T09:04:47.000Z',
                '00Q6700000oTbe9EAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'February 2021',
                '2021-02-09T09:07:40.000Z',
                '00Q6700000oTbedEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'February 2021',
                '2021-02-09T10:44:40.000Z',
                '00Q6700000oTc2MEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'February 2021',
                '2021-02-10T00:01:44.000Z',
                '00Q6700000oTgtMEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'February 2021',
                '2021-02-12T15:36:06.000Z',
                '00Q6700000oTrEiEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2021',
                '2021-02-13T11:25:21.000Z',
                '00Q6700000oTug0EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'February 2021',
                '2021-02-13T15:31:02.000Z',
                '00Q6700000oTv0UEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2021',
                '2021-02-13T15:49:39.000Z',
                '00Q6700000oTv1SEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'February 2021',
                '2021-02-13T17:23:14.000Z',
                '00Q6700000oTvCVEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'February 2021',
                '2021-02-15T08:15:30.000Z',
                '00Q6700000oU95dEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'February 2021',
                '2021-02-15T15:55:19.000Z',
                '00Q6700000oUAAYEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'February 2021',
                '2021-02-16T17:45:55.000Z',
                '00Q6700000oUJUcEAO',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'February 2021',
                '2021-02-17T09:58:09.000Z',
                '00Q6700000oUQo0EAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'February 2021',
                '2021-02-17T10:56:22.000Z',
                '00Q6700000oUQyjEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES',
            ],
            [
                'February 2021',
                '2021-02-17T13:13:53.000Z',
                '00Q6700000oURMMEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - SAINT AVIT (MONT DE MARSAN)',
            ],
            [
                'February 2021',
                '2021-02-17T21:58:51.000Z',
                '00Q6700000oUYjyEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'February 2021',
                '2021-02-18T12:00:55.000Z',
                '00Q6700000oUapJEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'February 2021',
                '2021-02-19T13:33:53.000Z',
                '00Q6700000oUjDcEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2021',
                '2021-02-20T08:41:30.000Z',
                '00Q6700000oUokLEAS',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'February 2021',
                '2021-02-20T09:07:16.000Z',
                '00Q6700000oUoldEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'February 2021',
                '2021-02-24T13:19:41.000Z',
                '00Q6700000oV6QcEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2021',
                '2021-02-24T17:24:04.000Z',
                '00Q6700000oV7ixEAC',
                'Qualified',
                'Direct',
                'France',
                'Company',
                'Volvo car France',
            ],
            [
                'February 2021',
                '2021-02-27T06:47:31.000Z',
                '00Q6700000oVMLrEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  J.M. AUTOMOBILES - SAUVERNY (DIVONNE LES BAINS)',
            ],
            [
                'February 2021',
                '2021-02-27T17:43:41.000Z',
                '00Q6700000qLq8REAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)',
            ],
            [
                'February 2021',
                '2021-02-27T17:57:11.000Z',
                '00Q6700000oVN5aEAG',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE EST AUTOS (11ème) - PARIS',
            ],
            [
                'February 2021',
                '2021-02-28T14:23:46.000Z',
                '00Q6700000oVQW0EAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2021',
                '2021-03-02T15:06:06.000Z',
                '00Q6700000oVaH5EAK',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'March 2021',
                '2021-03-04T09:21:53.000Z',
                '00Q6700000oVjdPEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'March 2021',
                '2021-03-04T15:20:37.000Z',
                '00Q6700000oVkXQEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'March 2021',
                '2021-03-06T14:19:19.000Z',
                '00Q6700000oVsk9EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'March 2021',
                '2021-03-09T09:44:25.000Z',
                '00Q6700000pKQQLEA4',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'March 2021',
                '2021-03-10T13:56:46.000Z',
                '00Q6700000pKWExEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'March 2021',
                '2021-03-11T08:40:20.000Z',
                '00Q6700000pKaLTEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'March 2021',
                '2021-03-11T10:22:04.000Z',
                '00Q6700000pKaanEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'March 2021',
                '2021-03-11T11:10:19.000Z',
                '00Q6700000pKaiDEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'March 2021',
                '2021-03-11T12:00:16.000Z',
                '00Q6700000pKap4EAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'March 2021',
                '2021-03-11T12:25:53.000Z',
                '00Q6700000pKatpEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            [
                'March 2021',
                '2021-03-11T18:27:54.000Z',
                '00Q6700000pKbqSEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2021',
                '2021-03-12T15:02:59.000Z',
                '00Q6700000pKg3zEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'March 2021',
                '2021-03-12T16:45:31.000Z',
                '00Q6700000pKgHXEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'March 2021',
                '2021-03-12T22:11:19.000Z',
                '00Q6700000pKjGlEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'March 2021',
                '2021-03-13T14:59:58.000Z',
                '00Q6700000pKjydEAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'March 2021',
                '2021-03-14T09:23:24.000Z',
                '00Q6700000qLrjnEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2021',
                '2021-03-14T18:09:22.000Z',
                '00Q6700000pKnTFEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'March 2021',
                '2021-03-14T19:04:09.000Z',
                '00Q6700000pKnz9EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'March 2021',
                '2021-03-14T20:54:18.000Z',
                '00Q6700000pKptAEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'March 2021',
                '2021-03-14T20:57:52.000Z',
                '00Q6700000pKptZEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'March 2021',
                '2021-03-16T13:22:53.000Z',
                '00Q6700000pKw20EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'March 2021',
                '2021-03-16T13:29:31.000Z',
                '00Q6700000pKw3IEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'March 2021',
                '2021-03-16T14:36:19.000Z',
                '00Q6700000pKwCZEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - THIBAULT AUTOMOBILES - ALENCON',
            ],
            [
                'March 2021',
                '2021-03-17T10:57:51.000Z',
                '00Q6700000pL0HUEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'March 2021',
                '2021-03-17T14:22:04.000Z',
                '00Q6700000pL0knEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'March 2021',
                '2021-03-18T08:12:50.000Z',
                '00Q6700000pL4UxEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'March 2021',
                '2021-03-18T09:47:40.000Z',
                '00Q6700000pL4e4EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'March 2021',
                '2021-03-18T10:23:52.000Z',
                '00Q6700000pL4l0EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'March 2021',
                '2021-03-18T13:31:58.000Z',
                '00Q6700000pL5A2EAK',
                'Unqualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'March 2021',
                '2021-03-18T14:35:23.000Z',
                '00Q6700000pL5L0EAK',
                'Unqualified',
                'Typeform',
                'France',
                'Single Home',
                'Volvo -  PREMIUM AUTOMOBILES - AUXERRE',
            ],
            [
                'March 2021',
                '2021-03-18T16:05:32.000Z',
                '00Q6700000pL622EAC',
                'Qualified',
                'Other',
                'France',
                'Company',
                'Volvo car France',
            ],
            [
                'March 2021',
                '2021-03-19T15:12:33.000Z',
                '00Q6700000pLAxsEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'March 2021',
                '2021-03-20T10:25:36.000Z',
                '00Q6700000pLES0EAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'March 2021',
                '2021-03-20T15:37:40.000Z',
                '00Q6700000pLEpTEAW',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'March 2021',
                '2021-03-20T20:01:55.000Z',
                '00Q6700000pLHSIEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'March 2021',
                '2021-03-21T17:31:51.000Z',
                '00Q6700000pLI9NEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'March 2021',
                '2021-03-22T10:37:40.000Z',
                '00Q6700000pLLO3EAO',
                'Qualified',
                'Email',
                'France',
                'Single Home',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            [
                'March 2021',
                '2021-03-22T15:20:02.000Z',
                '00Q6700000pLNQREA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'March 2021',
                '2021-03-22T18:23:35.000Z',
                '00Q6700000pLNuaEAG',
                'Transfered to ZP',
                'Phone',
                'France',
                'Multi Family Home',
                'Volvo -  PREMIUM AUTOMOBILES - AUXERRE',
            ],
            [
                'March 2021',
                '2021-03-22T20:30:06.000Z',
                '00Q6700000pLQe6EAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'March 2021',
                '2021-03-23T10:05:35.000Z',
                '00Q6700000pLRgEEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'March 2021',
                '2021-03-23T10:05:53.000Z',
                '00Q6700000pLRgOEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'March 2021',
                '2021-03-23T10:06:13.000Z',
                '00Q6700000pLRgYEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'March 2021',
                '2021-03-23T10:07:11.000Z',
                '00Q6700000pLRgdEAG',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'March 2021',
                '2021-03-23T10:08:59.000Z',
                '00Q6700000pLRh4EAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'March 2021',
                '2021-03-23T10:11:58.000Z',
                '00Q6700000pLRhnEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - EPINAY SUR SEINE',
            ],
            [
                'March 2021',
                '2021-03-23T10:14:07.000Z',
                '00Q6700000pLRi7EAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            [
                'March 2021',
                '2021-03-23T10:14:42.000Z',
                '00Q6700000pLRiWEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'March 2021',
                '2021-03-23T10:15:10.000Z',
                '00Q6700000pLRigEAG',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'March 2021',
                '2021-03-23T10:16:47.000Z',
                '00Q6700000pLRjFEAW',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'March 2021',
                '2021-03-23T10:16:58.000Z',
                '00Q6700000pLRjUEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'March 2021',
                '2021-03-23T10:20:42.000Z',
                '00Q6700000pLRkDEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'March 2021',
                '2021-03-23T10:21:00.000Z',
                '00Q6700000pLRkIEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE',
            ],
            [
                'March 2021',
                '2021-03-23T10:21:22.000Z',
                '00Q6700000pLRkNEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'March 2021',
                '2021-03-23T10:21:38.000Z',
                '00Q6700000pLRkSEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'March 2021',
                '2021-03-23T10:26:11.000Z',
                '00Q6700000pLRlQEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'March 2021',
                '2021-03-23T10:27:31.000Z',
                '00Q6700000pLRlkEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'March 2021',
                '2021-03-23T10:35:01.000Z',
                '00Q6700000pLRqpEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'March 2021',
                '2021-03-23T10:47:44.000Z',
                '00Q6700000pLRtZEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2021',
                '2021-03-23T11:01:34.000Z',
                '00Q6700000pLRvLEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'March 2021',
                '2021-03-23T11:06:10.000Z',
                '00Q6700000pLRvuEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'March 2021',
                '2021-03-23T11:30:35.000Z',
                '00Q6700000pLRxvEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'March 2021',
                '2021-03-23T11:38:02.000Z',
                '00Q6700000pLRyZEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'March 2021',
                '2021-03-23T12:03:35.000Z',
                '00Q6700000pLS30EAG',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES',
            ],
            [
                'March 2021',
                '2021-03-23T12:09:51.000Z',
                '00Q6700000pLS3UEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'March 2021',
                '2021-03-23T12:34:21.000Z',
                '00Q6700000pLS5kEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'March 2021',
                '2021-03-23T14:38:14.000Z',
                '00Q6700000pLSSPEA4',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'March 2021',
                '2021-03-23T15:03:32.000Z',
                '00Q6700000pLSViEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'March 2021',
                '2021-03-23T15:23:59.000Z',
                '00Q6700000pLSaIEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - SAINT AVIT (MONT DE MARSAN)',
            ],
            [
                'March 2021',
                '2021-03-23T16:20:04.000Z',
                '00Q6700000pLShnEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'March 2021',
                '2021-03-23T17:11:51.000Z',
                '00Q6700000pLSqlEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                "Volvo -  CAR'S SERVICES - ONET LE CHATEAU (RODEZ)",
            ],
            [
                'March 2021',
                '2021-03-23T17:27:10.000Z',
                '00Q6700000pLSswEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'March 2021',
                '2021-03-23T19:48:25.000Z',
                '00Q6700000pLVkmEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)',
            ],
            [
                'March 2021',
                '2021-03-23T20:28:53.000Z',
                '00Q6700000pLVpNEAW',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'March 2021',
                '2021-03-23T20:36:44.000Z',
                '00Q6700000pLVpwEAG',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'March 2021',
                '2021-03-24T07:14:59.000Z',
                '00Q6700000pLWG9EAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'March 2021',
                '2021-03-24T12:25:57.000Z',
                '00Q6700000pLWvqEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2021',
                '2021-03-24T13:41:28.000Z',
                '00Q6700000pLX6UEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'March 2021',
                '2021-03-24T19:17:14.000Z',
                '00Q6700000pLa67EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'March 2021',
                '2021-03-24T20:27:00.000Z',
                '00Q6700000pLafBEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'March 2021',
                '2021-03-25T13:54:59.000Z',
                '00Q6700000pLby8EAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'March 2021',
                '2021-03-25T14:51:44.000Z',
                '00Q6700000pLc4kEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'March 2021',
                '2021-03-27T10:28:35.000Z',
                '00Q6700000pLkOcEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'March 2021',
                '2021-03-27T15:28:03.000Z',
                '00Q6700000pLkgCEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'March 2021',
                '2021-03-28T17:21:12.000Z',
                '00Q6700000pLnsuEAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'March 2021',
                '2021-03-29T07:10:30.000Z',
                '00Q6700000pLqhLEAS',
                'Unqualified',
                'Email',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'March 2021',
                '2021-03-29T07:13:03.000Z',
                '00Q6700000pLqhaEAC',
                'Qualified',
                'Email',
                'France',
                'Company',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'March 2021',
                '2021-03-29T13:30:52.000Z',
                '00Q6700000pLrc6EAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'March 2021',
                '2021-03-30T08:24:41.000Z',
                '00Q6700000pLvoHEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)',
            ],
            [
                'March 2021',
                '2021-03-30T09:43:08.000Z',
                '00Q6700000pLvyCEAS',
                'Qualified',
                'Email',
                'France',
                'Company',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'March 2021',
                '2021-03-30T18:34:38.000Z',
                '00Q6700000pLzGpEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)',
            ],
            [
                'March 2021',
                '2021-03-30T21:18:07.000Z',
                '00Q6700000pM00yEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'March 2021',
                '2021-03-31T09:47:13.000Z',
                '00Q6700000pM0h4EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'March 2021',
                '2021-03-31T15:46:57.000Z',
                '00Q6700000pM1JzEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'March 2021',
                '2021-03-31T15:51:58.000Z',
                '00Q6700000pM1LDEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'April 2021',
                '2021-04-02T14:29:17.000Z',
                '00Q6700000pMD9uEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'April 2021',
                '2021-04-06T19:45:14.000Z',
                '00Q6700000pMcuLEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'April 2021',
                '2021-04-07T12:01:16.000Z',
                '00Q6700000pMeymEAC',
                'Qualified',
                'Typeform',
                'France',
                'Company',
                'Volvo -  DELHORBE AUTOMOBILES - DIZY (EPERNAY)',
            ],
            [
                'April 2021',
                '2021-04-08T06:44:03.000Z',
                '00Q6700000pMjLyEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'April 2021',
                '2021-04-08T13:22:11.000Z',
                '00Q6700000pMkEnEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'April 2021',
                '2021-04-08T17:35:51.000Z',
                '00Q6700000pMl1QEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'April 2021',
                '2021-04-09T14:25:31.000Z',
                '00Q6700000pMpO3EAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'April 2021',
                '2021-04-09T14:47:15.000Z',
                '00Q6700000pMpRCEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'April 2021',
                '2021-04-10T08:33:37.000Z',
                '00Q6700000pMsoFEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'April 2021',
                '2021-04-12T13:18:44.000Z',
                '00Q6700000pN2VZEA0',
                'Unqualified',
                'Typeform',
                'France',
                'Single Home',
                'Volvo -  GROUPE JFC LISIEUX - LISIEUX',
            ],
            [
                'April 2021',
                '2021-04-13T19:17:49.000Z',
                '00Q6700000pNBXxEAO',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'April 2021',
                '2021-04-14T16:04:23.000Z',
                '00Q6700000pNDm0EAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'April 2021',
                '2021-04-16T09:50:32.000Z',
                '00Q6700000pNO1nEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'April 2021',
                '2021-04-17T18:08:36.000Z',
                '00Q6700000pNTxnEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'April 2021',
                '2021-04-18T13:18:08.000Z',
                '00Q6700000pNWtWEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'April 2021',
                '2021-04-19T11:20:36.000Z',
                '00Q6700000pNaoGEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'April 2021',
                '2021-04-19T17:05:40.000Z',
                '00Q6700000pNbqpEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'April 2021',
                '2021-04-21T09:02:42.000Z',
                '00Q6700000pNlu8EAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'April 2021',
                '2021-04-22T13:05:04.000Z',
                '00Q6700000pNrv9EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'April 2021',
                '2021-04-22T15:55:44.000Z',
                '00Q6700000pNsRpEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'April 2021',
                '2021-04-23T16:34:41.000Z',
                '00Q6700000pNxhQEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - VERSAILLES',
            ],
            [
                'April 2021',
                '2021-04-23T16:40:25.000Z',
                '00Q6700000pNxiOEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'April 2021',
                '2021-04-25T15:16:44.000Z',
                '00Q6700000pO5JMEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'April 2021',
                '2021-04-26T07:53:34.000Z',
                '00Q6700000pO8V3EAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'April 2021',
                '2021-04-26T13:18:19.000Z',
                '00Q6700000pO9NKEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'April 2021',
                '2021-04-26T14:42:49.000Z',
                '00Q6700000pO9ZqEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'April 2021',
                '2021-04-27T13:46:02.000Z',
                '00Q6700000pOEYXEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS (11ème) - PARIS',
            ],
            [
                'April 2021',
                '2021-04-27T13:53:32.000Z',
                '00Q6700000pOEZkEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'April 2021',
                '2021-04-28T15:57:38.000Z',
                '00Q6700000pOJdiEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'April 2021',
                '2021-04-29T08:57:33.000Z',
                '00Q6700000pONaqEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'April 2021',
                '2021-04-29T17:11:04.000Z',
                '00Q6700000pOOdVEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - BORGO',
            ],
            [
                'May 2021',
                '2021-05-02T18:11:58.000Z',
                '00Q6700000pObNqEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'May 2021',
                '2021-05-04T08:21:19.000Z',
                '00Q6700000qLPnpEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ACTENA AUTOMOBILES - NEUILLY SUR SEINE',
            ],
            [
                'May 2021',
                '2021-05-04T14:02:23.000Z',
                '00Q6700000qLRJ9EAO',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'May 2021',
                '2021-05-06T14:57:57.000Z',
                '00Q6700000qLXbEEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'May 2021',
                '2021-05-06T19:59:10.000Z',
                '00Q6700000qLYIREA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES',
            ],
            [
                'May 2021',
                '2021-05-10T15:39:13.000Z',
                '00Q6700000qLeTaEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'May 2021',
                '2021-05-10T17:20:47.000Z',
                '00Q6700000qLejuEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI',
            ],
            [
                'May 2021',
                '2021-05-10T17:25:53.000Z',
                '00Q6700000qLekYEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'May 2021',
                '2021-05-10T19:00:30.000Z',
                '00Q6700000qLeuOEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'May 2021',
                '2021-05-12T17:12:44.000Z',
                '00Q6700000qLjQAEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'May 2021',
                '2021-05-12T19:35:06.000Z',
                '00Q6700000qLjfEEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'May 2021',
                '2021-05-13T07:20:46.000Z',
                '00Q6700000qLkGKEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'May 2021',
                '2021-05-15T20:39:24.000Z',
                '00Q6700000qLqPSEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'May 2021',
                '2021-05-16T02:09:39.000Z',
                '00Q6700000qLqa1EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'May 2021',
                '2021-05-17T15:46:09.000Z',
                '00Q6700000qLt1sEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'May 2021',
                '2021-05-18T12:28:38.000Z',
                '00Q6700000qLvDFEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'May 2021',
                '2021-05-18T20:06:42.000Z',
                '00Q6700000qLwYHEA0',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'May 2021',
                '2021-05-20T09:59:42.000Z',
                '00Q6700000qLzdPEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI',
            ],
            [
                'May 2021',
                '2021-05-20T11:49:39.000Z',
                '00Q6700000qLzpzEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'May 2021',
                '2021-05-21T11:32:47.000Z',
                '00Q6700000qM7GQEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VILLEFRANCHE-SUR-SAÔNE',
            ],
            [
                'May 2021',
                '2021-05-21T13:32:13.000Z',
                '00Q6700000qM7XMEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'LARSON by Autosphere',
            ],
            [
                'May 2021',
                '2021-05-23T15:41:41.000Z',
                '00Q6700000qMBxzEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - LIMOGES',
            ],
            [
                'May 2021',
                '2021-05-24T16:46:00.000Z',
                '00Q6700000qMEddEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'May 2021',
                '2021-05-25T19:24:11.000Z',
                '00Q6700000qMHXsEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'May 2021',
                '2021-05-26T08:31:30.000Z',
                '00Q6700000qMIZNEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  DARMENDRAIL AUTOMOBILES - SAINT AVIT (MONT DE MARSAN)',
            ],
            [
                'May 2021',
                '2021-05-26T20:15:58.000Z',
                '00Q6700000qMKNjEAO',
                'Unqualified',
                'Typeform Volvo',
                'Belgium',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'May 2021',
                '2021-05-27T11:26:18.000Z',
                '00Q6700000qMLgmEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'May 2021',
                '2021-05-28T20:31:25.000Z',
                '00Q6700000qMQ4REAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'May 2021',
                '2021-05-29T13:31:17.000Z',
                '00Q6700000qMQmTEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'May 2021',
                '2021-05-30T07:12:02.000Z',
                '00Q6700000qMRULEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'May 2021',
                '2021-05-31T06:10:26.000Z',
                '00Q6700000qMSOOEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2021',
                '2021-06-01T12:44:16.000Z',
                '00Q6700000qMV8QEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'June 2021',
                '2021-06-03T09:14:25.000Z',
                '00Q6700000qMZBUEA4',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'June 2021',
                '2021-06-04T08:29:01.000Z',
                '00Q6700000qMb6OEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CMM AUTOMOBILES - SAINT PIERRE',
            ],
            [
                'June 2021',
                '2021-06-04T20:34:45.000Z',
                '00Q6700000qMcbmEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO COLMAR - COLMAR',
            ],
            [
                'June 2021',
                '2021-06-07T11:02:56.000Z',
                '00Q6700000qMgNKEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI',
            ],
            [
                'June 2021',
                '2021-06-07T18:38:33.000Z',
                '00Q6700000qMhb7EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'June 2021',
                '2021-06-08T15:22:44.000Z',
                '00Q6700000qMjPaEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'June 2021',
                '2021-06-10T08:06:08.000Z',
                '00Q6700000qMn29EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2021',
                '2021-06-10T13:53:15.000Z',
                '00Q6700000qMnpbEAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'June 2021',
                '2021-06-10T14:03:50.000Z',
                '00Q6700000qMnrDEAS',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'June 2021',
                '2021-06-10T14:13:24.000Z',
                '00Q6700000qMnsaEAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'June 2021',
                '2021-06-10T19:02:38.000Z',
                '00Q6700000qMoePEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'June 2021',
                '2021-06-12T11:51:10.000Z',
                '00Q6700000qMrXgEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'June 2021',
                '2021-06-14T14:28:42.000Z',
                '00Q6700000qMuLlEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AUTO PREMIUM 08 - CHARLEVILLE MEZIERES',
            ],
            [
                'June 2021',
                '2021-06-15T07:30:14.000Z',
                '00Q6700000qMvvEEAS',
                'Unqualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  AUTO PREMIUM 08 - CHARLEVILLE MEZIERES',
            ],
            [
                'June 2021',
                '2021-06-16T08:23:21.000Z',
                '00Q6700000qMyn0EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'June 2021',
                '2021-06-16T13:15:36.000Z',
                '00Q6700000qN0OXEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'June 2021',
                '2021-06-16T14:15:00.000Z',
                '00Q6700000qN0X5EAK',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'June 2021',
                '2021-06-16T14:41:29.000Z',
                '00Q6700000qN0aOEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'June 2021',
                '2021-06-18T05:28:32.000Z',
                '00Q6700000qN43fEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'June 2021',
                '2021-06-18T13:07:12.000Z',
                '00Q6700000qN4x7EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'June 2021',
                '2021-06-21T12:59:39.000Z',
                '00Q6700000qN9mcEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2021',
                '2021-06-21T15:25:35.000Z',
                '00Q6700000qNA9QEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'June 2021',
                '2021-06-24T12:39:28.000Z',
                '00Q6700000qNHUXEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'June 2021',
                '2021-06-27T18:56:46.000Z',
                '00Q6700000qNOE5EAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'June 2021',
                '2021-06-28T10:16:32.000Z',
                '00Q6700000qNPXwEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'June 2021',
                '2021-06-28T15:21:59.000Z',
                '00Q6700000qNQrrEAG',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'June 2021',
                '2021-06-28T16:29:32.000Z',
                '00Q6700000qNR0aEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'June 2021',
                '2021-06-29T14:27:56.000Z',
                '00Q6700000qNSsKEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'June 2021',
                '2021-06-29T14:34:54.000Z',
                '00Q6700000qNSt3EAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'June 2021',
                '2021-06-30T12:30:04.000Z',
                '00Q6700000qNV9YEAW',
                'Qualified',
                'Zeplug',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2021',
                '2021-07-07T13:22:22.000Z',
                '00Q6700000qNisoEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES PAU - LESCAR',
            ],
            [
                'July 2021',
                '2021-07-08T09:32:45.000Z',
                '00Q6700000qNkNlEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'July 2021',
                '2021-07-09T08:24:46.000Z',
                '00Q6700000qNm0tEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'July 2021',
                '2021-07-09T19:16:16.000Z',
                '00Q6700000qNn35EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'July 2021',
                '2021-07-11T00:48:18.000Z',
                '00Q6700000qNoMmEAK',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'July 2021',
                '2021-07-13T15:52:04.000Z',
                '00Q6700000qNstbEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT CHARTRES - NOGENT LE PHAYE (CHARTRES)',
            ],
            [
                'July 2021',
                '2021-07-14T22:28:50.000Z',
                '00Q6700000qNw5mEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'July 2021',
                '2021-07-15T13:55:25.000Z',
                '00Q6700000qNxcLEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2021',
                '2021-07-16T04:27:13.000Z',
                '00Q6700000qO0CHEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2021',
                '2021-07-16T09:44:12.000Z',
                '00Q6700000qO0cJEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'July 2021',
                '2021-07-16T11:18:44.000Z',
                '00Q6700000qO0lzEAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES',
            ],
            [
                'July 2021',
                '2021-07-16T14:10:50.000Z',
                '00Q6700000qO16nEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'July 2021',
                '2021-07-17T15:03:34.000Z',
                '00Q6700000qO2o0EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'July 2021',
                '2021-07-18T20:29:56.000Z',
                '00Q6700000qO4L5EAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'July 2021',
                '2021-07-19T12:07:21.000Z',
                '00Q6700000qO5YcEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'July 2021',
                '2021-07-19T12:35:45.000Z',
                '00Q6700000qO5cPEAS',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'July 2021',
                '2021-07-19T16:07:59.000Z',
                '00Q6700000qO6RYEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'July 2021',
                '2021-07-20T08:19:44.000Z',
                '00Q6700000qO7wAEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'July 2021',
                '2021-07-20T08:46:33.000Z',
                '00Q6700000qO7zeEAC',
                'Qualified',
                'Direct',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'July 2021',
                '2021-07-22T06:36:49.000Z',
                '00Q6700000qOBstEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'July 2021',
                '2021-07-23T11:19:12.000Z',
                '00Q6700000qOEgFEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'July 2021',
                '2021-07-23T11:39:48.000Z',
                '00Q6700000qOEjxEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE',
            ],
            [
                'July 2021',
                '2021-07-25T13:20:56.000Z',
                '00Q6700000qOHOxEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - LIMOGES',
            ],
            [
                'July 2021',
                '2021-07-25T20:20:10.000Z',
                '00Q6700000qOHsEEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                "Volvo -  FIRST AUTOMOBILES - VILLENEUVE D'ASCQ",
            ],
            [
                'July 2021',
                '2021-07-26T09:33:28.000Z',
                '00Q6700000qOIXHEA4',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE EST AUTOS (11ème) - PARIS',
            ],
            [
                'July 2021',
                '2021-07-27T07:36:08.000Z',
                '00Q6700000qOKKpEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'July 2021',
                '2021-07-27T14:20:32.000Z',
                '00Q6700000qOLTHEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'July 2021',
                '2021-07-27T19:19:57.000Z',
                '00Q6700000qOLz8EAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'July 2021',
                '2021-07-28T13:38:48.000Z',
                '00Q6700000qONxMEAW',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'July 2021',
                '2021-07-29T11:42:50.000Z',
                '00Q6700000qOQ5AEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'July 2021',
                '2021-07-30T14:57:09.000Z',
                '00Q6700000qOSNVEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'August 2021',
                '2021-08-02T08:39:27.000Z',
                '00Q6700000qOVsREAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2021',
                '2021-08-04T09:24:36.000Z',
                '00Q6700000qOaTJEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2021',
                '2021-08-04T15:53:30.000Z',
                '00Q6700000qObLFEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'August 2021',
                '2021-08-07T16:15:14.000Z',
                '00Q6700000qOgcfEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'August 2021',
                '2021-08-08T07:38:44.000Z',
                '00Q6700000qOhF8EAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'August 2021',
                '2021-08-08T09:55:58.000Z',
                '00Q6700000qOhLQEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2021',
                '2021-08-08T17:35:05.000Z',
                '00Q6700000qOhrqEAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'August 2021',
                '2021-08-10T14:31:26.000Z',
                '00Q6700000qOlZAEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'August 2021',
                '2021-08-12T13:01:01.000Z',
                '00Q6700000qOpTFEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'August 2021',
                '2021-08-12T15:36:33.000Z',
                '00Q6700000qOppBEAS',
                'Qualified',
                'Email',
                'France',
                'Company',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'August 2021',
                '2021-08-12T18:37:44.000Z',
                '00Q6700000qOqB7EAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'August 2021',
                '2021-08-15T10:52:17.000Z',
                '00Q6700000qOueoEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                "Volvo -  CAR'S SERVICES - ONET LE CHATEAU (RODEZ)",
            ],
            [
                'August 2021',
                '2021-08-16T16:12:35.000Z',
                '00Q6700000qOxJoEAK',
                'Unqualified',
                'Zeplug',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'August 2021',
                '2021-08-17T20:28:30.000Z',
                '00Q6700000sRmyWEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'August 2021',
                '2021-08-18T07:47:05.000Z',
                '00Q6700000qw0UTEAY',
                'Qualified',
                'Email',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'August 2021',
                '2021-08-19T08:19:33.000Z',
                '00Q6700000qw3EMEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'August 2021',
                '2021-08-19T20:58:31.000Z',
                '00Q6700000qw4lpEAA',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'August 2021',
                '2021-08-20T14:45:11.000Z',
                '00Q6700000qw6KpEAI',
                'Unqualified',
                'Email',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'August 2021',
                '2021-08-22T14:48:08.000Z',
                '00Q6700000qw9THEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2021',
                '2021-08-23T14:55:32.000Z',
                '00Q6700000qwBFZEA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'August 2021',
                '2021-08-24T13:09:24.000Z',
                '00Q6700000qwD6lEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'August 2021',
                '2021-08-24T16:59:49.000Z',
                '00Q6700000qwDdxEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'August 2021',
                '2021-08-26T10:16:14.000Z',
                '00Q6700000qwHNDEA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'August 2021',
                '2021-08-30T08:09:02.000Z',
                '00Q6700000qwNa1EAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'August 2021',
                '2021-08-30T12:07:50.000Z',
                '00Q6700000qwO5aEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'September 2021',
                '2021-09-01T15:03:40.000Z',
                '00Q6700000qwT0cEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'September 2021',
                '2021-09-02T09:23:18.000Z',
                '00Q6700000qwUSeEAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'September 2021',
                '2021-09-02T12:33:13.000Z',
                '00Q6700000qwUtVEAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VILLEFRANCHE-SUR-SAÔNE',
            ],
            [
                'September 2021',
                '2021-09-02T19:23:25.000Z',
                '00Q6700000sS9xZEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'September 2021',
                '2021-09-02T21:37:27.000Z',
                '00Q6700000qwW4uEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'September 2021',
                '2021-09-04T15:29:45.000Z',
                '00Q6700000qwZGIEA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'September 2021',
                '2021-09-05T06:44:05.000Z',
                '00Q6700000yPl0DEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'September 2021',
                '2021-09-05T07:33:38.000Z',
                '00Q6700000qwZvkEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'September 2021',
                '2021-09-05T17:11:52.000Z',
                '00Q6700000qwaYIEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'September 2021',
                '2021-09-06T16:10:13.000Z',
                '00Q6700000qwdKcEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'September 2021',
                '2021-09-08T09:48:50.000Z',
                '00Q6700000qwhMqEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'September 2021',
                '2021-09-08T12:51:40.000Z',
                '00Q6700000qwhoBEAQ',
                'Qualified',
                'Email',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'September 2021',
                '2021-09-09T09:02:59.000Z',
                '00Q6700000qwk0sEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'September 2021',
                '2021-09-09T09:37:33.000Z',
                '00Q6700000qwk6REAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2021',
                '2021-09-09T11:48:00.000Z',
                '00Q6700000qwkT6EAI',
                'Qualified',
                'Email',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2021',
                '2021-09-10T18:41:49.000Z',
                '00Q6700000qwntzEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'September 2021',
                '2021-09-12T18:51:19.000Z',
                '00Q6700000qwr6hEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2021',
                '2021-09-13T08:47:57.000Z',
                '00Q6700000qws2TEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'September 2021',
                '2021-09-13T09:39:25.000Z',
                '00Q6700000qwsDCEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'September 2021',
                '2021-09-13T14:38:57.000Z',
                '00Q6700000qwtC1EAI',
                'Qualified',
                'Direct',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2021',
                '2021-09-13T18:33:37.000Z',
                '00Q6700000qwtknEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'September 2021',
                '2021-09-14T09:28:38.000Z',
                '00Q6700000qwunyEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'September 2021',
                '2021-09-14T13:52:57.000Z',
                '00Q6700000qwvZWEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'September 2021',
                '2021-09-14T18:36:19.000Z',
                '00Q6700000qwwGzEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'September 2021',
                '2021-09-16T20:27:23.000Z',
                '00Q6700000qx2oWEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'September 2021',
                '2021-09-18T18:49:14.000Z',
                '00Q6700000qx6mJEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2021',
                '2021-09-18T19:13:42.000Z',
                '00Q6700000qx6oZEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'September 2021',
                '2021-09-19T17:55:38.000Z',
                '00Q6700000sRSCHEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI',
            ],
            [
                'September 2021',
                '2021-09-20T07:44:17.000Z',
                '00Q6700000sRT4nEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            [
                'September 2021',
                '2021-09-20T13:05:15.000Z',
                '00Q6700000sRUl2EAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'September 2021',
                '2021-09-21T12:22:53.000Z',
                '00Q6700000sRXztEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            [
                'September 2021',
                '2021-09-22T12:56:55.000Z',
                '00Q6700000sRbabEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.P.S - BOURG EN BRESSE',
            ],
            [
                'September 2021',
                '2021-09-22T13:04:39.000Z',
                '00Q6700000sRbboEAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE',
            ],
            [
                'September 2021',
                '2021-09-23T13:17:48.000Z',
                '00Q6700000sRetDEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE',
            ],
            [
                'September 2021',
                '2021-09-24T09:18:15.000Z',
                '00Q6700000sRgTiEAK',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'September 2021',
                '2021-09-24T13:14:15.000Z',
                '00Q6700000sRh37EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'September 2021',
                '2021-09-24T13:35:20.000Z',
                '00Q6700000sRh6aEAC',
                'Qualified',
                'Phone',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'September 2021',
                '2021-09-24T15:39:30.000Z',
                '00Q6700000sRhNCEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'September 2021',
                '2021-09-24T18:26:59.000Z',
                '00Q6700000sRhhAEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'September 2021',
                '2021-09-25T08:27:40.000Z',
                '00Q6700000sRiNoEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'September 2021',
                '2021-09-26T12:12:56.000Z',
                '00Q6700000sRjuKEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2021',
                '2021-09-26T21:09:06.000Z',
                '00Q6700000sRkUIEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PREMIUM AUTOMOBILES - AUXERRE',
            ],
            [
                'September 2021',
                '2021-09-27T07:05:38.000Z',
                '00Q6700000sRksKEAS',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2021',
                '2021-09-27T13:04:38.000Z',
                '00Q6700000sRlpAEAS',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2021',
                '2021-09-28T13:22:10.000Z',
                '00Q6700000sRob7EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'September 2021',
                '2021-09-28T14:15:09.000Z',
                '00Q6700000sRojpEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'September 2021',
                '2021-09-28T14:33:25.000Z',
                '00Q6700000sRooiEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'September 2021',
                '2021-09-28T14:38:16.000Z',
                '00Q6700000sRoplEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'September 2021',
                '2021-09-29T12:56:01.000Z',
                '00Q6700000sRr5IEAS',
                'Unqualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'September 2021',
                '2021-09-29T15:49:50.000Z',
                '00Q6700000sRrW9EAK',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'September 2021',
                '2021-09-29T20:19:16.000Z',
                '00Q6700000sRs5EEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2021',
                '2021-09-30T11:34:37.000Z',
                '00Q6700000sRtBmEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'September 2021',
                '2021-09-30T13:52:47.000Z',
                '00Q6700000sRtY4EAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'September 2021',
                '2021-09-30T15:24:52.000Z',
                '00Q6700000sRuBTEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'October 2021',
                '2021-10-03T19:47:07.000Z',
                '00Q6700000sS0vEEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'October 2021',
                '2021-10-04T12:36:04.000Z',
                '00Q6700000sS2WjEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'October 2021',
                '2021-10-04T14:09:41.000Z',
                '00Q6700000sS2nWEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'October 2021',
                '2021-10-05T14:37:24.000Z',
                '00Q6700000sS6ZVEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'October 2021',
                '2021-10-05T16:32:19.000Z',
                '00Q6700000sS70YEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'October 2021',
                '2021-10-05T21:36:33.000Z',
                '00Q6700000sS7cUEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'October 2021',
                '2021-10-07T14:28:46.000Z',
                '00Q6700000sSCQKEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'October 2021',
                '2021-10-08T07:19:26.000Z',
                '00Q6700000sSDsyEAG',
                'Unqualified',
                'Email',
                'France',
                'Company',
                'Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI',
            ],
            [
                'October 2021',
                '2021-10-08T08:51:02.000Z',
                '00Q6700000sSE7REAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'October 2021',
                '2021-10-08T11:40:50.000Z',
                '00Q6700000sSEaIEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'October 2021',
                '2021-10-08T13:43:34.000Z',
                '00Q6700000sSEzgEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'October 2021',
                '2021-10-08T14:07:45.000Z',
                '00Q6700000sSF2pEAG',
                'Qualified',
                'Direct',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2021',
                '2021-10-08T14:54:20.000Z',
                '00Q6700000sSFBoEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2021',
                '2021-10-08T15:23:26.000Z',
                '00Q6700000sSFFWEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'October 2021',
                '2021-10-08T15:53:31.000Z',
                '00Q6700000sSFLZEA4',
                'Unqualified',
                'Phone',
                'France',
                'Company',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'October 2021',
                '2021-10-09T11:42:46.000Z',
                '00Q6700000sSGyhEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'October 2021',
                '2021-10-10T16:27:37.000Z',
                '00Q6700000sSJluEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'October 2021',
                '2021-10-11T08:56:10.000Z',
                '00Q6700000sSKzBEAW',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'October 2021',
                '2021-10-11T09:04:23.000Z',
                '00Q6700000sSL0OEAW',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'October 2021',
                '2021-10-11T09:08:02.000Z',
                '00Q6700000sSL17EAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'October 2021',
                '2021-10-11T10:28:03.000Z',
                '00Q6700000sSLEFEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'October 2021',
                '2021-10-12T07:41:18.000Z',
                '00Q6700000sSNQrEAO',
                'Qualified',
                'Direct',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'October 2021',
                '2021-10-12T12:36:47.000Z',
                '00Q6700000sSOBYEA4',
                'Qualified',
                'Phone',
                'France',
                'Company',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'October 2021',
                '2021-10-13T12:23:55.000Z',
                '00Q6700000sSRm2EAG',
                'Unqualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'October 2021',
                '2021-10-13T14:58:12.000Z',
                '00Q6700000sST12EAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)',
            ],
            [
                'October 2021',
                '2021-10-14T07:17:01.000Z',
                '00Q6700000sSUOgEAO',
                'Unqualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'October 2021',
                '2021-10-14T14:08:50.000Z',
                '00Q6700000sSWJyEAO',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'October 2021',
                '2021-10-15T09:44:39.000Z',
                '00Q6700000sSYxMEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'October 2021',
                '2021-10-15T14:23:41.000Z',
                '00Q6700000sSaAWEA0',
                'Unqualified',
                'Phone',
                'France',
                'Company',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'October 2021',
                '2021-10-15T18:47:14.000Z',
                '00Q6700000sSb6wEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2021',
                '2021-10-16T15:02:17.000Z',
                '00Q6700000sSck0EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'October 2021',
                '2021-10-16T18:11:43.000Z',
                '00Q6700000sSd53EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'October 2021',
                '2021-10-18T09:00:52.000Z',
                '00Q6700000sSgDwEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'October 2021',
                '2021-10-18T09:46:22.000Z',
                '00Q6700000sSgKtEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'October 2021',
                '2021-10-18T15:20:55.000Z',
                '00Q6700000sShOhEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'October 2021',
                '2021-10-18T17:06:25.000Z',
                '00Q6700000sShirEAC',
                'Qualified',
                'Email',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2021',
                '2021-10-19T07:50:23.000Z',
                '00Q6700000sSjAGEA0',
                'Unqualified',
                'Email',
                'France',
                'Single Home',
                'Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI',
            ],
            [
                'October 2021',
                '2021-10-19T09:52:14.000Z',
                '00Q6700000sSjVOEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'October 2021',
                '2021-10-19T10:04:53.000Z',
                '00Q6700000sSjY8EAK',
                'Unqualified',
                'Email',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'October 2021',
                '2021-10-19T10:07:39.000Z',
                '00Q6700000sSjYcEAK',
                'Qualified',
                'Email',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2021',
                '2021-10-19T10:08:41.000Z',
                '00Q6700000sSjZ1EAK',
                'Qualified',
                'Email',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2021',
                '2021-10-19T10:11:58.000Z',
                '00Q6700000sSjZkEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES',
            ],
            [
                'October 2021',
                '2021-10-19T13:52:09.000Z',
                '00Q6700000sSkTtEAK',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE EST AUTOS (11ème) - PARIS',
            ],
            [
                'October 2021',
                '2021-10-19T14:16:22.000Z',
                '00Q6700000sSkXlEAK',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'October 2021',
                '2021-10-19T18:35:49.000Z',
                '00Q6700000sSlOVEA0',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'October 2021',
                '2021-10-20T13:47:17.000Z',
                '00Q6700000sSoPOEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'October 2021',
                '2021-10-20T14:44:48.000Z',
                '00Q6700000sSoa2EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - Malcorps Wavre',
            ],
            [
                'October 2021',
                '2021-10-20T19:16:55.000Z',
                '00Q6700000sSpJvEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2021',
                '2021-10-21T08:16:17.000Z',
                '00Q6700000sSqStEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'October 2021',
                '2021-10-21T11:41:35.000Z',
                '00Q6700000sSr8EEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'October 2021',
                '2021-10-21T18:29:06.000Z',
                '00Q6700000sSsSZEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - LIMOGES',
            ],
            [
                'October 2021',
                '2021-10-21T19:22:11.000Z',
                '00Q6700000sSsZVEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'October 2021',
                '2021-10-22T08:40:36.000Z',
                '00Q6700000sStmkEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'October 2021',
                '2021-10-22T09:25:33.000Z',
                '00Q6700000sStsqEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'October 2021',
                '2021-10-22T12:25:53.000Z',
                '00Q6700000sSuuNEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'October 2021',
                '2021-10-23T13:30:45.000Z',
                '00Q6700000sSxQ9EAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            [
                'October 2021',
                '2021-10-23T14:18:12.000Z',
                '00Q6700000sSxWvEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'October 2021',
                '2021-10-23T15:32:58.000Z',
                '00Q6700000sSxheEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'October 2021',
                '2021-10-25T07:06:42.000Z',
                '00Q6700000sT0eJEAS',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'October 2021',
                '2021-10-25T09:34:28.000Z',
                '00Q6700000sT0zSEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'October 2021',
                '2021-10-25T11:25:46.000Z',
                '00Q6700000sT1T2EAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'October 2021',
                '2021-10-25T12:08:48.000Z',
                '00Q6700000sT1ZtEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'October 2021',
                '2021-10-25T12:13:04.000Z',
                '00Q6700000sT1aNEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'October 2021',
                '2021-10-25T15:51:03.000Z',
                '00Q6700000sT2HcEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'October 2021',
                '2021-10-26T08:52:38.000Z',
                '00Q6700000sT49IEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'October 2021',
                '2021-10-26T13:42:51.000Z',
                '00Q6700000sT5KDEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'October 2021',
                '2021-10-26T19:28:52.000Z',
                '00Q6700000sT6fVEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2021',
                '2021-10-27T08:22:43.000Z',
                '00Q6700000sT7YJEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'October 2021',
                '2021-10-27T08:23:56.000Z',
                '00Q6700000sT7YYEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PREMIUM AUTOMOBILES - BARBEREY SAINT SULPICE (TROYES)',
            ],
            [
                'October 2021',
                '2021-10-27T09:29:43.000Z',
                '00Q6700000sT7l7EAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - BUCHELAY (MANTES LA JOLIE)',
            ],
            [
                'October 2021',
                '2021-10-27T12:36:30.000Z',
                '00Q6700000sT8F8EAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE DROITE - LORMONT (BORDEAUX)',
            ],
            [
                'October 2021',
                '2021-10-27T14:44:16.000Z',
                '00Q6700000sT8cSEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'October 2021',
                '2021-10-28T09:26:25.000Z',
                '00Q6700000sTAkwEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'October 2021',
                '2021-10-28T12:21:39.000Z',
                '00Q6700000sTB6nEAG',
                'Qualified',
                'Phone',
                'France',
                'Company',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'October 2021',
                '2021-10-28T14:20:34.000Z',
                '00Q6700000sTBTwEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'October 2021',
                '2021-10-29T03:20:11.000Z',
                '00Q6700000sTD4WEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - SAINT AVIT (MONT DE MARSAN)',
            ],
            [
                'October 2021',
                '2021-10-29T09:46:49.000Z',
                '00Q6700000sTDmuEAG',
                'Qualified',
                'Email',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'October 2021',
                '2021-10-29T14:03:30.000Z',
                '00Q6700000sTERgEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'October 2021',
                '2021-10-29T14:03:52.000Z',
                '00Q6700000sTERqEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'October 2021',
                '2021-10-29T14:20:26.000Z',
                '00Q6700000sTEYgEAO',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'October 2021',
                '2021-10-30T12:59:17.000Z',
                '00Q6700000sTGytEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'October 2021',
                '2021-10-30T13:21:36.000Z',
                '00Q6700000sTH0pEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'October 2021',
                '2021-10-30T15:30:17.000Z',
                '00Q6700000sTHCTEA4',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'October 2021',
                '2021-10-31T11:08:29.000Z',
                '00Q6700000sTIgNEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'November 2021',
                '2021-11-01T19:54:14.000Z',
                '00Q6700000sTLbCEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'November 2021',
                '2021-11-02T11:23:14.000Z',
                '00Q6700000sTN2WEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'November 2021',
                '2021-11-02T17:10:37.000Z',
                '00Q6700000sTO5CEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - BORGO',
            ],
            [
                'November 2021',
                '2021-11-02T22:14:56.000Z',
                '00Q6700000sTOikEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE DROITE - LORMONT (BORDEAUX)',
            ],
            [
                'November 2021',
                '2021-11-03T10:54:04.000Z',
                '00Q6700000sTPsvEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'November 2021',
                '2021-11-03T14:09:50.000Z',
                '00Q6700000sTQUyEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'November 2021',
                '2021-11-03T14:23:13.000Z',
                '00Q6700000sTQYbEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'November 2021',
                '2021-11-03T14:34:21.000Z',
                '00Q6700000sTQZoEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'November 2021',
                '2021-11-03T17:04:39.000Z',
                '00Q6700000sTR5lEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - ROISSY EN FRANCE',
            ],
            [
                'November 2021',
                '2021-11-04T10:00:43.000Z',
                '00Q6700000sTSk2EAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'November 2021',
                '2021-11-04T10:12:49.000Z',
                '00Q6700000sTSmhEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'November 2021',
                '2021-11-04T14:06:22.000Z',
                '00Q6700000sTTPPEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO COLMAR - COLMAR',
            ],
            [
                'November 2021',
                '2021-11-04T14:54:56.000Z',
                '00Q6700000sTTZFEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'November 2021',
                '2021-11-04T16:12:42.000Z',
                '00Q6700000sTTnGEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'November 2021',
                '2021-11-04T16:28:49.000Z',
                '00Q6700000sTTrIEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'November 2021',
                '2021-11-04T17:51:05.000Z',
                '00Q6700000sTU68EAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'November 2021',
                '2021-11-05T09:02:13.000Z',
                '00Q6700000sTVLxEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - CHAMPNIERS (ANGOULEME)',
            ],
            [
                'November 2021',
                '2021-11-05T10:28:43.000Z',
                '00Q6700000sTVdsEAG',
                'Unqualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'November 2021',
                '2021-11-06T10:00:52.000Z',
                '00Q6700000sTY0MEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'November 2021',
                '2021-11-06T14:12:16.000Z',
                '00Q6700000sTYQoEAO',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES',
            ],
            [
                'November 2021',
                '2021-11-06T15:15:40.000Z',
                '00Q6700000sTYXaEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'November 2021',
                '2021-11-06T16:25:03.000Z',
                '00Q6700000sTYeREAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'November 2021',
                '2021-11-06T16:56:00.000Z',
                '00Q6700000sTYinEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'November 2021',
                '2021-11-07T15:32:18.000Z',
                '00Q6700000sTaELEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'November 2021',
                '2021-11-07T18:44:44.000Z',
                '00Q6700000yP85iEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)',
            ],
            [
                'November 2021',
                '2021-11-08T07:00:13.000Z',
                '00Q6700000sTbhiEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'November 2021',
                '2021-11-08T15:05:43.000Z',
                '00Q6700000sTdvIEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'November 2021',
                '2021-11-08T16:49:35.000Z',
                '00Q6700000sTeKsEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'November 2021',
                '2021-11-08T17:07:20.000Z',
                '00Q6700000sTeOQEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'November 2021',
                '2021-11-09T12:42:25.000Z',
                '00Q6700000sTgahEAC',
                'Qualified',
                'Direct',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'November 2021',
                '2021-11-09T14:51:13.000Z',
                '00Q6700000sTh1JEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'November 2021',
                '2021-11-09T20:08:41.000Z',
                '00Q6700000sTi2mEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'November 2021',
                '2021-11-09T21:54:47.000Z',
                '00Q6700000sTiF7EAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - VERSAILLES',
            ],
            [
                'November 2021',
                '2021-11-10T15:48:27.000Z',
                '00Q6700000sTkTfEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'November 2021',
                '2021-11-10T16:11:22.000Z',
                '00Q6700000sTkY6EAK',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'November 2021',
                '2021-11-10T16:48:18.000Z',
                '00Q6700000sTkeOEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'November 2021',
                '2021-11-11T23:19:07.000Z',
                '00Q6700000sToVfEAK',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'November 2021',
                '2021-11-12T11:32:28.000Z',
                '00Q6700000sTpkKEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'November 2021',
                '2021-11-13T11:01:02.000Z',
                '00Q6700000sTsMyEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2021',
                '2021-11-13T11:51:35.000Z',
                '00Q6700000sTsW5EAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'November 2021',
                '2021-11-13T14:17:24.000Z',
                '00Q6700000sTssaEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'November 2021',
                '2021-11-13T16:02:51.000Z',
                '00Q6700000sTt8nEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'November 2021',
                '2021-11-15T12:09:08.000Z',
                '00Q6700000sTxtDEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  J.D. AUTOMOBILES - SAINT GEREON (ANCENIS)',
            ],
            [
                'November 2021',
                '2021-11-15T14:42:13.000Z',
                '00Q6700000sTyHjEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'November 2021',
                '2021-11-15T20:35:12.000Z',
                '00Q6700000sTzEyEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'November 2021',
                '2021-11-16T15:53:04.000Z',
                '00Q6700000sU1jYEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'November 2021',
                '2021-11-16T16:54:46.000Z',
                '00Q6700000sU1tYEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'November 2021',
                '2021-11-16T17:47:00.000Z',
                '00Q6700000sU21IEAS',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'November 2021',
                '2021-11-18T10:28:42.000Z',
                '00Q6700000sU9rZEAS',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'November 2021',
                '2021-11-18T11:07:27.000Z',
                '00Q6700000sU9znEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'November 2021',
                '2021-11-18T13:42:18.000Z',
                '00Q6700000sUAmJEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'November 2021',
                '2021-11-18T19:05:48.000Z',
                '00Q6700000sUCJqEAO',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'November 2021',
                '2021-11-19T09:48:31.000Z',
                '00Q6700000sUE4NEAW',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'November 2021',
                '2021-11-19T09:55:08.000Z',
                '00Q6700000sUE5pEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'November 2021',
                '2021-11-19T10:36:07.000Z',
                '00Q6700000sUECMEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'November 2021',
                '2021-11-20T16:19:53.000Z',
                '00Q6700000sUHtlEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'November 2021',
                '2021-11-21T20:42:46.000Z',
                '00Q6700000sUL17EAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'November 2021',
                '2021-11-22T09:41:29.000Z',
                '00Q6700000sUMufEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - BUCHELAY (MANTES LA JOLIE)',
            ],
            [
                'November 2021',
                '2021-11-22T10:35:34.000Z',
                '00Q6700000sUNSSEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE SAINT ETIENNE - SAINT ETIENNE',
            ],
            [
                'November 2021',
                '2021-11-22T17:43:49.000Z',
                '00Q6700000sUQDMEA4',
                'Qualified',
                'Form Volvo Employee FR',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2021',
                '2021-11-23T08:31:58.000Z',
                '00Q6700000sUSENEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'November 2021',
                '2021-11-23T08:44:56.000Z',
                '00Q6700000yQS03EAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2021',
                '2021-11-23T09:14:05.000Z',
                '00Q6700000sUSPpEAO',
                'Qualified',
                'Form Volvo Employee FR',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2021',
                '2021-11-23T10:27:15.000Z',
                '00Q6700000wfiNqEAI',
                'Qualified',
                'Form Volvo Employee FR',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2021',
                '2021-11-23T10:29:15.000Z',
                '00Q6700000sUSnNEAW',
                'Qualified',
                'Form Volvo Employee FR',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2021',
                '2021-11-23T10:47:25.000Z',
                '00Q6700000sUSsdEAG',
                'Qualified',
                'Email',
                'France',
                'Single Home',
                'Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI',
            ],
            [
                'November 2021',
                '2021-11-23T12:06:32.000Z',
                '00Q6700000sUTFXEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'November 2021',
                '2021-11-23T18:19:33.000Z',
                '00Q6700000sUUyZEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'November 2021',
                '2021-11-24T08:45:10.000Z',
                '00Q6700000sUWNjEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'November 2021',
                '2021-11-24T10:31:24.000Z',
                '00Q6700000sUWsDEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'November 2021',
                '2021-11-24T17:10:35.000Z',
                '00Q6700000sUYUOEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'November 2021',
                '2021-11-24T18:45:42.000Z',
                '00Q6700000sUYtDEAW',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VILLEFRANCHE-SUR-SAÔNE',
            ],
            [
                'November 2021',
                '2021-11-25T11:22:45.000Z',
                '00Q6700000sUagiEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'November 2021',
                '2021-11-25T16:16:58.000Z',
                '00Q6700000sUbz7EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'November 2021',
                '2021-11-25T18:30:15.000Z',
                '00Q6700000sUcXdEAK',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - NEUILLY SUR SEINE',
            ],
            [
                'November 2021',
                '2021-11-26T06:44:47.000Z',
                '00Q6700000sUdW2EAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2021',
                '2021-11-26T09:46:00.000Z',
                '00Q6700000sUe4OEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2021',
                '2021-11-26T12:02:35.000Z',
                '00Q6700000sUeo9EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'November 2021',
                '2021-11-26T15:12:06.000Z',
                '00Q6700000sUfijEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'November 2021',
                '2021-11-26T15:21:09.000Z',
                '00Q6700000sUflJEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'November 2021',
                '2021-11-26T17:06:40.000Z',
                '00Q6700000sUgE1EAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'November 2021',
                '2021-11-27T12:45:18.000Z',
                '00Q6700000sUiOSEA0',
                'Qualified',
                'Form Volvo Employee FR',
                'France',
                'Single Home',
                'Volvo Car France',
            ],
            [
                'November 2021',
                '2021-11-27T12:59:26.000Z',
                '00Q6700000sUiRCEA0',
                'Qualified',
                'Form Volvo Employee FR',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'November 2021',
                '2021-11-27T14:14:13.000Z',
                '00Q6700000sUid3EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'November 2021',
                '2021-11-29T09:54:12.000Z',
                '00Q6700000sUnmPEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'November 2021',
                '2021-11-29T16:13:20.000Z',
                '00Q6700000sUpdYEAS',
                'Unqualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'November 2021',
                '2021-11-29T18:44:44.000Z',
                '00Q6700000sUqL1EAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'November 2021',
                '2021-11-29T19:44:48.000Z',
                '00Q6700000sUqUNEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'November 2021',
                '2021-11-30T09:39:46.000Z',
                '00Q6700000sUreDEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'November 2021',
                '2021-11-30T09:57:52.000Z',
                '00Q6700000sUrmPEAS',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'November 2021',
                '2021-11-30T13:46:56.000Z',
                '00Q6700000sUt3kEAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'December 2021',
                '2021-12-01T07:03:34.000Z',
                '00Q6700000sUvp6EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'December 2021',
                '2021-12-01T11:59:26.000Z',
                '00Q6700000sUwZNEA0',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE EST AUTOS (11ème) - PARIS',
            ],
            [
                'December 2021',
                '2021-12-01T12:34:32.000Z',
                '00Q6700000sUwehEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'December 2021',
                '2021-12-01T16:18:50.000Z',
                '00Q6700000sUxfFEAS',
                'Unqualified',
                'Phone',
                'France',
                'Company',
                "Volvo -  CAR'S SERVICES - ONET LE CHATEAU (RODEZ)",
            ],
            [
                'December 2021',
                '2021-12-01T16:27:46.000Z',
                '00Q6700000sUxgwEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
            ],
            [
                'December 2021',
                '2021-12-01T17:01:31.000Z',
                '00Q6700000sUxrkEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'December 2021',
                '2021-12-02T10:28:44.000Z',
                '00Q6700000sUzuhEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'December 2021',
                '2021-12-02T17:45:34.000Z',
                '00Q6700000sV1dPEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'December 2021',
                '2021-12-03T09:35:00.000Z',
                '00Q6700000sV3glEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'December 2021',
                '2021-12-03T10:48:32.000Z',
                '00Q6700000sV3v7EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'December 2021',
                '2021-12-03T16:45:39.000Z',
                '00Q6700000sV58FEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'December 2021',
                '2021-12-03T23:42:11.000Z',
                '00Q6700000sV633EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'December 2021',
                '2021-12-04T11:43:30.000Z',
                '00Q6700000sV6v5EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'December 2021',
                '2021-12-04T16:17:40.000Z',
                '00Q6700000sV7ZZEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES',
            ],
            [
                'December 2021',
                '2021-12-04T17:02:57.000Z',
                '00Q6700000sV7gLEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'December 2021',
                '2021-12-05T21:50:50.000Z',
                '00Q6700000sVAFDEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'December 2021',
                '2021-12-06T14:07:18.000Z',
                '00Q6700000sVC44EAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'December 2021',
                '2021-12-06T18:46:25.000Z',
                '00Q6700000sVDXHEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2021',
                '2021-12-07T09:33:10.000Z',
                '00Q6700000sVEsQEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'December 2021',
                '2021-12-08T09:20:49.000Z',
                '00Q6700000sVIgiEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI',
            ],
            [
                'December 2021',
                '2021-12-08T14:21:11.000Z',
                '00Q6700000sVJiFEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'December 2021',
                '2021-12-08T15:14:47.000Z',
                '00Q6700000sVJrHEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'December 2021',
                '2021-12-09T08:18:27.000Z',
                '00Q6700000sVLm2EAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'December 2021',
                '2021-12-09T09:25:23.000Z',
                '00Q6700000sVM8mEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)',
            ],
            [
                'December 2021',
                '2021-12-09T09:44:52.000Z',
                '00Q6700000sVMEaEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PERICAUD AUTOMOBILES - CHAMPNIERS (ANGOULEME)',
            ],
            [
                'December 2021',
                '2021-12-09T15:21:10.000Z',
                '00Q6700000sVNs3EAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI',
            ],
            [
                'December 2021',
                '2021-12-09T17:16:52.000Z',
                '00Q6700000sVOWpEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'December 2021',
                '2021-12-10T00:15:40.000Z',
                '00Q6700000sVPWkEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  MARTINIQUE AUTOMOBILES - LE LAMENTIN',
            ],
            [
                'December 2021',
                '2021-12-10T08:24:43.000Z',
                '00Q6700000sVQ4WEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2021',
                '2021-12-10T13:23:19.000Z',
                '00Q6700000sVRPPEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI',
            ],
            [
                'December 2021',
                '2021-12-10T13:25:14.000Z',
                '00Q6700000sVRQSEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'December 2021',
                '2021-12-11T09:23:27.000Z',
                '00Q6700000sVTuKEAW',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  CAVALLARI AUTOMOBILES - NICE',
            ],
            [
                'December 2021',
                '2021-12-11T14:15:08.000Z',
                '00Q6700000sVUqTEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'December 2021',
                '2021-12-11T15:18:35.000Z',
                '00Q6700000sVV96EAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2021',
                '2021-12-12T09:46:21.000Z',
                '00Q6700000sVWv1EAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2021',
                '2021-12-12T21:03:13.000Z',
                '00Q6700000sVYO0EAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'December 2021',
                '2021-12-13T08:53:55.000Z',
                '00Q6700000sVZTPEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            [
                'December 2021',
                '2021-12-13T09:47:45.000Z',
                '00Q6700000sVZj1EAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'December 2021',
                '2021-12-13T12:59:35.000Z',
                '00Q6700000sVafqEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2021',
                '2021-12-13T19:58:44.000Z',
                '00Q6700000sVcw7EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'December 2021',
                '2021-12-14T07:31:56.000Z',
                '00Q6700000teyJIEAY',
                'Qualified',
                'Other',
                'France',
                'Company',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'December 2021',
                '2021-12-14T19:03:07.000Z',
                '00Q6700000tf0sBEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'December 2021',
                '2021-12-15T08:25:13.000Z',
                '00Q6700000tf20iEAA',
                'Unqualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - SAINT AVIT (MONT DE MARSAN)',
            ],
            [
                'December 2021',
                '2021-12-15T12:39:52.000Z',
                '00Q6700000tf2zeEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'December 2021',
                '2021-12-15T21:12:03.000Z',
                '00Q6700000tf4lgEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'December 2021',
                '2021-12-16T14:23:28.000Z',
                '00Q6700000tf6mlEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'December 2021',
                '2021-12-16T15:26:54.000Z',
                '00Q6700000tf71IEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'December 2021',
                '2021-12-16T17:11:09.000Z',
                '00Q6700000tf7NxEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PREMIUM AUTOMOBILES - AUXERRE',
            ],
            [
                'December 2021',
                '2021-12-17T10:20:40.000Z',
                '00Q6700000tf9V1EAI',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'December 2021',
                '2021-12-17T10:58:58.000Z',
                '00Q6700000tf9cREAQ',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'December 2021',
                '2021-12-17T11:29:33.000Z',
                '00Q6700000tf9uBEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'December 2021',
                '2021-12-17T15:59:02.000Z',
                '00Q6700000tfAonEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'December 2021',
                '2021-12-17T18:50:15.000Z',
                '00Q6700000tfBLWEA2',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'December 2021',
                '2021-12-18T08:50:27.000Z',
                '00Q6700000tfCIzEAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'December 2021',
                '2021-12-18T09:59:04.000Z',
                '00Q6700000tfCPvEAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'December 2021',
                '2021-12-18T13:53:27.000Z',
                '00Q6700000tfCogEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'December 2021',
                '2021-12-18T18:31:27.000Z',
                '00Q6700000tfDQuEAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)',
            ],
            [
                'December 2021',
                '2021-12-20T15:56:09.000Z',
                '00Q6700000tfI7iEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'December 2021',
                '2021-12-21T10:17:56.000Z',
                '00Q6700000tfK1sEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'December 2021',
                '2021-12-21T17:26:53.000Z',
                '00Q6700000tfLR1EAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2021',
                '2021-12-21T17:52:57.000Z',
                '00Q6700000tfLYNEA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)',
            ],
            [
                'December 2021',
                '2021-12-22T10:20:14.000Z',
                '00Q6700000tfMnQEAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'December 2021',
                '2021-12-22T13:59:54.000Z',
                '00Q6700000tfNN5EAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'December 2021',
                '2021-12-22T19:00:45.000Z',
                '00Q6700000tfOBoEAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE SAINT ETIENNE - SAINT ETIENNE',
            ],
            [
                'December 2021',
                '2021-12-23T09:59:23.000Z',
                '00Q6700000tfP8gEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'December 2021',
                '2021-12-23T15:14:33.000Z',
                '00Q6700000tfPwvEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'December 2021',
                '2021-12-23T16:22:24.000Z',
                '00Q6700000tfQ83EAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'December 2021',
                '2021-12-24T12:25:37.000Z',
                '00Q6700000tfReKEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'December 2021',
                '2021-12-26T14:02:16.000Z',
                '00Q6700000tfVGtEAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'December 2021',
                '2021-12-27T16:56:24.000Z',
                '00Q6700000tfYI7EAM',
                'Unqualified',
                'Email',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'December 2021',
                '2021-12-28T09:35:41.000Z',
                '00Q6700000tfZmDEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)',
            ],
            [
                'December 2021',
                '2021-12-28T10:15:37.000Z',
                '00Q6700000tfZrmEAE',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'December 2021',
                '2021-12-28T14:10:55.000Z',
                '00Q6700000tfaUeEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'December 2021',
                '2021-12-29T10:22:56.000Z',
                '00Q6700000tfcYYEAY',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'December 2021',
                '2021-12-29T16:20:48.000Z',
                '00Q6700000tfdWpEAI',
                'Qualified',
                'Phone',
                'France',
                'Company',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'December 2021',
                '2021-12-29T17:19:10.000Z',
                '00Q6700000tfdgfEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'December 2021',
                '2021-12-29T20:48:37.000Z',
                '00Q6700000tfe9hEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'December 2021',
                '2021-12-29T23:08:05.000Z',
                '00Q6700000tfeLnEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2021',
                '2021-12-30T08:18:56.000Z',
                '00Q6700000tfehyEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'December 2021',
                '2021-12-30T10:01:31.000Z',
                '00Q6700000tfeuiEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'December 2021',
                '2021-12-30T21:56:40.000Z',
                '00Q6700000tfggxEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2021',
                '2021-12-31T09:30:03.000Z',
                '00Q6700000tfhXxEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'December 2021',
                '2021-12-31T12:37:01.000Z',
                '00Q6700000tfhwZEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'January 2022',
                '2022-01-02T16:50:34.000Z',
                '00Q6700000yRJHyEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'January 2022',
                '2022-01-03T10:22:15.000Z',
                '00Q6700000tfmIxEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'January 2022',
                '2022-01-03T14:37:10.000Z',
                '00Q6700000tfn48EAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PREMIUM AUTOMOBILES - AUXERRE',
            ],
            [
                'January 2022',
                '2022-01-03T20:02:13.000Z',
                '00Q6700000tfobiEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'January 2022',
                '2022-01-04T10:17:43.000Z',
                '00Q6700000tfpcPEAQ',
                'Qualified',
                'Email',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'January 2022',
                '2022-01-04T15:59:41.000Z',
                '00Q6700000tfqSfEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'January 2022',
                '2022-01-05T11:41:18.000Z',
                '00Q6700000tfsIREAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  J.M. AUTOMOBILES - SAUVERNY (DIVONNE LES BAINS)',
            ],
            [
                'January 2022',
                '2022-01-06T14:04:51.000Z',
                '00Q6700000tfvzOEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'January 2022',
                '2022-01-06T16:03:04.000Z',
                '00Q6700000tfwHXEAY',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'January 2022',
                '2022-01-06T17:12:50.000Z',
                '00Q6700000tfwTUEAY',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'January 2022',
                '2022-01-07T10:36:16.000Z',
                '00Q6700000tfxu7EAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CARS PREMIUM - CARCASSONNE',
            ],
            [
                'January 2022',
                '2022-01-09T13:31:59.000Z',
                '00Q6700000tg2CtEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO COLMAR - COLMAR',
            ],
            [
                'January 2022',
                '2022-01-09T17:46:57.000Z',
                '00Q6700000tg2bFEAQ',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'January 2022',
                '2022-01-10T22:04:24.000Z',
                '00Q6700000tg5jiEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'January 2022',
                '2022-01-11T08:36:31.000Z',
                '00Q6700000tg6I8EAI',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'January 2022',
                '2022-01-11T09:25:09.000Z',
                '00Q6700000tg6OGEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'January 2022',
                '2022-01-11T09:28:01.000Z',
                '00Q6700000tg6OaEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'January 2022',
                '2022-01-11T10:23:55.000Z',
                '00Q6700000tg6W5EAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'January 2022',
                '2022-01-11T10:32:56.000Z',
                '00Q6700000tg6XIEAY',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'January 2022',
                '2022-01-11T12:23:13.000Z',
                '00Q6700000tg6sBEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'January 2022',
                '2022-01-11T14:20:35.000Z',
                '00Q6700000tg7CpEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'January 2022',
                '2022-01-11T17:36:46.000Z',
                '00Q6700000tg7sWEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'January 2022',
                '2022-01-12T09:47:46.000Z',
                '00Q6700000tg9KOEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'January 2022',
                '2022-01-12T19:43:02.000Z',
                '00Q6700000tgiYjEAI',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'January 2022',
                '2022-01-13T15:45:34.000Z',
                '00Q6700000tgm44EAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'January 2022',
                '2022-01-14T11:50:32.000Z',
                '00Q6700000tgoGjEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'January 2022',
                '2022-01-14T16:54:35.000Z',
                '00Q6700000tgpQiEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'January 2022',
                '2022-01-15T13:33:54.000Z',
                '00Q6700000tgr5MEAQ',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'January 2022',
                '2022-01-15T14:10:24.000Z',
                '00Q6700000tgrACEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'January 2022',
                '2022-01-16T22:03:56.000Z',
                '00Q6700000tgtdLEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'January 2022',
                '2022-01-17T14:58:10.000Z',
                '00Q6700000tgvLlEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'January 2022',
                '2022-01-18T09:08:27.000Z',
                '00Q6700000tgxw7EAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'January 2022',
                '2022-01-18T13:30:00.000Z',
                '00Q6700000tgyhiEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'January 2022',
                '2022-01-18T15:17:28.000Z',
                '00Q6700000tgyygEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'January 2022',
                '2022-01-18T15:38:18.000Z',
                '00Q6700000tgz7cEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'January 2022',
                '2022-01-18T16:56:27.000Z',
                '00Q6700000tgzvmEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - CHAMPNIERS (ANGOULEME)',
            ],
            [
                'January 2022',
                '2022-01-18T16:58:09.000Z',
                '00Q6700000tgzwQEAQ',
                'Qualified',
                'Phone',
                'France',
                'Company',
                'Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI',
            ],
            [
                'January 2022',
                '2022-01-18T17:07:43.000Z',
                '00Q6700000tgzylEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'January 2022',
                '2022-01-18T17:18:37.000Z',
                '00Q6700000th02eEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE JFC LISIEUX - LISIEUX',
            ],
            [
                'January 2022',
                '2022-01-18T18:22:46.000Z',
                '00Q6700000th0I3EAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PREMIUM AUTOMOBILES - BARBEREY SAINT SULPICE (TROYES)',
            ],
            [
                'January 2022',
                '2022-01-19T08:12:28.000Z',
                '00Q6700000th1aNEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'January 2022',
                '2022-01-19T08:17:18.000Z',
                '00Q6700000th1b6EAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'January 2022',
                '2022-01-19T22:03:45.000Z',
                '00Q6700000th3wCEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'January 2022',
                '2022-01-20T08:19:45.000Z',
                '00Q6700000th4gpEAA',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'January 2022',
                '2022-01-20T08:44:48.000Z',
                '00Q6700000th4jyEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'January 2022',
                '2022-01-20T14:26:07.000Z',
                '00Q6700000th5oCEAQ',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE EST AUTOS (11ème) - PARIS',
            ],
            [
                'January 2022',
                '2022-01-20T14:27:32.000Z',
                '00Q6700000th5obEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'January 2022',
                '2022-01-21T09:39:21.000Z',
                '00Q6700000th81gEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'January 2022',
                '2022-01-21T11:14:21.000Z',
                '00Q6700000th8HeEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'January 2022',
                '2022-01-21T12:57:29.000Z',
                '00Q6700000th8YVEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)',
            ],
            [
                'January 2022',
                '2022-01-21T13:11:34.000Z',
                '00Q6700000th8bJEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'January 2022',
                '2022-01-21T13:40:45.000Z',
                '00Q6700000th8gsEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'January 2022',
                '2022-01-21T16:55:37.000Z',
                '00Q6700000th9MiEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PREMIUM AUTOMOBILES - AUXERRE',
            ],
            [
                'January 2022',
                '2022-01-21T20:55:42.000Z',
                '00Q6700000th9vdEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'January 2022',
                '2022-01-22T04:55:57.000Z',
                '00Q6700000thAOLEA2',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE EST AUTOS (11ème) - PARIS',
            ],
            [
                'January 2022',
                '2022-01-22T05:30:49.000Z',
                '00Q6700000thAPiEAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'January 2022',
                '2022-01-22T16:05:11.000Z',
                '00Q6700000thBU5EAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'January 2022',
                '2022-01-23T21:44:26.000Z',
                '00Q6700000thE3DEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'January 2022',
                '2022-01-24T16:04:21.000Z',
                '00Q6700000thGKEEA2',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'January 2022',
                '2022-01-25T07:31:07.000Z',
                '00Q6700000thHtXEAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'January 2022',
                '2022-01-25T09:00:55.000Z',
                '00Q6700000thI4fEAE',
                'Qualified',
                'Form Volvo Employee FR',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'January 2022',
                '2022-01-25T09:26:55.000Z',
                '00Q6700000thI8rEAE',
                'Qualified',
                'Form Volvo Employee FR',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'January 2022',
                '2022-01-25T15:28:04.000Z',
                '00Q6700000thJKKEA2',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE',
            ],
            [
                'January 2022',
                '2022-01-25T15:42:50.000Z',
                '00Q6700000thJOHEA2',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'January 2022',
                '2022-01-25T16:23:54.000Z',
                '00Q6700000thJVXEA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'January 2022',
                '2022-01-25T16:36:32.000Z',
                '00Q6700000thJYCEA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'January 2022',
                '2022-01-26T08:44:12.000Z',
                '00Q6700000thLBtEAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'January 2022',
                '2022-01-27T08:44:47.000Z',
                '00Q6700000thOIKEA2',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'January 2022',
                '2022-01-27T10:31:37.000Z',
                '00Q6700000thOYDEA2',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'January 2022',
                '2022-01-27T15:13:21.000Z',
                '00Q6700000thPRNEA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'January 2022',
                '2022-01-28T15:40:22.000Z',
                '00Q6700000thSCXEA2',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'January 2022',
                '2022-01-28T15:49:18.000Z',
                '00Q6700000thSDaEAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'January 2022',
                '2022-01-29T14:36:17.000Z',
                '00Q6700000thU9zEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'January 2022',
                '2022-01-29T14:39:24.000Z',
                '00Q6700000thUAOEA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'January 2022',
                '2022-01-30T14:27:15.000Z',
                '00Q6700000thVyvEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'January 2022',
                '2022-01-30T20:59:48.000Z',
                '00Q6700000thWdQEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'January 2022',
                '2022-01-31T09:56:49.000Z',
                '00Q6700000thXYhEAM',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'January 2022',
                '2022-01-31T15:32:36.000Z',
                '00Q6700000thYdgEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'February 2022',
                '2022-02-01T10:10:26.000Z',
                '00Q67000016DGvCEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2022',
                '2022-02-01T23:18:52.000Z',
                '00Q6700000thdPGEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'February 2022',
                '2022-02-02T10:40:01.000Z',
                '00Q6700000theJlEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'February 2022',
                '2022-02-03T08:55:16.000Z',
                '00Q6700000thhHbEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'February 2022',
                '2022-02-03T10:57:05.000Z',
                '00Q6700000thhk7EAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2022',
                '2022-02-03T12:54:30.000Z',
                '00Q6700000thi9hEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'February 2022',
                '2022-02-04T16:00:10.000Z',
                '00Q6700000thmdgEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE JFC LISIEUX - LISIEUX',
            ],
            [
                'February 2022',
                '2022-02-05T15:31:56.000Z',
                '00Q6700000thorrEAA',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'February 2022',
                '2022-02-06T11:03:35.000Z',
                '00Q6700000thqCvEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'February 2022',
                '2022-02-07T09:21:45.000Z',
                '00Q6700000thsBpEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'February 2022',
                '2022-02-07T10:53:19.000Z',
                '00Q6700000thsWJEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'February 2022',
                '2022-02-07T12:50:23.000Z',
                '00Q6700000thszLEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'February 2022',
                '2022-02-07T13:58:25.000Z',
                '00Q6700000thtFhEAI',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE',
            ],
            [
                'February 2022',
                '2022-02-07T15:16:00.000Z',
                '00Q6700000thtf2EAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PERICAUD AUTOMOBILES - LIMOGES',
            ],
            [
                'February 2022',
                '2022-02-07T17:49:26.000Z',
                '00Q6700000thuGSEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'February 2022',
                '2022-02-08T13:22:53.000Z',
                '00Q6700000thy76EAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'February 2022',
                '2022-02-08T14:22:31.000Z',
                '00Q6700000thyTwEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2022',
                '2022-02-08T16:51:31.000Z',
                '00Q6700000thzKREAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'February 2022',
                '2022-02-08T16:59:00.000Z',
                '00Q6700000thzMhEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'February 2022',
                '2022-02-09T08:42:14.000Z',
                '00Q6700000ti1e7EAA',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2022',
                '2022-02-09T10:05:06.000Z',
                '00Q6700000ti1vNEAQ',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'February 2022',
                '2022-02-09T14:33:14.000Z',
                '00Q6700000ti2soEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'February 2022',
                '2022-02-09T16:34:56.000Z',
                '00Q6700000ti3NwEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - SAINT MARTIN SUR LE PRE (CHALONS EN CHAMPAGNE)',
            ],
            [
                'February 2022',
                '2022-02-09T17:27:31.000Z',
                '00Q6700000ti3gPEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'February 2022',
                '2022-02-09T19:03:46.000Z',
                '00Q6700000ti3zgEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'February 2022',
                '2022-02-10T09:53:15.000Z',
                '00Q6700000ti5ddEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'February 2022',
                '2022-02-10T13:29:19.000Z',
                '00Q6700000ti6TFEAY',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'February 2022',
                '2022-02-10T13:45:34.000Z',
                '00Q6700000ti6X7EAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'February 2022',
                '2022-02-10T14:18:07.000Z',
                '00Q6700000ti6dyEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'February 2022',
                '2022-02-10T14:55:43.000Z',
                '00Q6700000ti6neEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'February 2022',
                '2022-02-10T16:18:10.000Z',
                '00Q6700000ti7JiEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'February 2022',
                '2022-02-11T21:45:28.000Z',
                '00Q6700000tiBHeEAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2022',
                '2022-02-12T15:30:33.000Z',
                '00Q6700000tiCTMEA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'February 2022',
                '2022-02-12T15:39:39.000Z',
                '00Q6700000tiCUAEA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'February 2022',
                '2022-02-13T09:36:41.000Z',
                '00Q6700000tiDYDEA2',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'February 2022',
                '2022-02-13T14:34:59.000Z',
                '00Q6700000tiE2DEAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'February 2022',
                '2022-02-14T16:36:56.000Z',
                '00Q6700000tiH96EAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'February 2022',
                '2022-02-14T16:57:58.000Z',
                '00Q6700000tiHEVEA2',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'February 2022',
                '2022-02-14T17:17:47.000Z',
                '00Q6700000tiHJVEA2',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'February 2022',
                '2022-02-15T15:24:56.000Z',
                '00Q6700000tiKVREA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'February 2022',
                '2022-02-16T10:05:13.000Z',
                '00Q6700000tiMbpEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'February 2022',
                '2022-02-16T10:07:36.000Z',
                '00Q6700000tiMcdEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'February 2022',
                '2022-02-16T14:50:39.000Z',
                '00Q6700000tiNfoEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'February 2022',
                '2022-02-16T15:08:48.000Z',
                '00Q6700000tiNkeEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'February 2022',
                '2022-02-16T15:18:10.000Z',
                '00Q6700000tiNmfEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'February 2022',
                '2022-02-16T16:36:40.000Z',
                '00Q6700000tiO93EAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'February 2022',
                '2022-02-16T18:17:45.000Z',
                '00Q6700000tiOc3EAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'February 2022',
                '2022-02-16T19:41:57.000Z',
                '00Q6700000tiOqAEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'February 2022',
                '2022-02-18T10:02:00.000Z',
                '00Q6700000tiTZqEAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'February 2022',
                '2022-02-18T11:06:15.000Z',
                '00Q6700000tiTkoEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'February 2022',
                '2022-02-18T17:41:08.000Z',
                '00Q6700000tiVCdEAM',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'February 2022',
                '2022-02-18T19:53:42.000Z',
                '00Q6700000tiVQLEA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'February 2022',
                '2022-02-18T20:56:55.000Z',
                '00Q6700000tiVWOEA2',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            [
                'February 2022',
                '2022-02-19T13:29:28.000Z',
                '00Q6700000tiWd1EAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PREMIUM AUTOMOBILES - AUXERRE',
            ],
            [
                'February 2022',
                '2022-02-19T14:44:51.000Z',
                '00Q6700000tiWnGEAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PREMIUM AUTOMOBILES - BARBEREY SAINT SULPICE (TROYES)',
            ],
            [
                'February 2022',
                '2022-02-19T20:12:59.000Z',
                '00Q6700000tiXR8EAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PREMIUM AUTOMOBILES - AUXERRE',
            ],
            [
                'February 2022',
                '2022-02-20T18:42:45.000Z',
                '00Q6700000tiYyNEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2022',
                '2022-02-21T15:01:29.000Z',
                '00Q6700000tibSqEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'February 2022',
                '2022-02-21T15:30:09.000Z',
                '00Q6700000tibakEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'February 2022',
                '2022-02-21T21:41:55.000Z',
                '00Q6700000tick3EAA',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'February 2022',
                '2022-02-22T08:23:45.000Z',
                '00Q6700000tidMXEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'February 2022',
                '2022-02-22T09:15:47.000Z',
                '00Q6700000tidYEEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'February 2022',
                '2022-02-22T15:14:34.000Z',
                '00Q6700000tienMEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'February 2022',
                '2022-02-22T18:04:26.000Z',
                '00Q6700000tifRhEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'February 2022',
                '2022-02-23T10:49:39.000Z',
                '00Q6700000tihGwEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'February 2022',
                '2022-02-23T15:59:50.000Z',
                '00Q6700000tiiFkEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'February 2022',
                '2022-02-23T17:24:34.000Z',
                '00Q6700000tiifnEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'February 2022',
                '2022-02-23T18:29:47.000Z',
                '00Q6700000tiivMEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'February 2022',
                '2022-02-24T08:46:40.000Z',
                '00Q6700000tik5aEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'February 2022',
                '2022-02-24T08:52:21.000Z',
                '00Q6700000tik6OEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'February 2022',
                '2022-02-24T13:34:00.000Z',
                '00Q6700000til1tEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'February 2022',
                '2022-02-24T15:33:05.000Z',
                '00Q6700000tilQUEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'February 2022',
                '2022-02-24T16:13:28.000Z',
                '00Q6700000tilXGEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'February 2022',
                '2022-02-24T17:14:43.000Z',
                '00Q6700000tillxEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'February 2022',
                '2022-02-24T20:17:05.000Z',
                '00Q6700000tim8xEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'February 2022',
                '2022-02-25T08:37:48.000Z',
                '00Q6700000timxzEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'February 2022',
                '2022-02-25T09:40:35.000Z',
                '00Q6700000tin6hEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'February 2022',
                '2022-02-25T10:02:52.000Z',
                '00Q6700000tinAjEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'February 2022',
                '2022-02-25T10:19:11.000Z',
                '00Q6700000tinE2EAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'February 2022',
                '2022-02-26T09:10:21.000Z',
                '00Q6700000tipMhEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'February 2022',
                '2022-02-26T10:36:06.000Z',
                '00Q6700000tipXaEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'February 2022',
                '2022-02-26T17:25:07.000Z',
                '00Q6700000tiqM4EAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.P.S - BOURG EN BRESSE',
            ],
            [
                'February 2022',
                '2022-02-26T18:20:30.000Z',
                '00Q6700000tiqRsEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'February 2022',
                '2022-02-27T10:34:34.000Z',
                '00Q6700000tirDNEAY',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'February 2022',
                '2022-02-28T10:10:09.000Z',
                '00Q6700000titMuEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'February 2022',
                '2022-02-28T10:25:28.000Z',
                '00Q6700000titRWEAY',
                'Unqualified',
                'Direct',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'February 2022',
                '2022-02-28T10:42:41.000Z',
                '00Q6700000titVmEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'February 2022',
                '2022-02-28T10:57:50.000Z',
                '00Q6700000titYgEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'February 2022',
                '2022-02-28T13:42:13.000Z',
                '00Q6700000tiu0QEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'February 2022',
                '2022-02-28T15:44:09.000Z',
                '00Q6700000tiuclEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'February 2022',
                '2022-02-28T16:27:46.000Z',
                '00Q6700000tiumwEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'March 2022',
                '2022-03-01T09:23:43.000Z',
                '00Q6700000tixNHEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'March 2022',
                '2022-03-01T09:59:57.000Z',
                '00Q6700000tixV6EAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'March 2022',
                '2022-03-02T08:53:52.000Z',
                '00Q6700000tj3JUEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'March 2022',
                '2022-03-02T12:54:35.000Z',
                '00Q6700000tj4FhEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            [
                'March 2022',
                '2022-03-02T16:55:19.000Z',
                '00Q6700000vDWWXEA4',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'March 2022',
                '2022-03-03T00:44:52.000Z',
                '00Q6700000vDXgKEAW',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'March 2022',
                '2022-03-03T09:43:11.000Z',
                '00Q6700000vDYO3EAO',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'March 2022',
                '2022-03-03T09:44:46.000Z',
                '00Q6700000vDYO8EAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'March 2022',
                '2022-03-03T10:19:59.000Z',
                '00Q6700000vDYX5EAO',
                'Unqualified',
                'Direct',
                'France',
                'Multi Family Home',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'March 2022',
                '2022-03-03T17:56:39.000Z',
                '00Q6700000vDaMlEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'March 2022',
                '2022-03-03T18:03:01.000Z',
                '00Q6700000vDaO5EAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'March 2022',
                '2022-03-04T10:21:08.000Z',
                '00Q6700000vDbxcEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PRESTIGE CARS - TOULON SUR ALLIER (MOULIN)',
            ],
            [
                'March 2022',
                '2022-03-04T13:50:25.000Z',
                '00Q6700000vDcbwEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'March 2022',
                '2022-03-04T14:02:21.000Z',
                '00Q6700000vDceMEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'March 2022',
                '2022-03-04T14:35:00.000Z',
                '00Q6700000vDckjEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'March 2022',
                '2022-03-04T15:02:43.000Z',
                '00Q6700000vDcrBEAS',
                'Qualified',
                'Zeplug',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES',
            ],
            [
                'March 2022',
                '2022-03-04T17:18:12.000Z',
                '00Q6700000vDdKHEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'March 2022',
                '2022-03-04T22:01:49.000Z',
                '00Q6700000vDdqwEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'March 2022',
                '2022-03-05T09:28:52.000Z',
                '00Q6700000vDeSHEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'March 2022',
                '2022-03-05T11:41:06.000Z',
                '00Q6700000vDehHEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'March 2022',
                '2022-03-05T15:05:10.000Z',
                '00Q6700000vDf6CEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'March 2022',
                '2022-03-06T12:25:52.000Z',
                '00Q6700000vDh0vEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2022',
                '2022-03-07T09:59:30.000Z',
                '00Q6700000vDiuoEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'March 2022',
                '2022-03-07T15:13:24.000Z',
                '00Q6700000vDsJxEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'March 2022',
                '2022-03-07T16:26:36.000Z',
                '00Q670000132cyCEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'March 2022',
                '2022-03-07T19:51:01.000Z',
                '00Q6700000vDturEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'March 2022',
                '2022-03-08T07:48:49.000Z',
                '00Q6700000vDuqFEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'March 2022',
                '2022-03-08T09:06:57.000Z',
                '00Q6700000vDv5FEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'March 2022',
                '2022-03-08T10:23:10.000Z',
                '00Q6700000vDvYbEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'March 2022',
                '2022-03-08T11:31:13.000Z',
                '00Q6700000vDvpyEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2022',
                '2022-03-08T13:23:20.000Z',
                '00Q6700000vDwADEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'March 2022',
                '2022-03-08T15:42:27.000Z',
                '00Q6700000vDwp2EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'March 2022',
                '2022-03-08T16:48:34.000Z',
                '00Q6700000vDx6eEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'March 2022',
                '2022-03-08T20:41:00.000Z',
                '00Q6700000vDxoMEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'March 2022',
                '2022-03-09T08:34:05.000Z',
                '00Q6700000vDyiMEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PRESTIGE CARS - AUBIERE (CLERMONT FERRAND)',
            ],
            [
                'March 2022',
                '2022-03-09T09:45:52.000Z',
                '00Q6700000vDyy4EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'March 2022',
                '2022-03-09T11:51:49.000Z',
                '00Q6700000vDzUnEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'March 2022',
                '2022-03-09T14:43:44.000Z',
                '00Q6700000vE042EAC',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'March 2022',
                '2022-03-09T15:30:59.000Z',
                '00Q6700000vE0FtEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'March 2022',
                '2022-03-09T16:15:21.000Z',
                '00Q6700000vE0PoEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'March 2022',
                '2022-03-09T17:51:57.000Z',
                '00Q6700000vE0xoEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2022',
                '2022-03-09T18:15:42.000Z',
                '00Q6700000vE146EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'March 2022',
                '2022-03-10T09:22:18.000Z',
                '00Q6700000vE2tsEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'March 2022',
                '2022-03-10T10:48:38.000Z',
                '00Q6700000vE3FZEA0',
                'Unqualified',
                'Direct',
                'France',
                'Company',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'March 2022',
                '2022-03-10T13:25:33.000Z',
                '00Q6700000vE3kdEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2022',
                '2022-03-10T13:28:11.000Z',
                '00Q6700000vE3kxEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2022',
                '2022-03-10T13:31:36.000Z',
                '00Q6700000vE3m5EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2022',
                '2022-03-10T13:39:13.000Z',
                '00Q6700000vE3o6EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2022',
                '2022-03-10T13:41:06.000Z',
                '00Q6700000vE3oQEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2022',
                '2022-03-10T13:43:11.000Z',
                '00Q6700000vE3pJEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2022',
                '2022-03-10T13:45:16.000Z',
                '00Q6700000vE3q2EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2022',
                '2022-03-10T13:47:06.000Z',
                '00Q6700000vE3qCEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2022',
                '2022-03-10T13:49:05.000Z',
                '00Q6700000vE3m1EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2022',
                '2022-03-10T13:51:01.000Z',
                '00Q6700000vE3rZEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2022',
                '2022-03-10T13:53:48.000Z',
                '00Q6700000vE3s3EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2022',
                '2022-03-10T13:56:02.000Z',
                '00Q6700000vE3srEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2022',
                '2022-03-10T14:23:14.000Z',
                '00Q6700000vE407EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2022',
                '2022-03-10T14:23:35.000Z',
                '00Q67000010IOsUEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'March 2022',
                '2022-03-10T16:31:51.000Z',
                '00Q6700000vE4ahEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'March 2022',
                '2022-03-11T08:33:34.000Z',
                '00Q6700000vE6DVEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'March 2022',
                '2022-03-11T11:06:40.000Z',
                '00Q6700000vE6hkEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'March 2022',
                '2022-03-11T11:18:26.000Z',
                '00Q6700000vE6kKEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES',
            ],
            [
                'March 2022',
                '2022-03-11T13:33:14.000Z',
                '00Q6700000vE78REAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'March 2022',
                '2022-03-11T14:05:50.000Z',
                '00Q6700000vE7DWEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'March 2022',
                '2022-03-11T14:45:18.000Z',
                '00Q6700000vE7TeEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'March 2022',
                '2022-03-11T21:35:03.000Z',
                '00Q6700000vE8jGEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'March 2022',
                '2022-03-11T22:10:08.000Z',
                '00Q6700000vE8mKEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE JFC LISIEUX - LISIEUX',
            ],
            [
                'March 2022',
                '2022-03-12T09:48:15.000Z',
                '00Q6700000vE9VUEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2022',
                '2022-03-12T10:24:20.000Z',
                '00Q6700000vE9aZEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'March 2022',
                '2022-03-12T18:01:00.000Z',
                '00Q6700000vEAZXEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'March 2022',
                '2022-03-13T09:02:48.000Z',
                '00Q6700000vEBSlEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'March 2022',
                '2022-03-13T16:28:08.000Z',
                '00Q6700000vECCKEA4',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'March 2022',
                '2022-03-14T08:33:14.000Z',
                '00Q6700000vEDMzEAO',
                'Qualified',
                'Direct',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - LIMOGES',
            ],
            [
                'March 2022',
                '2022-03-14T10:14:41.000Z',
                '00Q6700000vEDjZEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'March 2022',
                '2022-03-14T17:12:11.000Z',
                '00Q6700000yQuaJEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'March 2022',
                '2022-03-14T17:51:55.000Z',
                '00Q6700000vEFdLEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'March 2022',
                '2022-03-14T20:31:39.000Z',
                '00Q6700000vEG2kEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'March 2022',
                '2022-03-15T09:52:01.000Z',
                '00Q6700000vEHGgEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'March 2022',
                '2022-03-15T11:48:25.000Z',
                '00Q6700000vEHgoEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2022',
                '2022-03-15T14:54:10.000Z',
                '00Q6700000vEIFiEAO',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'March 2022',
                '2022-03-15T17:02:40.000Z',
                '00Q6700000vEIlKEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2022',
                '2022-03-16T10:17:09.000Z',
                '00Q6700000vEKoiEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'March 2022',
                '2022-03-16T10:19:30.000Z',
                '00Q6700000vEKpHEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'March 2022',
                '2022-03-16T10:35:07.000Z',
                '00Q6700000vEKuKEAW',
                'Unqualified',
                'Phone',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'March 2022',
                '2022-03-16T14:35:32.000Z',
                '00Q6700000vELdhEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'March 2022',
                '2022-03-16T14:35:43.000Z',
                '00Q6700000vELdmEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE DROITE - LORMONT (BORDEAUX)',
            ],
            [
                'March 2022',
                '2022-03-16T17:04:46.000Z',
                '00Q6700000vEMD6EAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'March 2022',
                '2022-03-16T21:02:01.000Z',
                '00Q6700000vEMwVEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'March 2022',
                '2022-03-17T06:52:52.000Z',
                '00Q6700000vENeNEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'March 2022',
                '2022-03-17T09:27:45.000Z',
                '00Q6700000vEO25EAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'March 2022',
                '2022-03-17T10:52:28.000Z',
                '00Q6700000vEOL2EAO',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'March 2022',
                '2022-03-17T11:06:06.000Z',
                '00Q6700000vEON8EAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'March 2022',
                '2022-03-17T16:48:05.000Z',
                '00Q6700000vEPdAEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'March 2022',
                '2022-03-17T20:42:09.000Z',
                '00Q6700000vEQFJEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2022',
                '2022-03-18T17:00:52.000Z',
                '00Q6700000vESlOEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'March 2022',
                '2022-03-18T17:13:58.000Z',
                '00Q6700000vESoSEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'March 2022',
                '2022-03-18T17:54:51.000Z',
                '00Q6700000vESvdEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            [
                'March 2022',
                '2022-03-18T21:16:14.000Z',
                '00Q6700000vETHyEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'March 2022',
                '2022-03-19T00:01:27.000Z',
                '00Q6700000vETUnEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PRESTIGE CARS - AUBIERE (CLERMONT FERRAND)',
            ],
            [
                'March 2022',
                '2022-03-19T09:38:50.000Z',
                '00Q6700000vETyEEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'March 2022',
                '2022-03-19T10:46:22.000Z',
                '00Q6700000vEU5AEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'March 2022',
                '2022-03-19T15:39:45.000Z',
                '00Q6700000vG9UMEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'March 2022',
                '2022-03-20T06:26:14.000Z',
                '00Q6700000vEVZVEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'March 2022',
                '2022-03-20T08:00:29.000Z',
                '00Q6700000vEVcFEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'March 2022',
                '2022-03-20T11:01:51.000Z',
                '00Q6700000yQXLiEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'March 2022',
                '2022-03-20T17:40:33.000Z',
                '00Q6700000vEWVAEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2022',
                '2022-03-20T18:15:56.000Z',
                '00Q6700000vEWZHEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)',
            ],
            [
                'March 2022',
                '2022-03-20T18:20:10.000Z',
                '00Q6700000vEWZlEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'March 2022',
                '2022-03-20T21:23:05.000Z',
                '00Q6700000vEWo2EAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'March 2022',
                '2022-03-21T10:03:47.000Z',
                '00Q6700000vEXn5EAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'March 2022',
                '2022-03-21T16:50:14.000Z',
                '00Q6700000vEZKBEA4',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'March 2022',
                '2022-03-21T21:57:50.000Z',
                '00Q6700000vEabHEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'March 2022',
                '2022-03-22T09:02:51.000Z',
                '00Q6700000vEbheEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'March 2022',
                '2022-03-22T09:17:28.000Z',
                '00Q6700000vEbksEAC',
                'Unqualified',
                'Zeplug',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2022',
                '2022-03-22T09:23:59.000Z',
                '00Q6700000vEbmKEAS',
                'Unqualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'March 2022',
                '2022-03-22T10:51:11.000Z',
                '00Q6700000vEc7IEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'March 2022',
                '2022-03-22T11:07:36.000Z',
                '00Q6700000vEcAMEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'March 2022',
                '2022-03-22T11:41:37.000Z',
                '00Q6700000vEcGtEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'March 2022',
                '2022-03-22T15:02:48.000Z',
                '00Q6700000vEd8PEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'March 2022',
                '2022-03-22T15:19:22.000Z',
                '00Q6700000vEdDKEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            [
                'March 2022',
                '2022-03-22T16:12:08.000Z',
                '00Q6700000vEdQOEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'March 2022',
                '2022-03-22T16:42:51.000Z',
                '00Q6700000vEdYXEA0',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'March 2022',
                '2022-03-22T16:45:24.000Z',
                '00Q6700000vEdZLEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'March 2022',
                '2022-03-22T16:52:15.000Z',
                '00Q6700000vGY1HEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE SAINT ETIENNE - SAINT ETIENNE',
            ],
            [
                'March 2022',
                '2022-03-22T17:13:30.000Z',
                '00Q6700000vEdfdEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'March 2022',
                '2022-03-22T17:38:59.000Z',
                '00Q6700000vEdlgEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'March 2022',
                '2022-03-22T17:52:26.000Z',
                '00Q6700000vEdofEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'March 2022',
                '2022-03-22T17:57:08.000Z',
                '00Q6700000vEdpxEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'March 2022',
                '2022-03-23T09:54:47.000Z',
                '00Q6700000vEfUmEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'March 2022',
                '2022-03-23T13:15:10.000Z',
                '00Q6700000vEgCaEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo - FIRST AUTOMOBILES - AULNOY LEZ VALENCIENNES',
            ],
            [
                'March 2022',
                '2022-03-23T13:31:14.000Z',
                '00Q6700000vEgJ8EAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - SAINT MARTIN SUR LE PRE (CHALONS EN CHAMPAGNE)',
            ],
            [
                'March 2022',
                '2022-03-23T13:37:08.000Z',
                '00Q6700000vEgKQEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'March 2022',
                '2022-03-23T13:38:15.000Z',
                '00Q6700000wfgTSEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            [
                'March 2022',
                '2022-03-23T14:24:11.000Z',
                '00Q6700000vEgWvEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'March 2022',
                '2022-03-23T16:00:23.000Z',
                '00Q6700000vEgx7EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'March 2022',
                '2022-03-23T16:11:48.000Z',
                '00Q6700000vEh1sEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            [
                'March 2022',
                '2022-03-23T17:23:21.000Z',
                '00Q6700000vEhICEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)',
            ],
            [
                'March 2022',
                '2022-03-24T06:56:35.000Z',
                '00Q6700000yRQkHEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'March 2022',
                '2022-03-24T09:11:57.000Z',
                '00Q6700000vEiwmEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'March 2022',
                '2022-03-24T10:17:46.000Z',
                '00Q6700000vEjDJEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'March 2022',
                '2022-03-24T14:05:37.000Z',
                '00Q6700000vEjyVEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'March 2022',
                '2022-03-24T16:03:35.000Z',
                '00Q6700000vEkUhEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - DISTRE (SAUMUR)',
            ],
            [
                'March 2022',
                '2022-03-24T16:05:20.000Z',
                '00Q6700000vEkV1EAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - DISTRE (SAUMUR)',
            ],
            [
                'March 2022',
                '2022-03-24T16:14:52.000Z',
                '00Q6700000vEkWiEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'March 2022',
                '2022-03-24T17:00:43.000Z',
                '00Q6700000vEkiyEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'March 2022',
                '2022-03-24T19:47:52.000Z',
                '00Q6700000vElDeEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'March 2022',
                '2022-03-24T22:17:40.000Z',
                '00Q6700000vElXeEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2022',
                '2022-03-25T09:22:01.000Z',
                '00Q6700000vEmZKEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'March 2022',
                '2022-03-25T13:39:35.000Z',
                '00Q6700000vEnKSEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'March 2022',
                '2022-03-25T13:44:08.000Z',
                '00Q6700000vEnLkEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'March 2022',
                '2022-03-25T15:19:03.000Z',
                '00Q6700000vEni0EAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'March 2022',
                '2022-03-25T17:07:29.000Z',
                '00Q6700000vEo6gEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'March 2022',
                '2022-03-26T10:18:29.000Z',
                '00Q6700000vEpfTEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'March 2022',
                '2022-03-26T12:33:26.000Z',
                '00Q6700000vEpusEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'March 2022',
                '2022-03-26T14:01:32.000Z',
                '00Q6700000vEq78EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'March 2022',
                '2022-03-26T15:54:11.000Z',
                '00Q6700000vEqKMEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'March 2022',
                '2022-03-26T16:25:20.000Z',
                '00Q6700000vEqNGEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2022',
                '2022-03-26T17:11:35.000Z',
                '00Q6700000vEqS1EAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'March 2022',
                '2022-03-26T19:04:31.000Z',
                '00Q6700000vEqfUEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'March 2022',
                '2022-03-26T19:13:19.000Z',
                '00Q6700000vEqgSEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'March 2022',
                '2022-03-27T00:49:08.000Z',
                '00Q6700000vErAPEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'March 2022',
                '2022-03-27T10:17:15.000Z',
                '00Q6700000vErdREAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.P.S - BOURG EN BRESSE',
            ],
            [
                'March 2022',
                '2022-03-27T10:38:39.000Z',
                '00Q6700000vErfDEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2022',
                '2022-03-28T10:08:55.000Z',
                '00Q6700000vEu9GEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'March 2022',
                '2022-03-28T12:57:34.000Z',
                '00Q6700000vEugTEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'March 2022',
                '2022-03-28T13:16:58.000Z',
                '00Q6700000vEujmEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2022',
                '2022-03-28T20:03:56.000Z',
                '00Q6700000vEwoeEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'March 2022',
                '2022-03-29T08:41:32.000Z',
                '00Q6700000vEyRVEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2022',
                '2022-03-29T08:46:29.000Z',
                '00Q6700000vEySTEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'March 2022',
                '2022-03-29T11:26:36.000Z',
                '00Q6700000vEyyXEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'March 2022',
                '2022-03-29T12:36:30.000Z',
                '00Q6700000vEz8rEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2022',
                '2022-03-29T12:37:42.000Z',
                '00Q6700000vEz91EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'March 2022',
                '2022-03-29T13:31:47.000Z',
                '00Q6700000vEzJQEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'March 2022',
                '2022-03-29T13:48:04.000Z',
                '00Q6700000vEzMoEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'March 2022',
                '2022-03-29T14:33:05.000Z',
                '00Q6700000vEzWLEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  CAVALLARI AUTOMOBILES - NICE',
            ],
            [
                'March 2022',
                '2022-03-30T09:55:37.000Z',
                '00Q6700000vF1jKEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'March 2022',
                '2022-03-30T10:55:19.000Z',
                '00Q6700000vF1scEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'March 2022',
                '2022-03-30T16:49:15.000Z',
                '00Q6700000vF3MJEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'March 2022',
                '2022-03-31T08:25:59.000Z',
                '00Q6700000vF4auEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'March 2022',
                '2022-03-31T10:54:17.000Z',
                '00Q6700000vF5DGEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'March 2022',
                '2022-03-31T11:31:50.000Z',
                '00Q6700000vF5KbEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - CHAMPNIERS (ANGOULEME)',
            ],
            [
                'March 2022',
                '2022-03-31T12:04:55.000Z',
                '00Q6700000vF5QFEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'March 2022',
                '2022-03-31T14:19:27.000Z',
                '00Q6700000vF5tCEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'March 2022',
                '2022-03-31T16:44:58.000Z',
                '00Q6700000vF6VQEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'March 2022',
                '2022-03-31T16:47:20.000Z',
                '00Q6700000vF6VuEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'March 2022',
                '2022-03-31T17:13:22.000Z',
                '00Q6700000vF6buEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'April 2022',
                '2022-04-01T08:35:57.000Z',
                '00Q6700000vF87oEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'April 2022',
                '2022-04-01T08:41:57.000Z',
                '00Q6700000vF88wEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'April 2022',
                '2022-04-01T10:56:12.000Z',
                '00Q6700000vF93JEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'April 2022',
                '2022-04-01T11:02:58.000Z',
                '00Q6700000vF94CEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'April 2022',
                '2022-04-01T11:43:38.000Z',
                '00Q6700000vF9AKEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'April 2022',
                '2022-04-01T12:54:08.000Z',
                '00Q6700000vF9PIEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'April 2022',
                '2022-04-01T13:46:22.000Z',
                '00Q6700000vF9XFEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'April 2022',
                '2022-04-01T16:00:17.000Z',
                '00Q6700000vF9s8EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  A.P.S - BOURG EN BRESSE',
            ],
            [
                'April 2022',
                '2022-04-01T18:10:47.000Z',
                '00Q6700000vFABFEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'April 2022',
                '2022-04-02T07:57:55.000Z',
                '00Q6700000vFAunEAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'April 2022',
                '2022-04-02T14:56:19.000Z',
                '00Q67000010ItsKEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'April 2022',
                '2022-04-02T15:56:33.000Z',
                '00Q6700000vFC3gEAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'April 2022',
                '2022-04-02T21:48:02.000Z',
                '00Q6700000vFCV1EAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'April 2022',
                '2022-04-03T11:28:46.000Z',
                '00Q6700000vFD6hEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo - FIRST AUTOMOBILES - AULNOY LEZ VALENCIENNES',
            ],
            [
                'April 2022',
                '2022-04-04T08:10:06.000Z',
                '00Q6700000vFEU0EAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'April 2022',
                '2022-04-04T09:37:53.000Z',
                '00Q6700000vFEkmEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'April 2022',
                '2022-04-04T15:09:15.000Z',
                '00Q6700000vFFtLEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'April 2022',
                '2022-04-04T15:27:33.000Z',
                '00Q6700000vFFyuEAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PREMIUM AUTOMOBILES - BARBEREY SAINT SULPICE (TROYES)',
            ],
            [
                'April 2022',
                '2022-04-04T15:47:12.000Z',
                '00Q6700000vFG2cEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'April 2022',
                '2022-04-04T17:15:11.000Z',
                '00Q6700000vFGPBEA4',
                'Unqualified',
                'Website',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'April 2022',
                '2022-04-05T08:12:26.000Z',
                '00Q6700000vFHVSEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)',
            ],
            [
                'April 2022',
                '2022-04-05T08:38:00.000Z',
                '00Q6700000vFHZ5EAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                "Volvo -  CAR'S SERVICES - ONET LE CHATEAU (RODEZ)",
            ],
            [
                'April 2022',
                '2022-04-05T14:39:33.000Z',
                '00Q6700000vFIaFEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'April 2022',
                '2022-04-05T15:37:27.000Z',
                '00Q6700000vFIl8EAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'April 2022',
                '2022-04-05T17:33:53.000Z',
                '00Q6700000vFJGjEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'April 2022',
                '2022-04-06T12:34:24.000Z',
                '00Q6700000vFMwcEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'April 2022',
                '2022-04-06T15:55:24.000Z',
                '00Q6700000vFNj7EAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - DISTRE (SAUMUR)',
            ],
            [
                'April 2022',
                '2022-04-06T16:45:51.000Z',
                '00Q6700000vFOHWEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'April 2022',
                '2022-04-06T17:04:17.000Z',
                '00Q6700000vFOK6EAO',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'April 2022',
                '2022-04-07T08:41:22.000Z',
                '00Q6700000vFPZ6EAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'April 2022',
                '2022-04-07T08:49:02.000Z',
                '00Q6700000vFPb2EAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'April 2022',
                '2022-04-07T08:56:11.000Z',
                '00Q6700000vFPcKEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'April 2022',
                '2022-04-07T09:39:41.000Z',
                '00Q6700000vFPmWEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'April 2022',
                '2022-04-07T12:22:59.000Z',
                '00Q6700000vFQFMEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'April 2022',
                '2022-04-07T13:41:52.000Z',
                '00Q6700000vFQSfEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PERICAUD AUTOMOBILES - CHAMPNIERS (ANGOULEME)',
            ],
            [
                'April 2022',
                '2022-04-07T13:44:19.000Z',
                '00Q6700000vFQTYEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)',
            ],
            [
                'April 2022',
                '2022-04-07T15:23:33.000Z',
                '00Q6700000vFQogEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'April 2022',
                '2022-04-07T16:01:13.000Z',
                '00Q6700000vFQwfEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'April 2022',
                '2022-04-07T16:26:34.000Z',
                '00Q6700000vFR1BEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'April 2022',
                '2022-04-07T16:36:17.000Z',
                '00Q6700000vFR2xEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'April 2022',
                '2022-04-07T17:01:23.000Z',
                '00Q6700000vFR74EAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'April 2022',
                '2022-04-07T18:46:29.000Z',
                '00Q6700000vFRMEEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'April 2022',
                '2022-04-08T07:23:08.000Z',
                '00Q6700000vFSBGEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'April 2022',
                '2022-04-08T17:18:16.000Z',
                '00Q6700000vFTtmEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'April 2022',
                '2022-04-09T07:37:38.000Z',
                '00Q6700000vFV7NEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'April 2022',
                '2022-04-09T13:04:12.000Z',
                '00Q6700000vFVZREA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'April 2022',
                '2022-04-09T13:05:51.000Z',
                '00Q6700000vFVZgEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'April 2022',
                '2022-04-09T13:31:00.000Z',
                '00Q6700000vFVcBEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'April 2022',
                '2022-04-09T15:02:38.000Z',
                '00Q6700000vFVlcEAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'April 2022',
                '2022-04-11T08:28:09.000Z',
                '00Q6700000vFYjWEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'April 2022',
                '2022-04-11T12:41:02.000Z',
                '00Q6700000vFZWEEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'April 2022',
                '2022-04-11T13:11:22.000Z',
                '00Q6700000vFZbYEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'April 2022',
                '2022-04-11T14:08:12.000Z',
                '00Q6700000vFZohEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'April 2022',
                '2022-04-11T14:37:13.000Z',
                '00Q6700000vFZwhEAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'April 2022',
                '2022-04-11T18:08:59.000Z',
                '00Q6700000vFaboEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'April 2022',
                '2022-04-12T12:22:11.000Z',
                '00Q6700000vFcRfEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'April 2022',
                '2022-04-12T13:23:26.000Z',
                '00Q6700000vFcamEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  CAR'S SERVICES - ONET LE CHATEAU (RODEZ)",
            ],
            [
                'April 2022',
                '2022-04-12T14:55:17.000Z',
                '00Q6700000vFdFIEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            [
                'April 2022',
                '2022-04-12T16:14:37.000Z',
                '00Q6700000vFdSgEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'April 2022',
                '2022-04-13T08:43:23.000Z',
                '00Q6700000vFf5tEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'April 2022',
                '2022-04-13T11:57:13.000Z',
                '00Q6700000vFfkHEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'April 2022',
                '2022-04-13T16:23:09.000Z',
                '00Q6700000vFgZ5EAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'April 2022',
                '2022-04-13T16:32:00.000Z',
                '00Q6700000vFga8EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'April 2022',
                '2022-04-14T07:09:35.000Z',
                '00Q6700000vFhaxEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'April 2022',
                '2022-04-14T07:11:47.000Z',
                '00Q6700000vFhb2EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'April 2022',
                '2022-04-14T08:35:39.000Z',
                '00Q6700000vFhksEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'April 2022',
                '2022-04-14T08:59:13.000Z',
                '00Q6700000vFhopEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'April 2022',
                '2022-04-14T09:12:08.000Z',
                '00Q6700000vFhqqEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'April 2022',
                '2022-04-14T12:10:14.000Z',
                '00Q6700000vFiILEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  AMPLITUDE AUTOMOBILES - SAINTES',
            ],
            [
                'April 2022',
                '2022-04-14T13:43:29.000Z',
                '00Q6700000vFiVjEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'April 2022',
                '2022-04-14T15:00:06.000Z',
                '00Q6700000vFiiJEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'April 2022',
                '2022-04-14T15:12:28.000Z',
                '00Q6700000vFikFEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'April 2022',
                '2022-04-14T22:19:23.000Z',
                '00Q6700000vFjZIEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'April 2022',
                '2022-04-15T09:25:52.000Z',
                '00Q6700000vFkFUEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'April 2022',
                '2022-04-15T12:35:05.000Z',
                '00Q6700000vFkdkEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'April 2022',
                '2022-04-15T13:00:50.000Z',
                '00Q6700000vFkgPEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'April 2022',
                '2022-04-15T13:10:35.000Z',
                '00Q6700000vFkhIEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'April 2022',
                '2022-04-15T15:08:45.000Z',
                '00Q6700000vFkxkEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'April 2022',
                '2022-04-15T16:13:36.000Z',
                '00Q6700000vFl88EAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'April 2022',
                '2022-04-16T07:01:48.000Z',
                '00Q6700000vFm9CEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'April 2022',
                '2022-04-16T09:22:32.000Z',
                '00Q6700000vFmJbEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'April 2022',
                '2022-04-16T09:26:37.000Z',
                '00Q6700000vFmJgEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'April 2022',
                '2022-04-16T09:50:39.000Z',
                '00Q6700000vFmLmEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'April 2022',
                '2022-04-16T12:35:46.000Z',
                '00Q6700000vFmZFEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'April 2022',
                '2022-04-16T13:45:28.000Z',
                '00Q6700000vFmdqEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'April 2022',
                '2022-04-17T13:48:25.000Z',
                '00Q6700000wds7QEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - BORGO',
            ],
            [
                'April 2022',
                '2022-04-18T13:42:15.000Z',
                '00Q6700000vFpGDEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - SAINTES',
            ],
            [
                'April 2022',
                '2022-04-18T18:59:10.000Z',
                '00Q6700000vFpsUEAS',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - NEUILLY SUR SEINE',
            ],
            [
                'April 2022',
                '2022-04-18T19:51:59.000Z',
                '00Q6700000vFpz6EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VILLEFRANCHE-SUR-SAÔNE',
            ],
            [
                'April 2022',
                '2022-04-19T08:27:55.000Z',
                '00Q6700000vFqxnEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'April 2022',
                '2022-04-19T09:55:10.000Z',
                '00Q6700000vFrE0EAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'April 2022',
                '2022-04-19T15:08:49.000Z',
                '00Q6700000vFs5cEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'April 2022',
                '2022-04-19T15:10:51.000Z',
                '00Q6700000vFs61EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'April 2022',
                '2022-04-19T15:14:14.000Z',
                '00Q6700000vFs6fEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'April 2022',
                '2022-04-19T15:18:07.000Z',
                '00Q6700000vFs7nEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'April 2022',
                '2022-04-19T15:22:15.000Z',
                '00Q6700000vFs8REAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'April 2022',
                '2022-04-19T15:25:17.000Z',
                '00Q6700000vFs8vEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'April 2022',
                '2022-04-19T15:27:38.000Z',
                '00Q6700000vFs9AEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'April 2022',
                '2022-04-19T15:30:29.000Z',
                '00Q6700000vFs9oEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'April 2022',
                '2022-04-19T15:33:08.000Z',
                '00Q6700000vFsAIEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'April 2022',
                '2022-04-19T15:42:15.000Z',
                '00Q6700000vFsC9EAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'April 2022',
                '2022-04-19T15:43:58.000Z',
                '00Q6700000vFsCOEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'April 2022',
                '2022-04-19T15:49:23.000Z',
                '00Q6700000zdXPDEA2',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'April 2022',
                '2022-04-19T16:01:52.000Z',
                '00Q6700000vFsGpEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'April 2022',
                '2022-04-19T16:42:48.000Z',
                '00Q6700000vFsNlEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'April 2022',
                '2022-04-19T19:56:38.000Z',
                '00Q6700000vFsuzEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'April 2022',
                '2022-04-20T06:12:30.000Z',
                '00Q6700000vFthQEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PRESTIGE CARS - AUBIERE (CLERMONT FERRAND)',
            ],
            [
                'April 2022',
                '2022-04-20T12:55:55.000Z',
                '00Q6700000vFuiNEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)',
            ],
            [
                'April 2022',
                '2022-04-20T18:44:15.000Z',
                '00Q6700000vFwUwEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'April 2022',
                '2022-04-21T09:46:30.000Z',
                '00Q6700000vFy58EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'April 2022',
                '2022-04-21T21:39:14.000Z',
                '00Q6700000vG06gEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'April 2022',
                '2022-04-22T04:31:43.000Z',
                '00Q6700000vG0bnEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'April 2022',
                '2022-04-22T04:48:48.000Z',
                '00Q6700000vG0d5EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'April 2022',
                '2022-04-22T09:56:02.000Z',
                '00Q6700000vG1FGEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'April 2022',
                '2022-04-22T11:24:25.000Z',
                '00Q6700000vG1TLEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'April 2022',
                '2022-04-22T12:09:31.000Z',
                '00Q6700000vG1ZsEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'April 2022',
                '2022-04-22T12:21:26.000Z',
                '00Q6700000vG1bKEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'April 2022',
                '2022-04-22T13:21:56.000Z',
                '00Q6700000vG1qUEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'April 2022',
                '2022-04-22T13:51:29.000Z',
                '00Q6700000vG1wXEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'April 2022',
                '2022-04-22T14:55:41.000Z',
                '00Q6700000vG26EEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'April 2022',
                '2022-04-22T15:41:59.000Z',
                '00Q6700000vG2GNEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'April 2022',
                '2022-04-22T16:33:02.000Z',
                '00Q6700000vG2RLEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'April 2022',
                '2022-04-23T08:29:05.000Z',
                '00Q6700000vG3bMEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'April 2022',
                '2022-04-23T12:37:57.000Z',
                '00Q6700000vG402EAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'April 2022',
                '2022-04-23T14:14:12.000Z',
                '00Q6700000vG4AHEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'April 2022',
                '2022-04-23T15:19:10.000Z',
                '00Q6700000vG4IVEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'April 2022',
                '2022-04-23T15:28:01.000Z',
                '00Q6700000vG4JdEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'April 2022',
                '2022-04-23T15:35:02.000Z',
                '00Q6700000vG4KREA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'April 2022',
                '2022-04-23T15:36:21.000Z',
                '00Q6700000vG4KWEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'April 2022',
                '2022-04-23T17:50:19.000Z',
                '00Q6700000vG4XVEA0',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'April 2022',
                '2022-04-24T13:05:43.000Z',
                '00Q6700000vG5gFEAS',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'April 2022',
                '2022-04-25T08:57:45.000Z',
                '00Q6700000vG747EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'April 2022',
                '2022-04-25T12:38:22.000Z',
                '00Q6700000vG7PyEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'April 2022',
                '2022-04-25T14:03:43.000Z',
                '00Q6700000vG7baEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT CHARTRES - NOGENT LE PHAYE (CHARTRES)',
            ],
            [
                'April 2022',
                '2022-04-25T14:08:48.000Z',
                '00Q6700000vG7c4EAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'April 2022',
                '2022-04-25T14:11:14.000Z',
                '00Q6700000vG7cTEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT CHARTRES - NOGENT LE PHAYE (CHARTRES)',
            ],
            [
                'April 2022',
                '2022-04-25T15:22:04.000Z',
                '00Q6700000vG7lfEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PREMIUM AUTOMOBILES - BARBEREY SAINT SULPICE (TROYES)',
            ],
            [
                'April 2022',
                '2022-04-25T22:20:37.000Z',
                '00Q6700000vG8cAEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'April 2022',
                '2022-04-26T07:53:06.000Z',
                '00Q6700000vG9HwEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'April 2022',
                '2022-04-26T08:16:24.000Z',
                '00Q6700000vG9MrEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'April 2022',
                '2022-04-26T08:49:13.000Z',
                '00Q6700000vG9XkEAK',
                'Qualified',
                'Email',
                'France',
                'Single Home',
                'ABVV Chantilly',
            ],
            [
                'April 2022',
                '2022-04-26T13:20:13.000Z',
                '00Q6700000vGALWEA4',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'LARSON by Autosphere',
            ],
            [
                'April 2022',
                '2022-04-26T14:51:50.000Z',
                '00Q6700000vGAdIEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'April 2022',
                '2022-04-26T17:15:36.000Z',
                '00Q6700000vGB3uEAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'April 2022',
                '2022-04-26T18:42:02.000Z',
                '00Q6700000vGBDgEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'April 2022',
                '2022-04-27T06:55:26.000Z',
                '00Q6700000vGC96EAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'April 2022',
                '2022-04-27T08:55:55.000Z',
                '00Q6700000vGCPdEAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE EST AUTOS (11ème) - PARIS',
            ],
            [
                'April 2022',
                '2022-04-27T09:27:58.000Z',
                '00Q6700000vGCWFEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'April 2022',
                '2022-04-27T17:57:47.000Z',
                '00Q6700000vGETMEA4',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'April 2022',
                '2022-04-27T19:51:30.000Z',
                '00Q6700000vGEiLEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'April 2022',
                '2022-04-28T07:38:30.000Z',
                '00Q6700000vGFY2EAO',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'April 2022',
                '2022-04-28T09:36:08.000Z',
                '00Q6700000vGGFFEA4',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'April 2022',
                '2022-04-28T10:49:48.000Z',
                '00Q6700000vGGWDEA4',
                'Qualified',
                'Email',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'April 2022',
                '2022-04-28T10:51:49.000Z',
                '00Q6700000vGGWEEA4',
                'Unqualified',
                'Email',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'April 2022',
                '2022-04-28T10:55:35.000Z',
                '00Q6700000vGGXuEAO',
                'Qualified',
                'Email',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'April 2022',
                '2022-04-28T10:56:59.000Z',
                '00Q6700000vGGYEEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'April 2022',
                '2022-04-28T13:46:39.000Z',
                '00Q6700000vGH09EAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'April 2022',
                '2022-04-28T15:57:39.000Z',
                '00Q6700000vGHOWEA4',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'April 2022',
                '2022-04-28T16:59:47.000Z',
                '00Q6700000vGHXcEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'April 2022',
                '2022-04-29T09:22:10.000Z',
                '00Q6700000vGIyOEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - SAINT MARTIN SUR LE PRE (CHALONS EN CHAMPAGNE)',
            ],
            [
                'April 2022',
                '2022-04-29T13:10:49.000Z',
                '00Q6700000vGJmkEAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PREMIUM AUTOMOBILES - BARBEREY SAINT SULPICE (TROYES)',
            ],
            [
                'April 2022',
                '2022-04-30T13:12:56.000Z',
                '00Q6700000vGM4NEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'May 2022',
                '2022-05-01T20:52:32.000Z',
                '00Q6700000vGOKwEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-03T09:26:47.000Z',
                '00Q6700000vGSRyEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'May 2022',
                '2022-05-03T10:49:14.000Z',
                '00Q6700000vGShjEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'May 2022',
                '2022-05-03T14:19:29.000Z',
                '00Q6700000vGTNkEAO',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'May 2022',
                '2022-05-03T14:20:45.000Z',
                '00Q6700000vGTNzEAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'May 2022',
                '2022-05-04T08:53:51.000Z',
                '00Q6700000vGVphEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'May 2022',
                '2022-05-04T10:45:01.000Z',
                '00Q6700000vGWBiEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'ABVV Chantilly',
            ],
            [
                'May 2022',
                '2022-05-04T13:52:16.000Z',
                '00Q6700000vGWpJEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'May 2022',
                '2022-05-04T21:22:27.000Z',
                '00Q6700000vGY0nEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'May 2022',
                '2022-05-05T08:48:45.000Z',
                '00Q6700000vGYwbEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'May 2022',
                '2022-05-05T09:05:13.000Z',
                '00Q6700000vGYzzEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'May 2022',
                '2022-05-05T10:42:04.000Z',
                '00Q6700000vGZIwEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'May 2022',
                '2022-05-05T17:48:12.000Z',
                '00Q6700000vGaekEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AUTO PREMIUM 08 - CHARLEVILLE MEZIERES',
            ],
            [
                'May 2022',
                '2022-05-05T18:12:59.000Z',
                '00Q6700000vGahyEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'May 2022',
                '2022-05-06T06:58:08.000Z',
                '00Q6700000vGbleEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'May 2022',
                '2022-05-06T09:17:19.000Z',
                '00Q6700000vGc63EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'May 2022',
                '2022-05-06T10:24:47.000Z',
                '00Q6700000vGcKAEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'May 2022',
                '2022-05-06T12:11:50.000Z',
                '00Q6700000vGcbpEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'May 2022',
                '2022-05-06T12:33:06.000Z',
                '00Q6700000vGcfwEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'May 2022',
                '2022-05-06T15:19:57.000Z',
                '00Q6700000vGdAPEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-06T15:33:44.000Z',
                '00Q6700000vGdD9EAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'May 2022',
                '2022-05-06T15:54:12.000Z',
                '00Q6700000vGdGcEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'May 2022',
                '2022-05-06T16:08:42.000Z',
                '00Q6700000vGdK5EAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'May 2022',
                '2022-05-06T20:06:40.000Z',
                '00Q6700000vGdl1EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)',
            ],
            [
                'May 2022',
                '2022-05-07T06:12:47.000Z',
                '00Q6700000vGeFLEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-07T08:31:10.000Z',
                '00Q6700000vGeOSEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'May 2022',
                '2022-05-07T15:26:00.000Z',
                '00Q6700000vGf7IEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'May 2022',
                '2022-05-08T06:04:47.000Z',
                '00Q6700000vGg58EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-08T15:03:37.000Z',
                '00Q6700000vGgbEEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'May 2022',
                '2022-05-09T07:35:55.000Z',
                '00Q6700000vGhh7EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'May 2022',
                '2022-05-09T08:09:39.000Z',
                '00Q6700000vGhn5EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'May 2022',
                '2022-05-09T13:26:00.000Z',
                '00Q6700000vGiuoEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'May 2022',
                '2022-05-09T14:57:03.000Z',
                '00Q6700000wds7zEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'May 2022',
                '2022-05-09T15:43:30.000Z',
                '00Q6700000vGjMsEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PREMIUM AUTOMOBILES - BARBEREY SAINT SULPICE (TROYES)',
            ],
            [
                'May 2022',
                '2022-05-09T19:47:01.000Z',
                '00Q6700000vGkVWEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'May 2022',
                '2022-05-10T07:13:29.000Z',
                '00Q6700000vGlQFEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'May 2022',
                '2022-05-10T13:30:03.000Z',
                '00Q6700000vGmlIEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'May 2022',
                '2022-05-10T14:56:09.000Z',
                '00Q6700000vGn9tEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'May 2022',
                '2022-05-11T07:10:22.000Z',
                '00Q6700000vGpJKEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'May 2022',
                '2022-05-11T08:15:11.000Z',
                '00Q6700000vGpZDEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'May 2022',
                '2022-05-11T12:48:07.000Z',
                '00Q6700000vGqhlEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'May 2022',
                '2022-05-11T15:23:26.000Z',
                '00Q6700000vGrIEEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'May 2022',
                '2022-05-11T15:47:18.000Z',
                '00Q6700000vGrPAEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE DROITE - LORMONT (BORDEAUX)',
            ],
            [
                'May 2022',
                '2022-05-12T07:21:39.000Z',
                '00Q6700000vGtBwEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'May 2022',
                '2022-05-12T09:42:53.000Z',
                '00Q6700000vGthBEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'May 2022',
                '2022-05-12T09:55:35.000Z',
                '00Q6700000vGtjlEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'May 2022',
                '2022-05-12T14:59:04.000Z',
                '00Q6700000vGurEEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'May 2022',
                '2022-05-12T17:20:11.000Z',
                '00Q6700000vGvHqEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'May 2022',
                '2022-05-13T10:39:07.000Z',
                '00Q6700000vGy2PEAS',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'May 2022',
                '2022-05-13T13:04:09.000Z',
                '00Q6700000vGyaqEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE JFC LISIEUX - LISIEUX',
            ],
            [
                'May 2022',
                '2022-05-13T13:58:54.000Z',
                '00Q6700000vGzJLEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'May 2022',
                '2022-05-13T16:22:27.000Z',
                '00Q6700000vGznGEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'May 2022',
                '2022-05-14T09:19:30.000Z',
                '00Q6700000vH1TgEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'May 2022',
                '2022-05-14T12:37:07.000Z',
                '00Q6700000vH1s2EAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'May 2022',
                '2022-05-14T13:03:30.000Z',
                '00Q6700000vH1uXEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'May 2022',
                '2022-05-14T22:42:34.000Z',
                '00Q6700000vH2scEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'May 2022',
                '2022-05-16T09:52:06.000Z',
                '00Q6700000vH6hvEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'May 2022',
                '2022-05-16T15:55:06.000Z',
                '00Q6700000vH92gEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'May 2022',
                '2022-05-17T00:31:50.000Z',
                '00Q6700000vHADuEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-17T09:21:12.000Z',
                '00Q6700000vHBhyEAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'May 2022',
                '2022-05-17T10:59:10.000Z',
                '00Q6700000vHC5gEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-17T11:16:30.000Z',
                '00Q6700000vHC8uEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-17T12:45:38.000Z',
                '00Q6700000vHCRKEA4',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'May 2022',
                '2022-05-17T13:32:56.000Z',
                '00Q6700000vHCaTEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-17T13:34:45.000Z',
                '00Q6700000vHCaxEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
            ],
            [
                'May 2022',
                '2022-05-17T14:45:39.000Z',
                '00Q6700000vHCt8EAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'May 2022',
                '2022-05-17T16:03:53.000Z',
                '00Q6700000vHDKyEAO',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'May 2022',
                '2022-05-17T17:46:30.000Z',
                '00Q6700000vHDnQEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'May 2022',
                '2022-05-17T20:14:44.000Z',
                '00Q6700000vHE8KEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'May 2022',
                '2022-05-17T22:20:32.000Z',
                '00Q6700000vHEL9EAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CAVALLARI AUTOMOBILES - NICE',
            ],
            [
                'May 2022',
                '2022-05-18T07:28:44.000Z',
                '00Q6700000vHF1oEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'May 2022',
                '2022-05-18T07:41:01.000Z',
                '00Q6700000vHF3fEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'May 2022',
                '2022-05-18T09:41:16.000Z',
                '00Q6700000vHFRmEAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'May 2022',
                '2022-05-18T12:18:45.000Z',
                '00Q6700000vHFx1EAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PRESTIGE CARS - AUBIERE (CLERMONT FERRAND)',
            ],
            [
                'May 2022',
                '2022-05-18T12:27:24.000Z',
                '00Q6700000vHFyiEAG',
                'Qualified',
                'Direct',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'May 2022',
                '2022-05-18T12:59:01.000Z',
                '00Q6700000vHG34EAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-18T14:13:38.000Z',
                '00Q6700000vHGIOEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-18T14:19:08.000Z',
                '00Q6700000vHGJbEAO',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'May 2022',
                '2022-05-18T15:54:59.000Z',
                '00Q6700000vHHkzEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'May 2022',
                '2022-05-18T17:08:43.000Z',
                '00Q6700000vHI2TEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PRESTIGE CARS - AUBIERE (CLERMONT FERRAND)',
            ],
            [
                'May 2022',
                '2022-05-19T14:51:12.000Z',
                '00Q6700000vHLt9EAG',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'May 2022',
                '2022-05-19T15:35:39.000Z',
                '00Q6700000vHM2aEAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'May 2022',
                '2022-05-20T07:59:05.000Z',
                '00Q6700000vHNjxEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'May 2022',
                '2022-05-20T09:12:25.000Z',
                '00Q6700000vHO1MEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'May 2022',
                '2022-05-20T09:31:00.000Z',
                '00Q6700000vHO4pEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'May 2022',
                '2022-05-20T09:38:49.000Z',
                '00Q6700000vHO6HEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'May 2022',
                '2022-05-20T13:05:02.000Z',
                '00Q6700000vHOiBEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'May 2022',
                '2022-05-20T13:35:52.000Z',
                '00Q6700000vHOnaEAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-20T13:56:16.000Z',
                '00Q6700000vHOrrEAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'May 2022',
                '2022-05-20T17:10:56.000Z',
                '00Q6700000vHPPyEAO',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'May 2022',
                '2022-05-20T22:13:00.000Z',
                '00Q6700000vHPs7EAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Evreux - NORMANVILLE (EVREUX)',
            ],
            [
                'May 2022',
                '2022-05-21T07:39:12.000Z',
                '00Q6700000vHQQOEA4',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'May 2022',
                '2022-05-24T09:06:10.000Z',
                '00Q6700000vHZ6UEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'May 2022',
                '2022-05-24T13:47:47.000Z',
                '00Q6700000vHaINEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)',
            ],
            [
                'May 2022',
                '2022-05-25T04:57:06.000Z',
                '00Q6700000vHcBpEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'May 2022',
                '2022-05-25T09:55:12.000Z',
                '00Q6700000vHd4DEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'May 2022',
                '2022-05-25T13:54:48.000Z',
                '00Q6700000vHe1zEAC',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'May 2022',
                '2022-05-25T14:44:33.000Z',
                '00Q6700000vHeEjEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'May 2022',
                '2022-05-25T15:35:41.000Z',
                '00Q6700000vHeToEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-25T15:41:23.000Z',
                '00Q6700000vHeWEEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-25T15:46:57.000Z',
                '00Q6700000zdXT0EAM',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-25T15:57:12.000Z',
                '00Q6700000vHea6EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T06:34:18.000Z',
                '00Q6700000wddzwEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'May 2022',
                '2022-05-27T06:45:43.000Z',
                '00Q6700000wde0fEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T07:13:56.000Z',
                '00Q6700000wde8sEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T07:21:03.000Z',
                '00Q6700000wdeAFEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T07:25:59.000Z',
                '00Q6700000wdeBDEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T07:30:17.000Z',
                '00Q6700000wdeBcEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T07:39:29.000Z',
                '00Q6700000wdeDEEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T07:43:22.000Z',
                '00Q6700000wdeDiEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T07:46:36.000Z',
                '00Q67000010Jc88EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T07:51:38.000Z',
                '00Q6700000zdXMOEA2',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T07:56:26.000Z',
                '00Q6700000wdeFZEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T08:11:27.000Z',
                '00Q6700000wdeJHEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T08:28:43.000Z',
                '00Q6700000wdeMGEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T08:39:08.000Z',
                '00Q6700000wdeOREAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T08:45:59.000Z',
                '00Q6700000wdeQDEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T08:50:40.000Z',
                '00Q6700000wdeRQEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T08:57:21.000Z',
                '00Q6700000wdeU0EAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T09:04:06.000Z',
                '00Q6700000wdeVDEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T09:12:41.000Z',
                '00Q6700000wdeWVEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T09:41:26.000Z',
                '00Q6700000wdei7EAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T09:47:31.000Z',
                '00Q6700000wdeiqEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T09:54:18.000Z',
                '00Q6700000wdejZEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T12:03:54.000Z',
                '00Q6700000wdfD3EAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T12:08:36.000Z',
                '00Q6700000wdfDwEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T12:15:19.000Z',
                '00Q6700000wdfFsEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T12:22:49.000Z',
                '00Q6700000zdiYiEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T12:26:02.000Z',
                '00Q6700000wdfHKEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T12:35:33.000Z',
                '00Q6700000wdfJVEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T12:39:36.000Z',
                '00Q6700000wdfKOEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'May 2022',
                '2022-05-27T12:45:26.000Z',
                '00Q6700000wdfLHEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'May 2022',
                '2022-05-27T14:09:15.000Z',
                '00Q6700000wdfaCEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE DROITE - LORMONT (BORDEAUX)',
            ],
            [
                'May 2022',
                '2022-05-28T09:48:37.000Z',
                '00Q6700000wdhkhEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'May 2022',
                '2022-05-28T14:04:08.000Z',
                '00Q6700000wdiAkEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'May 2022',
                '2022-05-30T08:27:28.000Z',
                '00Q6700000wdl7eEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'May 2022',
                '2022-05-30T08:29:55.000Z',
                '00Q6700000wdl7oEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'May 2022',
                '2022-05-30T09:19:39.000Z',
                '00Q6700000wdlJVEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'May 2022',
                '2022-05-30T12:51:52.000Z',
                '00Q6700000wdlwSEAQ',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'May 2022',
                '2022-05-30T14:13:44.000Z',
                '00Q6700000wdmCVEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'May 2022',
                '2022-05-30T14:43:11.000Z',
                '00Q6700000wdmKxEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'May 2022',
                '2022-05-30T15:18:18.000Z',
                '00Q6700000wdmShEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-30T15:27:37.000Z',
                '00Q6700000wdmUOEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'May 2022',
                '2022-05-30T15:30:50.000Z',
                '00Q6700000wdmUiEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'May 2022',
                '2022-05-30T15:34:37.000Z',
                '00Q6700000wdmVWEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'May 2022',
                '2022-05-30T15:42:24.000Z',
                '00Q6700000wdmWZEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'May 2022',
                '2022-05-30T15:45:11.000Z',
                '00Q6700000wdmX3EAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'May 2022',
                '2022-05-30T15:47:31.000Z',
                '00Q6700000wdmXwEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'May 2022',
                '2022-05-30T15:50:02.000Z',
                '00Q6700000wdmYQEAY',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'May 2022',
                '2022-05-30T15:53:15.000Z',
                '00Q6700000wdmZJEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'May 2022',
                '2022-05-30T20:26:39.000Z',
                '00Q6700000wdn8TEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-31T07:47:18.000Z',
                '00Q6700000wdnoPEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2022',
                '2022-05-31T08:50:28.000Z',
                '00Q6700000wdnyPEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'May 2022',
                '2022-05-31T13:46:20.000Z',
                '00Q6700000wdoxNEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'May 2022',
                '2022-05-31T14:19:15.000Z',
                '00Q6700000wdp4JEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'May 2022',
                '2022-05-31T14:38:33.000Z',
                '00Q6700000wdp9EEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CYRNEA NORTH CARS - BORGO',
            ],
            [
                'May 2022',
                '2022-05-31T15:12:32.000Z',
                '00Q6700000wdpMQEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'LARSON by Autosphere',
            ],
            [
                'May 2022',
                '2022-05-31T16:27:54.000Z',
                '00Q6700000wdpY8EAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'May 2022',
                '2022-05-31T18:29:28.000Z',
                '00Q6700000wdpzsEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'May 2022',
                '2022-05-31T20:39:24.000Z',
                '00Q6700000wdqEEEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)',
            ],
            [
                'June 2022',
                '2022-06-01T06:59:34.000Z',
                '00Q6700000wdqqaEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
            ],
            [
                'June 2022',
                '2022-06-01T08:24:48.000Z',
                '00Q6700000wdr6qEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T08:35:36.000Z',
                '00Q6700000wdr83EAA',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T08:38:16.000Z',
                '00Q6700000wdr8hEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T08:45:03.000Z',
                '00Q6700000wdr9zEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T08:47:56.000Z',
                '00Q6700000wdrAJEAY',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T08:50:51.000Z',
                '00Q6700000wdrAiEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T08:53:31.000Z',
                '00Q6700000wdrB7EAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T08:56:11.000Z',
                '00Q6700000wdrBbEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T08:59:06.000Z',
                '00Q6700000wdrC5EAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T09:02:41.000Z',
                '00Q6700000wdrD8EAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T09:12:58.000Z',
                '00Q6700000wdrFYEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - BORGO',
            ],
            [
                'June 2022',
                '2022-06-01T09:16:29.000Z',
                '00Q6700000wdrGbEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T09:19:28.000Z',
                '00Q6700000wdrHKEAY',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T09:21:19.000Z',
                '00Q6700000wdrISEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - BORGO',
            ],
            [
                'June 2022',
                '2022-06-01T09:22:28.000Z',
                '00Q6700000wdrIXEAY',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T09:25:13.000Z',
                '00Q6700000wdrJBEAY',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T09:25:30.000Z',
                '00Q6700000wdrJLEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CYRNEA NORTH CARS - BORGO',
            ],
            [
                'June 2022',
                '2022-06-01T09:32:50.000Z',
                '00Q6700000wdrKeEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T09:36:49.000Z',
                '00Q6700000wdrM0EAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T09:39:00.000Z',
                '00Q6700000wdrMZEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - BORGO',
            ],
            [
                'June 2022',
                '2022-06-01T09:40:33.000Z',
                '00Q6700000wdrMtEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T09:41:59.000Z',
                '00Q6700000wdrNDEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - BORGO',
            ],
            [
                'June 2022',
                '2022-06-01T09:43:32.000Z',
                '00Q6700000wdrNXEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T09:44:55.000Z',
                '00Q6700000wdrNmEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - BORGO',
            ],
            [
                'June 2022',
                '2022-06-01T09:46:27.000Z',
                '00Q6700000wdrO6EAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T09:52:29.000Z',
                '00Q6700000wdrPdEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - BORGO',
            ],
            [
                'June 2022',
                '2022-06-01T09:54:58.000Z',
                '00Q6700000wdrQCEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - BORGO',
            ],
            [
                'June 2022',
                '2022-06-01T13:19:28.000Z',
                '00Q6700000wdrz2EAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T13:23:30.000Z',
                '00Q6700000wdrzgEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T13:26:47.000Z',
                '00Q6700000wds0UEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T13:32:09.000Z',
                '00Q6700000wds1cEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T13:40:02.000Z',
                '00Q6700000wds3JEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PREMIUM AUTOMOBILES - BARBEREY SAINT SULPICE (TROYES)',
            ],
            [
                'June 2022',
                '2022-06-01T13:44:55.000Z',
                '00Q6700000wds47EAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'June 2022',
                '2022-06-01T13:49:48.000Z',
                '00Q6700000wds4bEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T13:53:22.000Z',
                '00Q6700000wds5FEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T13:57:11.000Z',
                '00Q6700000wds6DEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T14:00:15.000Z',
                '00Q6700000wds7LEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T14:09:10.000Z',
                '00Q6700000wds97EAA',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T14:50:52.000Z',
                '00Q6700000wdsHGEAY',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T15:28:56.000Z',
                '00Q6700000wdsOvEAI',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'June 2022',
                '2022-06-01T17:04:06.000Z',
                '00Q6700000wdsemEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'June 2022',
                '2022-06-01T20:33:54.000Z',
                '00Q6700000wdt9zEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                "Volvo -  FIRST AUTOMOBILES - VILLENEUVE D'ASCQ",
            ],
            [
                'June 2022',
                '2022-06-02T07:03:52.000Z',
                '00Q6700000wdvLGEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'June 2022',
                '2022-06-02T08:49:08.000Z',
                '00Q6700000wdvbnEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'June 2022',
                '2022-06-02T09:13:04.000Z',
                '00Q6700000wdve3EAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2022',
                '2022-06-02T20:40:24.000Z',
                '00Q6700000wdxCDEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'June 2022',
                '2022-06-03T05:58:32.000Z',
                '00Q6700000wdxlIEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'June 2022',
                '2022-06-03T11:47:27.000Z',
                '00Q6700000wdyZ0EAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            [
                'June 2022',
                '2022-06-03T12:17:30.000Z',
                '00Q6700000wdyeTEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'June 2022',
                '2022-06-03T15:24:23.000Z',
                '00Q6700000wdz6PEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'June 2022',
                '2022-06-03T21:40:28.000Z',
                '00Q6700000wdzrzEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - NEUILLY SUR SEINE',
            ],
            [
                'June 2022',
                '2022-06-04T09:51:27.000Z',
                '00Q6700000we0cgEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'June 2022',
                '2022-06-04T16:05:38.000Z',
                '00Q6700000we1I3EAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'June 2022',
                '2022-06-05T09:29:47.000Z',
                '00Q6700000we2CpEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'June 2022',
                '2022-06-06T13:53:05.000Z',
                '00Q6700000we5JqEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'June 2022',
                '2022-06-07T09:48:19.000Z',
                '00Q6700000we8ceEAA',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'June 2022',
                '2022-06-07T14:11:23.000Z',
                '00Q6700000we9NzEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'June 2022',
                '2022-06-07T18:30:38.000Z',
                '00Q6700000weAEoEAM',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'June 2022',
                '2022-06-08T08:44:05.000Z',
                '00Q6700000weC98EAE',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'June 2022',
                '2022-06-08T12:00:20.000Z',
                '00Q6700000weD80EAE',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'June 2022',
                '2022-06-08T14:45:06.000Z',
                '00Q6700000weEJREA2',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'June 2022',
                '2022-06-08T16:25:35.000Z',
                '00Q6700000weEv6EAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'June 2022',
                '2022-06-09T09:05:13.000Z',
                '00Q6700000weHjzEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'June 2022',
                '2022-06-09T10:31:36.000Z',
                '00Q6700000weI9iEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'June 2022',
                '2022-06-09T12:26:28.000Z',
                '00Q6700000weIbMEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'June 2022',
                '2022-06-09T12:35:33.000Z',
                '00Q6700000weIg8EAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'June 2022',
                '2022-06-09T15:32:35.000Z',
                '00Q6700000weJTzEAM',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'June 2022',
                '2022-06-09T17:56:33.000Z',
                '00Q6700000weK5rEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'June 2022',
                '2022-06-10T06:17:01.000Z',
                '00Q6700000weLi6EAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'June 2022',
                '2022-06-10T08:32:43.000Z',
                '00Q6700000weM3xEAE',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS (11ème) - PARIS',
            ],
            [
                'June 2022',
                '2022-06-10T09:02:46.000Z',
                '00Q6700000weMAFEA2',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'June 2022',
                '2022-06-10T09:53:10.000Z',
                '00Q6700000weMOMEA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'June 2022',
                '2022-06-10T09:58:43.000Z',
                '00Q6700000weMPeEAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - BUCHELAY (MANTES LA JOLIE)',
            ],
            [
                'June 2022',
                '2022-06-10T14:03:29.000Z',
                '00Q6700000weNKfEAM',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'June 2022',
                '2022-06-11T14:48:59.000Z',
                '00Q6700000weR1HEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'June 2022',
                '2022-06-12T21:07:22.000Z',
                '00Q6700000weU2IEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'June 2022',
                '2022-06-13T05:13:56.000Z',
                '00Q6700000weUvNEAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'June 2022',
                '2022-06-13T08:11:37.000Z',
                '00Q6700000whZtxEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            [
                'June 2022',
                '2022-06-13T15:11:11.000Z',
                '00Q6700000weWwoEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'LARSON by Autosphere',
            ],
            [
                'June 2022',
                '2022-06-13T16:43:36.000Z',
                '00Q6700000weXNLEA2',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'June 2022',
                '2022-06-14T08:45:51.000Z',
                '00Q6700000weZt5EAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'June 2022',
                '2022-06-14T08:49:23.000Z',
                '00Q6700000weZtyEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'June 2022',
                '2022-06-14T08:53:07.000Z',
                '00Q6700000weZtLEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'June 2022',
                '2022-06-14T08:56:17.000Z',
                '00Q6700000weZvzEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'June 2022',
                '2022-06-14T08:56:39.000Z',
                '00Q6700000weZw4EAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'June 2022',
                '2022-06-14T09:00:45.000Z',
                '00Q6700000weZx2EAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'June 2022',
                '2022-06-14T09:44:35.000Z',
                '00Q6700000wea8UEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'June 2022',
                '2022-06-14T10:00:12.000Z',
                '00Q6700000weaD5EAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - VERSAILLES',
            ],
            [
                'June 2022',
                '2022-06-14T13:37:21.000Z',
                '00Q6700000web87EAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2022',
                '2022-06-14T14:40:12.000Z',
                '00Q6700000webWQEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'June 2022',
                '2022-06-14T14:55:13.000Z',
                '00Q6700000webb6EAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2022',
                '2022-06-14T16:26:17.000Z',
                '00Q6700000wec0aEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'June 2022',
                '2022-06-14T17:01:58.000Z',
                '00Q6700000wecA1EAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'June 2022',
                '2022-06-14T20:44:32.000Z',
                '00Q6700000weckOEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)',
            ],
            [
                'June 2022',
                '2022-06-14T23:03:38.000Z',
                '00Q6700000wed3CEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS (11ème) - PARIS',
            ],
            [
                'June 2022',
                '2022-06-15T06:59:16.000Z',
                '00Q6700000wee88EAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'June 2022',
                '2022-06-15T08:18:39.000Z',
                '00Q6700000weePJEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'June 2022',
                '2022-06-15T09:25:29.000Z',
                '00Q6700000weedGEAQ',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'June 2022',
                '2022-06-15T12:00:53.000Z',
                '00Q6700000wefGmEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo - THIBAULT AUTOMOBILES - ALENCON',
            ],
            [
                'June 2022',
                '2022-06-15T12:04:33.000Z',
                '00Q6700000wefHVEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo - THIBAULT AUTOMOBILES - ALENCON',
            ],
            [
                'June 2022',
                '2022-06-15T12:14:22.000Z',
                '00Q6700000wefLIEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo - THIBAULT AUTOMOBILES - ALENCON',
            ],
            [
                'June 2022',
                '2022-06-15T12:17:12.000Z',
                '00Q6700000wefM1EAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'June 2022',
                '2022-06-15T12:19:55.000Z',
                '00Q6700000wefMaEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo - THIBAULT AUTOMOBILES - ALENCON',
            ],
            [
                'June 2022',
                '2022-06-15T12:22:10.000Z',
                '00Q6700000wefNJEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'June 2022',
                '2022-06-15T13:07:04.000Z',
                '00Q6700000wefYCEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'June 2022',
                '2022-06-15T15:09:38.000Z',
                '00Q6700000weg8OEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'June 2022',
                '2022-06-15T15:57:01.000Z',
                '00Q6700000wegOMEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'June 2022',
                '2022-06-16T12:55:25.000Z',
                '00Q6700000wejxuEAA',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES PAU - LESCAR',
            ],
            [
                'June 2022',
                '2022-06-16T13:08:17.000Z',
                '00Q6700000wek18EAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'June 2022',
                '2022-06-16T14:06:56.000Z',
                '00Q6700000wekDsEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'June 2022',
                '2022-06-16T15:31:15.000Z',
                '00Q6700000wekZpEAI',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'June 2022',
                '2022-06-16T15:33:33.000Z',
                '00Q6700000wekacEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'June 2022',
                '2022-06-16T15:35:43.000Z',
                '00Q6700000wekarEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'June 2022',
                '2022-06-16T18:04:58.000Z',
                '00Q6700000wemFDEAY',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'June 2022',
                '2022-06-17T09:55:20.000Z',
                '00Q6700000wepTEEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'June 2022',
                '2022-06-17T14:13:09.000Z',
                '00Q6700000weqNsEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'June 2022',
                '2022-06-17T20:37:21.000Z',
                '00Q6700000werdxEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'June 2022',
                '2022-06-18T07:41:42.000Z',
                '00Q6700000wetAIEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'June 2022',
                '2022-06-18T08:58:56.000Z',
                '00Q6700000wetKwEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'June 2022',
                '2022-06-18T09:07:26.000Z',
                '00Q6700000wetMdEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'June 2022',
                '2022-06-18T09:45:55.000Z',
                '00Q6700000wetUhEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'June 2022',
                '2022-06-18T13:22:47.000Z',
                '00Q6700000wett8EAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'June 2022',
                '2022-06-18T15:09:48.000Z',
                '00Q6700000weu6REAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'June 2022',
                '2022-06-20T08:20:38.000Z',
                '00Q6700000weyJTEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'June 2022',
                '2022-06-20T12:53:36.000Z',
                '00Q6700000wg4EyEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'June 2022',
                '2022-06-20T13:16:57.000Z',
                '00Q6700000wezRyEAI',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'June 2022',
                '2022-06-20T15:12:08.000Z',
                '00Q6700000wezucEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'June 2022',
                '2022-06-20T15:59:22.000Z',
                '00Q6700000wf03FEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'June 2022',
                '2022-06-21T06:37:42.000Z',
                '00Q6700000wf1A4EAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'June 2022',
                '2022-06-21T08:39:54.000Z',
                '00Q6700000wf1WIEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'June 2022',
                '2022-06-21T09:17:48.000Z',
                '00Q6700000wf1e7EAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'June 2022',
                '2022-06-21T09:41:49.000Z',
                '00Q6700000wf1lcEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'June 2022',
                '2022-06-21T12:49:42.000Z',
                '00Q6700000wf2LzEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'June 2022',
                '2022-06-21T14:00:24.000Z',
                '00Q6700000wf2ZSEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'June 2022',
                '2022-06-21T14:14:08.000Z',
                '00Q6700000wf2cREAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'June 2022',
                '2022-06-21T14:20:28.000Z',
                '00Q6700000wf2dZEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'June 2022',
                '2022-06-21T15:26:50.000Z',
                '00Q6700000wf2xyEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'June 2022',
                '2022-06-21T16:01:40.000Z',
                '00Q6700000wf362EAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'June 2022',
                '2022-06-21T16:25:38.000Z',
                '00Q6700000wf3D7EAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'June 2022',
                '2022-06-22T06:51:26.000Z',
                '00Q6700000wf4HKEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - BORGO',
            ],
            [
                'June 2022',
                '2022-06-22T09:52:53.000Z',
                '00Q6700000wf5BfEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'June 2022',
                '2022-06-22T10:15:43.000Z',
                '00Q6700000wf5H5EAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'June 2022',
                '2022-06-22T12:45:00.000Z',
                '00Q6700000wf5iNEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'June 2022',
                '2022-06-22T15:19:46.000Z',
                '00Q6700000wf6ZKEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'June 2022',
                '2022-06-22T15:33:47.000Z',
                '00Q6700000wf6csEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'June 2022',
                '2022-06-22T15:35:35.000Z',
                '00Q6700000wf6dCEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'June 2022',
                '2022-06-22T17:40:13.000Z',
                '00Q6700000wf75zEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - ROISSY EN FRANCE',
            ],
            [
                'June 2022',
                '2022-06-23T10:08:32.000Z',
                '00Q6700000wf8bnEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'June 2022',
                '2022-06-23T10:11:49.000Z',
                '00Q6700000wf8cMEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'June 2022',
                '2022-06-23T10:14:12.000Z',
                '00Q6700000wf8cqEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'June 2022',
                '2022-06-23T10:16:38.000Z',
                '00Q6700000wf8dAEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'June 2022',
                '2022-06-23T10:18:35.000Z',
                '00Q6700000wf8djEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'June 2022',
                '2022-06-23T10:20:18.000Z',
                '00Q6700000wf8e3EAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'June 2022',
                '2022-06-23T10:32:07.000Z',
                '00Q6700000wf8gOEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'June 2022',
                '2022-06-23T13:03:57.000Z',
                '00Q6700000wf97UEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'June 2022',
                '2022-06-23T13:43:03.000Z',
                '00Q6700000wf9GlEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2022',
                '2022-06-23T14:25:31.000Z',
                '00Q6700000wf9J2EAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'June 2022',
                '2022-06-23T15:13:56.000Z',
                '00Q6700000wf9utEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'June 2022',
                '2022-06-24T07:09:39.000Z',
                '00Q6700000wfBWCEA2',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'June 2022',
                '2022-06-24T08:02:52.000Z',
                '00Q6700000wfBc5EAE',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE EST AUTOS (11ème) - PARIS',
            ],
            [
                'June 2022',
                '2022-06-24T08:53:38.000Z',
                '00Q6700000wfBkOEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'June 2022',
                '2022-06-24T09:37:41.000Z',
                '00Q6700000wfBtLEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'June 2022',
                '2022-06-24T09:45:39.000Z',
                '00Q6700000wfBwPEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'June 2022',
                '2022-06-24T13:38:15.000Z',
                '00Q6700000wfCd4EAE',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'June 2022',
                '2022-06-24T14:47:51.000Z',
                '00Q6700000wfCsEEAU',
                'Unqualified',
                'Direct',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'June 2022',
                '2022-06-24T14:55:39.000Z',
                '00Q6700000wfCtvEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2022',
                '2022-06-25T07:41:48.000Z',
                '00Q6700000wfEDJEA2',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'June 2022',
                '2022-06-25T08:23:20.000Z',
                '00Q6700000wfEFPEA2',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'June 2022',
                '2022-06-25T10:11:32.000Z',
                '00Q6700000wfES4EAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - EVERLINE - DENNEY (BELFORT)',
            ],
            [
                'June 2022',
                '2022-06-25T10:53:50.000Z',
                '00Q6700000wfEWpEAM',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'June 2022',
                '2022-06-25T12:57:22.000Z',
                '00Q6700000wfEhnEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'June 2022',
                '2022-06-25T14:51:25.000Z',
                '00Q6700000wfEv6EAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'June 2022',
                '2022-06-25T17:21:04.000Z',
                '00Q6700000wfFCMEA2',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AUTO PREMIUM 08 - CHARLEVILLE MEZIERES',
            ],
            [
                'June 2022',
                '2022-06-27T08:03:53.000Z',
                '00Q6700000wfHiUEAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'June 2022',
                '2022-06-27T08:21:44.000Z',
                '00Q6700000wfHm7EAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'June 2022',
                '2022-06-27T08:48:10.000Z',
                '00Q6700000wfHrqEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'June 2022',
                '2022-06-27T09:35:53.000Z',
                '00Q6700000wfI2lEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'June 2022',
                '2022-06-27T09:47:28.000Z',
                '00Q6700000wfI6EEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'June 2022',
                '2022-06-27T14:44:01.000Z',
                '00Q6700000wfJ5VEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'June 2022',
                '2022-06-27T16:16:49.000Z',
                '00Q6700000wfJTFEA2',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'June 2022',
                '2022-06-28T05:19:20.000Z',
                '00Q6700000wfKgNEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VIENNE',
            ],
            [
                'June 2022',
                '2022-06-28T08:09:25.000Z',
                '00Q6700000wfKxEEAU',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'June 2022',
                '2022-06-28T14:02:29.000Z',
                '00Q6700000wfLzZEAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  AUTO PREMIUM 08 - CHARLEVILLE MEZIERES',
            ],
            [
                'June 2022',
                '2022-06-28T14:37:46.000Z',
                '00Q6700000wfM53EAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'June 2022',
                '2022-06-28T15:09:22.000Z',
                '00Q6700000wfMDbEAM',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'June 2022',
                '2022-06-28T15:13:43.000Z',
                '00Q6700000wfME0EAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2022',
                '2022-06-28T15:47:59.000Z',
                '00Q6700000wfMLkEAM',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'June 2022',
                '2022-06-28T15:49:28.000Z',
                '00Q6700000wfMLzEAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'June 2022',
                '2022-06-28T16:16:56.000Z',
                '00Q6700000wfMQQEA2',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'June 2022',
                '2022-06-28T19:57:52.000Z',
                '00Q6700000wfMqiEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'June 2022',
                '2022-06-29T08:20:08.000Z',
                '00Q6700000wfNZWEA2',
                'Unqualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'June 2022',
                '2022-06-29T08:30:34.000Z',
                '00Q6700000wfNbXEAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'June 2022',
                '2022-06-29T09:42:34.000Z',
                '00Q6700000wfNoMEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'June 2022',
                '2022-06-29T11:57:55.000Z',
                '00Q6700000wfO9qEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'June 2022',
                '2022-06-29T12:21:51.000Z',
                '00Q6700000wfODnEAM',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - SAINTES',
            ],
            [
                'June 2022',
                '2022-06-29T12:33:41.000Z',
                '00Q6700000wfOFZEA2',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'June 2022',
                '2022-06-29T13:18:51.000Z',
                '00Q6700000wfOMvEAM',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'June 2022',
                '2022-06-29T14:41:21.000Z',
                '00Q6700000wfOcOEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'June 2022',
                '2022-06-29T15:15:57.000Z',
                '00Q6700000wfOigEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'June 2022',
                '2022-06-29T17:15:06.000Z',
                '00Q6700000wfP78EAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2022',
                '2022-06-30T07:46:03.000Z',
                '00Q6700000wfQ2VEAU',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'June 2022',
                '2022-06-30T07:53:31.000Z',
                '00Q6700000wfQ3JEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'June 2022',
                '2022-06-30T08:06:56.000Z',
                '00Q6700000wfQ5AEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'June 2022',
                '2022-06-30T08:17:36.000Z',
                '00Q6700000wfQ72EAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'June 2022',
                '2022-06-30T09:31:03.000Z',
                '00Q6700000wfQL9EAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'June 2022',
                '2022-06-30T11:49:26.000Z',
                '00Q6700000wfQiSEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'June 2022',
                '2022-06-30T12:21:06.000Z',
                '00Q6700000wfQnmEAE',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'June 2022',
                '2022-06-30T13:21:00.000Z',
                '00Q6700000wfQxDEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'June 2022',
                '2022-06-30T13:37:27.000Z',
                '00Q6700000wfQzxEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'June 2022',
                '2022-06-30T15:47:16.000Z',
                '00Q6700000wfROxEAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'July 2022',
                '2022-07-01T07:37:01.000Z',
                '00Q6700000wfTKVEA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'July 2022',
                '2022-07-01T08:37:23.000Z',
                '00Q6700000wfTSAEA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'July 2022',
                '2022-07-02T07:53:02.000Z',
                '00Q6700000wfVIREA2',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'July 2022',
                '2022-07-02T11:59:04.000Z',
                '00Q6700000wfVy8EAE',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'July 2022',
                '2022-07-02T12:46:48.000Z',
                '00Q6700000wfWR0EAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'July 2022',
                '2022-07-03T09:34:02.000Z',
                '00Q6700000wfXJvEAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'July 2022',
                '2022-07-03T16:38:55.000Z',
                '00Q6700000wfXjtEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'July 2022',
                '2022-07-04T08:24:14.000Z',
                '00Q6700000wfYcyEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES',
            ],
            [
                'July 2022',
                '2022-07-04T08:57:35.000Z',
                '00Q6700000wfYi8EAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'July 2022',
                '2022-07-04T09:33:47.000Z',
                '00Q6700000wfYo6EAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'July 2022',
                '2022-07-04T12:02:20.000Z',
                '00Q6700000wfZ81EAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'July 2022',
                '2022-07-04T12:19:07.000Z',
                '00Q6700000wfZAvEAM',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'July 2022',
                '2022-07-04T14:31:41.000Z',
                '00Q6700000wfZTsEAM',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - NEUILLY SUR SEINE',
            ],
            [
                'July 2022',
                '2022-07-04T23:52:08.000Z',
                '00Q6700000wfaUNEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'ABVV Roissy en France',
            ],
            [
                'July 2022',
                '2022-07-05T06:41:25.000Z',
                '00Q6700000wfamgEAA',
                'Qualified',
                'Email',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2022',
                '2022-07-05T12:00:04.000Z',
                '00Q6700000wfbRREAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'July 2022',
                '2022-07-05T16:00:18.000Z',
                '00Q6700000wfc8VEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'July 2022',
                '2022-07-05T16:04:06.000Z',
                '00Q6700000wfc8zEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'July 2022',
                '2022-07-05T17:33:34.000Z',
                '00Q6700000wfcLZEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - SAINTES',
            ],
            [
                'July 2022',
                '2022-07-05T20:08:58.000Z',
                '00Q6700000wfcg5EAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2022',
                '2022-07-06T14:01:23.000Z',
                '00Q6700000wfeNGEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'July 2022',
                '2022-07-07T07:53:27.000Z',
                '00Q6700000wfgApEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'July 2022',
                '2022-07-07T08:06:03.000Z',
                '00Q6700000wfgCMEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'July 2022',
                '2022-07-07T08:08:11.000Z',
                '00Q6700000wfgCWEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'July 2022',
                '2022-07-07T08:44:28.000Z',
                '00Q6700000wfgMWEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'July 2022',
                '2022-07-07T09:42:47.000Z',
                '00Q6700000wfgYmEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'July 2022',
                '2022-07-07T12:27:42.000Z',
                '00Q6700000wfh2tEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - EVERLINE - DENNEY (BELFORT)',
            ],
            [
                'July 2022',
                '2022-07-07T14:32:41.000Z',
                '00Q6700000wfhVbEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)',
            ],
            [
                'July 2022',
                '2022-07-07T15:07:58.000Z',
                '00Q6700000wfhcaEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'July 2022',
                '2022-07-08T05:15:54.000Z',
                '00Q6700000wfipxEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'July 2022',
                '2022-07-08T08:22:50.000Z',
                '00Q6700000wfj7BEAQ',
                'Unqualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'July 2022',
                '2022-07-08T08:44:28.000Z',
                '00Q6700000wfjA0EAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - BUCHELAY (MANTES LA JOLIE)',
            ],
            [
                'July 2022',
                '2022-07-08T08:51:56.000Z',
                '00Q6700000wfjBNEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'July 2022',
                '2022-07-08T09:03:52.000Z',
                '00Q6700000wfjDTEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'July 2022',
                '2022-07-08T09:08:47.000Z',
                '00Q6700000wfjI4EAI',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2022',
                '2022-07-08T12:50:05.000Z',
                '00Q6700000wfjkWEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'July 2022',
                '2022-07-08T12:52:48.000Z',
                '00Q6700000wfjkqEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'July 2022',
                '2022-07-08T12:53:35.000Z',
                '00Q6700000wfjl0EAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'July 2022',
                '2022-07-08T12:56:57.000Z',
                '00Q6700000wfjlUEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'July 2022',
                '2022-07-08T14:46:36.000Z',
                '00Q6700000wfk4MEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'July 2022',
                '2022-07-09T14:19:41.000Z',
                '00Q6700000wflhkEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'July 2022',
                '2022-07-10T09:22:16.000Z',
                '00Q6700000wfmlTEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - SAINT AVIT (MONT DE MARSAN)',
            ],
            [
                'July 2022',
                '2022-07-10T13:50:19.000Z',
                '00Q6700000wfn43EAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'July 2022',
                '2022-07-11T08:05:09.000Z',
                '00Q6700000wfoCpEAI',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'July 2022',
                '2022-07-11T08:41:52.000Z',
                '00Q6700000wfoIJEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'July 2022',
                '2022-07-11T09:10:37.000Z',
                '00Q6700000wfoMQEAY',
                'Qualified',
                'Direct',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'July 2022',
                '2022-07-11T09:57:46.000Z',
                '00Q6700000wfoUUEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2022',
                '2022-07-11T10:01:47.000Z',
                '00Q6700000wfoVDEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'July 2022',
                '2022-07-11T13:21:19.000Z',
                '00Q6700000wfp3wEAA',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'July 2022',
                '2022-07-11T14:54:08.000Z',
                '00Q6700000wfpNEEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'July 2022',
                '2022-07-11T14:57:55.000Z',
                '00Q6700000wfpOHEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'July 2022',
                '2022-07-11T15:02:46.000Z',
                '00Q6700000wfpPAEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'July 2022',
                '2022-07-11T15:42:32.000Z',
                '00Q6700000wfpVfEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'July 2022',
                '2022-07-11T16:35:58.000Z',
                '00Q6700000wfpi6EAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'July 2022',
                '2022-07-12T08:21:41.000Z',
                '00Q6700000wfqmAEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'July 2022',
                '2022-07-12T12:04:59.000Z',
                '00Q6700000wfrK0EAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'July 2022',
                '2022-07-12T12:25:13.000Z',
                '00Q6700000wfrMQEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'July 2022',
                '2022-07-12T14:32:37.000Z',
                '00Q6700000wfrfnEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - NEUILLY SUR SEINE',
            ],
            [
                'July 2022',
                '2022-07-12T17:19:49.000Z',
                '00Q6700000wfsCfEAI',
                'Unqualified',
                'Zeplug',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'July 2022',
                '2022-07-13T06:00:35.000Z',
                '00Q6700000wfspNEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'July 2022',
                '2022-07-13T08:55:38.000Z',
                '00Q6700000wft8jEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            [
                'July 2022',
                '2022-07-13T09:53:54.000Z',
                '00Q6700000wftJ3EAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'July 2022',
                '2022-07-13T12:16:42.000Z',
                '00Q6700000wftduEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'July 2022',
                '2022-07-13T14:14:58.000Z',
                '00Q6700000wftvKEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'July 2022',
                '2022-07-13T21:18:56.000Z',
                '00Q6700000wfuwTEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'July 2022',
                '2022-07-15T11:59:49.000Z',
                '00Q6700000wfyKFEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'July 2022',
                '2022-07-15T14:23:52.000Z',
                '00Q6700000wfyfXEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'July 2022',
                '2022-07-16T09:05:16.000Z',
                '00Q6700000wfzoaEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'July 2022',
                '2022-07-16T09:49:45.000Z',
                '00Q6700000wfzswEAA',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  PRESTIGE CARS - AUBIERE (CLERMONT FERRAND)',
            ],
            [
                'July 2022',
                '2022-07-16T09:56:49.000Z',
                '00Q6700000wfztLEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'July 2022',
                '2022-07-17T06:46:05.000Z',
                '00Q6700000wg1PZEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'July 2022',
                '2022-07-18T08:41:24.000Z',
                '00Q6700000wg3AQEAY',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'July 2022',
                '2022-07-18T10:28:46.000Z',
                '00Q6700000wg3TZEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'July 2022',
                '2022-07-18T12:38:53.000Z',
                '00Q6700000wg3oMEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'July 2022',
                '2022-07-18T12:55:52.000Z',
                '00Q6700000wg3qSEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'July 2022',
                '2022-07-18T16:25:43.000Z',
                '00Q6700000wg4TKEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - DISTRE (SAUMUR)',
            ],
            [
                'July 2022',
                '2022-07-18T17:05:36.000Z',
                '00Q6700000wg4ZcEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2022',
                '2022-07-19T08:44:59.000Z',
                '00Q67000015wZIMEA2',
                'Qualification',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'July 2022',
                '2022-07-19T12:18:57.000Z',
                '00Q6700000wg6ZFEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'July 2022',
                '2022-07-19T12:24:18.000Z',
                '00Q6700000wg6aIEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'July 2022',
                '2022-07-19T13:22:24.000Z',
                '00Q6700000wg74IEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'July 2022',
                '2022-07-19T13:37:45.000Z',
                '00Q6700000wg77HEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'July 2022',
                '2022-07-19T16:14:14.000Z',
                '00Q6700000xAo3pEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'July 2022',
                '2022-07-19T20:23:19.000Z',
                '00Q6700000wg8FwEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2022',
                '2022-07-20T07:14:08.000Z',
                '00Q6700000wgtauEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'July 2022',
                '2022-07-20T07:23:11.000Z',
                '00Q6700000wg95XEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'ABVV Roissy en France',
            ],
            [
                'July 2022',
                '2022-07-20T12:19:15.000Z',
                '00Q6700000wgA1EEAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'July 2022',
                '2022-07-20T12:25:01.000Z',
                '00Q6700000wgA2bEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'July 2022',
                '2022-07-20T15:55:29.000Z',
                '00Q6700000wgAwfEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'July 2022',
                '2022-07-20T17:38:39.000Z',
                '00Q6700000wgBGYEA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'July 2022',
                '2022-07-21T07:58:25.000Z',
                '00Q6700000wgCcnEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'July 2022',
                '2022-07-21T12:42:09.000Z',
                '00Q6700000wgDPfEAM',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'July 2022',
                '2022-07-21T13:25:08.000Z',
                '00Q6700000wgDXoEAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'July 2022',
                '2022-07-21T13:28:09.000Z',
                '00Q6700000wgDYhEAM',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'July 2022',
                '2022-07-21T14:48:55.000Z',
                '00Q6700000wgDokEAE',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'July 2022',
                '2022-07-22T07:39:51.000Z',
                '00Q6700000wgFaDEAU',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'July 2022',
                '2022-07-22T09:31:25.000Z',
                '00Q6700000wgFsCEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'July 2022',
                '2022-07-23T09:25:55.000Z',
                '00Q6700000wgIDFEA2',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'July 2022',
                '2022-07-23T12:51:47.000Z',
                '00Q6700000wgIVdEAM',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'July 2022',
                '2022-07-25T12:26:27.000Z',
                '00Q6700000wgLqlEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'July 2022',
                '2022-07-25T13:14:37.000Z',
                '00Q6700000wgLxXEAU',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'July 2022',
                '2022-07-25T17:43:17.000Z',
                '00Q6700000wgMeWEAU',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'July 2022',
                '2022-07-26T10:00:43.000Z',
                '00Q6700000wgNzSEAU',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'July 2022',
                '2022-07-26T12:56:04.000Z',
                '00Q6700000wgOSyEAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'July 2022',
                '2022-07-26T13:01:35.000Z',
                '00Q6700000wgOTrEAM',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'July 2022',
                '2022-07-26T14:28:36.000Z',
                '00Q6700000wgOjLEAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'July 2022',
                '2022-07-26T16:48:49.000Z',
                '00Q6700000wgP81EAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'July 2022',
                '2022-07-27T09:25:19.000Z',
                '00Q6700000wgQSqEAM',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'July 2022',
                '2022-07-27T14:06:52.000Z',
                '00Q6700000wgR9tEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'July 2022',
                '2022-07-27T15:18:45.000Z',
                '00Q6700000wgRQfEAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'July 2022',
                '2022-07-27T15:30:45.000Z',
                '00Q6700000wgRTeEAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE JFC LISIEUX - LISIEUX',
            ],
            [
                'July 2022',
                '2022-07-28T12:11:28.000Z',
                '00Q6700000wgTPoEAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'July 2022',
                '2022-07-28T13:00:52.000Z',
                '00Q6700000wgTWuEAM',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'July 2022',
                '2022-07-28T13:27:06.000Z',
                '00Q6700000wgTaIEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'July 2022',
                '2022-07-28T13:57:35.000Z',
                '00Q6700000whX0gEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'July 2022',
                '2022-07-28T16:32:34.000Z',
                '00Q6700000wgUeCEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'July 2022',
                '2022-07-29T07:56:14.000Z',
                '00Q6700000wgWkcEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - DISTRE (SAUMUR)',
            ],
            [
                'July 2022',
                '2022-07-29T09:41:39.000Z',
                '00Q6700000wgWxbEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'July 2022',
                '2022-07-29T10:02:39.000Z',
                '00Q6700000wgX0pEAE',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'July 2022',
                '2022-07-29T12:00:21.000Z',
                '00Q6700000wgXDeEAM',
                'Meeting Scheduled',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'July 2022',
                '2022-07-29T17:12:08.000Z',
                '00Q6700000wgXynEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - BRIVE LA GAILLARDE',
            ],
            [
                'July 2022',
                '2022-07-30T09:23:14.000Z',
                '00Q6700000wgZ6fEAE',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'July 2022',
                '2022-07-30T15:23:23.000Z',
                '00Q6700000wgZd5EAE',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'July 2022',
                '2022-07-31T10:49:46.000Z',
                '00Q6700000wgaQHEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'August 2022',
                '2022-08-01T15:49:23.000Z',
                '00Q6700000wgdYNEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'August 2022',
                '2022-08-02T06:56:36.000Z',
                '00Q6700000wgeqbEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - FIRST AUTOMOBILES - AULNOY LEZ VALENCIENNES',
            ],
            [
                'August 2022',
                '2022-08-02T09:54:57.000Z',
                '00Q6700000wgfCwEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'August 2022',
                '2022-08-02T10:21:35.000Z',
                '00Q6700000wgfGsEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'August 2022',
                '2022-08-02T12:58:12.000Z',
                '00Q6700000wgfe6EAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'August 2022',
                '2022-08-02T15:41:30.000Z',
                '00Q6700000wgg7IEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'August 2022',
                '2022-08-02T16:50:34.000Z',
                '00Q6700000wggOEEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'August 2022',
                '2022-08-02T17:10:28.000Z',
                '00Q6700000wggRXEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'August 2022',
                '2022-08-03T07:17:22.000Z',
                '00Q6700000wghZmEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'August 2022',
                '2022-08-03T07:40:05.000Z',
                '00Q6700000wghcqEAA',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'August 2022',
                '2022-08-03T12:36:07.000Z',
                '00Q6700000wgiIXEAY',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'August 2022',
                '2022-08-03T12:48:13.000Z',
                '00Q6700000wgiKrEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            [
                'August 2022',
                '2022-08-04T13:29:51.000Z',
                '00Q6700000wgkg8EAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'August 2022',
                '2022-08-04T15:00:54.000Z',
                '00Q6700000wgkylEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'August 2022',
                '2022-08-04T19:17:58.000Z',
                '00Q6700000wglqFEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'August 2022',
                '2022-08-04T20:02:16.000Z',
                '00Q6700000wgluMEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'August 2022',
                '2022-08-05T14:29:18.000Z',
                '00Q6700000wgojrEAA',
                'Unqualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'August 2022',
                '2022-08-05T14:50:37.000Z',
                '00Q6700000wgonyEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'August 2022',
                '2022-08-06T08:53:03.000Z',
                '00Q6700000wgqNfEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'August 2022',
                '2022-08-06T10:03:14.000Z',
                '00Q6700000wgqTBEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'August 2022',
                '2022-08-08T10:04:28.000Z',
                '00Q6700000wgtZXEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'August 2022',
                '2022-08-08T14:10:14.000Z',
                '00Q6700000wguGaEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'August 2022',
                '2022-08-08T15:04:24.000Z',
                '00Q6700000wguSREAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'August 2022',
                '2022-08-09T07:54:39.000Z',
                '00Q6700000wgvoZEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'August 2022',
                '2022-08-09T10:05:58.000Z',
                '00Q6700000wgw3UEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)',
            ],
            [
                'August 2022',
                '2022-08-10T06:27:48.000Z',
                '00Q6700000wgxvBEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'August 2022',
                '2022-08-10T07:54:58.000Z',
                '00Q6700000wgy2gEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'August 2022',
                '2022-08-10T16:48:39.000Z',
                '00Q6700000wgzLYEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'August 2022',
                '2022-08-11T06:25:47.000Z',
                '00Q6700000wh09qEAA',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  DELHORBE AUTOMOBILES - SAINT MARTIN SUR LE PRE (CHALONS EN CHAMPAGNE)',
            ],
            [
                'August 2022',
                '2022-08-11T12:05:55.000Z',
                '00Q6700000wh0leEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2022',
                '2022-08-12T07:46:20.000Z',
                '00Q6700000wh3DvEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'August 2022',
                '2022-08-13T13:19:15.000Z',
                '00Q6700000wh5z1EAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'August 2022',
                '2022-08-15T09:25:36.000Z',
                '00Q6700000wh8WaEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'August 2022',
                '2022-08-15T20:19:57.000Z',
                '00Q6700000wh9iiEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'August 2022',
                '2022-08-16T08:08:12.000Z',
                '00Q6700000whAnrEAE',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - NEUILLY SUR SEINE',
            ],
            [
                'August 2022',
                '2022-08-16T13:20:13.000Z',
                '00Q6700000whBV6EAM',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'August 2022',
                '2022-08-17T08:00:09.000Z',
                '00Q6700000whDQVEA2',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
            ],
            [
                'August 2022',
                '2022-08-18T09:29:19.000Z',
                '00Q6700000whG2eEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)',
            ],
            [
                'August 2022',
                '2022-08-18T09:35:22.000Z',
                '00Q6700000whG3XEAU',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'August 2022',
                '2022-08-18T15:20:59.000Z',
                '00Q6700000whHZUEA2',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PREMIUM AUTOMOBILES - BARBEREY SAINT SULPICE (TROYES)',
            ],
            [
                'August 2022',
                '2022-08-18T16:26:36.000Z',
                '00Q6700000whHtQEAU',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'August 2022',
                '2022-08-19T08:58:03.000Z',
                '00Q6700000whJfbEAE',
                'Qualified',
                'Zeplug',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'August 2022',
                '2022-08-20T12:32:22.000Z',
                '00Q6700000whM79EAE',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'August 2022',
                '2022-08-20T15:05:52.000Z',
                '00Q6700000whMJjEAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'August 2022',
                '2022-08-21T07:26:15.000Z',
                '00Q6700000whN7jEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'August 2022',
                '2022-08-21T08:41:48.000Z',
                '00Q6700000whNB2EAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES PAU - LESCAR',
            ],
            [
                'August 2022',
                '2022-08-21T08:47:45.000Z',
                '00Q6700000whNBbEAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'August 2022',
                '2022-08-22T09:06:19.000Z',
                '00Q6700000whOhEEAU',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'August 2022',
                '2022-08-22T12:02:32.000Z',
                '00Q6700000whPpsEAE',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
            ],
            [
                'August 2022',
                '2022-08-22T12:54:10.000Z',
                '00Q6700000whQFvEAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'August 2022',
                '2022-08-22T18:11:46.000Z',
                '00Q6700000whS10EAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2022',
                '2022-08-22T18:59:53.000Z',
                '00Q6700000whSAGEA2',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE SAINT ETIENNE - SAINT ETIENNE',
            ],
            [
                'August 2022',
                '2022-08-23T08:12:39.000Z',
                '00Q6700000whTjOEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'August 2022',
                '2022-08-23T14:28:14.000Z',
                '00Q6700000whUZFEA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'August 2022',
                '2022-08-23T14:42:09.000Z',
                '00Q67000010J0cdEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARAUTO SERVICES - GAP',
            ],
            [
                'August 2022',
                '2022-08-24T14:45:04.000Z',
                '00Q6700000whXmrEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'August 2022',
                '2022-08-24T16:05:45.000Z',
                '00Q6700000whY21EAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'August 2022',
                '2022-08-24T16:06:29.000Z',
                '00Q6700000whY2LEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'August 2022',
                '2022-08-24T18:17:34.000Z',
                '00Q6700000whYJmEAM',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'August 2022',
                '2022-08-25T08:31:25.000Z',
                '00Q6700000whZFxEAM',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'August 2022',
                '2022-08-25T09:25:52.000Z',
                '00Q6700000whZNrEAM',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'August 2022',
                '2022-08-25T14:25:03.000Z',
                '00Q6700000wha8OEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'August 2022',
                '2022-08-25T14:56:18.000Z',
                '00Q6700000whaD9EAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'August 2022',
                '2022-08-25T15:42:56.000Z',
                '00Q6700000whaPXEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'August 2022',
                '2022-08-26T14:46:22.000Z',
                '00Q6700000whcgtEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'August 2022',
                '2022-08-26T18:20:54.000Z',
                '00Q6700000whd6TEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'August 2022',
                '2022-08-27T07:51:19.000Z',
                '00Q6700000whdb2EAA',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'August 2022',
                '2022-08-27T07:56:33.000Z',
                '00Q6700000whdbHEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'August 2022',
                '2022-08-27T08:06:26.000Z',
                '00Q6700000whdbqEAA',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'LARSON by Autosphere',
            ],
            [
                'August 2022',
                '2022-08-27T10:57:47.000Z',
                '00Q6700000whdo1EAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2022',
                '2022-08-27T13:22:00.000Z',
                '00Q6700000whdyGEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'August 2022',
                '2022-08-29T04:08:40.000Z',
                '00Q6700000whfuQEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Tesla Store Lyon-Chemin des Gorges',
            ],
            [
                'August 2022',
                '2022-08-29T12:05:38.000Z',
                '00Q6700000whgf2EAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'August 2022',
                '2022-08-29T12:43:45.000Z',
                '00Q6700000whgkWEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'August 2022',
                '2022-08-29T14:32:00.000Z',
                '00Q6700000whh2kEAA',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'August 2022',
                '2022-08-29T15:10:35.000Z',
                '00Q6700000whh9MEAQ',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'August 2022',
                '2022-08-29T16:29:21.000Z',
                '00Q6700000whhUoEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'August 2022',
                '2022-08-30T09:15:45.000Z',
                '00Q6700000whienEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2022',
                '2022-08-30T15:41:10.000Z',
                '00Q6700000whjgeEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'August 2022',
                '2022-08-30T17:10:37.000Z',
                '00Q6700000whk3GEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'August 2022',
                '2022-08-31T08:32:11.000Z',
                '00Q6700000whlDyEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'August 2022',
                '2022-08-31T08:44:58.000Z',
                '00Q6700000whlFpEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'August 2022',
                '2022-08-31T08:55:36.000Z',
                '00Q6700000whlGxEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'August 2022',
                '2022-08-31T12:52:13.000Z',
                '00Q6700000whlo6EAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'August 2022',
                '2022-08-31T14:40:42.000Z',
                '00Q6700000yOfgvEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'August 2022',
                '2022-08-31T15:07:24.000Z',
                '00Q6700000yOfksEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2022',
                '2022-08-31T15:55:28.000Z',
                '00Q670000130irLEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NÖRDIK STAR AUTOS - CHALON SUR SAÔNE',
            ],
            [
                'September 2022',
                '2022-09-01T09:02:45.000Z',
                '00Q6700000yOh8WEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'September 2022',
                '2022-09-01T15:31:30.000Z',
                '00Q6700000yOiJjEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'September 2022',
                '2022-09-01T22:48:15.000Z',
                '00Q6700000yOj3wEAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE',
            ],
            [
                'September 2022',
                '2022-09-02T07:15:01.000Z',
                '00Q6700000yOjSkEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'September 2022',
                '2022-09-02T07:51:25.000Z',
                '00Q6700000yOjWIEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'September 2022',
                '2022-09-02T12:02:55.000Z',
                '00Q6700000yOkAwEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'September 2022',
                '2022-09-02T12:09:23.000Z',
                '00Q6700000yOkBaEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'September 2022',
                '2022-09-02T18:51:11.000Z',
                '00Q6700000yOkwUEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2022',
                '2022-09-03T08:43:54.000Z',
                '00Q6700000yOlZsEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'September 2022',
                '2022-09-03T09:30:22.000Z',
                '00Q6700000yOldGEAS',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'September 2022',
                '2022-09-03T09:41:02.000Z',
                '00Q6700000yOle9EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'September 2022',
                '2022-09-03T12:52:20.000Z',
                '00Q6700000yOlsfEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'September 2022',
                '2022-09-03T14:01:34.000Z',
                '00Q6700000yOly0EAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'September 2022',
                '2022-09-03T14:44:22.000Z',
                '00Q6700000yOm21EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'September 2022',
                '2022-09-03T15:15:27.000Z',
                '00Q6700000yOm5AEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'September 2022',
                '2022-09-03T16:36:59.000Z',
                '00Q6700000yOmDYEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'September 2022',
                '2022-09-04T12:30:07.000Z',
                '00Q6700000yOnL9EAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo Car France - Nanterre',
            ],
            [
                'September 2022',
                '2022-09-04T15:44:59.000Z',
                '00Q6700000yOngCEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'September 2022',
                '2022-09-05T07:29:43.000Z',
                '00Q6700000yOoWhEAK',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE EST AUTOS (11ème) - PARIS',
            ],
            [
                'September 2022',
                '2022-09-05T09:49:23.000Z',
                '00Q6700000yOou0EAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'September 2022',
                '2022-09-05T14:29:10.000Z',
                '00Q6700000yOphbEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'September 2022',
                '2022-09-05T16:37:32.000Z',
                '00Q6700000yOqbAEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'September 2022',
                '2022-09-05T17:10:35.000Z',
                '00Q6700000yOqkmEAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'September 2022',
                '2022-09-05T17:11:49.000Z',
                '00Q6700000yOqlKEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'September 2022',
                '2022-09-05T17:47:20.000Z',
                '00Q6700000yOqvAEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'September 2022',
                '2022-09-06T08:04:24.000Z',
                '00Q6700000yOsClEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'September 2022',
                '2022-09-06T12:47:04.000Z',
                '00Q6700000yOt8uEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'September 2022',
                '2022-09-06T13:24:47.000Z',
                '00Q6700000yOtH3EAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'September 2022',
                '2022-09-07T14:53:18.000Z',
                '00Q6700000yOwdeEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'September 2022',
                '2022-09-08T14:18:29.000Z',
                '00Q6700000yOzVFEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'September 2022',
                '2022-09-08T14:39:07.000Z',
                '00Q6700000yQCWHEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'September 2022',
                '2022-09-08T14:52:07.000Z',
                '00Q6700000yOzbIEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'September 2022',
                '2022-09-09T07:14:56.000Z',
                '00Q6700000yP1TZEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'September 2022',
                '2022-09-09T07:40:45.000Z',
                '00Q6700000yP1WdEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'September 2022',
                '2022-09-09T09:53:22.000Z',
                '00Q6700000yP24LEAS',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'September 2022',
                '2022-09-09T11:16:55.000Z',
                '00Q6700000yP2KyEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)',
            ],
            [
                'September 2022',
                '2022-09-09T13:05:11.000Z',
                '00Q6700000yP2e5EAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'September 2022',
                '2022-09-09T14:08:08.000Z',
                '00Q6700000yP32QEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'September 2022',
                '2022-09-09T15:23:28.000Z',
                '00Q6700000yP3EMEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'September 2022',
                '2022-09-09T16:35:53.000Z',
                '00Q6700000yP3Q3EAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'September 2022',
                '2022-09-09T17:03:42.000Z',
                '00Q6700000yP3SsEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'September 2022',
                '2022-09-10T08:19:21.000Z',
                '00Q6700000yP4oaEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'September 2022',
                '2022-09-10T09:23:14.000Z',
                '00Q6700000yP5HtEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'September 2022',
                '2022-09-10T12:33:24.000Z',
                '00Q6700000yP5eEEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'September 2022',
                '2022-09-10T16:35:48.000Z',
                '00Q6700000yP65yEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'September 2022',
                '2022-09-11T20:52:36.000Z',
                '00Q6700000yP8SSEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'September 2022',
                '2022-09-12T07:19:52.000Z',
                '00Q6700000yP95ZEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'September 2022',
                '2022-09-12T07:35:28.000Z',
                '00Q6700000yP97BEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'September 2022',
                '2022-09-12T13:01:45.000Z',
                '00Q6700000yPAO2EAO',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'September 2022',
                '2022-09-12T13:25:20.000Z',
                '00Q6700000yPAUPEA4',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'September 2022',
                '2022-09-12T14:56:25.000Z',
                '00Q6700000yPAqaEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'September 2022',
                '2022-09-12T16:32:15.000Z',
                '00Q6700000yPB8EEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'September 2022',
                '2022-09-12T17:05:07.000Z',
                '00Q6700000yPBEhEAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'September 2022',
                '2022-09-13T12:46:10.000Z',
                '00Q6700000yPDiZEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'September 2022',
                '2022-09-13T14:44:37.000Z',
                '00Q6700000yPEPaEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'September 2022',
                '2022-09-13T16:26:45.000Z',
                '00Q6700000yPEmUEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'September 2022',
                '2022-09-14T08:34:28.000Z',
                '00Q6700000yPGKIEA4',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'September 2022',
                '2022-09-14T08:52:40.000Z',
                '00Q6700000yPGNgEAO',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'September 2022',
                '2022-09-14T09:15:45.000Z',
                '00Q6700000yPGR9EAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'September 2022',
                '2022-09-14T13:05:53.000Z',
                '00Q6700000yPHO2EAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'September 2022',
                '2022-09-14T14:44:08.000Z',
                '00Q6700000yPHmJEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'September 2022',
                '2022-09-14T16:00:19.000Z',
                '00Q6700000yPI1EEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'September 2022',
                '2022-09-14T16:02:05.000Z',
                '00Q6700000yPI1dEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'September 2022',
                '2022-09-15T09:46:27.000Z',
                '00Q6700000yPKt4EAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'September 2022',
                '2022-09-15T14:19:26.000Z',
                '00Q6700000yPMdNEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'September 2022',
                '2022-09-15T16:03:18.000Z',
                '00Q6700000yPN4yEAG',
                'Qualified',
                'Email',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'September 2022',
                '2022-09-16T07:41:26.000Z',
                '00Q6700000yPOfJEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'September 2022',
                '2022-09-16T17:13:16.000Z',
                '00Q6700000yPQvoEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'September 2022',
                '2022-09-17T08:29:25.000Z',
                '00Q6700000yPRnxEAG',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'September 2022',
                '2022-09-17T15:06:15.000Z',
                '00Q6700000yPSTKEA4',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'September 2022',
                '2022-09-18T06:59:01.000Z',
                '00Q6700000yPTljEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'September 2022',
                '2022-09-19T09:37:36.000Z',
                '00Q6700000yPWiqEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'September 2022',
                '2022-09-19T11:15:46.000Z',
                '00Q6700000zehjHEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'September 2022',
                '2022-09-20T20:18:39.000Z',
                '00Q6700000yPdCNEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'September 2022',
                '2022-09-21T09:59:17.000Z',
                '00Q6700000yPet5EAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'September 2022',
                '2022-09-21T13:30:32.000Z',
                '00Q6700000yPfcAEAS',
                'Qualified',
                'Email',
                'France',
                'Single Home',
                'Volvo -  CARS PREMIUM - PERPIGNAN',
            ],
            [
                'September 2022',
                '2022-09-21T13:42:45.000Z',
                '00Q6700000yPfepEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  AUTO PREMIUM 08 - CHARLEVILLE MEZIERES',
            ],
            [
                'September 2022',
                '2022-09-21T15:07:10.000Z',
                '00Q6700000yPfxcEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'September 2022',
                '2022-09-21T15:44:53.000Z',
                '00Q6700000yPgA1EAK',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'September 2022',
                '2022-09-21T16:34:26.000Z',
                '00Q6700000yPgNqEAK',
                'Meeting Scheduled',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'September 2022',
                '2022-09-22T07:09:02.000Z',
                '00Q6700000yPiC2EAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CMM AUTOMOBILES - SAINTE CLOTILDE',
            ],
            [
                'September 2022',
                '2022-09-22T08:41:40.000Z',
                '00Q6700000yPihaEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - ROISSY EN FRANCE',
            ],
            [
                'September 2022',
                '2022-09-22T08:56:14.000Z',
                '00Q6700000yPimQEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'September 2022',
                '2022-09-22T13:15:07.000Z',
                '00Q6700000yPkCEEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'September 2022',
                '2022-09-22T14:48:23.000Z',
                '00Q6700000yPkweEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'September 2022',
                '2022-09-22T15:28:48.000Z',
                '00Q6700000yPl9eEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'September 2022',
                '2022-09-22T22:14:27.000Z',
                '00Q6700000yPmvlEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'September 2022',
                '2022-09-23T07:16:38.000Z',
                '00Q6700000yPnSlEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'September 2022',
                '2022-09-23T07:18:47.000Z',
                '00Q6700000yPnmzEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'September 2022',
                '2022-09-23T12:38:53.000Z',
                '00Q6700000yPpjgEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'September 2022',
                '2022-09-23T12:46:34.000Z',
                '00Q6700000yPpm1EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'September 2022',
                '2022-09-23T14:48:52.000Z',
                '00Q6700000yPqJkEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'September 2022',
                '2022-09-23T15:03:28.000Z',
                '00Q6700000yPqSvEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'September 2022',
                '2022-09-23T16:46:13.000Z',
                '00Q6700000yPqiUEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'September 2022',
                '2022-09-24T07:33:07.000Z',
                '00Q6700000yPs3YEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'September 2022',
                '2022-09-24T13:16:09.000Z',
                '00Q6700000yPsbaEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'September 2022',
                '2022-09-25T07:35:19.000Z',
                '00Q6700000yPyUVEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'September 2022',
                '2022-09-25T15:21:02.000Z',
                '00Q6700000yQ1AnEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - EVERLINE - DENNEY (BELFORT)',
            ],
            [
                'September 2022',
                '2022-09-25T15:30:14.000Z',
                '00Q6700000yQ1BHEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'September 2022',
                '2022-09-25T18:25:31.000Z',
                '00Q6700000yQ1LbEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'September 2022',
                '2022-09-26T07:43:35.000Z',
                '00Q6700000yQ20ZEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'September 2022',
                '2022-09-26T09:04:33.000Z',
                '00Q6700000yQ2CSEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'September 2022',
                '2022-09-26T12:17:46.000Z',
                '00Q6700000yQ2i5EAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'September 2022',
                '2022-09-26T18:04:39.000Z',
                '00Q6700000yQ3kMEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2022',
                '2022-09-27T08:25:18.000Z',
                '00Q6700000yQ4iREAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'September 2022',
                '2022-09-27T09:54:08.000Z',
                '00Q6700000yQ4vuEAC',
                'Qualified',
                'Email',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'September 2022',
                '2022-09-27T14:33:47.000Z',
                '00Q6700000yQ5o0EAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'September 2022',
                '2022-09-27T14:39:35.000Z',
                '00Q6700000yQ5otEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'September 2022',
                '2022-09-27T19:41:34.000Z',
                '00Q6700000yQ6g7EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'September 2022',
                '2022-09-28T07:57:11.000Z',
                '00Q6700000yQ7SpEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'September 2022',
                '2022-09-28T09:49:55.000Z',
                '00Q6700000yQ7kyEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'September 2022',
                '2022-09-28T09:56:01.000Z',
                '00Q6700000yQ7lwEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'September 2022',
                '2022-09-28T12:15:53.000Z',
                '00Q6700000yQ88BEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'September 2022',
                '2022-09-28T12:53:53.000Z',
                '00Q6700000yQ8CcEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AUTO PREMIUM 08 - CHARLEVILLE MEZIERES',
            ],
            [
                'September 2022',
                '2022-09-28T19:04:16.000Z',
                '00Q6700000yQ9PkEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)',
            ],
            [
                'September 2022',
                '2022-09-29T07:56:04.000Z',
                '00Q6700000yQAE4EAO',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'September 2022',
                '2022-09-29T09:15:28.000Z',
                '00Q6700000yQAQYEA4',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'September 2022',
                '2022-09-29T12:29:36.000Z',
                '00Q6700000yQB1yEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'September 2022',
                '2022-09-29T12:32:00.000Z',
                '00Q6700000yQB2SEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'September 2022',
                '2022-09-29T13:14:25.000Z',
                '00Q6700000yQB9iEAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'September 2022',
                '2022-09-30T08:26:11.000Z',
                '00Q6700000yQDYUEA4',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'September 2022',
                '2022-09-30T09:41:48.000Z',
                '00Q6700000yQDgSEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'September 2022',
                '2022-09-30T11:53:51.000Z',
                '00Q6700000yQDuFEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'September 2022',
                '2022-09-30T12:51:13.000Z',
                '00Q6700000yQE4YEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'September 2022',
                '2022-09-30T13:01:01.000Z',
                '00Q6700000yQE5vEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'September 2022',
                '2022-09-30T16:28:14.000Z',
                '00Q6700000yQEdmEAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'October 2022',
                '2022-10-01T16:39:50.000Z',
                '00Q6700000yQGCUEA4',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'October 2022',
                '2022-10-01T17:27:18.000Z',
                '00Q6700000yQGFTEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)',
            ],
            [
                'October 2022',
                '2022-10-02T12:50:49.000Z',
                '00Q6700000yQH0ZEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES',
            ],
            [
                'October 2022',
                '2022-10-02T13:40:46.000Z',
                '00Q6700000yQH47EAG',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'October 2022',
                '2022-10-02T15:54:03.000Z',
                '00Q6700000yQHC6EAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VILLEFRANCHE-SUR-SAÔNE',
            ],
            [
                'October 2022',
                '2022-10-03T09:58:25.000Z',
                '00Q6700000yQIBtEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'October 2022',
                '2022-10-03T13:15:32.000Z',
                '00Q6700000yQIVyEAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'October 2022',
                '2022-10-03T17:01:13.000Z',
                '00Q6700000yQJ5PEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'October 2022',
                '2022-10-03T19:43:05.000Z',
                '00Q6700000yQJMXEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'October 2022',
                '2022-10-04T08:01:46.000Z',
                '00Q6700000yQKAlEAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'October 2022',
                '2022-10-05T08:27:21.000Z',
                '00Q6700000yQNBWEA4',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'October 2022',
                '2022-10-05T08:44:48.000Z',
                '00Q6700000yQNDIEA4',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'October 2022',
                '2022-10-05T13:27:14.000Z',
                '00Q6700000yQNuPEAW',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'October 2022',
                '2022-10-05T14:11:17.000Z',
                '00Q6700000yQO1LEAW',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'October 2022',
                '2022-10-05T17:05:40.000Z',
                '00Q6700000yQOfBEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'October 2022',
                '2022-10-06T06:45:27.000Z',
                '00Q6700000yQPVFEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'October 2022',
                '2022-10-06T07:47:09.000Z',
                '00Q6700000yQPZSEA4',
                'Unqualified',
                'Direct',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'October 2022',
                '2022-10-06T07:59:26.000Z',
                '00Q6700000yQPb8EAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'October 2022',
                '2022-10-06T08:46:37.000Z',
                '00Q6700000yQPgmEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'October 2022',
                '2022-10-06T09:15:44.000Z',
                '00Q6700000yQPkUEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'October 2022',
                '2022-10-06T12:33:16.000Z',
                '00Q6700000yQQGkEAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'October 2022',
                '2022-10-06T14:59:50.000Z',
                '00Q6700000yQQbxEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'October 2022',
                '2022-10-07T07:52:36.000Z',
                '00Q6700000yQRtqEAG',
                'Unqualified',
                'Email',
                'France',
                'Single Home',
                'ABVV Chantilly',
            ],
            [
                'October 2022',
                '2022-10-07T08:04:03.000Z',
                '00Q6700000yQRvDEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)',
            ],
            [
                'October 2022',
                '2022-10-07T12:35:40.000Z',
                '00Q6700000yQTBuEAO',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'October 2022',
                '2022-10-07T12:48:38.000Z',
                '00Q6700000yQTDkEAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'October 2022',
                '2022-10-07T12:52:39.000Z',
                '00Q6700000yQTEOEA4',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'October 2022',
                '2022-10-07T12:56:04.000Z',
                '00Q6700000yQTF7EAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'October 2022',
                '2022-10-07T14:13:33.000Z',
                '00Q6700000yQTPREA4',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'October 2022',
                '2022-10-07T14:46:41.000Z',
                '00Q6700000yQTSvEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'October 2022',
                '2022-10-08T08:44:57.000Z',
                '00Q6700000yQUcMEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'October 2022',
                '2022-10-08T10:12:25.000Z',
                '00Q670000132cxxEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'October 2022',
                '2022-10-08T12:46:49.000Z',
                '00Q6700000yQV33EAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'October 2022',
                '2022-10-08T15:22:01.000Z',
                '00Q6700000yQVJGEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE DROITE - LORMONT (BORDEAUX)',
            ],
            [
                'October 2022',
                '2022-10-09T18:04:53.000Z',
                '00Q6700000yQWi2EAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'October 2022',
                '2022-10-09T19:36:54.000Z',
                '00Q6700000yQWoPEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'October 2022',
                '2022-10-10T07:50:49.000Z',
                '00Q6700000yQXNFEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'October 2022',
                '2022-10-10T12:31:19.000Z',
                '00Q6700000yQYVjEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'October 2022',
                '2022-10-10T16:15:21.000Z',
                '00Q6700000yQZFsEAO',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'October 2022',
                '2022-10-10T21:08:42.000Z',
                '00Q6700000yQaIKEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES',
            ],
            [
                'October 2022',
                '2022-10-11T08:15:26.000Z',
                '00Q6700000yQb3REAS',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'October 2022',
                '2022-10-11T08:23:42.000Z',
                '00Q6700000yQb4jEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'October 2022',
                '2022-10-11T08:50:44.000Z',
                '00Q6700000yQb7YEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'October 2022',
                '2022-10-11T09:00:24.000Z',
                '00Q6700000yQbAmEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'October 2022',
                '2022-10-11T12:47:45.000Z',
                '00Q6700000yQbjwEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo - THIBAULT AUTOMOBILES - ALENCON',
            ],
            [
                'October 2022',
                '2022-10-11T12:50:06.000Z',
                '00Q6700000yQbjxEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'October 2022',
                '2022-10-11T12:52:12.000Z',
                '00Q6700000yQbkGEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'October 2022',
                '2022-10-11T13:42:08.000Z',
                '00Q6700000yQbqiEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE DROITE - LORMONT (BORDEAUX)',
            ],
            [
                'October 2022',
                '2022-10-11T13:49:16.000Z',
                '00Q6700000yQbrgEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'October 2022',
                '2022-10-11T17:26:41.000Z',
                '00Q6700000yQcODEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS',
            ],
            [
                'October 2022',
                '2022-10-12T07:52:34.000Z',
                '00Q67000010J86EEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'October 2022',
                '2022-10-12T12:58:38.000Z',
                '00Q6700000yQe0uEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'October 2022',
                '2022-10-12T14:06:51.000Z',
                '00Q6700000yQeDZEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'October 2022',
                '2022-10-13T06:42:49.000Z',
                '00Q6700000yQfgcEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'October 2022',
                '2022-10-13T06:50:39.000Z',
                '00Q6700000zg7qjEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'October 2022',
                '2022-10-13T08:26:49.000Z',
                '00Q6700000yQgEUEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'October 2022',
                '2022-10-13T08:56:11.000Z',
                '00Q6700000yQgIWEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'October 2022',
                '2022-10-13T14:03:08.000Z',
                '00Q6700000yQhGQEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'October 2022',
                '2022-10-13T14:09:15.000Z',
                '00Q6700000yQhHnEAK',
                'Qualified',
                'Direct',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2022',
                '2022-10-13T19:06:39.000Z',
                '00Q6700000yQi5kEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'October 2022',
                '2022-10-14T09:17:55.000Z',
                '00Q6700000yQjy3EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'October 2022',
                '2022-10-14T14:32:00.000Z',
                '00Q6700000yQksIEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'October 2022',
                '2022-10-14T16:19:50.000Z',
                '00Q6700000yQlDJEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'October 2022',
                '2022-10-15T08:32:35.000Z',
                '00Q6700000yQm7wEAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'October 2022',
                '2022-10-15T12:11:31.000Z',
                '00Q6700000yQmSkEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'October 2022',
                '2022-10-15T14:28:02.000Z',
                '00Q6700000yQmeHEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'October 2022',
                '2022-10-15T14:31:02.000Z',
                '00Q6700000yQmegEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'October 2022',
                '2022-10-15T14:44:21.000Z',
                '00Q6700000yQmgcEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'October 2022',
                '2022-10-15T16:08:33.000Z',
                '00Q6700000yQmnsEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'October 2022',
                '2022-10-16T13:23:09.000Z',
                '00Q6700000yQnxtEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'October 2022',
                '2022-10-16T15:22:06.000Z',
                '00Q6700000yQo6bEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'October 2022',
                '2022-10-17T08:31:14.000Z',
                '00Q6700000yQpFjEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'October 2022',
                '2022-10-17T09:15:44.000Z',
                '00Q6700000yQpO7EAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'October 2022',
                '2022-10-17T11:39:56.000Z',
                '00Q6700000yQpgpEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'October 2022',
                '2022-10-18T09:46:41.000Z',
                '00Q6700000yQsGaEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'October 2022',
                '2022-10-18T10:07:02.000Z',
                '00Q6700000yQsKhEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'October 2022',
                '2022-10-18T14:04:25.000Z',
                '00Q6700000yQsxLEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'October 2022',
                '2022-10-18T16:51:38.000Z',
                '00Q6700000yQtUAEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'October 2022',
                '2022-10-18T16:55:32.000Z',
                '00Q6700000yQtUoEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'October 2022',
                '2022-10-19T13:19:51.000Z',
                '00Q6700000yQvAgEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
            ],
            [
                'October 2022',
                '2022-10-19T13:49:39.000Z',
                '00Q6700000yQvG0EAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'October 2022',
                '2022-10-20T12:33:28.000Z',
                '00Q6700000yQy7IEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'October 2022',
                '2022-10-20T13:20:45.000Z',
                '00Q6700000yQyIDEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'October 2022',
                '2022-10-20T13:25:33.000Z',
                '00Q6700000yQyJnEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - DIZY (EPERNAY)',
            ],
            [
                'October 2022',
                '2022-10-20T14:27:36.000Z',
                '00Q6700000yQyTYEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NÖRDIK STAR AUTOS - CHALON SUR SAÔNE',
            ],
            [
                'October 2022',
                '2022-10-20T15:18:07.000Z',
                '00Q67000015CxoTEAS',
                'Qualification',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - NIMES',
            ],
            [
                'October 2022',
                '2022-10-20T15:26:42.000Z',
                '00Q6700000yQyc1EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'October 2022',
                '2022-10-21T06:42:00.000Z',
                '00Q6700000yR0G2EAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'October 2022',
                '2022-10-21T12:03:11.000Z',
                '00Q6700000yR0xpEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'October 2022',
                '2022-10-21T13:08:33.000Z',
                '00Q6700000yR168EAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'October 2022',
                '2022-10-21T14:53:46.000Z',
                '00Q6700000yR1JrEAK',
                'Unqualified',
                'Email',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'October 2022',
                '2022-10-21T16:15:46.000Z',
                '00Q6700000yR1W3EAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'October 2022',
                '2022-10-22T04:23:55.000Z',
                '00Q6700000yR2GREA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - SAINT AVIT (MONT DE MARSAN)',
            ],
            [
                'October 2022',
                '2022-10-22T10:22:33.000Z',
                '00Q6700000yR2fCEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'October 2022',
                '2022-10-22T15:42:18.000Z',
                '00Q6700000yR35PEAS',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'October 2022',
                '2022-10-22T17:20:52.000Z',
                '00Q6700000yR3EHEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'October 2022',
                '2022-10-22T18:20:11.000Z',
                '00Q6700000yR3HfEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'October 2022',
                '2022-10-23T19:17:41.000Z',
                '00Q6700000yR4b2EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'October 2022',
                '2022-10-24T09:31:21.000Z',
                '00Q6700000yR5RkEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'October 2022',
                '2022-10-24T12:24:23.000Z',
                '00Q6700000yR5p3EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'October 2022',
                '2022-10-24T15:10:53.000Z',
                '00Q6700000yR6FuEAK',
                'Unqualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'October 2022',
                '2022-10-25T08:27:27.000Z',
                '00Q6700000yR7Q9EAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'October 2022',
                '2022-10-25T11:58:41.000Z',
                '00Q6700000yR8IFEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'October 2022',
                '2022-10-25T15:12:12.000Z',
                '00Q6700000yR8wWEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'October 2022',
                '2022-10-25T16:32:23.000Z',
                '00Q6700000yR93SEAS',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'October 2022',
                '2022-10-26T16:01:36.000Z',
                '00Q6700000yRCC6EAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2022',
                '2022-10-27T14:19:58.000Z',
                '00Q6700000yRDz4EAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'October 2022',
                '2022-10-27T14:45:36.000Z',
                '00Q6700000yRE2rEAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'October 2022',
                '2022-10-27T16:19:02.000Z',
                '00Q6700000yREGAEA4',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - ROISSY EN FRANCE',
            ],
            [
                'October 2022',
                '2022-10-28T14:18:42.000Z',
                '00Q6700000yRFjXEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'October 2022',
                '2022-10-28T15:03:05.000Z',
                '00Q6700000yRFtKEAW',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'October 2022',
                '2022-10-29T07:43:28.000Z',
                '00Q6700000yRGnOEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'October 2022',
                '2022-10-29T08:09:51.000Z',
                '00Q6700000yRRVCEA4',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'October 2022',
                '2022-10-29T09:32:22.000Z',
                '00Q6700000yRGujEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'October 2022',
                '2022-10-29T09:41:13.000Z',
                '00Q6700000yRGvNEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'October 2022',
                '2022-10-30T19:12:07.000Z',
                '00Q67000015C8NmEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'October 2022',
                '2022-10-31T13:03:53.000Z',
                '00Q6700000yRSZ0EAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'November 2022',
                '2022-11-02T08:19:55.000Z',
                '00Q6700000yRLX8EAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Evreux - NORMANVILLE (EVREUX)',
            ],
            [
                'November 2022',
                '2022-11-02T13:10:29.000Z',
                '00Q6700000yRLv9EAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'November 2022',
                '2022-11-02T22:03:29.000Z',
                '00Q6700000yRNo2EAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AUTO PREMIUM 08 - CHARLEVILLE MEZIERES',
            ],
            [
                'November 2022',
                '2022-11-03T14:01:28.000Z',
                '00Q6700000yROqsEAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'November 2022',
                '2022-11-03T14:49:28.000Z',
                '00Q6700000yRP34EAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'November 2022',
                '2022-11-04T08:49:47.000Z',
                '00Q6700000yRQGpEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - ROISSY EN FRANCE',
            ],
            [
                'November 2022',
                '2022-11-04T10:28:53.000Z',
                '00Q6700000yRQPmEAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'November 2022',
                '2022-11-04T13:59:03.000Z',
                '00Q6700000ySOUoEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'November 2022',
                '2022-11-04T15:19:57.000Z',
                '00Q6700000yRQpREAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'November 2022',
                '2022-11-04T15:43:35.000Z',
                '00Q6700000yRQuiEAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'November 2022',
                '2022-11-04T16:15:56.000Z',
                '00Q6700000yRQyGEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'November 2022',
                '2022-11-04T17:11:43.000Z',
                '00Q6700000yRR4xEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'November 2022',
                '2022-11-04T17:17:55.000Z',
                '00Q6700000yRR5bEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'November 2022',
                '2022-11-05T09:42:22.000Z',
                '00Q6700000yRRTVEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'November 2022',
                '2022-11-05T13:37:56.000Z',
                '00Q6700000yRRd1EAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'November 2022',
                '2022-11-05T14:31:30.000Z',
                '00Q6700000yRRgoEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'November 2022',
                '2022-11-06T10:19:01.000Z',
                '00Q6700000yRSH1EAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'November 2022',
                '2022-11-06T11:10:42.000Z',
                '00Q6700000yRSJqEAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'November 2022',
                '2022-11-07T09:28:26.000Z',
                '00Q6700000yRTEEEA4',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'November 2022',
                '2022-11-07T10:46:18.000Z',
                '00Q6700000yRTK2EAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'November 2022',
                '2022-11-07T14:47:51.000Z',
                '00Q6700000yRTifEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'November 2022',
                '2022-11-07T14:50:23.000Z',
                '00Q6700000yRTjJEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'November 2022',
                '2022-11-07T17:19:11.000Z',
                '00Q6700000yRU1rEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'November 2022',
                '2022-11-08T09:54:00.000Z',
                '00Q6700000yRVRVEA4',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'November 2022',
                '2022-11-08T12:05:02.000Z',
                '00Q6700000yRVfVEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'November 2022',
                '2022-11-08T14:38:42.000Z',
                '00Q6700000yRVwUEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'November 2022',
                '2022-11-08T14:57:07.000Z',
                '00Q6700000yRW76EAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'November 2022',
                '2022-11-08T18:18:25.000Z',
                '00Q6700000yRWfqEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'November 2022',
                '2022-11-09T08:38:47.000Z',
                '00Q6700000yRXH2EAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'November 2022',
                '2022-11-09T16:42:56.000Z',
                '00Q6700000yRYKeEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'November 2022',
                '2022-11-09T17:31:30.000Z',
                '00Q6700000yRYVcEAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - DISTRE (SAUMUR)',
            ],
            [
                'November 2022',
                '2022-11-10T08:14:59.000Z',
                '00Q6700000yRZPsEAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'November 2022',
                '2022-11-10T15:47:42.000Z',
                '00Q6700000yRhnSEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'November 2022',
                '2022-11-10T17:22:30.000Z',
                '00Q6700000yRik5EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - SAINTES',
            ],
            [
                'November 2022',
                '2022-11-11T15:22:09.000Z',
                '00Q6700000yRo88EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Groupe Saint Christophe',
            ],
            [
                'November 2022',
                '2022-11-12T15:55:46.000Z',
                '00Q6700000yRtz9EAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  CAR'S SERVICES - ONET LE CHATEAU (RODEZ)",
            ],
            [
                'November 2022',
                '2022-11-14T08:32:15.000Z',
                '00Q6700000yRzWvEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'November 2022',
                '2022-11-14T13:51:37.000Z',
                '00Q6700000yS1hgEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'November 2022',
                '2022-11-14T15:04:12.000Z',
                '00Q6700000yS2CKEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'November 2022',
                '2022-11-14T16:29:45.000Z',
                '00Q6700000yS2uYEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'November 2022',
                '2022-11-14T19:45:37.000Z',
                '00Q6700000yS4PVEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'November 2022',
                '2022-11-15T16:05:43.000Z',
                '00Q6700000yS8nxEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'November 2022',
                '2022-11-15T16:31:06.000Z',
                '00Q67000010JfV3EAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'November 2022',
                '2022-11-15T17:13:18.000Z',
                '00Q6700000yS9KrEAK',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'November 2022',
                '2022-11-16T06:42:38.000Z',
                '00Q6700000ySBpvEAG',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)',
            ],
            [
                'November 2022',
                '2022-11-16T08:46:15.000Z',
                '00Q6700000ySCHLEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'November 2022',
                '2022-11-17T13:57:01.000Z',
                '00Q6700000ySKSZEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'November 2022',
                '2022-11-17T16:33:30.000Z',
                '00Q6700000ySLd4EAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            [
                'November 2022',
                '2022-11-17T16:53:51.000Z',
                '00Q6700000ySLmqEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - EVERLINE - DENNEY (BELFORT)',
            ],
            [
                'November 2022',
                '2022-11-18T10:51:18.000Z',
                '00Q6700000ySOphEAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'November 2022',
                '2022-11-18T11:48:32.000Z',
                '00Q6700000ySPBnEAO',
                'Qualified',
                'Email',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'November 2022',
                '2022-11-18T14:56:38.000Z',
                '00Q6700000ySQ7DEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'November 2022',
                '2022-11-18T15:59:52.000Z',
                '00Q6700000ySQZUEA4',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - ROISSY EN FRANCE',
            ],
            [
                'November 2022',
                '2022-11-19T06:48:58.000Z',
                '00Q6700000ySScKEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'November 2022',
                '2022-11-19T11:01:44.000Z',
                '00Q6700000ySTkjEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'November 2022',
                '2022-11-19T14:37:56.000Z',
                '00Q6700000ySUiAEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'November 2022',
                '2022-11-19T15:55:05.000Z',
                '00Q6700000ySV7QEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'November 2022',
                '2022-11-21T11:08:45.000Z',
                '00Q6700000ySaK3EAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'November 2022',
                '2022-11-21T14:33:44.000Z',
                '00Q6700000ySbLpEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'November 2022',
                '2022-11-22T10:25:28.000Z',
                '00Q6700000ySes8EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'November 2022',
                '2022-11-22T13:37:57.000Z',
                '00Q6700000ySfpFEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'November 2022',
                '2022-11-22T13:39:52.000Z',
                '00Q6700000ySfpoEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'November 2022',
                '2022-11-22T14:55:24.000Z',
                '00Q6700000ySgG6EAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'November 2022',
                '2022-11-23T09:26:44.000Z',
                '00Q6700000ySitlEAC',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'November 2022',
                '2022-11-23T10:16:46.000Z',
                '00Q6700000ySjCEEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'November 2022',
                '2022-11-23T12:22:54.000Z',
                '00Q6700000ySjtXEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'November 2022',
                '2022-11-23T13:28:39.000Z',
                '00Q6700000ySkDJEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'November 2022',
                '2022-11-23T15:51:23.000Z',
                '00Q6700000ySmHsEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'November 2022',
                '2022-11-23T16:00:27.000Z',
                '00Q6700000ySmL6EAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'November 2022',
                '2022-11-23T17:02:48.000Z',
                '00Q6700000ySmrCEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'November 2022',
                '2022-11-24T10:26:09.000Z',
                '00Q6700000ySpE4EAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'November 2022',
                '2022-11-24T10:42:50.000Z',
                '00Q6700000ySpJdEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - LIMOGES',
            ],
            [
                'November 2022',
                '2022-11-25T15:24:28.000Z',
                '00Q67000010ICwCEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2022',
                '2022-11-26T09:27:06.000Z',
                '00Q67000010IEpiEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
            ],
            [
                'November 2022',
                '2022-11-26T13:52:53.000Z',
                '00Q67000010IFwuEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'November 2022',
                '2022-11-26T14:43:29.000Z',
                '00Q67000010IG7xEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'November 2022',
                '2022-11-26T14:59:44.000Z',
                '00Q67000010IGCYEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'November 2022',
                '2022-11-26T21:26:29.000Z',
                '00Q67000010IHQvEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'November 2022',
                '2022-11-28T08:57:36.000Z',
                '00Q67000010IK5XEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                "Volvo -  CAR'S SERVICES - ONET LE CHATEAU (RODEZ)",
            ],
            [
                'November 2022',
                '2022-11-28T10:13:28.000Z',
                '00Q67000010IKRTEA4',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'November 2022',
                '2022-11-28T14:44:31.000Z',
                '00Q67000010ILfJEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'November 2022',
                '2022-11-28T15:15:36.000Z',
                '00Q67000010ILq9EAG',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'November 2022',
                '2022-11-28T15:28:23.000Z',
                '00Q67000010IM5kEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'November 2022',
                '2022-11-28T16:34:10.000Z',
                '00Q67000010IMREEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AUTO PREMIUM 08 - CHARLEVILLE MEZIERES',
            ],
            [
                'November 2022',
                '2022-11-28T18:36:09.000Z',
                '00Q67000010IN8FEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - EVERLINE - DENNEY (BELFORT)',
            ],
            [
                'November 2022',
                '2022-11-28T21:02:55.000Z',
                '00Q67000010INX2EAO',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'November 2022',
                '2022-11-29T08:54:37.000Z',
                '00Q67000010IOW0EAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'November 2022',
                '2022-11-29T10:01:33.000Z',
                '00Q67000010IOp6EAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'November 2022',
                '2022-11-29T13:22:46.000Z',
                '00Q67000010IPmVEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'November 2022',
                '2022-11-30T08:16:18.000Z',
                '00Q67000010ISYJEA4',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'December 2022',
                '2022-12-01T14:46:20.000Z',
                '00Q67000010IYJbEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'December 2022',
                '2022-12-01T16:55:54.000Z',
                '00Q67000010IZC7EAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'December 2022',
                '2022-12-02T10:30:32.000Z',
                '00Q67000010IbA3EAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'December 2022',
                '2022-12-02T14:58:35.000Z',
                '00Q67000010Ic8mEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'December 2022',
                '2022-12-02T15:02:19.000Z',
                '00Q67000010Ic9GEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'December 2022',
                '2022-12-02T15:06:37.000Z',
                '00Q67000010IcAOEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'December 2022',
                '2022-12-02T15:26:40.000Z',
                '00Q67000010IcE2EAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'December 2022',
                '2022-12-02T15:41:20.000Z',
                '00Q67000010IcHPEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'December 2022',
                '2022-12-02T17:23:08.000Z',
                '00Q67000010IchyEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'December 2022',
                '2022-12-02T18:00:41.000Z',
                '00Q67000010IctFEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'December 2022',
                '2022-12-02T19:50:04.000Z',
                '00Q67000010IdAkEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'December 2022',
                '2022-12-03T10:15:14.000Z',
                '00Q67000010IdymEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'December 2022',
                '2022-12-03T14:00:43.000Z',
                '00Q67000010IeaMEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'December 2022',
                '2022-12-03T16:03:46.000Z',
                '00Q67000010Iew3EAC',
                'Meeting Scheduled',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'December 2022',
                '2022-12-03T16:04:13.000Z',
                '00Q67000010IeuSEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'December 2022',
                '2022-12-03T16:11:01.000Z',
                '00Q67000010IexQEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'December 2022',
                '2022-12-04T09:23:40.000Z',
                '00Q67000010Ig6YEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'December 2022',
                '2022-12-05T14:14:31.000Z',
                '00Q67000010Ij2TEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'December 2022',
                '2022-12-05T15:17:34.000Z',
                '00Q67000010IjFrEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'December 2022',
                '2022-12-05T15:23:20.000Z',
                '00Q67000010IjGzEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
            ],
            [
                'December 2022',
                '2022-12-05T15:43:09.000Z',
                '00Q67000010IjKwEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'December 2022',
                '2022-12-05T16:32:02.000Z',
                '00Q67000010Ik4zEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'December 2022',
                '2022-12-06T15:11:45.000Z',
                '00Q67000010ImOQEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'December 2022',
                '2022-12-06T16:12:13.000Z',
                '00Q67000010ImenEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'December 2022',
                '2022-12-06T17:06:38.000Z',
                '00Q67000010Ims2EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'December 2022',
                '2022-12-06T17:17:17.000Z',
                '00Q67000010In6NEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'December 2022',
                '2022-12-06T18:48:20.000Z',
                '00Q67000010InLwEAK',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'December 2022',
                '2022-12-07T10:04:59.000Z',
                '00Q67000010IoBPEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'December 2022',
                '2022-12-07T12:30:44.000Z',
                '00Q67000010IoXnEAK',
                'Qualified',
                'Email',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'December 2022',
                '2022-12-07T13:15:29.000Z',
                '00Q67000010IoeAEAS',
                'Qualified',
                'Email',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'December 2022',
                '2022-12-07T14:30:13.000Z',
                '00Q67000010Iop9EAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'December 2022',
                '2022-12-07T16:44:02.000Z',
                '00Q67000010IpMoEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'December 2022',
                '2022-12-07T22:35:33.000Z',
                '00Q67000010IpzyEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'December 2022',
                '2022-12-08T06:53:53.000Z',
                '00Q67000010IqDdEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'December 2022',
                '2022-12-08T11:07:16.000Z',
                '00Q67000010IqeeEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE",
            ],
            [
                'December 2022',
                '2022-12-08T11:10:42.000Z',
                '00Q67000010IqetEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'December 2022',
                '2022-12-08T15:23:32.000Z',
                '00Q67000010Ir7lEAC',
                'Meeting Scheduled',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'December 2022',
                '2022-12-08T20:34:07.000Z',
                '00Q67000010IrYwEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'December 2022',
                '2022-12-08T22:28:37.000Z',
                '00Q67000010IrfdEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'December 2022',
                '2022-12-09T08:08:26.000Z',
                '00Q67000010Is01EAC',
                'Meeting Scheduled',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'December 2022',
                '2022-12-09T13:28:34.000Z',
                '00Q67000010IsfUEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'December 2022',
                '2022-12-12T10:04:25.000Z',
                '00Q67000010IwrSEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'December 2022',
                '2022-12-12T10:54:26.000Z',
                '00Q67000010Ix00EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'December 2022',
                '2022-12-12T12:42:14.000Z',
                '00Q67000010IxDsEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'December 2022',
                '2022-12-13T10:52:46.000Z',
                '00Q67000010J07WEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)',
            ],
            [
                'December 2022',
                '2022-12-13T15:44:12.000Z',
                '00Q67000010J0n7EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'December 2022',
                '2022-12-14T10:19:18.000Z',
                '00Q67000010J2E6EAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - DISTRE (SAUMUR)',
            ],
            [
                'December 2022',
                '2022-12-14T10:39:06.000Z',
                '00Q67000010J2GMEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'December 2022',
                '2022-12-14T15:00:50.000Z',
                '00Q67000010J2tWEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE DROITE - LORMONT (BORDEAUX)',
            ],
            [
                'December 2022',
                '2022-12-15T12:08:46.000Z',
                '00Q67000010J4rKEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'December 2022',
                '2022-12-15T15:55:19.000Z',
                '00Q67000010J5bTEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'December 2022',
                '2022-12-15T16:24:53.000Z',
                '00Q67000010J5gTEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'December 2022',
                '2022-12-16T10:46:11.000Z',
                '00Q67000010J70HEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            [
                'December 2022',
                '2022-12-17T08:40:19.000Z',
                '00Q67000010J9XaEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'December 2022',
                '2022-12-17T09:46:31.000Z',
                '00Q67000010J9ebEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'December 2022',
                '2022-12-17T20:14:38.000Z',
                '00Q67000010JAyrEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'December 2022',
                '2022-12-19T07:50:59.000Z',
                '00Q67000010JCiWEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'December 2022',
                '2022-12-19T09:04:29.000Z',
                '00Q67000010JCsMEAW',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'December 2022',
                '2022-12-19T13:23:15.000Z',
                '00Q67000010JDkSEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'December 2022',
                '2022-12-19T13:59:02.000Z',
                '00Q67000010JDoFEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2022',
                '2022-12-19T14:33:13.000Z',
                '00Q67000010JDxWEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'December 2022',
                '2022-12-19T17:04:54.000Z',
                '00Q67000010JEuIEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'December 2022',
                '2022-12-19T19:10:32.000Z',
                '00Q67000010JFULEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'December 2022',
                '2022-12-20T12:40:00.000Z',
                '00Q67000010JHduEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - SAINT MARTIN SUR LE PRE (CHALONS EN CHAMPAGNE)',
            ],
            [
                'December 2022',
                '2022-12-20T14:40:09.000Z',
                '00Q67000010JHtOEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'December 2022',
                '2022-12-21T10:21:00.000Z',
                '00Q67000010JJiNEAW',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'December 2022',
                '2022-12-21T13:18:04.000Z',
                '00Q67000010JK6tEAG',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'December 2022',
                '2022-12-21T13:32:50.000Z',
                '00Q67000010JK8BEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'December 2022',
                '2022-12-22T08:49:59.000Z',
                '00Q67000010JLj0EAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'December 2022',
                '2022-12-22T09:57:56.000Z',
                '00Q67000010JLsWEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo Car France',
            ],
            [
                'December 2022',
                '2022-12-22T12:25:57.000Z',
                '00Q67000010JMHWEA4',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'December 2022',
                '2022-12-22T13:23:01.000Z',
                '00Q67000010JMOcEAO',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'December 2022',
                '2022-12-22T17:46:14.000Z',
                '00Q67000010JNdqEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'December 2022',
                '2022-12-23T15:26:52.000Z',
                '00Q67000010JPWwEAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'December 2022',
                '2022-12-23T17:04:00.000Z',
                '00Q67000010JPuFEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'December 2022',
                '2022-12-26T17:44:52.000Z',
                '00Q67000010JUCfEAO',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'December 2022',
                '2022-12-27T20:37:02.000Z',
                '00Q67000010JViwEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'December 2022',
                '2022-12-28T07:04:31.000Z',
                '00Q67000010JVvhEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)',
            ],
            [
                'December 2022',
                '2022-12-28T15:13:50.000Z',
                '00Q67000010JWgGEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'December 2022',
                '2022-12-28T18:28:38.000Z',
                '00Q67000010JWunEAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - DISTRE (SAUMUR)',
            ],
            [
                'December 2022',
                '2022-12-29T09:51:07.000Z',
                '00Q67000010JXeHEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'December 2022',
                '2022-12-29T10:43:30.000Z',
                '00Q67000010JXm3EAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'December 2022',
                '2022-12-29T12:43:37.000Z',
                '00Q67000010JXuqEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'December 2022',
                '2022-12-29T19:04:58.000Z',
                '00Q67000010JYyPEAW',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'December 2022',
                '2022-12-29T19:07:34.000Z',
                '00Q67000010JYyZEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'December 2022',
                '2022-12-30T13:36:47.000Z',
                '00Q67000010JZmZEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'January 2023',
                '2023-01-01T15:41:56.000Z',
                '00Q67000010JbDHEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'January 2023',
                '2023-01-01T18:20:16.000Z',
                '00Q67000010JbG1EAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE JFC LISIEUX - LISIEUX',
            ],
            [
                'January 2023',
                '2023-01-02T10:58:37.000Z',
                '00Q67000010JbdKEAS',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI',
            ],
            [
                'January 2023',
                '2023-01-02T17:01:02.000Z',
                '00Q67000010JbzuEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'January 2023',
                '2023-01-03T12:46:45.000Z',
                '00Q67000010Jcj9EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'January 2023',
                '2023-01-04T13:52:12.000Z',
                '00Q67000010JexcEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PREMIUM AUTOMOBILES - AUXERRE',
            ],
            [
                'January 2023',
                '2023-01-04T15:35:23.000Z',
                '00Q67000010Jfa8EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'January 2023',
                '2023-01-04T16:43:24.000Z',
                '00Q67000010Jfl1EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'January 2023',
                '2023-01-05T09:13:25.000Z',
                '00Q67000010JgadEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'January 2023',
                '2023-01-05T10:49:21.000Z',
                '00Q67000010JgkJEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NÖRDIK STAR AUTOS - CHALON SUR SAÔNE',
            ],
            [
                'January 2023',
                '2023-01-05T11:14:16.000Z',
                '00Q67000010JgmPEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'January 2023',
                '2023-01-05T20:57:54.000Z',
                '00Q67000010JhlXEAS',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - CHAMPNIERS (ANGOULEME)',
            ],
            [
                'January 2023',
                '2023-01-06T08:47:13.000Z',
                '00Q67000010Ji3mEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'January 2023',
                '2023-01-06T11:01:14.000Z',
                '00Q67000010JiELEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'January 2023',
                '2023-01-06T17:21:18.000Z',
                '00Q67000010JicmEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'January 2023',
                '2023-01-07T10:07:03.000Z',
                '00Q67000010JjBUEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'January 2023',
                '2023-01-07T10:19:05.000Z',
                '00Q67000010JjC8EAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'January 2023',
                '2023-01-08T19:56:33.000Z',
                '00Q67000010JkyoEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'January 2023',
                '2023-01-09T09:37:09.000Z',
                '00Q67000010JuuSEAS',
                'Meeting Scheduled',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'January 2023',
                '2023-01-09T13:59:46.000Z',
                '00Q67000010JwCUEA0',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            [
                'January 2023',
                '2023-01-09T17:37:34.000Z',
                '00Q67000010Jwh8EAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'January 2023',
                '2023-01-10T08:45:23.000Z',
                '00Q67000010JxRkEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            [
                'January 2023',
                '2023-01-10T08:57:39.000Z',
                '00Q67000010JxY2EAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'January 2023',
                '2023-01-10T10:42:28.000Z',
                '00Q67000010JyQ9EAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - ROISSY EN FRANCE',
            ],
            [
                'January 2023',
                '2023-01-10T10:49:18.000Z',
                '00Q67000010JyRREA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'January 2023',
                '2023-01-10T14:22:20.000Z',
                '00Q67000010Jz2mEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'January 2023',
                '2023-01-10T16:10:44.000Z',
                '00Q67000010JzLnEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - CHAMPNIERS (ANGOULEME)',
            ],
            [
                'January 2023',
                '2023-01-10T16:19:14.000Z',
                '00Q67000010JzNjEAK',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'January 2023',
                '2023-01-11T14:19:14.000Z',
                '00Q67000010K0tzEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'January 2023',
                '2023-01-12T08:32:34.000Z',
                '00Q67000010K2HcEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'January 2023',
                '2023-01-12T12:28:48.000Z',
                '00Q67000010K2f8EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            [
                'January 2023',
                '2023-01-12T14:12:22.000Z',
                '00Q67000010K2pwEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'January 2023',
                '2023-01-13T15:21:03.000Z',
                '00Q6700000zdQ46EAE',
                'Qualified',
                'Direct',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - CHAMPNIERS (ANGOULEME)',
            ],
            [
                'January 2023',
                '2023-01-13T15:27:06.000Z',
                '00Q6700000zdQ4uEAE',
                'Qualified',
                'Volkswagen Form',
                'France',
                'Company',
                'Volvo -  PERICAUD AUTOMOBILES - CHAMPNIERS (ANGOULEME)',
            ],
            [
                'January 2023',
                '2023-01-14T21:57:13.000Z',
                '00Q6700000zdRczEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'January 2023',
                '2023-01-16T07:55:35.000Z',
                '00Q6700000zdSy9EAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'January 2023',
                '2023-01-16T08:29:41.000Z',
                '00Q6700000zdT1mEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'January 2023',
                '2023-01-16T10:40:42.000Z',
                '00Q6700000zdTFeEAM',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'January 2023',
                '2023-01-16T14:03:34.000Z',
                '00Q6700000zdTXsEAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'January 2023',
                '2023-01-17T08:19:20.000Z',
                '00Q6700000zdUbGEAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'January 2023',
                '2023-01-17T09:17:12.000Z',
                '00Q6700000zdUfrEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'January 2023',
                '2023-01-18T09:28:01.000Z',
                '00Q6700000zdWeCEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VILLEFRANCHE-SUR-SAÔNE',
            ],
            [
                'January 2023',
                '2023-01-18T13:58:51.000Z',
                '00Q6700000zdX2xEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'January 2023',
                '2023-01-18T16:10:37.000Z',
                '00Q6700000zdXJPEA2',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'January 2023',
                '2023-01-18T16:23:25.000Z',
                '00Q6700000zdXLkEAM',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'January 2023',
                '2023-01-18T16:30:06.000Z',
                '00Q6700000zdXNHEA2',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'January 2023',
                '2023-01-18T16:34:06.000Z',
                '00Q6700000zdXOeEAM',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'January 2023',
                '2023-01-18T16:48:27.000Z',
                '00Q6700000zdXRTEA2',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'January 2023',
                '2023-01-18T16:53:05.000Z',
                '00Q6700000zdXS7EAM',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'January 2023',
                '2023-01-18T17:02:30.000Z',
                '00Q6700000zdXTKEA2',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'January 2023',
                '2023-01-18T19:13:53.000Z',
                '00Q6700000zdXg6EAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'January 2023',
                '2023-01-19T09:04:05.000Z',
                '00Q6700000zdYIzEAM',
                'Meeting Scheduled',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'January 2023',
                '2023-01-19T09:42:18.000Z',
                '00Q6700000zdYNaEAM',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'January 2023',
                '2023-01-20T09:41:26.000Z',
                '00Q6700000zdaa8EAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'January 2023',
                '2023-01-20T10:11:59.000Z',
                '00Q670000132YE9EAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'January 2023',
                '2023-01-20T10:34:04.000Z',
                '00Q6700000zdae0EAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'January 2023',
                '2023-01-20T14:14:17.000Z',
                '00Q6700000zdaxqEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'January 2023',
                '2023-01-20T14:52:10.000Z',
                '00Q6700000zdb3UEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'January 2023',
                '2023-01-22T13:58:33.000Z',
                '00Q6700000zddJgEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - LIMOGES',
            ],
            [
                'January 2023',
                '2023-01-23T09:49:54.000Z',
                '00Q6700000zdeDGEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'January 2023',
                '2023-01-23T14:10:20.000Z',
                '00Q6700000zdfapEAA',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'LARSON by Autosphere',
            ],
            [
                'January 2023',
                '2023-01-24T12:10:26.000Z',
                '00Q6700000zdhkUEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'January 2023',
                '2023-01-24T14:21:56.000Z',
                '00Q6700000zdi0rEAA',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)',
            ],
            [
                'January 2023',
                '2023-01-24T17:03:53.000Z',
                '00Q6700000zdiZqEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'January 2023',
                '2023-01-24T17:15:06.000Z',
                '00Q6700000zdib8EAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'January 2023',
                '2023-01-25T10:26:50.000Z',
                '00Q6700000zdjoHEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE SAINT ETIENNE - SAINT ETIENNE',
            ],
            [
                'January 2023',
                '2023-01-25T15:35:00.000Z',
                '00Q6700000zdkvlEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'January 2023',
                '2023-01-26T15:47:54.000Z',
                '00Q6700000zdnTcEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'January 2023',
                '2023-01-27T13:45:14.000Z',
                '00Q6700000zdpVMEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'ELYPSE AUTO',
            ],
            [
                'January 2023',
                '2023-01-27T16:41:40.000Z',
                '00Q6700000zdpoYEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'January 2023',
                '2023-01-28T15:12:42.000Z',
                '00Q6700000zdquzEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'January 2023',
                '2023-01-28T16:01:14.000Z',
                '00Q6700000zdqycEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'January 2023',
                '2023-01-28T16:41:29.000Z',
                '00Q6700000zdr0sEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'January 2023',
                '2023-01-28T18:27:01.000Z',
                '00Q6700000zdr8DEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'January 2023',
                '2023-01-29T16:45:10.000Z',
                '00Q6700000zds3OEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)',
            ],
            [
                'January 2023',
                '2023-01-30T07:46:02.000Z',
                '00Q6700000zdsefEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'January 2023',
                '2023-01-30T13:20:53.000Z',
                '00Q6700000zdtCYEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'January 2023',
                '2023-01-30T14:26:25.000Z',
                '00Q6700000zdtMBEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'January 2023',
                '2023-01-30T15:59:20.000Z',
                '00Q6700000zdtcAEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'February 2023',
                '2023-02-01T10:34:18.000Z',
                '00Q6700000zdzBREAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'ELYPSE AUTO',
            ],
            [
                'February 2023',
                '2023-02-01T14:59:22.000Z',
                '00Q6700000zdzuHEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'February 2023',
                '2023-02-02T12:06:08.000Z',
                '00Q6700000ze39gEAA',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'February 2023',
                '2023-02-03T15:00:10.000Z',
                '00Q6700000ze5SPEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'February 2023',
                '2023-02-04T10:39:57.000Z',
                '00Q6700000ze6TiEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'February 2023',
                '2023-02-04T11:13:34.000Z',
                '00Q6700000ze6WwEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'February 2023',
                '2023-02-04T13:13:56.000Z',
                '00Q6700000ze6fKEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'February 2023',
                '2023-02-05T14:18:22.000Z',
                '00Q6700000ze7biEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PREMIUM AUTOMOBILES - AUXERRE',
            ],
            [
                'February 2023',
                '2023-02-05T15:39:03.000Z',
                '00Q6700000ze7hMEAQ',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'February 2023',
                '2023-02-06T08:38:49.000Z',
                '00Q6700000ze8NcEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'February 2023',
                '2023-02-06T10:52:16.000Z',
                '00Q6700000ze8cwEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'February 2023',
                '2023-02-07T09:02:46.000Z',
                '00Q6700000zeBRMEA2',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'February 2023',
                '2023-02-07T14:29:11.000Z',
                '00Q6700000zeEMNEA2',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'February 2023',
                '2023-02-07T16:26:35.000Z',
                '00Q6700000zeCXFEA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  FELIX FAURE SAINT ETIENNE - SAINT ETIENNE',
            ],
            [
                'February 2023',
                '2023-02-07T16:28:23.000Z',
                '00Q6700000zeCXeEAM',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            [
                'February 2023',
                '2023-02-08T08:05:56.000Z',
                '00Q6700000zeE3SEAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE SAINT ETIENNE - SAINT ETIENNE',
            ],
            [
                'February 2023',
                '2023-02-08T13:38:04.000Z',
                '00Q6700000zeEe2EAE',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'February 2023',
                '2023-02-09T15:58:29.000Z',
                '00Q6700000zeHI8EAM',
                'Qualified',
                'Zeplug',
                'France',
                'Company',
                'ChargeGuru Business Services',
            ],
            [
                'February 2023',
                '2023-02-09T16:22:14.000Z',
                '00Q6700000zeHLHEA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'February 2023',
                '2023-02-11T10:44:58.000Z',
                '00Q6700000zeKjuEAE',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'February 2023',
                '2023-02-12T15:19:55.000Z',
                '00Q6700000zeLx9EAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'February 2023',
                '2023-02-13T09:03:04.000Z',
                '00Q6700000zeMjXEAU',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'February 2023',
                '2023-02-13T12:36:12.000Z',
                '00Q6700000zeNKEEA2',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            [
                'February 2023',
                '2023-02-15T08:30:09.000Z',
                '00Q6700000zeRMHEA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'February 2023',
                '2023-02-16T09:51:47.000Z',
                '00Q6700000zeTN3EAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE',
            ],
            [
                'February 2023',
                '2023-02-16T11:53:52.000Z',
                '00Q6700000zeTXnEAM',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'February 2023',
                '2023-02-16T14:11:32.000Z',
                '00Q6700000zeTmnEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'February 2023',
                '2023-02-16T14:38:13.000Z',
                '00Q6700000zeTqxEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'February 2023',
                '2023-02-17T14:08:44.000Z',
                '00Q6700000zeVcsEAE',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'February 2023',
                '2023-02-17T15:41:00.000Z',
                '00Q6700000zeVnAEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'February 2023',
                '2023-02-19T19:31:51.000Z',
                '00Q6700000zeYk1EAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
            ],
            [
                'February 2023',
                '2023-02-20T09:02:31.000Z',
                '00Q6700000zeZj5EAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)',
            ],
            [
                'February 2023',
                '2023-02-20T15:35:11.000Z',
                '00Q6700000zeaclEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'February 2023',
                '2023-02-21T14:46:47.000Z',
                '00Q6700000zecdFEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)',
            ],
            [
                'February 2023',
                '2023-02-21T17:51:47.000Z',
                '00Q6700000zedNjEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2023',
                '2023-02-23T08:52:06.000Z',
                '00Q6700000zegiaEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'February 2023',
                '2023-02-23T20:46:08.000Z',
                '00Q6700000zei1gEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'February 2023',
                '2023-02-24T09:08:58.000Z',
                '00Q6700000zeihDEAQ',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE',
            ],
            [
                'February 2023',
                '2023-02-24T09:29:42.000Z',
                '00Q6700000zeijpEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'February 2023',
                '2023-02-25T09:50:33.000Z',
                '00Q6700000zekiPEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'February 2023',
                '2023-02-25T14:13:22.000Z',
                '00Q6700000zepsoEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'February 2023',
                '2023-02-25T16:17:51.000Z',
                '00Q6700000zel88EAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'February 2023',
                '2023-02-26T08:03:11.000Z',
                '00Q6700000zele4EAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  AUTO PREMIUM 08 - CHARLEVILLE MEZIERES',
            ],
            [
                'February 2023',
                '2023-02-26T13:01:47.000Z',
                '00Q6700000zelx7EAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'February 2023',
                '2023-02-26T20:16:03.000Z',
                '00Q6700000zemY2EAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'February 2023',
                '2023-02-27T15:18:04.000Z',
                '00Q6700000zeo96EAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'February 2023',
                '2023-02-27T17:32:22.000Z',
                '00Q6700000zeobiEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'February 2023',
                '2023-02-28T05:30:04.000Z',
                '00Q6700000zepL2EAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'February 2023',
                '2023-02-28T09:17:37.000Z',
                '00Q6700000zepbPEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'February 2023',
                '2023-02-28T10:41:45.000Z',
                '00Q6700000zepsjEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'February 2023',
                '2023-02-28T10:52:36.000Z',
                '00Q6700000zepuQEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'February 2023',
                '2023-02-28T13:27:51.000Z',
                '00Q6700000zeqEGEAY',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'March 2023',
                '2023-03-01T10:06:01.000Z',
                '00Q6700000zesMpEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'March 2023',
                '2023-03-02T10:59:56.000Z',
                '00Q6700000zevV9EAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'LARSON by Autosphere',
            ],
            [
                'March 2023',
                '2023-03-03T13:15:01.000Z',
                '00Q6700000zexuUEAQ',
                'Qualified',
                'Email',
                'France',
                'Company',
                'ChargeGuru',
            ],
            [
                'March 2023',
                '2023-03-03T14:31:05.000Z',
                '00Q6700000zey1pEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'March 2023',
                '2023-03-03T14:59:05.000Z',
                '00Q6700000zey5cEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'March 2023',
                '2023-03-07T20:56:16.000Z',
                '00Q67000015CxGJEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'March 2023',
                '2023-03-08T17:23:16.000Z',
                '00Q67000015CAx2EAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'March 2023',
                '2023-03-09T09:52:51.000Z',
                '00Q6700000zf7d9EAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'March 2023',
                '2023-03-09T13:31:20.000Z',
                '00Q6700000zf83REAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'March 2023',
                '2023-03-09T17:48:24.000Z',
                '00Q6700000zf8eyEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'March 2023',
                '2023-03-09T18:28:53.000Z',
                '00Q6700000zf8hnEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'March 2023',
                '2023-03-11T07:16:12.000Z',
                '00Q6700000zfB8wEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  PRESTIGE CARS - AUBIERE (CLERMONT FERRAND)',
            ],
            [
                'March 2023',
                '2023-03-11T11:28:49.000Z',
                '00Q6700000zfBPsEAM',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PRESTIGE CARS - TOULON SUR ALLIER (MOULIN)',
            ],
            [
                'March 2023',
                '2023-03-11T15:14:20.000Z',
                '00Q6700000zfBesEAE',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'March 2023',
                '2023-03-11T15:33:23.000Z',
                '00Q6700000zfBgyEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'March 2023',
                '2023-03-11T17:45:26.000Z',
                '00Q6700000zfBrXEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'March 2023',
                '2023-03-11T21:42:07.000Z',
                '00Q6700000zfC0UEAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'March 2023',
                '2023-03-12T17:00:07.000Z',
                '00Q6700000zfCktEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'March 2023',
                '2023-03-12T19:17:02.000Z',
                '00Q6700000zfCv3EAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - SAINT MARTIN SUR LE PRE (CHALONS EN CHAMPAGNE)',
            ],
            [
                'March 2023',
                '2023-03-13T14:49:27.000Z',
                '00Q6700000zfECWEA2',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'March 2023',
                '2023-03-14T11:39:56.000Z',
                '00Q6700000zfFo2EAE',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'March 2023',
                '2023-03-14T12:11:51.000Z',
                '00Q6700000zfFruEAE',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'March 2023',
                '2023-03-15T13:37:17.000Z',
                '00Q6700000zfIzBEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'March 2023',
                '2023-03-15T17:38:48.000Z',
                '00Q6700000zfJotEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Evreux - NORMANVILLE (EVREUX)',
            ],
            [
                'March 2023',
                '2023-03-15T17:53:30.000Z',
                '00Q6700000zfJrEEAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'March 2023',
                '2023-03-16T07:09:23.000Z',
                '00Q6700000zfLBMEA2',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'March 2023',
                '2023-03-16T07:48:32.000Z',
                '00Q6700000zfLGlEAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            [
                'March 2023',
                '2023-03-16T09:46:33.000Z',
                '00Q6700000zfLcwEAE',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'March 2023',
                '2023-03-16T10:53:55.000Z',
                '00Q6700000zfLodEAE',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'March 2023',
                '2023-03-16T14:32:39.000Z',
                '00Q6700000zfMTfEAM',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'March 2023',
                '2023-03-16T17:30:43.000Z',
                '00Q6700000zfN5dEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'March 2023',
                '2023-03-17T10:02:41.000Z',
                '00Q6700000zfOZ8EAM',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'March 2023',
                '2023-03-17T11:01:56.000Z',
                '00Q6700000zfOjDEAU',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'March 2023',
                '2023-03-17T16:23:42.000Z',
                '00Q6700000zfPX6EAM',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'March 2023',
                '2023-03-17T17:53:23.000Z',
                '00Q6700000zfPqwEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'March 2023',
                '2023-03-19T21:04:44.000Z',
                '00Q6700000zfU0zEAE',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  CAVALLARI AUTOMOBILES - NICE',
            ],
            [
                'March 2023',
                '2023-03-20T08:21:48.000Z',
                '00Q6700000zfUbREAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'March 2023',
                '2023-03-20T08:58:20.000Z',
                '00Q6700000zfUfOEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'March 2023',
                '2023-03-20T12:56:10.000Z',
                '00Q6700000zfVDzEAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DARMENDRAIL AUTOMOBILES - SAINT AVIT (MONT DE MARSAN)',
            ],
            [
                'March 2023',
                '2023-03-20T14:07:36.000Z',
                '00Q6700000zfVNaEAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)',
            ],
            [
                'March 2023',
                '2023-03-20T19:45:57.000Z',
                '00Q6700000zfWK3EAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE DROITE - LORMONT (BORDEAUX)',
            ],
            [
                'March 2023',
                '2023-03-21T07:18:02.000Z',
                '00Q6700000zfX3mEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - SAINT MARTIN SUR LE PRE (CHALONS EN CHAMPAGNE)',
            ],
            [
                'March 2023',
                '2023-03-21T07:28:06.000Z',
                '00Q6700000zfX4HEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'March 2023',
                '2023-03-21T10:56:25.000Z',
                '00Q6700000zfXbHEAU',
                'Meeting Scheduled',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)',
            ],
            [
                'March 2023',
                '2023-03-21T17:31:28.000Z',
                '00Q6700000zfay1EAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'March 2023',
                '2023-03-22T11:04:31.000Z',
                '00Q6700000zfcjyEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'March 2023',
                '2023-03-23T08:46:48.000Z',
                '00Q6700000zfetBEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'March 2023',
                '2023-03-24T01:02:59.000Z',
                '00Q6700000zfiTIEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'March 2023',
                '2023-03-24T08:51:44.000Z',
                '00Q6700000zfjNVEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'March 2023',
                '2023-03-27T12:30:32.000Z',
                '00Q6700000zfsfbEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'March 2023',
                '2023-03-27T12:55:33.000Z',
                '00Q6700000zfspsEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'March 2023',
                '2023-03-27T13:48:22.000Z',
                '00Q6700000zft3bEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'March 2023',
                '2023-03-27T17:31:05.000Z',
                '00Q6700000zfu5tEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'March 2023',
                '2023-03-28T08:59:45.000Z',
                '00Q6700000zfwBAEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'March 2023',
                '2023-03-28T09:02:25.000Z',
                '00Q6700000zfwCIEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'March 2023',
                '2023-03-28T14:44:09.000Z',
                '00Q6700000zfxt3EAA',
                'Unqualified',
                'Direct',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'March 2023',
                '2023-03-28T16:00:42.000Z',
                '00Q670000130Hm8EAE',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'March 2023',
                '2023-03-29T12:50:12.000Z',
                '00Q6700000zg1i9EAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'March 2023',
                '2023-03-29T13:25:40.000Z',
                '00Q67000016DKkHEAW',
                'Waiting For Documents',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'March 2023',
                '2023-03-30T05:11:52.000Z',
                '00Q6700000zg5PpEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - NEUILLY SUR SEINE',
            ],
            [
                'March 2023',
                '2023-03-30T13:07:56.000Z',
                '00Q6700000zg8FbEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            [
                'March 2023',
                '2023-03-30T17:54:29.000Z',
                '00Q6700000zgAFjEAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'March 2023',
                '2023-03-30T18:10:49.000Z',
                '00Q6700000zgAKPEA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - BESANÇON',
            ],
            [
                'March 2023',
                '2023-03-31T08:18:55.000Z',
                '00Q6700000zgDNREA2',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'March 2023',
                '2023-03-31T12:45:04.000Z',
                '00Q6700000zgFczEAE',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'March 2023',
                '2023-03-31T12:58:20.000Z',
                '00Q6700000zgFjvEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI',
            ],
            [
                'April 2023',
                '2023-04-03T13:05:21.000Z',
                '00Q6700001308rNEAQ',
                'Unqualified',
                'Direct',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'April 2023',
                '2023-04-03T13:52:08.000Z',
                '00Q6700001309TkEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            [
                'April 2023',
                '2023-04-03T16:44:53.000Z',
                '00Q670000130ApgEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'April 2023',
                '2023-04-04T08:19:06.000Z',
                '00Q670000130FnTEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'April 2023',
                '2023-04-04T08:51:54.000Z',
                '00Q670000130Gv8EAE',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'April 2023',
                '2023-04-04T12:48:57.000Z',
                '00Q670000130JtMEAU',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'April 2023',
                '2023-04-04T13:43:04.000Z',
                '00Q670000130KfkEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'April 2023',
                '2023-04-04T16:41:18.000Z',
                '00Q670000130OGUEA2',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'April 2023',
                '2023-04-05T07:55:10.000Z',
                '00Q670000130VgDEAU',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE',
            ],
            [
                'April 2023',
                '2023-04-05T10:02:40.000Z',
                '00Q670000130Vu5EAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'April 2023',
                '2023-04-05T12:31:33.000Z',
                '00Q670000130WAhEAM',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'April 2023',
                '2023-04-06T07:24:15.000Z',
                '00Q670000130XWhEAM',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'April 2023',
                '2023-04-06T07:58:48.000Z',
                '00Q670000130XXuEAM',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'April 2023',
                '2023-04-07T10:56:24.000Z',
                '00Q670000130ZFtEAM',
                'Qualified',
                'Email',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'April 2023',
                '2023-04-08T08:46:01.000Z',
                '00Q670000130a0VEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'April 2023',
                '2023-04-08T13:35:38.000Z',
                '00Q670000130aD0EAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'April 2023',
                '2023-04-08T13:42:55.000Z',
                '00Q670000130aDKEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS',
            ],
            [
                'April 2023',
                '2023-04-08T17:15:29.000Z',
                '00Q670000130aMvEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'April 2023',
                '2023-04-11T16:31:28.000Z',
                '00Q670000130dNXEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'April 2023',
                '2023-04-12T15:32:31.000Z',
                '00Q670000130ekPEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'April 2023',
                '2023-04-13T14:51:06.000Z',
                '00Q670000130g9HEAQ',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'April 2023',
                '2023-04-13T16:14:02.000Z',
                '00Q670000130gRxEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'April 2023',
                '2023-04-14T14:41:48.000Z',
                '00Q670000130iDnEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'April 2023',
                '2023-04-14T15:20:13.000Z',
                '00Q670000130iHGEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'April 2023',
                '2023-04-14T16:10:05.000Z',
                '00Q670000130iLrEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'April 2023',
                '2023-04-15T13:07:04.000Z',
                '00Q670000130itbEAA',
                'Transfered to ZP',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'April 2023',
                '2023-04-15T14:25:39.000Z',
                '00Q670000130iwkEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'April 2023',
                '2023-04-15T15:12:39.000Z',
                '00Q670000130izAEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.P.S - BOURG EN BRESSE',
            ],
            [
                'April 2023',
                '2023-04-15T15:15:21.000Z',
                '00Q670000130izKEAQ',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.P.S - BOURG EN BRESSE',
            ],
            [
                'April 2023',
                '2023-04-18T08:03:57.000Z',
                '00Q670000130ndkEAA',
                'Qualified',
                'Phone',
                'France',
                'Single Home',
                'Volvo - EVERLINE - DENNEY (BELFORT)',
            ],
            [
                'April 2023',
                '2023-04-18T09:17:18.000Z',
                '00Q670000130m16EAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'April 2023',
                '2023-04-18T10:39:27.000Z',
                '00Q670000130mA1EAI',
                'Qualified',
                'Zeplug',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'April 2023',
                '2023-04-18T14:42:34.000Z',
                '00Q670000130mbMEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)',
            ],
            [
                'April 2023',
                '2023-04-19T05:24:15.000Z',
                '00Q670000130nQlEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)',
            ],
            [
                'April 2023',
                '2023-04-19T13:46:03.000Z',
                '00Q670000130oCbEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'April 2023',
                '2023-04-19T14:48:49.000Z',
                '00Q670000130oK5EAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'April 2023',
                '2023-04-19T14:55:33.000Z',
                '00Q670000130oL3EAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'April 2023',
                '2023-04-19T15:32:39.000Z',
                '00Q670000130oPjEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'April 2023',
                '2023-04-20T15:25:29.000Z',
                '00Q670000130qPfEAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'April 2023',
                '2023-04-21T10:00:55.000Z',
                '00Q670000130rHNEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'April 2023',
                '2023-04-21T13:18:25.000Z',
                '00Q670000130rk7EAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  EVERLINE - EPINAL CEDEX',
            ],
            [
                'April 2023',
                '2023-04-22T10:08:03.000Z',
                '00Q670000130swREAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - MAUREPAS',
            ],
            [
                'April 2023',
                '2023-04-22T23:38:15.000Z',
                '00Q670000130tXPEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'April 2023',
                '2023-04-24T08:26:18.000Z',
                '00Q670000130udsEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'April 2023',
                '2023-04-25T06:30:59.000Z',
                '00Q670000130wRVEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'April 2023',
                '2023-04-25T15:07:06.000Z',
                '00Q670000130xSxEAI',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'April 2023',
                '2023-04-25T16:10:47.000Z',
                '00Q670000130xZbEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)',
            ],
            [
                'April 2023',
                '2023-04-25T20:14:44.000Z',
                '00Q670000130y2nEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'April 2023',
                '2023-04-25T20:30:11.000Z',
                '00Q670000130y3lEAA',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'April 2023',
                '2023-04-25T22:10:33.000Z',
                '00Q670000130y8OEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                "Volvo -  FIRST AUTOMOBILES - VILLENEUVE D'ASCQ",
            ],
            [
                'April 2023',
                '2023-04-26T17:23:58.000Z',
                '00Q6700001310bhEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'April 2023',
                '2023-04-27T10:27:49.000Z',
                '00Q6700001311emEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'April 2023',
                '2023-04-27T16:47:44.000Z',
                '00Q6700001312ctEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'May 2023',
                '2023-05-04T14:33:59.000Z',
                '00Q670000131M2hEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'May 2023',
                '2023-05-06T09:20:21.000Z',
                '00Q670000131SzvEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'May 2023',
                '2023-05-06T13:09:48.000Z',
                '00Q670000131TFAEA2',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'May 2023',
                '2023-05-09T16:00:28.000Z',
                '00Q670000131YfAEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'May 2023',
                '2023-05-10T07:17:08.000Z',
                '00Q670000131aQXEAY',
                'Qualified',
                'Zeplug',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'May 2023',
                '2023-05-10T12:28:43.000Z',
                '00Q670000131bQSEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'May 2023',
                '2023-05-11T08:06:25.000Z',
                '00Q670000131dYYEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  CAR'S SERVICES - ONET LE CHATEAU (RODEZ)",
            ],
            [
                'May 2023',
                '2023-05-12T07:46:26.000Z',
                '00Q670000131hLQEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2023',
                '2023-05-12T10:22:30.000Z',
                '00Q670000131hh9EAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'May 2023',
                '2023-05-12T14:16:28.000Z',
                '00Q670000131iFhEAI',
                'Unqualified',
                'Phone',
                'France',
                'Company',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'May 2023',
                '2023-05-12T14:53:09.000Z',
                '00Q670000131iKIEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'May 2023',
                '2023-05-17T09:01:35.000Z',
                '00Q670000131r5xEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'May 2023',
                '2023-05-22T09:33:07.000Z',
                '00Q670000131ydEEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'May 2023',
                '2023-05-23T14:08:23.000Z',
                '00Q6700001322XxEAI',
                'Qualified',
                'Email',
                'France',
                'Company',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'May 2023',
                '2023-05-23T16:24:05.000Z',
                '00Q67000013239YEAQ',
                'Meeting Scheduled',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'May 2023',
                '2023-05-25T07:45:50.000Z',
                '00Q6700001326mDEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS',
            ],
            [
                'May 2023',
                '2023-05-26T08:51:59.000Z',
                '00Q6700001329NeEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'May 2023',
                '2023-05-26T17:10:15.000Z',
                '00Q670000132AFcEAM',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'May 2023',
                '2023-05-31T08:21:30.000Z',
                '00Q670000132HrDEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - CHAMPNIERS (ANGOULEME)',
            ],
            [
                'May 2023',
                '2023-05-31T15:46:05.000Z',
                '00Q670000132IoMEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'June 2023',
                '2023-06-02T08:52:08.000Z',
                '00Q670000132MMCEA2',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'June 2023',
                '2023-06-02T14:53:24.000Z',
                '00Q670000132N0fEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'June 2023',
                '2023-06-03T08:58:10.000Z',
                '00Q670000132NlQEAU',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'June 2023',
                '2023-06-06T15:07:34.000Z',
                '00Q670000132W1fEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'June 2023',
                '2023-06-06T16:47:33.000Z',
                '00Q670000132SCoEAM',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'June 2023',
                '2023-06-07T12:18:30.000Z',
                '00Q670000132VHsEAM',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'June 2023',
                '2023-06-08T07:33:08.000Z',
                '00Q670000132X2gEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'June 2023',
                '2023-06-09T08:40:26.000Z',
                '00Q670000132Z20EAE',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'June 2023',
                '2023-06-12T08:48:51.000Z',
                '00Q670000132cNYEAY',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'June 2023',
                '2023-06-12T09:09:47.000Z',
                '00Q670000132cy2EAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - CHAMPNIERS (ANGOULEME)',
            ],
            [
                'June 2023',
                '2023-06-12T09:09:52.000Z',
                '00Q670000132cyHEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'June 2023',
                '2023-06-13T10:26:10.000Z',
                '00Q670000132gJ3EAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'June 2023',
                '2023-06-15T09:36:50.000Z',
                '00Q670000132khoEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'June 2023',
                '2023-06-15T16:36:34.000Z',
                '00Q670000132m3FEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'June 2023',
                '2023-06-17T09:45:52.000Z',
                '00Q6700001331voEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'June 2023',
                '2023-06-17T12:52:01.000Z',
                '00Q67000013326cEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'June 2023',
                '2023-06-20T11:26:34.000Z',
                '00Q6700001335y0EAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - LIMOGES',
            ],
            [
                'June 2023',
                '2023-06-21T16:32:18.000Z',
                '00Q6700001338uFEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'June 2023',
                '2023-06-22T09:46:06.000Z',
                '00Q6700001339lZEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'June 2023',
                '2023-06-22T11:25:09.000Z',
                '00Q6700001339wbEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'June 2023',
                '2023-06-22T12:52:49.000Z',
                '00Q670000133A7BEAU',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'June 2023',
                '2023-06-22T13:00:57.000Z',
                '00Q670000133A8iEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'June 2023',
                '2023-06-22T14:15:17.000Z',
                '00Q670000133AIdEAM',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'June 2023',
                '2023-06-23T08:41:43.000Z',
                '00Q670000133BfkEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'June 2023',
                '2023-06-23T08:55:01.000Z',
                '00Q670000133BgsEAE',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'June 2023',
                '2023-06-23T12:32:36.000Z',
                '00Q670000133C2UEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'June 2023',
                '2023-06-23T16:31:20.000Z',
                '00Q670000133CmmEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'June 2023',
                '2023-06-24T08:22:25.000Z',
                '00Q670000133DcQEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NÖRDIK STAR AUTOS - CHALON SUR SAÔNE',
            ],
            [
                'June 2023',
                '2023-06-24T13:22:28.000Z',
                '00Q670000133DrpEAE',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'June 2023',
                '2023-06-24T15:28:50.000Z',
                '00Q670000133DzKEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'June 2023',
                '2023-06-26T09:11:12.000Z',
                '00Q670000133FcdEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'June 2023',
                '2023-06-26T09:30:56.000Z',
                '00Q670000133FeUEAU',
                'Qualified',
                'Email',
                'France',
                'Single Home',
                'JMJ Automobiles',
            ],
            [
                'June 2023',
                '2023-06-26T13:45:35.000Z',
                '00Q670000133GXuEAM',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'June 2023',
                '2023-06-29T08:14:16.000Z',
                '00Q670000133MmqEAE',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Multi Family Home',
                'Concession Inconnue',
            ],
            [
                'June 2023',
                '2023-06-29T16:55:35.000Z',
                '00Q670000133NwEEAU',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'June 2023',
                '2023-06-30T09:46:14.000Z',
                '00Q670000133P2HEAU',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'July 2023',
                '2023-07-01T14:59:46.000Z',
                '00Q670000133QgrEAE',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'July 2023',
                '2023-07-03T14:12:19.000Z',
                '00Q670000133TTQEA2',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'July 2023',
                '2023-07-04T08:44:49.000Z',
                '00Q670000133UQMEA2',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'July 2023',
                '2023-07-06T16:39:45.000Z',
                '00Q670000133aAwEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'July 2023',
                '2023-07-07T12:37:05.000Z',
                '00Q670000133bX9EAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'July 2023',
                '2023-07-07T15:36:27.000Z',
                '00Q670000133brOEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'July 2023',
                '2023-07-10T04:26:37.000Z',
                '00Q670000133fZOEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'July 2023',
                '2023-07-10T07:34:24.000Z',
                '00Q670000133fhIEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'July 2023',
                '2023-07-10T08:24:47.000Z',
                '00Q670000133fkWEAQ',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'July 2023',
                '2023-07-11T10:31:41.000Z',
                '00Q670000133i19EAA',
                'Unqualified',
                'Email',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'July 2023',
                '2023-07-12T13:16:35.000Z',
                '00Q670000133kTxEAI',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'July 2023',
                '2023-07-12T13:24:58.000Z',
                '00Q670000133kVeEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'July 2023',
                '2023-07-12T13:29:06.000Z',
                '00Q670000133kWNEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'July 2023',
                '2023-07-12T14:21:02.000Z',
                '00Q670000133kdTEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'July 2023',
                '2023-07-17T09:27:04.000Z',
                '00Q670000133s53EAA',
                'Unqualified',
                'Email',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'July 2023',
                '2023-07-18T13:21:43.000Z',
                '00Q670000133wSAEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'July 2023',
                '2023-07-19T17:26:58.000Z',
                '00Q670000133zPyEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'July 2023',
                '2023-07-20T08:44:28.000Z',
                '00Q6700001340OJEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'July 2023',
                '2023-07-22T16:08:08.000Z',
                '00Q6700001346FyEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'July 2023',
                '2023-07-24T07:37:42.000Z',
                '00Q6700001347l2EAA',
                'Unqualified',
                'Email',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'July 2023',
                '2023-07-25T08:39:34.000Z',
                '00Q6700001349g5EAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'July 2023',
                '2023-07-25T16:23:06.000Z',
                '00Q670000134B1sEAE',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'July 2023',
                '2023-07-26T07:58:54.000Z',
                '00Q67000015ddJDEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'July 2023',
                '2023-07-29T12:55:38.000Z',
                '00Q67000015dilJEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'August 2023',
                '2023-08-04T07:32:33.000Z',
                '00Q67000015BNOSEA4',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'August 2023',
                '2023-08-04T16:34:04.000Z',
                '00Q67000015BOFxEAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'August 2023',
                '2023-08-04T16:39:42.000Z',
                '00Q67000015BOHGEA4',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'August 2023',
                '2023-08-09T07:53:18.000Z',
                '00Q67000015BV8IEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX',
            ],
            [
                'August 2023',
                '2023-08-16T08:25:45.000Z',
                '00Q67000015Bhx3EAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'August 2023',
                '2023-08-17T13:29:31.000Z',
                '00Q67000015BkTzEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'August 2023',
                '2023-08-17T16:53:25.000Z',
                '00Q67000015Bl11EAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'August 2023',
                '2023-08-21T16:47:18.000Z',
                '00Q67000015BphkEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            [
                'August 2023',
                '2023-08-23T05:09:38.000Z',
                '00Q67000015BrMbEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PARIS',
            ],
            [
                'August 2023',
                '2023-08-23T06:11:17.000Z',
                '00Q67000015BrOrEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'August 2023',
                '2023-08-25T07:39:54.000Z',
                '00Q67000015BuxNEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - DECHY (DOUAI)',
            ],
            [
                'August 2023',
                '2023-08-25T09:28:28.000Z',
                '00Q67000015Bv3uEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'August 2023',
                '2023-08-25T14:22:40.000Z',
                '00Q67000015BvbUEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2023',
                '2023-08-26T10:13:35.000Z',
                '00Q67000015BwqMEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'August 2023',
                '2023-08-28T15:49:18.000Z',
                '00Q67000015Bz3YEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'August 2023',
                '2023-08-29T14:28:54.000Z',
                '00Q67000015C0xDEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'August 2023',
                '2023-08-29T16:55:54.000Z',
                '00Q67000015C1DfEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'August 2023',
                '2023-08-29T17:33:25.000Z',
                '00Q67000015C1FqEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'August 2023',
                '2023-08-30T11:48:46.000Z',
                '00Q67000015C2VfEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'August 2023',
                '2023-08-30T14:04:04.000Z',
                '00Q67000015C2kLEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'August 2023',
                '2023-08-31T13:19:09.000Z',
                '00Q67000015C3xgEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
            ],
            [
                'September 2023',
                '2023-09-01T09:04:35.000Z',
                '00Q67000015C58zEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'September 2023',
                '2023-09-01T16:04:07.000Z',
                '00Q67000015C86tEAC',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - NEUILLY SUR SEINE',
            ],
            [
                'September 2023',
                '2023-09-01T16:47:24.000Z',
                '00Q67000015C89iEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'September 2023',
                '2023-09-02T10:22:38.000Z',
                '00Q67000015C8wYEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'September 2023',
                '2023-09-02T12:03:41.000Z',
                '00Q67000015C914EAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - EVERLINE - DENNEY (BELFORT)',
            ],
            [
                'September 2023',
                '2023-09-02T16:27:56.000Z',
                '00Q67000015C9G9EAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'September 2023',
                '2023-09-04T13:57:59.000Z',
                '00Q67000015CAxMEAW',
                'Meeting Scheduled',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'September 2023',
                '2023-09-05T08:12:16.000Z',
                '00Q67000015CBnmEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2023',
                '2023-09-05T13:24:43.000Z',
                '00Q67000015CCHwEAO',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'September 2023',
                '2023-09-05T14:47:39.000Z',
                '00Q67000015CCRcEAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'September 2023',
                '2023-09-08T15:47:38.000Z',
                '00Q67000015CGg7EAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'September 2023',
                '2023-09-08T16:03:28.000Z',
                '00Q67000015CGhKEAW',
                'Meeting Scheduled',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'September 2023',
                '2023-09-09T08:20:22.000Z',
                '00Q67000015CH9CEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'September 2023',
                '2023-09-09T14:22:19.000Z',
                '00Q67000015CHPPEA4',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'September 2023',
                '2023-09-11T08:49:20.000Z',
                '00Q67000015CIekEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2023',
                '2023-09-11T10:05:44.000Z',
                '00Q67000015CIlvEAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'September 2023',
                '2023-09-11T12:27:58.000Z',
                '00Q67000015CJ3qEAG',
                'Qualified',
                'Email',
                'France',
                'Single Home',
                'ABVV Chantilly',
            ],
            [
                'September 2023',
                '2023-09-12T14:17:27.000Z',
                '00Q67000015CKeOEAW',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'LARSON by Autosphere',
            ],
            [
                'September 2023',
                '2023-09-13T11:16:28.000Z',
                '00Q67000015CLqjEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'September 2023',
                '2023-09-14T09:00:59.000Z',
                '00Q67000015COJJEA4',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'September 2023',
                '2023-09-14T09:05:25.000Z',
                '00Q67000015CN1lEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'September 2023',
                '2023-09-14T14:22:29.000Z',
                '00Q67000015CNZTEA4',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'September 2023',
                '2023-09-15T08:32:31.000Z',
                '00Q67000015CONkEAO',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'September 2023',
                '2023-09-15T13:15:51.000Z',
                '00Q67000015COyQEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'September 2023',
                '2023-09-15T16:16:33.000Z',
                '00Q67000015CPIqEAO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'September 2023',
                '2023-09-16T10:00:12.000Z',
                '00Q67000015CPpJEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'September 2023',
                '2023-09-16T10:02:56.000Z',
                '00Q67000015CPpTEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES - REDON',
            ],
            [
                'September 2023',
                '2023-09-16T14:29:34.000Z',
                '00Q67000015CQ2gEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'September 2023',
                '2023-09-16T16:09:18.000Z',
                '00Q67000015CQ85EAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOMMAUTO - RIVERY LES AMIENS',
            ],
            [
                'September 2023',
                '2023-09-16T16:17:36.000Z',
                '00Q67000015CQ8eEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'September 2023',
                '2023-09-18T10:10:29.000Z',
                '00Q67000015CRY6EAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'September 2023',
                '2023-09-18T14:25:27.000Z',
                '00Q67000015CS3LEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'September 2023',
                '2023-09-18T17:35:46.000Z',
                '00Q67000015CSPQEA4',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'September 2023',
                '2023-09-20T09:58:40.000Z',
                '00Q67000015CUXeEAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'September 2023',
                '2023-09-20T17:12:48.000Z',
                '00Q67000015CVDKEA4',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'September 2023',
                '2023-09-21T08:40:49.000Z',
                '00Q67000015CVibEAG',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - NEUILLY SUR SEINE',
            ],
            [
                'September 2023',
                '2023-09-21T13:37:48.000Z',
                '00Q67000015CW8MEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'September 2023',
                '2023-09-22T17:11:16.000Z',
                '00Q67000015CXniEAG',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'September 2023',
                '2023-09-23T09:39:28.000Z',
                '00Q67000015CYLZEA4',
                'Meeting Scheduled',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'September 2023',
                '2023-09-23T13:20:30.000Z',
                '00Q67000015CYVPEA4',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'September 2023',
                '2023-09-23T16:45:35.000Z',
                '00Q67000015CYfKEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'September 2023',
                '2023-09-25T08:29:40.000Z',
                '00Q67000015CZyhEAG',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'September 2023',
                '2023-09-25T12:15:31.000Z',
                '00Q67000015CaI5EAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE',
            ],
            [
                'September 2023',
                '2023-09-25T12:57:40.000Z',
                '00Q67000015CaMWEA0',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2023',
                '2023-09-25T17:26:39.000Z',
                '00Q67000015CarSEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'September 2023',
                '2023-09-26T09:44:37.000Z',
                '00Q67000015CbwREAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'September 2023',
                '2023-09-27T13:00:32.000Z',
                '00Q67000015CdguEAC',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'September 2023',
                '2023-09-28T09:47:00.000Z',
                '00Q67000015Cf9XEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2023',
                '2023-09-28T13:07:57.000Z',
                '00Q67000015CfQEEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'September 2023',
                '2023-09-28T14:25:44.000Z',
                '00Q67000015CfaDEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'September 2023',
                '2023-09-29T09:26:10.000Z',
                '00Q67000015CgSGEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'September 2023',
                '2023-09-30T11:44:48.000Z',
                '00Q67000015ChsrEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'September 2023',
                '2023-09-30T14:34:56.000Z',
                '00Q67000015ChzJEAS',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'October 2023',
                '2023-10-01T07:52:50.000Z',
                '00Q67000015CiQdEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - GUERANDE',
            ],
            [
                'October 2023',
                '2023-10-02T10:48:58.000Z',
                '00Q67000015CjSUEA0',
                'Waiting For Documents',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE',
            ],
            [
                'October 2023',
                '2023-10-02T12:54:43.000Z',
                '00Q67000015CjfeEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)',
            ],
            [
                'October 2023',
                '2023-10-03T07:45:54.000Z',
                '00Q67000015CkldEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'October 2023',
                '2023-10-03T14:27:02.000Z',
                '00Q67000015ClOTEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'October 2023',
                '2023-10-03T14:36:02.000Z',
                '00Q67000015ClPHEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'October 2023',
                '2023-10-03T15:03:43.000Z',
                '00Q67000015ClS1EAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  CAR'S SERVICES - ONET LE CHATEAU (RODEZ)",
            ],
            [
                'October 2023',
                '2023-10-04T10:01:56.000Z',
                '00Q67000015CnaoEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'October 2023',
                '2023-10-05T07:45:37.000Z',
                '00Q67000015CohYEAS',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            [
                'October 2023',
                '2023-10-05T08:17:36.000Z',
                '00Q67000015CojeEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'October 2023',
                '2023-10-05T08:19:56.000Z',
                '00Q67000015CojtEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'October 2023',
                '2023-10-05T14:26:05.000Z',
                '00Q67000015CpEiEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'October 2023',
                '2023-10-07T13:05:00.000Z',
                '00Q67000015CrZqEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DELHORBE AUTOMOBILES - REIMS',
            ],
            [
                'October 2023',
                '2023-10-09T14:47:07.000Z',
                '00Q67000015CtlJEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'October 2023',
                '2023-10-11T12:29:50.000Z',
                '00Q67000015CvtDEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  CAR'S SERVICES - ONET LE CHATEAU (RODEZ)",
            ],
            [
                'October 2023',
                '2023-10-11T12:45:38.000Z',
                '00Q67000015CvuVEAS',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - DISTRE (SAUMUR)',
            ],
            [
                'October 2023',
                '2023-10-11T13:27:51.000Z',
                '00Q67000015CvywEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'October 2023',
                '2023-10-11T13:39:11.000Z',
                '00Q67000015CvzfEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'October 2023',
                '2023-10-11T19:07:00.000Z',
                '00Q67000015CwVzEAK',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)',
            ],
            [
                'October 2023',
                '2023-10-11T20:27:56.000Z',
                '00Q67000015CwYjEAK',
                'Unqualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'October 2023',
                '2023-10-12T09:44:17.000Z',
                '00Q67000015CwzaEAC',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'October 2023',
                '2023-10-12T13:16:45.000Z',
                '00Q67000015CxIeEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT',
            ],
            [
                'October 2023',
                '2023-10-13T13:43:59.000Z',
                '00Q67000015CyVwEAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'October 2023',
                '2023-10-14T08:18:52.000Z',
                '00Q67000015Cz7jEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'October 2023',
                '2023-10-14T11:43:01.000Z',
                '00Q67000015CzJzEAK',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'October 2023',
                '2023-10-14T11:45:08.000Z',
                '00Q67000015CzKOEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            [
                'October 2023',
                '2023-10-14T13:53:48.000Z',
                '00Q67000015CzT6EAK',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - ROISSY EN FRANCE',
            ],
            [
                'October 2023',
                '2023-10-15T09:08:43.000Z',
                '00Q67000015CzyQEAS',
                'Qualification',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'October 2023',
                '2023-10-16T17:15:35.000Z',
                '00Q67000015D1V6EAK',
                'Qualification',
                'Typeform Volvo',
                'France',
                'Single Home',
                'ABVV Chantilly',
            ],
            [
                'October 2023',
                '2023-10-17T13:50:41.000Z',
                '00Q67000015D35jEAC',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'October 2023',
                '2023-10-17T15:27:07.000Z',
                '00Q67000015D3GcEAK',
                'Waiting For Documents',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
            [
                'October 2023',
                '2023-10-18T07:54:33.000Z',
                '00Q67000015D5ILEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
            ],
            [
                'October 2023',
                '2023-10-18T12:43:22.000Z',
                '00Q67000015D5fcEAC',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES',
            ],
            [
                'October 2023',
                '2023-10-18T13:33:19.000Z',
                '00Q67000015D5kmEAC',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2023',
                '2023-10-19T07:23:09.000Z',
                '00Q67000015D6dKEAS',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'October 2023',
                '2023-10-19T16:00:52.000Z',
                '00Q67000015D7ZOEA0',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'October 2023',
                '2023-10-21T09:35:35.000Z',
                '00Q67000015D9EJEA0',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'October 2023',
                '2023-10-21T12:19:12.000Z',
                '00Q67000015D9M3EAK',
                'Qualification',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'October 2023',
                '2023-10-23T07:39:56.000Z',
                '00Q67000015DAMxEAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'October 2023',
                '2023-10-23T15:10:04.000Z',
                '00Q67000015DAxKEAW',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'October 2023',
                '2023-10-24T09:44:07.000Z',
                '00Q67000016DB0PEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'October 2023',
                '2023-10-25T09:06:06.000Z',
                '00Q67000016DCKdEAO',
                'Qualification',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'October 2023',
                '2023-10-26T08:25:13.000Z',
                '00Q67000016DDZjEAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'October 2023',
                '2023-10-27T08:18:13.000Z',
                '00Q67000016DEcVEAW',
                'Waiting For Documents',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERFORMAUTO - LIEVIN',
            ],
            [
                'October 2023',
                '2023-10-27T08:39:09.000Z',
                '00Q67000016DEe2EAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Concession Inconnue',
            ],
            [
                'October 2023',
                '2023-10-27T12:57:39.000Z',
                '00Q67000016DEvhEAG',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'October 2023',
                '2023-10-27T13:58:59.000Z',
                '00Q67000016DF2KEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'October 2023',
                '2023-10-28T08:29:07.000Z',
                '00Q67000016DFZLEA4',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'October 2023',
                '2023-10-28T15:10:29.000Z',
                '00Q67000016DFsIEAW',
                'Waiting For Documents',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'October 2023',
                '2023-10-30T09:58:12.000Z',
                '00Q67000016DGmKEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'October 2023',
                '2023-10-31T13:49:05.000Z',
                '00Q67000016DIYeEAO',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET',
            ],
            [
                'October 2023',
                '2023-10-31T16:50:30.000Z',
                '00Q67000016DJ6hEAG',
                'Waiting For Documents',
                'Direct',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'November 2023',
                '2023-11-02T09:25:04.000Z',
                '00Q67000016DKOvEAO',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'November 2023',
                '2023-11-03T08:23:12.000Z',
                '00Q67000016DLvNEAW',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - ROISSY EN FRANCE',
            ],
            [
                'November 2023',
                '2023-11-03T08:33:53.000Z',
                '00Q67000016DLvrEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'November 2023',
                '2023-11-03T08:36:30.000Z',
                '00Q67000016DLw1EAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'November 2023',
                '2023-11-03T08:39:21.000Z',
                '00Q67000016DLwBEAW',
                'Meeting Scheduled',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'November 2023',
                '2023-11-03T08:45:07.000Z',
                '00Q67000016DLwaEAG',
                'Qualification',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'November 2023',
                '2023-11-03T13:21:12.000Z',
                '00Q67000016DMKIEA4',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'November 2023',
                '2023-11-04T08:15:26.000Z',
                '00Q67000016DN1JEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CARBURY AUTOMOBILES - VALENCE',
            ],
            [
                'November 2023',
                '2023-11-04T10:18:03.000Z',
                '00Q67000016DN5VEAW',
                'Qualification',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'November 2023',
                '2023-11-06T08:53:53.000Z',
                '00Q67000016DOeCEAW',
                'Meeting Scheduled',
                'Email',
                'France',
                'Single Home',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            [
                'November 2023',
                '2023-11-06T09:30:29.000Z',
                '00Q67000016DOh6EAG',
                'Waiting For Documents',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'November 2023',
                '2023-11-07T13:54:04.000Z',
                '00Q67000016DQZpEAO',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2023',
                '2023-11-07T15:13:05.000Z',
                '00Q67000016DQhKEAW',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'November 2023',
                '2023-11-07T16:10:03.000Z',
                '00Q67000016DQmjEAG',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CSD MOTORS - BOE (AGEN)',
            ],
            [
                'November 2023',
                '2023-11-08T14:32:18.000Z',
                '00Q67000015wYzkEAE',
                'Qualification',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN',
            ],
            [
                'November 2023',
                '2023-11-09T11:39:54.000Z',
                '00Q67000015waPWEAY',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2023',
                '2023-11-10T11:25:35.000Z',
                '00Q67000015wbtMEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2023',
                '2023-11-10T15:08:40.000Z',
                '00Q67000015wc9ZEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'November 2023',
                '2023-11-10T18:32:19.000Z',
                '00Q67000015wcRdEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'November 2023',
                '2023-11-13T11:00:22.000Z',
                '00Q67000015weicEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'November 2023',
                '2023-11-14T14:53:50.000Z',
                '00Q67000015wggpEAA',
                'Qualification',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'November 2023',
                '2023-11-15T14:55:31.000Z',
                '00Q67000015wiD4EAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'ELYPSE AUTO',
            ],
            [
                'November 2023',
                '2023-11-16T10:06:50.000Z',
                '00Q67000015wjCpEAI',
                'Meeting Scheduled',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  COMMERES SAS - SEMEAC (TARBES)',
            ],
            [
                'November 2023',
                '2023-11-16T16:04:37.000Z',
                '00Q67000015wjgnEAA',
                'Waiting For Documents',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'November 2023',
                '2023-11-16T18:18:42.000Z',
                '00Q67000015wjulEAA',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Volvo -  ELYSEE EST AUTOS (11ème) - PARIS',
            ],
            [
                'November 2023',
                '2023-11-17T14:07:50.000Z',
                '00Q67000015wkqCEAQ',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Company',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'November 2023',
                '2023-11-17T15:36:50.000Z',
                '00Q67000015wkyBEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  FELIX FAURE AUTOMOBILES - LYON',
            ],
            [
                'November 2023',
                '2023-11-17T15:59:03.000Z',
                '00Q67000015wl0CEAQ',
                'Qualification',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'November 2023',
                '2023-11-18T08:43:12.000Z',
                '00Q67000015wloOEAQ',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                "Volvo -  VOLVO CÔTE D'AZUR - FREJUS",
            ],
            [
                'November 2023',
                '2023-11-20T15:24:04.000Z',
                '00Q67000015woLLEAY',
                'Qualification',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'November 2023',
                '2023-11-21T09:44:20.000Z',
                '00Q67000015wpMXEAY',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'November 2023',
                '2023-11-21T10:38:11.000Z',
                '00Q67000015wpStEAI',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'November 2023',
                '2023-11-22T10:02:31.000Z',
                '00Q67000015wqoDEAQ',
                'Qualification',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER',
            ],
            [
                'November 2023',
                '2023-11-23T10:34:33.000Z',
                '00Q67000015wsHTEAY',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            [
                'November 2023',
                '2023-11-24T08:09:26.000Z',
                '00Q67000015wtDxEAI',
                'Qualification',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'November 2023',
                '2023-11-24T08:11:38.000Z',
                '00Q67000015wtE2EAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'November 2023',
                '2023-11-24T08:43:12.000Z',
                '00Q67000015wtFoEAI',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'November 2023',
                '2023-11-25T13:33:46.000Z',
                '00Q67000015wuroEAA',
                'Qualification',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            [
                'November 2023',
                '2023-11-25T15:15:51.000Z',
                '00Q67000015wuwBEAQ',
                'Qualification',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'November 2023',
                '2023-11-25T15:55:44.000Z',
                '00Q67000015wv0vEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN',
            ],
            [
                'November 2023',
                '2023-11-27T13:52:04.000Z',
                '00Q67000015wwpfEAA',
                'Qualification',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS',
            ],
            [
                'November 2023',
                '2023-11-28T10:37:21.000Z',
                '00Q67000015wxxwEAA',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SP MOTORS - BOURGES',
            ],
            [
                'November 2023',
                '2023-11-28T10:39:32.000Z',
                '00Q67000015wxyBEAQ',
                'Qualification',
                'Volvo dealership Form',
                'France',
                'Company',
                'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)',
            ],
            [
                'November 2023',
                '2023-11-28T13:24:48.000Z',
                '00Q67000015wyAqEAI',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'LARSON by Autosphere',
            ],
            [
                'November 2023',
                '2023-11-28T15:18:56.000Z',
                '00Q67000015wyLTEAY',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'November 2023',
                '2023-11-28T17:44:13.000Z',
                '00Q67000015wyWWEAY',
                'Qualification',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'November 2023',
                '2023-11-29T08:41:31.000Z',
                '00Q67000015wzFgEAI',
                'Meeting Scheduled',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS',
            ],
            [
                'November 2023',
                '2023-11-30T09:38:43.000Z',
                '00Q67000015x0dPEAQ',
                'Qualification',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - LE HAVRE',
            ],
            [
                'November 2023',
                '2023-11-30T13:09:09.000Z',
                '00Q67000015x0syEAA',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PEMZEC AUTOMOBILES 29 - BREST',
            ],
            [
                'November 2023',
                '2023-11-30T14:37:10.000Z',
                '00Q67000015x0zGEAQ',
                'Meeting Scheduled',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  THIBAUT - ROUEN',
            ],
            [
                'November 2023',
                '2023-11-30T16:48:07.000Z',
                '00Q67000015x1E6EAI',
                'Unqualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'December 2023',
                '2023-12-01T17:47:54.000Z',
                '00Q67000015x2eCEAQ',
                'Qualification',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'December 2023',
                '2023-12-02T14:13:35.000Z',
                '00Q67000015x3IfEAI',
                'Qualification',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  VK MOTORS - CALAIS',
            ],
            [
                'December 2023',
                '2023-12-05T08:42:02.000Z',
                '00QSZ000001VHSD2A4',
                'Qualified',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ESPACE NORD AUTOMOBILES - METZ',
            ],
            [
                'December 2023',
                '2023-12-05T10:26:32.000Z',
                '00QSZ000001VYMc2AO',
                'Qualified',
                'Typeform Volvo',
                'France',
                'Single Home',
                'Concession Inconnue',
            ],
            [
                'December 2023',
                '2023-12-06T08:38:53.000Z',
                '00QSZ000001XKc62AG',
                'Qualification',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE',
            ],
            [
                'December 2023',
                '2023-12-07T17:15:29.000Z',
                '00QSZ000001aOuT2AU',
                'Qualification',
                'Volvo dealership Form',
                'France',
                'Company',
                'LARSON by Autosphere',
            ],
            [
                'December 2023',
                '2023-12-09T09:34:26.000Z',
                '00QSZ000001dDRR2A2',
                'New',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  AUTO PREMIUM 08 - CHARLEVILLE MEZIERES',
            ],
            [
                'December 2023',
                '2023-12-09T11:08:51.000Z',
                '00QSZ000001dDXv2AM',
                'Qualification',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'LARSON by Autosphere',
            ],
            [
                'December 2023',
                '2023-12-09T13:47:09.000Z',
                '00QSZ000001dPyr2AE',
                'Qualification',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  PERICAUD AUTOMOBILES - CHAMPNIERS (ANGOULEME)',
            ],
            [
                'December 2023',
                '2023-12-11T11:34:03.000Z',
                '00QSZ000001g0MP2AY',
                'New',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  ACTENA AUTOMOBILES - PORT MARLY',
            ],
            [
                'December 2023',
                '2023-12-11T16:06:24.000Z',
                '00QSZ000001gZAT2A2',
                'Transfered to ZP',
                'Volvo dealership Form',
                'France',
                'Multi Family Home',
                'Volvo -  ACTENA AUTOMOBILES - NEUILLY SUR SEINE',
            ],
            [
                'December 2023',
                '2023-12-12T08:59:49.000Z',
                '00QSZ000001hk732AA',
                'New',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            [
                'December 2023',
                '2023-12-12T16:44:21.000Z',
                '00QSZ000001ikwD2AQ',
                'New',
                'Volvo dealership Form',
                'France',
                'Single Home',
                'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY',
            ],
        ],
    },
};
