const UncheckedIcon = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="RadioButton">
                <circle id="Background" cx="8" cy="8.5" r="7.5" fill="white" stroke="#C5C9D3" />
            </g>
        </svg>
    );
};

export default UncheckedIcon;
