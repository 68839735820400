export const block5lMock = {
    statement_id: '01eea0b3-0a1e-15c7-9ef7-1c60d00746e1',
    status: { state: 'SUCCEEDED' },
    manifest: {
        format: 'JSON_ARRAY',
        schema: {
            column_count: 4,
            columns: [
                { name: 'business_contributor', type_text: 'STRING', type_name: 'STRING', position: 0 },
                { name: 'total_opp', type_text: 'BIGINT', type_name: 'LONG', position: 1 },
                { name: 'won_opp', type_text: 'BIGINT', type_name: 'LONG', position: 2 },
                { name: 'win_rate', type_text: 'DOUBLE', type_name: 'DOUBLE', position: 3 },
            ],
        },
        total_chunk_count: 1,
        chunks: [{ chunk_index: 0, row_offset: 0, row_count: 125 }],
        total_row_count: 125,
        truncated: false,
    },
    result: {
        chunk_index: 0,
        row_offset: 0,
        row_count: 125,
        data_array: [
            ['ABVV Chantilly', '3', '1', '0.3333333333333333'],
            ['ABVV Roissy en France', '2', '1', '0.5'],
            ['ChargeGuru Business Services', '5', '5', '1.0'],
            ['Concession Inconnue', '194', '79', '0.4072164948453608'],
            ['ELYPSE AUTO', '1', '0', '0.0'],
            ['JMJ Automobiles', '1', '0', '0.0'],
            ['LARSON by Autosphere', '12', '6', '0.5'],
            ['Tesla Store Lyon-Chemin des Gorges', '1', '0', '0.0'],
            ['Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY', '8', '6', '0.75'],
            ['Volvo -  A.B.V.V. AUTOMOBILES - ROISSY EN FRANCE', '5', '0', '0.0'],
            ['Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE', '12', '3', '0.25'],
            ['Volvo -  A.P.S - BOURG EN BRESSE', '2', '1', '0.5'],
            ['Volvo -  ACTENA AUTOMOBILES - BUCHELAY (MANTES LA JOLIE)', '2', '1', '0.5'],
            ['Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES', '6', '1', '0.16666666666666666'],
            ['Volvo -  ACTENA AUTOMOBILES - MAUREPAS', '8', '1', '0.125'],
            ['Volvo -  ACTENA AUTOMOBILES - NEUILLY SUR SEINE', '1', '0', '0.0'],
            ['Volvo -  ACTENA AUTOMOBILES - PARIS', '9', '3', '0.3333333333333333'],
            ['Volvo -  ACTENA AUTOMOBILES - PORT MARLY', '13', '5', '0.38461538461538464'],
            ['Volvo -  ACTENA AUTOMOBILES - VERSAILLES', '2', '2', '1.0'],
            ['Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE', '18', '9', '0.5'],
            ['Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)', '6', '2', '0.3333333333333333'],
            ['Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE', '7', '2', '0.2857142857142857'],
            ['Volvo -  AMPLITUDE AUTOMOBILES - SAINTES', '2', '0', '0.0'],
            ['Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)', '12', '5', '0.4166666666666667'],
            ['Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)', '8', '1', '0.125'],
            ['Volvo -  AUTO PREMIUM 08 - CHARLEVILLE MEZIERES', '2', '2', '1.0'],
            ['Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)', '13', '7', '0.5384615384615384'],
            ['Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT', '15', '6', '0.4'],
            ["Volvo -  CAR'S SERVICES - ONET LE CHATEAU (RODEZ)", '4', '2', '0.5'],
            ['Volvo -  CARBURY AUTOMOBILES - VALENCE', '7', '5', '0.7142857142857143'],
            ['Volvo -  CARS PREMIUM - CARCASSONNE', '1', '1', '1.0'],
            ['Volvo -  CARS PREMIUM - PERPIGNAN', '5', '3', '0.6'],
            ['Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)', '5', '0', '0.0'],
            ['Volvo -  COMMERES SAS - SEMEAC (TARBES)', '8', '4', '0.5'],
            ['Volvo -  CSD MOTORS - BOE (AGEN)', '7', '2', '0.2857142857142857'],
            ['Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)', '10', '3', '0.3'],
            ['Volvo -  CYRNEA NORTH CARS - BORGO', '2', '0', '0.0'],
            ['Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1', '13', '6', '0.46153846153846156'],
            ['Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)', '13', '10', '0.7692307692307693'],
            ['Volvo -  DARMENDRAIL AUTOMOBILES - SAINT AVIT (MONT DE MARSAN)', '2', '1', '0.5'],
            ['Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)', '23', '11', '0.4782608695652174'],
            ['Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)', '12', '3', '0.25'],
            ['Volvo -  DELHORBE AUTOMOBILES - REIMS', '9', '3', '0.3333333333333333'],
            ['Volvo -  DELHORBE AUTOMOBILES - SAINT MARTIN SUR LE PRE (CHALONS EN CHAMPAGNE)', '6', '3', '0.5'],
            ['Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)', '14', '6', '0.42857142857142855'],
            ['Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)', '6', '3', '0.5'],
            ['Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN', '13', '6', '0.46153846153846156'],
            ['Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)', '18', '8', '0.4444444444444444'],
            ['Volvo -  ELYSEE EST AUTOS (11ème) - PARIS', '3', '0', '0.0'],
            ['Volvo -  ELYSEE EST AUTOS - CHENNEVIERES', '15', '8', '0.5333333333333333'],
            ['Volvo -  ESPACE NORD AUTOMOBILES - METZ', '30', '14', '0.4666666666666667'],
            ['Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)', '9', '3', '0.3333333333333333'],
            ['Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)', '15', '10', '0.6666666666666666'],
            ['Volvo -  ESPACE SUD AUTOMOBILES - NIMES', '9', '5', '0.5555555555555556'],
            ['Volvo -  EVERLINE - BESANÇON', '8', '6', '0.75'],
            ['Volvo -  EVERLINE - EPINAL CEDEX', '5', '4', '0.8'],
            ['Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)', '4', '1', '0.25'],
            ['Volvo -  FELIX FAURE AUTOMOBILES - LYON', '8', '4', '0.5'],
            ['Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX', '8', '3', '0.375'],
            ['Volvo -  FELIX FAURE AUTOMOBILES - VIENNE', '1', '0', '0.0'],
            ['Volvo -  FELIX FAURE AUTOMOBILES - VILLEFRANCHE-SUR-SAÔNE', '4', '2', '0.5'],
            ['Volvo -  FELIX FAURE GRENOBLE - SEYSINNET - PARISET (GRENOBLE)', '1', '0', '0.0'],
            ['Volvo -  FELIX FAURE SAINT ETIENNE - SAINT ETIENNE', '3', '1', '0.3333333333333333'],
            ['Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)', '4', '0', '0.0'],
            ["Volvo -  FIRST AUTOMOBILES - VILLENEUVE D'ASCQ", '2', '0', '0.0'],
            ['Volvo -  GENERAL AUTOMOBILE - GUERANDE', '6', '3', '0.5'],
            ['Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)', '15', '6', '0.4'],
            ['Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN', '13', '7', '0.5384615384615384'],
            ['Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI', '7', '0', '0.0'],
            ['Volvo -  GROUPE DUFFORT CHARTRES - NOGENT LE PHAYE (CHARTRES)', '3', '0', '0.0'],
            ['Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS', '6', '4', '0.6666666666666666'],
            ['Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE', '3', '2', '0.6666666666666666'],
            ['Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS', '11', '7', '0.6363636363636364'],
            ['Volvo -  GROUPE JFC LISIEUX - LISIEUX', '2', '0', '0.0'],
            ['Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)', '12', '2', '0.16666666666666666'],
            ['Volvo -  Groupe JFC Evreux - NORMANVILLE (EVREUX)', '1', '0', '0.0'],
            ['Volvo -  J.D. AUTOMOBILES - SAINT GEREON (ANCENIS)', '1', '0', '0.0'],
            ['Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)', '4', '0', '0.0'],
            ['Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)', '30', '9', '0.3'],
            ['Volvo -  NÖRDIK STAR AUTOS - CHALON SUR SAÔNE', '2', '1', '0.5'],
            ['Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)', '20', '12', '0.6'],
            ['Volvo -  PEMZEC AUTOMOBILES - REDON', '1', '1', '1.0'],
            ['Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)', '12', '4', '0.3333333333333333'],
            ['Volvo -  PEMZEC AUTOMOBILES 29 - BREST', '12', '4', '0.3333333333333333'],
            ['Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER', '8', '3', '0.375'],
            ['Volvo -  PERFORMAUTO - DECHY (DOUAI)', '6', '4', '0.6666666666666666'],
            ['Volvo -  PERFORMAUTO - LIEVIN', '17', '5', '0.29411764705882354'],
            ['Volvo -  PERICAUD AUTOMOBILES - BRIVE LA GAILLARDE', '1', '0', '0.0'],
            ['Volvo -  PERICAUD AUTOMOBILES - CHAMPNIERS (ANGOULEME)', '3', '1', '0.3333333333333333'],
            ['Volvo -  PERICAUD AUTOMOBILES - LIMOGES', '4', '2', '0.5'],
            ['Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)', '7', '3', '0.42857142857142855'],
            ['Volvo -  PREMIUM AUTOMOBILES - AUXERRE', '1', '1', '1.0'],
            ['Volvo -  PREMIUM AUTOMOBILES - BARBEREY SAINT SULPICE (TROYES)', '3', '1', '0.3333333333333333'],
            ['Volvo -  PRESTIGE CARS - AUBIERE (CLERMONT FERRAND)', '3', '1', '0.3333333333333333'],
            ['Volvo -  PRESTIGE CARS - TOULON SUR ALLIER (MOULIN)', '2', '1', '0.5'],
            ['Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE', '6', '5', '0.8333333333333334'],
            ['Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)', '7', '3', '0.42857142857142855'],
            ['Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS', '12', '5', '0.4166666666666667'],
            ['Volvo -  SOMMAUTO - RIVERY LES AMIENS', '16', '5', '0.3125'],
            ['Volvo -  SP MOTORS - BOURGES', '9', '7', '0.7777777777777778'],
            ['Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE', '9', '3', '0.3333333333333333'],
            ['Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)', '13', '3', '0.23076923076923078'],
            ['Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS', '5', '1', '0.2'],
            ['Volvo -  THIBAUT - LE HAVRE', '10', '6', '0.6'],
            ['Volvo -  THIBAUT - ROUEN', '44', '18', '0.4090909090909091'],
            ['Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET', '8', '2', '0.25'],
            ['Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS', '23', '9', '0.391304347826087'],
            ['Volvo -  VAL DE LOIRE AUTOMOBILES - DISTRE (SAUMUR)', '2', '1', '0.5'],
            ['Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE', '17', '8', '0.47058823529411764'],
            ['Volvo -  VK MOTORS - CALAIS', '9', '4', '0.4444444444444444'],
            ['Volvo -  VOLVO COLMAR - COLMAR', '2', '0', '0.0'],
            ["Volvo -  VOLVO CÔTE D'AZUR - FREJUS", '2', '1', '0.5'],
            ['Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE DROITE - LORMONT (BORDEAUX)', '1', '0', '0.0'],
            [
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
                '23',
                '7',
                '0.30434782608695654',
            ],
            ['Volvo -  VOLVO SIPA AUTOMOBILES PAU - LESCAR', '2', '1', '0.5'],
            ['Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)', '9', '3', '0.3333333333333333'],
            ['Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)', '6', '2', '0.3333333333333333'],
            ['Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)', '9', '8', '0.8888888888888888'],
            ["Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE", '15', '7', '0.4666666666666667'],
            ['Volvo - EVERLINE - DENNEY (BELFORT)', '4', '1', '0.25'],
            ['Volvo - FIRST AUTOMOBILES - AULNOY LEZ VALENCIENNES', '1', '1', '1.0'],
            ['Volvo - Malcorps Wavre', '1', '0', '0.0'],
            ['Volvo - THIBAULT AUTOMOBILES - ALENCON', '1', '0', '0.0'],
            ['Volvo Car France', '1', '0', '0.0'],
            ['Volvo Car France - Nanterre', '1', '1', '1.0'],
        ],
    },
};
