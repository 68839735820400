import React, { useContext, useEffect, useState } from 'react';
import { BackendResponse } from 'src/providers/data-source-backend';
import { BackendContext } from 'src/ui/contexts/backend-context';
import Loader from 'src/ui/molecules/Loader';
import { NumberGraph } from 'src/ui/molecules/NumberGraph';
import { useAppAuth } from 'src/ui/contexts/auth-context';
import { FiltersContext } from 'src/ui/contexts/filters-context';
import { useLoading } from 'src/ui/contexts/loadingContext';
import { useLocation } from 'react-router-dom';
import NoDataDisplay from 'src/ui/molecules/NoDataDisplay';
import getOptions from 'src/utils/getOptions';

export function StatusTotalFinalisedInstallations() {
    const backendApi = useContext(BackendContext)!;
    const [data, setData] = useState<BackendResponse | undefined>();
    const { getAccessTokenSilently } = useAppAuth();
    const { filtersState } = useContext(FiltersContext)!;
    const location = useLocation();

    const { incrementLoading, decrementLoading } = useLoading();

    useEffect(() => {
        const populate = async () => {
            incrementLoading();
            try {
                const options = getOptions(location.pathname, filtersState);
                const accessToken = await getAccessTokenSilently({ cacheMode: 'off' });
                setData(
                    await backendApi.getQuery(
                        'finalisedInstallations',
                        'I-H',
                        accessToken,
                        options,
                        false,
                    ),
                );
            } catch (error) {
                console.error(error);
            } finally {
                decrementLoading();
            }
        };

        populate();
    }, [filtersState, backendApi, getAccessTokenSilently, location.pathname]);
    if ((data && !data.result) || (data && data.result && !data.result.data_array)) {
        return <NoDataDisplay />;
    }

    return (
        <>
            {data && data.result && (
                <NumberGraph
                    title={`Total ${data.title}`}
                    value={parseInt(data.result.data_array[0][0] ?? '0')}
                />
            )}
            {!data && <Loader />}
        </>
    );
}
