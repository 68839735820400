export const block1jMock = {
    statement_id: '01ee99e1-1ac8-1488-9962-6b07439ca35b',
    status: {
        state: 'SUCCEEDED',
    },
    manifest: {
        format: 'JSON_ARRAY',
        schema: {
            column_count: 3,
            columns: [
                {
                    name: 'total_leads',
                    type_text: 'BIGINT',
                    type_name: 'LONG',
                    position: 0,
                },
                {
                    name: 'qualified_leads',
                    type_text: 'BIGINT',
                    type_name: 'LONG',
                    position: 1,
                },
                {
                    name: 'percentage_qualified',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 2,
                },
            ],
        },
        total_chunk_count: 1,
        chunks: [
            {
                chunk_index: 0,
                row_offset: 0,
                row_count: 1,
            },
        ],
        total_row_count: 1,
        truncated: false,
    },
    result: {
        chunk_index: 0,
        row_offset: 0,
        row_count: 1,
        data_array: [['2029', '920', '45.0%']],
    },
};
