export const contributorsMock = {
    statement_id: '01ee9e4e-a9ff-1e2d-976f-47393330697f',
    status: { state: 'SUCCEEDED' },
    manifest: {
        format: 'JSON_ARRAY',
        schema: { column_count: 1, columns: [{ name: 'name', type_text: 'STRING', type_name: 'STRING', position: 0 }] },
        total_chunk_count: 1,
        chunks: [{ chunk_index: 0, row_offset: 0, row_count: 140 }],
        total_row_count: 140,
        truncated: false,
    },
    result: {
        chunk_index: 0,
        row_offset: 0,
        row_count: 140,
        data_array: [
            ['ABVV Chantilly', 'ABVV Chantilly'],
            ['ABVV Roissy en France', 'ABVV Roissy en France'],
            ['BMW MINI - SAINT-MERRI AMIENS - AMIENS', 'BMW MINI - SAINT-MERRI AMIENS - AMIENS'],
            ['ChargeGuru', 'ChargeGuru'],
            ['ChargeGuru Business Services', 'ChargeGuru Business Services'],
            ['Concession Inconnue', 'Concession Inconnue'],
            ['ELYPSE AUTO', 'ELYPSE AUTO'],
            ['Groupe Saint Christophe', 'Groupe Saint Christophe'],
            ['JMJ Automobiles', 'JMJ Automobiles'],
            ['LARSON by Autosphere', 'LARSON by Autosphere'],
            ['Tesla Store Lyon-Chemin des Gorges', 'Tesla Store Lyon-Chemin des Gorges'],
            ['Unknown Facilitator', 'Unknown Facilitator'],
            ['Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY', 'Volvo -  A.B.V.V. AUTOMOBILES - CHANTILLY'],
            ['Volvo -  A.B.V.V. AUTOMOBILES - EPINAY SUR SEINE', 'Volvo -  A.B.V.V. AUTOMOBILES - EPINAY SUR SEINE'],
            ['Volvo -  A.B.V.V. AUTOMOBILES - ROISSY EN FRANCE', 'Volvo -  A.B.V.V. AUTOMOBILES - ROISSY EN FRANCE'],
            [
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
                'Volvo -  A.B.V.V. AUTOMOBILES - SAINT OUEN L´AUMONE',
            ],
            ['Volvo -  A.P.S - BOURG EN BRESSE', 'Volvo -  A.P.S - BOURG EN BRESSE'],
            [
                'Volvo -  ACTENA AUTOMOBILES - BUCHELAY (MANTES LA JOLIE)',
                'Volvo -  ACTENA AUTOMOBILES - BUCHELAY (MANTES LA JOLIE)',
            ],
            ['Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES', 'Volvo -  ACTENA AUTOMOBILES - LA GARENNE COLOMBES'],
            ['Volvo -  ACTENA AUTOMOBILES - MAUREPAS', 'Volvo -  ACTENA AUTOMOBILES - MAUREPAS'],
            ['Volvo -  ACTENA AUTOMOBILES - NEUILLY SUR SEINE', 'Volvo -  ACTENA AUTOMOBILES - NEUILLY SUR SEINE'],
            ['Volvo -  ACTENA AUTOMOBILES - PARIS', 'Volvo -  ACTENA AUTOMOBILES - PARIS'],
            ['Volvo -  ACTENA AUTOMOBILES - PORT MARLY', 'Volvo -  ACTENA AUTOMOBILES - PORT MARLY'],
            ['Volvo -  ACTENA AUTOMOBILES - VERSAILLES', 'Volvo -  ACTENA AUTOMOBILES - VERSAILLES'],
            ['Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE', 'Volvo -  ACTION AUTOMOBILE 13 - MARSEILLE'],
            [
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
                'Volvo -  ACTION AUTOMOBILE DU VAR - LA GARDE (TOULON)',
            ],
            ['Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE', 'Volvo -  AMPLITUDE AUTOMOBILES - LA ROCHELLE'],
            ['Volvo -  AMPLITUDE AUTOMOBILES - SAINTES', 'Volvo -  AMPLITUDE AUTOMOBILES - SAINTES'],
            [
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
                'Volvo -  ATLANTEN - MOUILLERON LE CAPTIF (LA ROCHE SUR YON)',
            ],
            [
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
                'Volvo -  AUBERT AUTOMOBILES - TOURLAVILLE (CHERBOURG)',
            ],
            ['Volvo -  AUTO PREMIUM 08 - CHARLEVILLE MEZIERES', 'Volvo -  AUTO PREMIUM 08 - CHARLEVILLE MEZIERES'],
            [
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
                'Volvo -  CACHET GIRAUD ATLANTIQUE - BIARD (POITIERS)',
            ],
            ['Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT', 'Volvo -  CACHET GIRAUD ATLANTIQUE - NIORT'],
            ["Volvo -  CAR'S SERVICES - ONET LE CHATEAU (RODEZ)"],
            ['Volvo -  CARAUTO SERVICES - GAP', 'Volvo -  CARAUTO SERVICES - GAP'],
            ['Volvo -  CARBURY AUTOMOBILES - VALENCE', 'Volvo -  CARBURY AUTOMOBILES - VALENCE'],
            ['Volvo -  CARS PREMIUM - CARCASSONNE', 'Volvo -  CARS PREMIUM - CARCASSONNE'],
            ['Volvo -  CARS PREMIUM - PERPIGNAN', 'Volvo -  CARS PREMIUM - PERPIGNAN'],
            ['Volvo -  CAVALLARI AUTOMOBILES - MONACO', 'Volvo -  CAVALLARI AUTOMOBILES - MONACO'],
            ['Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)', 'Volvo -  CAVALLARI AUTOMOBILES - MOUGINS (CANNES)'],
            ['Volvo -  CAVALLARI AUTOMOBILES - NICE', 'Volvo -  CAVALLARI AUTOMOBILES - NICE'],
            ['Volvo -  CMM AUTOMOBILES - SAINT PIERRE', 'Volvo -  CMM AUTOMOBILES - SAINT PIERRE'],
            ['Volvo -  CMM AUTOMOBILES - SAINTE CLOTILDE', 'Volvo -  CMM AUTOMOBILES - SAINTE CLOTILDE'],
            ['Volvo -  COMMERES SAS - SEMEAC (TARBES)', 'Volvo -  COMMERES SAS - SEMEAC (TARBES)'],
            ['Volvo -  CSD MOTORS - BOE (AGEN)', 'Volvo -  CSD MOTORS - BOE (AGEN)'],
            ['Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)', 'Volvo -  CYRNEA NORTH CARS - AFA (AJACCIO)'],
            ['Volvo -  CYRNEA NORTH CARS - BORGO', 'Volvo -  CYRNEA NORTH CARS - BORGO'],
            [
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
                'Volvo -  CÔTE OUEST AUTOMOBILES - SAINT BRIEUC CEDEX 1',
            ],
            [
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
                'Volvo -  DARMENDRAIL AUTOMOBILES - ANGLET (BAYONNE)',
            ],
            [
                'Volvo -  DARMENDRAIL AUTOMOBILES - SAINT AVIT (MONT DE MARSAN)',
                'Volvo -  DARMENDRAIL AUTOMOBILES - SAINT AVIT (MONT DE MARSAN)',
            ],
            [
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
                'Volvo -  DEFRANCE AUTOMOBILES - CESSON SEVIGNE (RENNES)',
            ],
            [
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
                'Volvo -  DEFRANCE LAVAL SARL - SAINT BERTHEVIN (LAVAL)',
            ],
            ['Volvo -  DELHORBE AUTOMOBILES - DIZY (EPERNAY)', 'Volvo -  DELHORBE AUTOMOBILES - DIZY (EPERNAY)'],
            ['Volvo -  DELHORBE AUTOMOBILES - REIMS', 'Volvo -  DELHORBE AUTOMOBILES - REIMS'],
            [
                'Volvo -  DELHORBE AUTOMOBILES - SAINT MARTIN SUR LE PRE (CHALONS EN CHAMPAGNE)',
                'Volvo -  DELHORBE AUTOMOBILES - SAINT MARTIN SUR LE PRE (CHALONS EN CHAMPAGNE)',
            ],
            ['Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)', 'Volvo -  DISCOVER EPAGNY - EPAGNY ( ANNECY)'],
            [
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
                'Volvo -  ELYSEE AUTOMOBILES - AVON (FONTAINEBLEAU)',
            ],
            ['Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN', 'Volvo -  ELYSEE AUTOMOBILES 77 - MONTEVRAIN'],
            [
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
                'Volvo -  ELYSEE AUTOMOBILES SAS - VERT SAINT DENIS (MELUN)',
            ],
            ['Volvo -  ELYSEE EST AUTOS (11ème) - PARIS', 'Volvo -  ELYSEE EST AUTOS (11ème) - PARIS'],
            ['Volvo -  ELYSEE EST AUTOS - CHENNEVIERES', 'Volvo -  ELYSEE EST AUTOS - CHENNEVIERES'],
            ['Volvo -  ESPACE NORD AUTOMOBILES - METZ', 'Volvo -  ESPACE NORD AUTOMOBILES - METZ'],
            [
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
                'Volvo -  ESPACE NORD AUTOMOBILES - SOUFFELWEYERSHEIM (STRASBOURG)',
            ],
            [
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
                'Volvo -  ESPACE SUD AUTOMOBILES - MAUGUIO (MONTPELLIER)',
            ],
            ['Volvo -  ESPACE SUD AUTOMOBILES - NIMES', 'Volvo -  ESPACE SUD AUTOMOBILES - NIMES'],
            ['Volvo -  EVERLINE - BESANÇON', 'Volvo -  EVERLINE - BESANÇON'],
            ['Volvo -  EVERLINE - EPINAL CEDEX', 'Volvo -  EVERLINE - EPINAL CEDEX'],
            ['Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)', 'Volvo -  EVERLINE - SAUSHEIM (MULHOUSE)'],
            ['Volvo -  FELIX FAURE AUTOMOBILES - LYON', 'Volvo -  FELIX FAURE AUTOMOBILES - LYON'],
            ['Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX', 'Volvo -  FELIX FAURE AUTOMOBILES - VENISSIEUX'],
            ['Volvo -  FELIX FAURE AUTOMOBILES - VIENNE', 'Volvo -  FELIX FAURE AUTOMOBILES - VIENNE'],
            [
                'Volvo -  FELIX FAURE AUTOMOBILES - VILLEFRANCHE-SUR-SAÔNE',
                'Volvo -  FELIX FAURE AUTOMOBILES - VILLEFRANCHE-SUR-SAÔNE',
            ],
            [
                'Volvo -  FELIX FAURE GRENOBLE - SEYSINNET - PARISET (GRENOBLE)',
                'Volvo -  FELIX FAURE GRENOBLE - SEYSINNET - PARISET (GRENOBLE)',
            ],
            [
                'Volvo -  FELIX FAURE SAINT ETIENNE - SAINT ETIENNE',
                'Volvo -  FELIX FAURE SAINT ETIENNE - SAINT ETIENNE',
            ],
            ['Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)', 'Volvo -  FIRST AUTOMOBILES - RONCQ (LILLE)'],
            ["Volvo -  FIRST AUTOMOBILES - VILLENEUVE D'ASCQ"],
            ['Volvo -  GENERAL AUTOMOBILE - GUERANDE', 'Volvo -  GENERAL AUTOMOBILE - GUERANDE'],
            ['Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)', 'Volvo -  GENERAL AUTOMOBILE - ORVAULT (NANTES)'],
            ['Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN', 'Volvo -  GF PREMIUM AUTOMOBILES - SAINT QUENTIN'],
            ['Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI', 'Volvo -  GRIMAL AUTOMOBILES PREMIUM - ALBI'],
            [
                'Volvo -  GROUPE DUFFORT CHARTRES - NOGENT LE PHAYE (CHARTRES)',
                'Volvo -  GROUPE DUFFORT CHARTRES - NOGENT LE PHAYE (CHARTRES)',
            ],
            ['Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS', 'Volvo -  GROUPE DUFFORT LES ULIS - LES ULIS'],
            ['Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE', 'Volvo -  GROUPE DUFFORT MONTROUGE - MONTROUGE'],
            ['Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS', 'Volvo -  GROUPE DUFFORT ORLEANS - ORLEANS'],
            ['Volvo -  GROUPE JFC LISIEUX - LISIEUX', 'Volvo -  GROUPE JFC LISIEUX - LISIEUX'],
            [
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
                'Volvo -  Groupe JFC Caen - BIEVILLE BEUVILLE (CAEN)',
            ],
            ['Volvo -  Groupe JFC Evreux - NORMANVILLE (EVREUX)', 'Volvo -  Groupe JFC Evreux - NORMANVILLE (EVREUX)'],
            [
                'Volvo -  J.D. AUTOMOBILES - SAINT GEREON (ANCENIS)',
                'Volvo -  J.D. AUTOMOBILES - SAINT GEREON (ANCENIS)',
            ],
            [
                'Volvo -  J.M. AUTOMOBILES - SAUVERNY (DIVONNE LES BAINS)',
                'Volvo -  J.M. AUTOMOBILES - SAUVERNY (DIVONNE LES BAINS)',
            ],
            ['Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)', 'Volvo -  MACON NORD AUTOMOBILES - SANCE (MACON)'],
            ['Volvo -  MARTINIQUE AUTOMOBILES - LE LAMENTIN', 'Volvo -  MARTINIQUE AUTOMOBILES - LE LAMENTIN'],
            ['Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)', 'Volvo -  NUDANT AUTOMOBILES SAS - CHENÔVE (DIJON)'],
            ['Volvo -  NÖRDIK STAR AUTOS - CHALON SUR SAÔNE', 'Volvo -  NÖRDIK STAR AUTOS - CHALON SUR SAÔNE'],
            ['Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)', 'Volvo -  PEMZEC AUTOMOBILES - LANESTER (LORIENT)'],
            ['Volvo -  PEMZEC AUTOMOBILES - REDON', 'Volvo -  PEMZEC AUTOMOBILES - REDON'],
            ['Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)', 'Volvo -  PEMZEC AUTOMOBILES - THEIX (VANNES)'],
            ['Volvo -  PEMZEC AUTOMOBILES 29 - BREST', 'Volvo -  PEMZEC AUTOMOBILES 29 - BREST'],
            ['Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER', 'Volvo -  PEMZEC AUTOMOBILES 29 - QUIMPER'],
            ['Volvo -  PERFORMAUTO - DECHY (DOUAI)', 'Volvo -  PERFORMAUTO - DECHY (DOUAI)'],
            ['Volvo -  PERFORMAUTO - LIEVIN', 'Volvo -  PERFORMAUTO - LIEVIN'],
            [
                'Volvo -  PERICAUD AUTOMOBILES - BRIVE LA GAILLARDE',
                'Volvo -  PERICAUD AUTOMOBILES - BRIVE LA GAILLARDE',
            ],
            [
                'Volvo -  PERICAUD AUTOMOBILES - CHAMPNIERS (ANGOULEME)',
                'Volvo -  PERICAUD AUTOMOBILES - CHAMPNIERS (ANGOULEME)',
            ],
            ['Volvo -  PERICAUD AUTOMOBILES - LIMOGES', 'Volvo -  PERICAUD AUTOMOBILES - LIMOGES'],
            [
                'Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)',
                'Volvo -  PERICAUD AUTOMOBILES - TRELISSAC (PERIGUEUX)',
            ],
            ['Volvo -  PREMIUM AUTOMOBILES - AUXERRE', 'Volvo -  PREMIUM AUTOMOBILES - AUXERRE'],
            [
                'Volvo -  PREMIUM AUTOMOBILES - BARBEREY SAINT SULPICE (TROYES)',
                'Volvo -  PREMIUM AUTOMOBILES - BARBEREY SAINT SULPICE (TROYES)',
            ],
            [
                'Volvo -  PRESTIGE CARS - AUBIERE (CLERMONT FERRAND)',
                'Volvo -  PRESTIGE CARS - AUBIERE (CLERMONT FERRAND)',
            ],
            [
                'Volvo -  PRESTIGE CARS - TOULON SUR ALLIER (MOULIN)',
                'Volvo -  PRESTIGE CARS - TOULON SUR ALLIER (MOULIN)',
            ],
            ['Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE', 'Volvo -  SAINT-CHISTOPHE BOREAL - MAXEVILLE'],
            [
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
                'Volvo -  SAS JMJ AVENIR AUTOMOBILES - PERRIGNY (LONS LE SAUNIER)',
            ],
            ['Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS', 'Volvo -  SOCIETE BIDAUD PERE ET FILS - ATHIS MONS'],
            ['Volvo -  SOMMAUTO - RIVERY LES AMIENS', 'Volvo -  SOMMAUTO - RIVERY LES AMIENS'],
            [
                'Volvo -  SOMMAUTO - VAUCHELLES LES QUESNOY (ABBEVILLE)',
                'Volvo -  SOMMAUTO - VAUCHELLES LES QUESNOY (ABBEVILLE)',
            ],
            ['Volvo -  SP MOTORS - BOURGES', 'Volvo -  SP MOTORS - BOURGES'],
            [
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
                'Volvo -  SUEDE MEDITERRANEE AUTOMOBILES - AIX EN PROVENCE',
            ],
            [
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
                'Volvo -  SUEDE PROVENCE AUTOMOBILES SAS - MONTFAVET (AVIGNON)',
            ],
            ['Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS', 'Volvo -  THIBAULT OPTIMUM AUTOMOBILES - LE MANS'],
            ['Volvo -  THIBAUT - LE HAVRE', 'Volvo -  THIBAUT - LE HAVRE'],
            ['Volvo -  THIBAUT - ROUEN', 'Volvo -  THIBAUT - ROUEN'],
            ['Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET', 'Volvo -  VAL DE LOIRE AUTOMOBILE - CHOLET'],
            ['Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS', 'Volvo -  VAL DE LOIRE AUTOMOBILES - ANGERS'],
            [
                'Volvo -  VAL DE LOIRE AUTOMOBILES - DISTRE (SAUMUR)',
                'Volvo -  VAL DE LOIRE AUTOMOBILES - DISTRE (SAUMUR)',
            ],
            [
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
                'Volvo -  VAL DE LOIRE AUTOMOBILES TOURS - SAINT CYR SUR LOIRE',
            ],
            ['Volvo -  VK MOTORS - CALAIS', 'Volvo -  VK MOTORS - CALAIS'],
            ['Volvo -  VOLVO COLMAR - COLMAR', 'Volvo -  VOLVO COLMAR - COLMAR'],
            ["Volvo -  VOLVO CÔTE D'AZUR - FREJUS"],
            [
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE DROITE - LORMONT (BORDEAUX)',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE DROITE - LORMONT (BORDEAUX)',
            ],
            [
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
                'Volvo -  VOLVO SIPA AUTOMOBILES BORDEAUX RIVE GAUCHE - MERIGNAC (BORDEAUX)',
            ],
            ['Volvo -  VOLVO SIPA AUTOMOBILES PAU - LESCAR', 'Volvo -  VOLVO SIPA AUTOMOBILES PAU - LESCAR'],
            [
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
                'Volvo -  VOLVO SIPA AUTOMOBILES TOULOUSE SUD - LABEGE (TOULOUSE)',
            ],
            [
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
                'Volvo - DISCOVER LA RAVOIRE - LA RAVOIRE (CHAMBERY)',
            ],
            [
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
                'Volvo - DISCOVER VILLE LA GRAND - VILLE LA GRAND (ANNEMASSE)',
            ],
            ["Volvo - EUROP'AUTO DUNKERQUE - DUNKERQUE"],
            ['Volvo - EVERLINE - DENNEY (BELFORT)', 'Volvo - EVERLINE - DENNEY (BELFORT)'],
            [
                'Volvo - FIRST AUTOMOBILES - AULNOY LEZ VALENCIENNES',
                'Volvo - FIRST AUTOMOBILES - AULNOY LEZ VALENCIENNES',
            ],
            ['Volvo - Malcorps Wavre', 'Volvo - Malcorps Wavre'],
            ['Volvo - THIBAULT AUTOMOBILES - ALENCON', 'Volvo - THIBAULT AUTOMOBILES - ALENCON'],
            ['Volvo Car France', 'Volvo Car France'],
            ['Volvo Car France - Nanterre', 'Volvo Car France - Nanterre'],
            ['Volvo car France', 'Volvo car France'],
        ],
    },
};
