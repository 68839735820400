import React from 'react';
import { StatusMonthlyEvolutionOfCreatedLeadByStatus } from '../../atoms/graphs/status-monthly-evolution-of-created-lead-by-status';
import { StatusTotalCreatedLeadByStatus } from '../../atoms/graphs/status-total-created-lead-by-status';
import { StatusMonthlyEvolutionOfOpportunityByStatus } from '../../atoms/graphs/status-monthly-evolution-of-opportunity-by-status';
import { StatusTotalOpportunityByStatus } from '../../atoms/graphs/status-total-opportunity-by-status';
import { StatusMonthlyEvolutionOfOrderByStatus } from '../../atoms/graphs/status-monthly-evolution-of-order-by-status';
import { StatusTotalOrdersByStatus } from '../../atoms/graphs/status-total-orders-by-status';
import { StatusMonthlyEvolutionOfFinalisedInstallations } from '../../atoms/graphs/status-monthly-evolution-of-finalised-installations';
import { StatusTotalFinalisedInstallations } from '../../atoms/graphs/status-total-finalised-installations';
import { StatusMonthlyEvolutionOfQualifiedLeadRate } from '../../atoms/graphs/status-monthly-evolution-of-qualified-lead-rate';
import { StatusQualifiedLeadRate } from '../../atoms/graphs/status-qualified-lead-rate';
import { StatusMonthlyEvolutionOfProjectWinRate } from '../../atoms/graphs/status-monthly-evolution-of-project-win-rate';
import { StatusProjectWinRate } from '../../atoms/graphs/status-project-win-rate';
import { StatusMonthlyEvolutionOfQuotationLeadtime } from '../../atoms/graphs/status-monthly-evolution-of-quotation-leadtime';
import { StatusMonthlyEvolutionOfInstallationLeadtime } from '../../atoms/graphs/status-monthly-evolution-of-installation-leadtime';
import { GraphLayout } from '../../layout/GraphLayout';

export function StatusGraphs() {
    return (
        <GraphLayout
            title="Status"
            graphs={[
                <StatusMonthlyEvolutionOfCreatedLeadByStatus />,
                <StatusTotalCreatedLeadByStatus />,
                <StatusMonthlyEvolutionOfOpportunityByStatus />,
                <StatusTotalOpportunityByStatus />,
                <StatusMonthlyEvolutionOfOrderByStatus />,
                <StatusTotalOrdersByStatus />,
                <StatusMonthlyEvolutionOfFinalisedInstallations />,
                <StatusTotalFinalisedInstallations />,
                <StatusMonthlyEvolutionOfQualifiedLeadRate />,
                <StatusQualifiedLeadRate />,
                <StatusMonthlyEvolutionOfProjectWinRate />,
                <StatusProjectWinRate />,
                <StatusMonthlyEvolutionOfQuotationLeadtime />,
                <StatusMonthlyEvolutionOfInstallationLeadtime />,
            ]}
        />
    );
}
