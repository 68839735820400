import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import App from './ui/App';
import reportWebVitals from './ui/reportWebVitals';
import { CssBaseline, CssVarsProvider, StyledEngineProvider } from '@mui/joy';
import { theme } from './ui/theme';
import { buildAxiosInstance } from './providers/axios_connector';
import { AxiosInstance } from 'axios';
import { BackendApi } from './providers/data-source-backend';
import { FiltersReducer, filtersReducer } from './core/filters/service';

// Config
export type Config = {
    auth: {
        domain: string;
        clientId: string;
        audience: string;
    };
};

const loadConfig = async (axios_connector: AxiosInstance): Promise<Config> => {
    const defaultConfig: Config = {
        auth: {
            // domain: 'chargeguru-dev.eu.auth0.com', // default should be prod config
            // clientId: 'mVZDA8YMH2fRbDhT6LBLh3FSoLPguX69',
            // audience: 'https://installer.chargeguru.com',

            // domain: 'http://mock-server:1080', // default should be prod config
            // clientId: 'mVZDA8YMH2fRbDhT6LBLh3FSoLPguX69',
            // audience: 'https://installer.chargeguru.com',

            domain: `${process.env.REACT_APP_AUTH0_DOMAIN}`, // default should be prod config
            clientId: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
            audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
        },
    };
    return defaultConfig;
};

(async function () {
    const axios_connector = buildAxiosInstance('/');

    // Config
    const config = await loadConfig(axios_connector);

    // Providers
    const backendApi = new BackendApi(axios_connector);
    const filtersService: FiltersReducer = filtersReducer();

    // Service

    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    root.render(
        <React.StrictMode>
            <StyledEngineProvider injectFirst>
                <CssVarsProvider theme={theme}>
                    <CssBaseline />
                    <App backendApi={backendApi} config={config} filtersService={filtersService} />
                </CssVarsProvider>
            </StyledEngineProvider>
        </React.StrictMode>,
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals(console.log);
})();
