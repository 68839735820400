import React from 'react';
import { Stack, Typography } from '@mui/joy';

export function NumberGraph(props: { title: string; value: number; unit?: string }) {
    return (
        <Stack height="100%" alignItems="center">
            <Typography fontWeight={700}>{props.title}</Typography>
            <Stack height="100%" justifyContent="center">
                <Typography fontSize={100} fontWeight={700}>
                    {props.value}
                    {props.unit}
                </Typography>
            </Stack>
        </Stack>
    );
}
