import React from 'react';
import { GraphLayout } from '../../layout/GraphLayout';
import { ChannelMonthlyEvolutionOfCreatedLeadByChannel } from 'src/ui/atoms/graphs/channel-monthly-evolution-of-created-lead-by-channel';
import { ChannelTotalCreatedLeadByChannel } from 'src/ui/atoms/graphs/channel-total-created-lead-by-channel';
import { ChannelMonthlyEvolutionOfOpportunityByChannel } from 'src/ui/atoms/graphs/channel-monthly-evolution-of-opportunity-by-channel';
import { ChannelTotalOpportunityByChannel } from 'src/ui/atoms/graphs/channel-total-opportunities-by-channel';
import { ChannelMonthlyEvolutionOfOrderByChannel } from 'src/ui/atoms/graphs/channel-monthly-evolution-of-order-by-channel';
import { ChannelTotalOrdersByChannel } from 'src/ui/atoms/graphs/channel-total-orders-by-channel';
import { ChannelMonthlyEvolutionOfFinalisedInstallationsByChannel } from 'src/ui/atoms/graphs/channel-monthly-evolution-of-finalised-installations-by-channel';
import { ChannelTotalFinalisedInstallationsByChannel } from 'src/ui/atoms/graphs/channel-total-finalised-installations-by-channel';
import { ChannelMonthlyEvolutionOfQualifiedLeadRateByChannel } from 'src/ui/atoms/graphs/channel-monthly-evolution-of-qualified-lead-rate-by-channel';
import { ChannelQualifiedLeadRateByChannel } from 'src/ui/atoms/graphs/channel-qualified-lead-rate-by-channel';
import { ChannelMonthlyEvolutionOfProjectWinRateByChannel } from 'src/ui/atoms/graphs/channel-monthly-evolution-of-project-win-rate-by-channel';
import { ChannelProjectWinRateByChannel } from 'src/ui/atoms/graphs/channel-project-win-rate-by-channel';
import { ChannelMonthlyEvolutionOfQuotationLeadtimeByChannel } from 'src/ui/atoms/graphs/channel-monthly-evolution-of-quotation-leadtime-by-channel';
import { ChannelMonthlyEvolutionOfInstallationLeadtimeByChannel } from 'src/ui/atoms/graphs/channel-monthly-evolution-of-installation-leadtime-by-channel';

export function ChannelGraphs() {
    return (
        <GraphLayout
            title="Channel"
            graphs={[
                <ChannelMonthlyEvolutionOfCreatedLeadByChannel />,
                <ChannelTotalCreatedLeadByChannel />,
                <ChannelMonthlyEvolutionOfOpportunityByChannel />,
                <ChannelTotalOpportunityByChannel />,
                <ChannelMonthlyEvolutionOfOrderByChannel />,
                <ChannelTotalOrdersByChannel />,
                <ChannelMonthlyEvolutionOfFinalisedInstallationsByChannel />,
                <ChannelTotalFinalisedInstallationsByChannel />,
                <ChannelMonthlyEvolutionOfQualifiedLeadRateByChannel />,
                <ChannelQualifiedLeadRateByChannel />,
                <ChannelMonthlyEvolutionOfProjectWinRateByChannel />,
                <ChannelProjectWinRateByChannel />,
                <ChannelMonthlyEvolutionOfQuotationLeadtimeByChannel />,
                <ChannelMonthlyEvolutionOfInstallationLeadtimeByChannel />,
            ]}
        />
    );
}
