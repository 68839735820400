export const block4iMock = {
    statement_id: '01ee9b2f-01f6-1998-8e16-87dd788e99f4',
    status: {
        state: 'SUCCEEDED',
    },
    manifest: {
        format: 'JSON_ARRAY',
        schema: {
            column_count: 5,
            columns: [
                {
                    name: 'month',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 0,
                },
                {
                    name: 'country_name',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 1,
                },
                {
                    name: 'total_leads',
                    type_text: 'BIGINT',
                    type_name: 'LONG',
                    position: 2,
                },
                {
                    name: 'qualified_leads',
                    type_text: 'BIGINT',
                    type_name: 'LONG',
                    position: 3,
                },
                {
                    name: 'percentage_qualified',
                    type_text: 'DOUBLE',
                    type_name: 'DOUBLE',
                    position: 4,
                },
            ],
        },
        total_chunk_count: 1,
        chunks: [
            {
                chunk_index: 0,
                row_offset: 0,
                row_count: 41,
            },
        ],
        total_row_count: 41,
        truncated: false,
    },
    result: {
        chunk_index: 0,
        row_offset: 0,
        row_count: 41,
        data_array: [
            ['2019-12', 'France', '1', '0', '0.0'],
            ['2020-02', 'Belgium', '1', '0', '0.0'],
            ['2020-06', 'Belgium', '1', '0', '0.0'],
            ['2020-07', 'France', '1', '1', '1.0'],
            ['2020-08', 'France', '1', '0', '0.0'],
            ['2020-09', 'France', '2', '0', '0.0'],
            ['2020-10', 'Belgium', '1', '0', '0.0'],
            ['2020-12', 'France', '2', '1', '0.5'],
            ['2021-01', 'France', '3', '2', '0.6666666666666666'],
            ['2021-02', 'France', '1', '0', '0.0'],
            ['2021-04', 'France', '1', '0', '0.0'],
            ['2021-06', 'France', '2', '0', '0.0'],
            ['2021-08', 'France', '1', '0', '0.0'],
            ['2021-09', 'France', '4', '1', '0.25'],
            ['2021-10', 'France', '22', '1', '0.045454545454545456'],
            ['2021-11', 'France', '48', '19', '0.3958333333333333'],
            ['2021-12', 'France', '73', '40', '0.547945205479452'],
            ['2022-01', 'France', '69', '41', '0.5942028985507246'],
            ['2022-02', 'France', '84', '37', '0.44047619047619047'],
            ['2022-03', 'France', '182', '71', '0.3901098901098901'],
            ['2022-04', 'France', '156', '78', '0.5'],
            ['2022-05', 'France', '155', '68', '0.43870967741935485'],
            ['2022-06', 'France', '194', '76', '0.3917525773195876'],
            ['2022-07', 'France', '114', '65', '0.5701754385964912'],
            ['2022-08', 'France', '76', '42', '0.5526315789473685'],
            ['2022-09', 'France', '118', '59', '0.5'],
            ['2022-10', 'France', '109', '41', '0.3761467889908257'],
            ['2022-11', 'France', '86', '32', '0.37209302325581395'],
            ['2022-12', 'France', '80', '43', '0.5375'],
            ['2023-01', 'France', '72', '28', '0.3888888888888889'],
            ['2023-02', 'France', '45', '19', '0.4222222222222222'],
            ['2023-03', 'France', '61', '27', '0.4426229508196721'],
            ['2023-04', 'France', '50', '19', '0.38'],
            ['2023-05', 'France', '19', '5', '0.2631578947368421'],
            ['2023-06', 'France', '36', '16', '0.4444444444444444'],
            ['2023-07', 'France', '23', '12', '0.5217391304347826'],
            ['2023-08', 'France', '22', '11', '0.5'],
            ['2023-09', 'France', '43', '21', '0.4883720930232558'],
            ['2023-10', 'France', '42', '24', '0.5714285714285714'],
            ['2023-11', 'France', '32', '23', '0.71875'],
            ['2023-12', 'France', '3', '3', '1.0'],
        ],
    },
};
