import React, { useContext, useEffect, useState } from 'react';
import { BackendResponse } from 'src/providers/data-source-backend';
import { BackendContext } from 'src/ui/contexts/backend-context';
import Loader from 'src/ui/molecules/Loader';
import { DoughnutGraph } from 'src/ui/molecules/DoughnutGraph';
import { useAppAuth } from 'src/ui/contexts/auth-context';
import { FiltersContext } from 'src/ui/contexts/filters-context';
import { useLoading } from 'src/ui/contexts/loadingContext';
import { useLocation } from 'react-router-dom';
import getOptions from 'src/utils/getOptions';

export function ChannelTotalOrdersByChannel() {
    const backendApi = useContext(BackendContext)!;
    const [data, setData] = useState<BackendResponse | undefined>();
    const { getAccessTokenSilently } = useAppAuth();
    const { filtersState } = useContext(FiltersContext)!;
    const location = useLocation();
    const { incrementLoading, decrementLoading } = useLoading();

    useEffect(() => {
        const populate = async () => {
            incrementLoading();
            try {
                const options = getOptions(location.pathname, filtersState);
                const accessToken = await getAccessTokenSilently({ cacheMode: 'off' });
                setData(
                    await backendApi.getQuery(
                        'projectsWon',
                        'II-F',
                        accessToken,
                        options,
                    ),
                );
            } catch (error) {
                console.error(error);
            } finally {
                decrementLoading();
            }
        };

        populate();
    }, [filtersState, backendApi, getAccessTokenSilently, location.pathname]);

    return (
        <>
            {data && (
                <DoughnutGraph
                    data={data}
                    title={`Total ${data.title}`}
                    axisYValue="channel"
                    axisXValue="total_orders"
                    displayLegend={false}
                />
            )}
            {!data && <Loader />}
        </>
    );
}
