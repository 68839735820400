import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/joy';

export const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    const handleLogin = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: '/overview',
            },
        });
    };

    return (
        <Button onClick={handleLogin} data-test-id="login">
            Signin
        </Button>
    );
};
