import React, { useEffect, useState } from 'react';

import { PageTemplate } from '../layout/PageTemplate';
import { Card, Stack } from '@mui/joy';
import Filters from '../molecules/Filters';
import { ContributorsGraphs } from '../molecules/graphs/contributors-graphs';

export function Contributor() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, []);
    return (
        <PageTemplate greyBackground pageTitle={<span>Business contributor</span>}>
            <Stack
                sx={{
                    minHeight: 'calc(100vh - 82px)',
                    padding: '21px 20px',
                    justifyContent: 'space-between',
                }}
                data-test-id="overview-page"
            >
                <Stack
                    sx={{
                        justifyContent: 'flex-start',
                    }}
                >
                    <Card
                        sx={{
                            borderTopLeftRadius: 0,
                            border: 'none',
                        }}
                    >
                        <Filters />
                        {!loading && (
                            <>
                                <ContributorsGraphs />
                            </>
                        )}
                    </Card>
                </Stack>
            </Stack>
        </PageTemplate>
    );
}
