import { Stack } from '@mui/joy';
import Select from './form/Select';
import { useLoading } from 'src/ui/contexts/loadingContext';
import Loader from 'src/ui/molecules/Loader';

export default function Filters() {
    const { loadingCount } = useLoading();
    return (
        <Stack direction="row">
            <Stack marginRight="10px">
                <Select type="countries" />
            </Stack>
            <Stack marginRight="10px">
                <Select type="types" />
            </Stack>
            <Stack marginRight="10px">
                <Select type="contributors" />
            </Stack>
            <Stack marginRight="10px">
                <Select type="dates" />
            </Stack>
            {loadingCount > 0 && <Loader />}
        </Stack>
    );
}
