export const block1lMock = {
    statement_id: '01ee9b2e-c4b1-1e55-bf33-8e10a18fa861',
    status: {
        state: 'SUCCEEDED',
    },
    manifest: {
        format: 'JSON_ARRAY',
        schema: {
            column_count: 3,
            columns: [
                {
                    name: 'total_opp',
                    type_text: 'BIGINT',
                    type_name: 'LONG',
                    position: 0,
                },
                {
                    name: 'won_opp',
                    type_text: 'BIGINT',
                    type_name: 'LONG',
                    position: 1,
                },
                {
                    name: 'percentage_won',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 2,
                },
            ],
        },
        total_chunk_count: 1,
        chunks: [
            {
                chunk_index: 0,
                row_offset: 0,
                row_count: 1,
            },
        ],
        total_row_count: 1,
        truncated: false,
    },
    result: {
        chunk_index: 0,
        row_offset: 0,
        row_count: 1,
        data_array: [['1156', '484', '42.0%']],
    },
};
