export const block3kMock = {
    statement_id: '01ee9b2e-f78c-1a03-b840-3c9daa03c02a',
    status: {
        state: 'SUCCEEDED',
    },
    manifest: {
        format: 'JSON_ARRAY',
        schema: {
            column_count: 5,
            columns: [
                {
                    name: 'month',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 0,
                },
                {
                    name: 'project_type',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 1,
                },
                {
                    name: 'total_opp',
                    type_text: 'BIGINT',
                    type_name: 'LONG',
                    position: 2,
                },
                {
                    name: 'won_opp',
                    type_text: 'BIGINT',
                    type_name: 'LONG',
                    position: 3,
                },
                {
                    name: 'win_rate',
                    type_text: 'DOUBLE',
                    type_name: 'DOUBLE',
                    position: 4,
                },
            ],
        },
        total_chunk_count: 1,
        chunks: [
            {
                chunk_index: 0,
                row_offset: 0,
                row_count: 123,
            },
        ],
        total_row_count: 123,
        truncated: false,
    },
    result: {
        chunk_index: 0,
        row_offset: 0,
        row_count: 123,
        data_array: [
            ['2019-02', 'Single Home', '3', '2', '0.6666666666666666'],
            ['2019-09', 'Company', '2', '2', '1.0'],
            ['2019-09', 'Multi Family Home', '1', '0', '0.0'],
            ['2019-10', 'Single Home', '3', '1', '0.3333333333333333'],
            ['2019-10', 'Company', '2', '2', '1.0'],
            ['2019-11', 'Single Home', '6', '3', '0.5'],
            ['2019-11', 'Company', '2', '1', '0.5'],
            ['2019-11', 'Multi Family Home', '1', '0', '0.0'],
            ['2019-12', 'Company', '6', '3', '0.5'],
            ['2019-12', 'Multi Family Home', '1', '1', '1.0'],
            ['2019-12', 'Single Home', '5', '2', '0.4'],
            ['2020-01', 'Single Home', '9', '6', '0.6666666666666666'],
            ['2020-01', 'Multi Family Home', '2', '1', '0.5'],
            ['2020-02', 'Single Home', '2', '2', '1.0'],
            ['2020-06', 'Single Home', '3', '2', '0.6666666666666666'],
            ['2020-06', 'Company', '4', '1', '0.25'],
            ['2020-06', 'Multi Family Home', '1', '0', '0.0'],
            ['2020-07', 'Company', '13', '3', '0.23076923076923078'],
            ['2020-07', 'Single Home', '9', '4', '0.4444444444444444'],
            ['2020-07', 'Multi Family Home', '1', '0', '0.0'],
            ['2020-08', 'Single Home', '13', '7', '0.5384615384615384'],
            ['2020-08', 'Company', '4', '1', '0.25'],
            ['2020-08', 'Multi Family Home', '1', '1', '1.0'],
            ['2020-09', 'Single Home', '15', '6', '0.4'],
            ['2020-09', 'Multi Family Home', '1', '1', '1.0'],
            ['2020-09', 'Company', '6', '3', '0.5'],
            ['2020-10', 'Company', '7', '2', '0.2857142857142857'],
            ['2020-10', 'Single Home', '9', '6', '0.6666666666666666'],
            ['2020-10', 'Multi Family Home', '1', '0', '0.0'],
            ['2020-11', 'Single Home', '9', '4', '0.4444444444444444'],
            ['2020-11', 'Company', '5', '0', '0.0'],
            ['2020-11', 'Multi Family Home', '1', '0', '0.0'],
            ['2020-12', 'Company', '5', '3', '0.6'],
            ['2020-12', 'Single Home', '9', '7', '0.7777777777777778'],
            ['2021-01', 'Single Home', '8', '6', '0.75'],
            ['2021-01', 'Company', '6', '2', '0.3333333333333333'],
            ['2021-01', 'Multi Family Home', '1', '1', '1.0'],
            ['2021-02', 'Single Home', '16', '7', '0.4375'],
            ['2021-02', 'Company', '7', '4', '0.5714285714285714'],
            ['2021-03', 'Single Home', '12', '7', '0.5833333333333334'],
            ['2021-03', 'Company', '2', '1', '0.5'],
            ['2021-04', 'Single Home', '12', '2', '0.16666666666666666'],
            ['2021-04', 'Company', '7', '0', '0.0'],
            ['2021-04', 'Multi Family Home', '1', '0', '0.0'],
            ['2021-05', 'Single Home', '9', '4', '0.4444444444444444'],
            ['2021-05', 'Company', '6', '2', '0.3333333333333333'],
            ['2021-05', 'Multi Family Home', '1', '0', '0.0'],
            ['2021-06', 'Company', '2', '1', '0.5'],
            ['2021-06', 'Single Home', '6', '3', '0.5'],
            ['2021-07', 'Single Home', '7', '5', '0.7142857142857143'],
            ['2021-07', 'Multi Family Home', '1', '0', '0.0'],
            ['2021-07', 'Company', '5', '1', '0.2'],
            ['2021-08', 'Single Home', '6', '2', '0.3333333333333333'],
            ['2021-08', 'Company', '3', '1', '0.3333333333333333'],
            ['2021-09', 'Single Home', '14', '6', '0.42857142857142855'],
            ['2021-09', 'Company', '4', '2', '0.5'],
            ['2021-10', 'Single Home', '19', '9', '0.47368421052631576'],
            ['2021-10', 'Company', '7', '2', '0.2857142857142857'],
            ['2021-11', 'Single Home', '27', '9', '0.3333333333333333'],
            ['2021-11', 'Company', '6', '3', '0.5'],
            ['2021-12', 'Single Home', '27', '13', '0.48148148148148145'],
            ['2021-12', 'Company', '11', '2', '0.18181818181818182'],
            ['2022-01', 'Single Home', '30', '15', '0.5'],
            ['2022-01', 'Company', '3', '2', '0.6666666666666666'],
            ['2022-02', 'Company', '10', '5', '0.5'],
            ['2022-02', 'Single Home', '28', '12', '0.42857142857142855'],
            ['2022-02', 'Multi Family Home', '4', '1', '0.25'],
            ['2022-03', 'Single Home', '36', '13', '0.3611111111111111'],
            ['2022-03', 'Company', '12', '7', '0.5833333333333334'],
            ['2022-03', 'Multi Family Home', '1', '1', '1.0'],
            ['2022-04', 'Single Home', '56', '23', '0.4107142857142857'],
            ['2022-04', 'Company', '8', '1', '0.125'],
            ['2022-04', 'Multi Family Home', '3', '2', '0.6666666666666666'],
            ['2022-05', 'Single Home', '63', '27', '0.42857142857142855'],
            ['2022-05', 'Multi Family Home', '1', '1', '1.0'],
            ['2022-05', 'Company', '6', '1', '0.16666666666666666'],
            ['2022-06', 'Single Home', '73', '23', '0.3150684931506849'],
            ['2022-06', 'Company', '4', '1', '0.25'],
            ['2022-07', 'Single Home', '65', '28', '0.4307692307692308'],
            ['2022-07', 'Multi Family Home', '2', '0', '0.0'],
            ['2022-07', 'Company', '6', '2', '0.3333333333333333'],
            ['2022-08', 'Single Home', '48', '24', '0.5'],
            ['2022-08', 'Company', '2', '0', '0.0'],
            ['2022-08', 'Multi Family Home', '1', '0', '0.0'],
            ['2022-09', 'Multi Family Home', '3', '1', '0.3333333333333333'],
            ['2022-09', 'Company', '6', '2', '0.3333333333333333'],
            ['2022-09', 'Single Home', '60', '20', '0.3333333333333333'],
            ['2022-10', 'Single Home', '47', '19', '0.40425531914893614'],
            ['2022-10', 'Company', '7', '1', '0.14285714285714285'],
            ['2022-10', 'Multi Family Home', '1', '0', '0.0'],
            ['2022-11', 'Single Home', '28', '7', '0.25'],
            ['2022-11', 'Company', '2', '0', '0.0'],
            ['2022-12', 'Single Home', '30', '6', '0.2'],
            ['2022-12', 'Company', '12', '3', '0.25'],
            ['2022-12', 'Multi Family Home', '1', '1', '1.0'],
            ['2023-01', 'Single Home', '34', '11', '0.3235294117647059'],
            ['2023-01', 'Company', '5', '0', '0.0'],
            ['2023-01', 'Multi Family Home', '1', '0', '0.0'],
            ['2023-02', 'Single Home', '22', '7', '0.3181818181818182'],
            ['2023-02', 'Company', '5', '2', '0.4'],
            ['2023-03', 'Single Home', '17', '5', '0.29411764705882354'],
            ['2023-03', 'Company', '5', '3', '0.6'],
            ['2023-03', 'Multi Family Home', '1', '1', '1.0'],
            ['2023-04', 'Single Home', '21', '5', '0.23809523809523808'],
            ['2023-04', 'Company', '4', '1', '0.25'],
            ['2023-05', 'Single Home', '5', '3', '0.6'],
            ['2023-05', 'Multi Family Home', '1', '0', '0.0'],
            ['2023-05', 'Company', '2', '0', '0.0'],
            ['2023-06', 'Single Home', '17', '3', '0.17647058823529413'],
            ['2023-06', 'Company', '2', '0', '0.0'],
            ['2023-06', 'Multi Family Home', '1', '0', '0.0'],
            ['2023-07', 'Single Home', '11', '4', '0.36363636363636365'],
            ['2023-07', 'Company', '2', '2', '1.0'],
            ['2023-08', 'Single Home', '7', '4', '0.5714285714285714'],
            ['2023-09', 'Company', '3', '1', '0.3333333333333333'],
            ['2023-09', 'Single Home', '20', '2', '0.1'],
            ['2023-10', 'Single Home', '23', '2', '0.08695652173913043'],
            ['2023-10', 'Company', '1', '0', '0.0'],
            ['2023-10', 'Multi Family Home', '3', '1', '0.3333333333333333'],
            ['2023-11', 'Single Home', '24', '3', '0.125'],
            ['2023-11', 'Company', '4', '1', '0.25'],
            ['2023-12', 'Single Home', '12', '2', '0.16666666666666666'],
            ['2023-12', 'Company', '1', '0', '0.0'],
        ],
    },
};
