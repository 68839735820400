import React from 'react';
import { GraphLayout } from '../../layout/GraphLayout';
import { ContributorsMonthlyEvolutionOfOpportunityByContributor } from 'src/ui/atoms/graphs/contributors-monthly-evolution-of-opportunity-by-contributors';
import { ContributorsMonthlyEvolutionOfOrderByContributor } from 'src/ui/atoms/graphs/contributors-monthly-evolution-of-order-by-contributor';
import { ContributorsTotalOpportunityByContributor } from 'src/ui/atoms/graphs/contributors-total-opportunities-by-contributors';
import { ContributorsTotalOrdersByContributor } from 'src/ui/atoms/graphs/contributors-total-orders-by-contributor';
import { ContributorsQualifiedLeadRateByContributor } from 'src/ui/atoms/graphs/contributors-qualified-lead-rate-by-contributor';
import { ContributorsProjectWinRateByContributor } from 'src/ui/atoms/graphs/contributors-project-win-rate-by-contributor';
import { Grid, Stack } from '@mui/joy';

const sx = {
    height: '2000px',
    maxHeight: '500px',
    overflowY: 'scroll',
    alignItems: 'self-start',
    justifyContent: 'center',
    marginTop: '60px',
    display: 'flex',
};
export function ContributorsGraphs() {
    return (
        <Stack>
            <GraphLayout
                title="Business Contributors"
                graphs={[
                    <ContributorsMonthlyEvolutionOfOpportunityByContributor />,
                    <ContributorsTotalOpportunityByContributor />,
                    <ContributorsMonthlyEvolutionOfOrderByContributor />,
                    <ContributorsTotalOrdersByContributor />,
                    // <ContributorsMonthlyEvolutionOfFinalisedInstallationsByContributor />,
                    // <ContributorsTotalFinalisedInstallationsByContributor />,
                ]}
                biggerGraphs
            />
            <Grid sx={sx}>
                <ContributorsQualifiedLeadRateByContributor />,
            </Grid>

            <Grid sx={sx}>
                <ContributorsProjectWinRateByContributor />,
            </Grid>
        </Stack>
    );
}
