import { RefObject, useEffect } from 'react';

function useOutsideAlerter(ref: RefObject<HTMLElement>, func: Function) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: Event) {
            if (event.target && ref && ref.current && !ref.current.contains(event.target as Node)) {
                func();
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, func]);
}
export default useOutsideAlerter;
