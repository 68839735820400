import { Stack, Card, SvgIcon } from '@mui/joy';
import { theme } from '../../theme';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ChevronDownIcon } from 'src/ui/atoms/icons/ChevronDownIcon';
import SelectDefault from './SelectDefault';
import SelectDate from './SelectDate';
import useOutsideAlerter from 'src/utils/useOutsideAlerter';
import { FiltersContext } from 'src/ui/contexts/filters-context';
import { BackendContext } from 'src/ui/contexts/backend-context';
import { useLocation } from 'react-router-dom';
import { useAppAuth } from 'src/ui/contexts/auth-context';

interface SelectProps {
    type: 'countries' | 'types' | 'contributors' | 'dates';
}

export type ValuesResponse = { code: string; label: string }[];

export default function Select({ type }: SelectProps) {
    const [values, setValues] = useState<ValuesResponse>([]);
    const [open, setOpen] = useState<boolean>(false);
    const { filtersState } = useContext(FiltersContext)!;
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setOpen(false));
    const location = useLocation();
    const { getAccessTokenSilently } = useAppAuth();

    const page = -1 !== location.pathname.indexOf('/contributor') ? 'contributorPage' : 'overviewPage';
    const filtersOfThisPage = useMemo(() => {
        return page === 'contributorPage' ? filtersState[page] : filtersState[page][filtersState.tab];
    }, [filtersState, page]);

    const backendApi = useContext(BackendContext)!;

    useEffect(() => {
        const populate = async () => {
            const accessToken = await getAccessTokenSilently({ cacheMode: 'off' });
            switch (type) {
                case 'countries':
                    const countries = (await backendApi.getCountries(accessToken)).result.data_array.map((value) => {
                        return { label: value[0] as string, code: value[1] as string };
                    });
                    setValues(countries);
                    break;
                case 'types':
                    const types = (await backendApi.getTypes(accessToken)).result.data_array.map((value) => {
                        return { label: value[0] as string, code: value[1] as string };
                    });
                    setValues(types);
                    break;
                case 'contributors':
                    const contributors = (await backendApi.getContributors(accessToken)).result.data_array.map(
                        (value) => {
                            return { label: value[0] as string, code: value[1] as string };
                        },
                    );
                    setValues(contributors);
                    break;
            }
        };

        populate();
    }, [type, backendApi, getAccessTokenSilently]);

    const handleClick = useCallback(() => {
        setOpen(!open);
    }, [open]);

    let placeholder: string;
    switch (type) {
        case 'countries':
            placeholder = 'All countries';
            break;
        case 'types':
            placeholder = 'All project types';
            break;
        case 'contributors':
            placeholder = 'All Business contributor';
            break;
        case 'dates':
            placeholder = 'Date';
            break;
    }

    const getSelectText = () => {
        let selectText: string = placeholder;
        if (['countries', 'types', 'contributors'].includes(type)) {
            const valuesSelected = filtersOfThisPage[type];
            const numberOfSelectedItems = valuesSelected.length;

            if (numberOfSelectedItems === 0) {
                return selectText;
            } else if (numberOfSelectedItems === 1 && typeof valuesSelected[0] == 'string') {
                selectText = valuesSelected[0];
            } else {
                selectText = '';

                switch (type) {
                    case 'countries':
                        selectText = `${numberOfSelectedItems} countries`;
                        break;
                    case 'types':
                        selectText = `${numberOfSelectedItems} Project Types`;
                        break;
                    case 'contributors':
                        selectText = `${numberOfSelectedItems} Contributors`;
                        break;
                }
            }
        } else {
            const dateRadioSelected = filtersOfThisPage.datesRadio;
            switch (dateRadioSelected) {
                case 1:
                    selectText = 'Year to date';
                    break;
                case 2:
                    selectText = 'Last 6 months';
                    break;
                case 3:
                    selectText = 'Last year';
                    break;
                case 4:
                    selectText = 'Past 2 years';
                    break;
                case 5:
                    selectText = 'Custom';
                    break;
            }
        }

        const limit = 20;
        if (selectText.length <= limit) {
            return selectText;
        }

        return `${selectText.substring(0, 20)}...`;
    };

    const selectText = getSelectText();

    return (
        <Stack sx={{ position: 'relative', zIndex: 3 }} ref={wrapperRef}>
            <Stack
                direction="row"
                sx={{
                    cursor: 'pointer',
                    background: theme.palette.grey[100],
                    borderRadius: '8px',
                    padding: '8px 12px 8px 16px',
                    width: '210px',
                }}
                onClick={handleClick}
                justifyContent="space-between"
            >
                {selectText}
                <SvgIcon
                    sx={{
                        transition: 'all .2s ease-in',
                        transform: `rotate(${open ? 0 : '-180deg'})`,
                        marginLeft: '4px',
                    }}
                >
                    <ChevronDownIcon />
                </SvgIcon>
            </Stack>
            {open && (
                <Card
                    sx={{
                        position: 'absolute',
                        top: '50px',
                        zIndex: 3,
                        border: `1px solid ${theme.palette.grey[100]}`,
                        boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.06)',
                    }}
                >
                    {type === 'dates' ? <SelectDate withCustomDate /> : <SelectDefault values={values} type={type} />}
                </Card>
            )}
        </Stack>
    );
}
