export const block1kMock = {
    statement_id: '01ee9e7a-71b8-1405-b1b5-f7398ad81ae8',
    status: { state: 'SUCCEEDED' },
    manifest: {
        format: 'JSON_ARRAY',
        schema: {
            column_count: 4,
            columns: [
                { name: 'month', type_text: 'STRING', type_name: 'STRING', position: 0 },
                { name: 'total_opp', type_text: 'BIGINT', type_name: 'LONG', position: 1 },
                { name: 'won_opp', type_text: 'BIGINT', type_name: 'LONG', position: 2 },
                { name: 'win_rate', type_text: 'DOUBLE', type_name: 'DOUBLE', position: 3 },
            ],
        },
        total_chunk_count: 1,
        chunks: [{ chunk_index: 0, row_offset: 0, row_count: 50 }],
        total_row_count: 50,
        truncated: false,
    },
    result: {
        chunk_index: 0,
        row_offset: 0,
        row_count: 50,
        data_array: [
            ['2019-02', '2', '2', '1.0'],
            ['2019-09', '3', '2', '0.6666666666666666'],
            ['2019-10', '5', '3', '0.6'],
            ['2019-11', '9', '4', '0.4444444444444444'],
            ['2019-12', '12', '6', '0.5'],
            ['2020-01', '11', '7', '0.6363636363636364'],
            ['2020-02', '2', '2', '1.0'],
            ['2020-06', '8', '3', '0.375'],
            ['2020-07', '21', '7', '0.3333333333333333'],
            ['2020-08', '17', '9', '0.5294117647058824'],
            ['2020-09', '21', '10', '0.47619047619047616'],
            ['2020-10', '17', '8', '0.47058823529411764'],
            ['2020-11', '15', '4', '0.26666666666666666'],
            ['2020-12', '13', '10', '0.7692307692307693'],
            ['2021-01', '13', '9', '0.6923076923076923'],
            ['2021-02', '21', '11', '0.5238095238095238'],
            ['2021-03', '13', '8', '0.6153846153846154'],
            ['2021-04', '15', '2', '0.13333333333333333'],
            ['2021-05', '15', '6', '0.4'],
            ['2021-06', '8', '4', '0.5'],
            ['2021-07', '12', '6', '0.5'],
            ['2021-08', '9', '3', '0.3333333333333333'],
            ['2021-09', '17', '8', '0.47058823529411764'],
            ['2021-10', '25', '11', '0.44'],
            ['2021-11', '31', '12', '0.3870967741935484'],
            ['2021-12', '38', '15', '0.39473684210526316'],
            ['2022-01', '29', '17', '0.5862068965517241'],
            ['2022-02', '38', '18', '0.47368421052631576'],
            ['2022-03', '46', '21', '0.45652173913043476'],
            ['2022-04', '65', '26', '0.4'],
            ['2022-05', '66', '29', '0.4393939393939394'],
            ['2022-06', '68', '24', '0.35294117647058826'],
            ['2022-07', '71', '30', '0.4225352112676056'],
            ['2022-08', '48', '24', '0.5'],
            ['2022-09', '65', '23', '0.35384615384615387'],
            ['2022-10', '53', '20', '0.37735849056603776'],
            ['2022-11', '25', '7', '0.28'],
            ['2022-12', '41', '10', '0.24390243902439024'],
            ['2023-01', '32', '11', '0.34375'],
            ['2023-02', '24', '9', '0.375'],
            ['2023-03', '19', '9', '0.47368421052631576'],
            ['2023-04', '20', '6', '0.3'],
            ['2023-05', '7', '3', '0.42857142857142855'],
            ['2023-06', '13', '3', '0.23076923076923078'],
            ['2023-07', '11', '6', '0.5454545454545454'],
            ['2023-08', '6', '4', '0.6666666666666666'],
            ['2023-09', '12', '3', '0.25'],
            ['2023-10', '14', '3', '0.21428571428571427'],
            ['2023-11', '10', '5', '0.5'],
            ['2023-12', '2', '2', '1.0'],
        ],
    },
};
