import { Typography, Stack } from '@mui/joy';
import { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { theme } from '../../theme';
import RadioInput from '../RadioInput';
import { formatDate } from '../../../utils/formatDate';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { FiltersContext } from 'src/ui/contexts/filters-context';
import { removeMonthsToToday, removeYearsToToday } from 'src/utils/removeDatesToToday';
import { filtersActions } from 'src/core/filters/service';
import { useLocation } from 'react-router-dom';

interface ChoicesDatesInterface {
    label: string;
    value: number;
    date?: Date;
}

interface SelectDateProps {
    withCustomDate?: boolean;
    isExport?: boolean;
    setSelectedDates?: Function;
}

type ValuePiece = Date | null;

export type DateValue = ValuePiece | [ValuePiece, ValuePiece];

export default function SelectDate({ withCustomDate, isExport = false, setSelectedDates }: SelectDateProps) {
    const { dispatch, filtersState } = useContext(FiltersContext)!;
    const [selectedRadioExport, setSelectedRadioExport] = useState(1);
    const location = useLocation();
    const page = -1 !== location.pathname.indexOf('/contributor') ? 'contributorPage' : 'overviewPage';
    const filtersOfThisPage = useMemo(() => {
        return page === 'contributorPage' ? filtersState[page] : filtersState[page][filtersState.tab];
    }, [filtersState, page]);

    const [dateRange, setDateRange] = useState<DateValue>(
        isExport
            ? filtersState.exportDatesRadio === 5
                ? [filtersState.exportDates[0], filtersState.exportDates[1]]
                : [null, null]
            : filtersOfThisPage.datesRadio === 5
              ? [filtersOfThisPage.dates[0], filtersOfThisPage.dates[1]]
              : [null, null],
    );
    const [textDateRange, setTextDateRange] = useState<string>('');

    const choices: ChoicesDatesInterface[] = useMemo(() => {
        return [
            { label: 'Year to date', value: 1, date: new Date(removeYearsToToday(0), 0, 1) },
            { label: 'Last 6 months', value: 2, date: removeMonthsToToday(6) },
            { label: 'Last year', value: 3, date: removeMonthsToToday(12) },
            { label: 'Past 2 years', value: 4, date: new Date(removeYearsToToday(1), 0, 1) },
        ];
    }, []);

    if (!choices.find((e) => e.value === 5)) {
        choices.push(withCustomDate ? { label: 'With custom dates', value: 5 } : { label: 'All time', value: 5 });
    }

    const handleChange = useCallback(
        async (value: number) => {
            if (!isExport) {
                await dispatch(
                    filtersActions.setDatesRadio(value, page, page === 'contributorPage' ? null : filtersState.tab),
                );
            } else {
                setSelectedRadioExport(value);
            }

            if (value === 5) {
                if (Array.isArray(dateRange) && dateRange[0] && dateRange[1]) {
                    if (withCustomDate) {
                        await dispatch(
                            filtersActions.setDates(
                                [dateRange[0], dateRange[1]],
                                page,
                                page === 'contributorPage' ? null : filtersState.tab,
                            ),
                        );
                    }
                } else if (setSelectedDates) {
                    setSelectedDates([new Date('1900-01-01'), new Date()]);
                }
            } else {
                setDateRange([null, null]);
                const choice = choices.find((e) => e.value === value);
                if (!choice) {
                    return;
                }
                if (!isExport) {
                    await dispatch(
                        filtersActions.setDates(
                            [choice.date ?? new Date(removeYearsToToday(0), 0, 1), new Date()],
                            page,
                            page === 'contributorPage' ? null : filtersState.tab,
                        ),
                    );
                } else if (setSelectedDates) {
                    setSelectedDates([choice.date ?? new Date(removeYearsToToday(0), 0, 1), new Date()]);
                }
            }
        },
        [choices, dateRange, dispatch, isExport, filtersState.tab, page, setSelectedDates, withCustomDate],
    );

    useEffect(() => {
        setTextDateRange(
            Array.isArray(dateRange) && dateRange[0] && dateRange[1]
                ? formatDate(new Date(dateRange[0]!)) + ' - ' + formatDate(new Date(dateRange[1]!))
                : '',
        );

        if (Array.isArray(dateRange) && dateRange[0] && dateRange[1]) {
            dispatch(
                isExport
                    ? filtersActions.setExportDates([dateRange[0], dateRange[1]])
                    : filtersActions.setDates(
                          [dateRange[0], dateRange[1]],
                          page,
                          page === 'contributorPage' ? null : filtersState.tab,
                      ),
            );
        }
    }, [dateRange, filtersState.tab, isExport, dispatch, page]);

    return (
        <Stack>
            <Stack sx={{ marginBottom: '8px', width: '331px' }}>
                {choices.map((choice) => {
                    let textDate;
                    if (choice.value !== 5) {
                        textDate = formatDate(choice.date!) + ' - ' + formatDate(new Date());
                    } else {
                        textDate = textDateRange;
                    }
                    return (
                        <RadioInput
                            key={choice.value}
                            label={
                                <>
                                    <Typography>{choice.label}</Typography>{' '}
                                    {(choice.value !== 5 || withCustomDate) && (
                                        <Typography sx={{ color: theme.palette.grey[500] }}>{textDate}</Typography>
                                    )}
                                </>
                            }
                            value={choice.value}
                            handleChange={() => handleChange(choice.value)}
                            selectedValue={isExport ? selectedRadioExport : filtersOfThisPage.datesRadio}
                        />
                    );
                })}
                {(isExport ? selectedRadioExport : filtersOfThisPage.datesRadio) === 5 && withCustomDate && (
                    <Calendar onChange={setDateRange} value={dateRange} allowPartialRange={true} selectRange />
                )}
            </Stack>
        </Stack>
    );
}
