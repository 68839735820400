export const removeMonthsToToday = (months: number) => {
    const dateToChange = new Date();
    dateToChange.setMonth(dateToChange.getMonth() - months);
    return dateToChange;
};

export const removeYearsToToday = (years: number) => {
    const year = new Date().getFullYear();
    return year - years;
};
