import React, { PropsWithChildren } from 'react';
import { Stack } from '@mui/joy';
import useScrollToTop from '../../utils/useScrollToTop';
import { theme } from '../theme';
import { SideBar } from './SideBar';
import { Header } from './Header';

interface PageTemplateProps extends PropsWithChildren {
    greyBackground?: boolean;
    pageTitle?: React.ReactNode;
}

export function PageTemplate({ pageTitle, greyBackground = false, ...props }: PageTemplateProps) {
    const sx = greyBackground ? { backgroundColor: theme.palette.grey[100] } : {};
    useScrollToTop();

    return (
        <Stack direction="row">
            <SideBar />
            <Stack justifyContent="space-between" sx={sx} width="100%">
                <Header pageTitle={pageTitle} />
                {props.children}
            </Stack>
        </Stack>
    );
}
