import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Dropdown, Menu, MenuButton, MenuItem, Stack, Typography } from '@mui/joy';
import { theme } from '../theme';
import ProfileIcon from '../atoms/icons/ProfileIcon';

interface HeaderProps {
    pageTitle?: React.ReactNode;
}

export function Header({ pageTitle = 'Overview' }: HeaderProps) {
    const { logout } = useAuth0();

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    };

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            sx={{ position: 'sticky', top: '0', background: theme.palette.neutral[50] }}
            height="82px"
            padding="20px 40px"
            borderBottom={`1px solid ${theme.palette.grey[100]}`}
            zIndex={4}
        >
            <Typography sx={{ fontSize: '18px' }} level="body-lg">
                {pageTitle}
            </Typography>
            <Dropdown>
                <MenuButton
                    sx={{
                        background: theme.palette.secondary[100],
                        borderRadius: '100%',
                        padding: 0,
                        border: 'none',
                        height: '40px',
                        width: '40px',
                    }}
                >
                    <ProfileIcon />
                </MenuButton>
                <Menu sx={{ border: `1px solid ${theme.palette.secondary[100]}` }}>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
            </Dropdown>
        </Stack>
    );
}
