import React from 'react';
import { Grid, Typography } from '@mui/joy';

export function GraphLayout(props: { title: string; graphs: Array<JSX.Element>; biggerGraphs?: boolean }) {
    return (
        <Grid container spacing={5} sx={{ flexGrow: 1 }}>
            {props.graphs.map((graph, idx) => (
                <Grid
                    key={idx}
                    xs={6}
                    sx={{
                        height: props.biggerGraphs ? '2000px' : '500px',
                        maxHeight: '500px',
                        overflowY: props.biggerGraphs ? 'scroll' : 'auto',
                        alignItems: props.biggerGraphs ? 'self-start' : 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        // marginTop: props.biggerGraphs ? '50px' : '0',
                        marginTop: '50px',
                    }}
                >
                    {graph}
                </Grid>
            ))}
        </Grid>
    );
}
