export const block2iMock = {
    statement_id: '01ee99e1-75c5-1d74-b333-32e83aa718d9',
    status: {
        state: 'SUCCEEDED',
    },
    manifest: {
        format: 'JSON_ARRAY',
        schema: {
            column_count: 5,
            columns: [
                {
                    name: 'month',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 0,
                },
                {
                    name: 'channel',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 1,
                },
                {
                    name: 'total_leads',
                    type_text: 'BIGINT',
                    type_name: 'LONG',
                    position: 2,
                },
                {
                    name: 'qualified_leads',
                    type_text: 'BIGINT',
                    type_name: 'LONG',
                    position: 3,
                },
                {
                    name: 'percentage_qualified',
                    type_text: 'DOUBLE',
                    type_name: 'DOUBLE',
                    position: 4,
                },
            ],
        },
        total_chunk_count: 1,
        chunks: [
            {
                chunk_index: 0,
                row_offset: 0,
                row_count: 112,
            },
        ],
        total_row_count: 112,
        truncated: false,
    },
    result: {
        chunk_index: 0,
        row_offset: 0,
        row_count: 112,
        data_array: [
            ['2019-12', 'Typeform Volvo', '1', '0', '0.0'],
            ['2020-02', 'Typeform Volvo', '1', '0', '0.0'],
            ['2020-06', 'Typeform Volvo', '1', '0', '0.0'],
            ['2020-07', 'Typeform Volvo', '1', '1', '1.0'],
            ['2020-08', 'Typeform Volvo', '1', '0', '0.0'],
            ['2020-09', 'Typeform Volvo', '2', '0', '0.0'],
            ['2020-10', 'Typeform Volvo', '1', '0', '0.0'],
            ['2020-12', 'Typeform Volvo', '2', '1', '0.5'],
            ['2021-01', 'Typeform Volvo', '3', '2', '0.6666666666666666'],
            ['2021-02', 'Typeform Volvo', '1', '0', '0.0'],
            ['2021-04', 'Typeform Volvo', '1', '0', '0.0'],
            ['2021-06', 'Typeform Volvo', '2', '0', '0.0'],
            ['2021-08', 'Typeform Volvo', '1', '0', '0.0'],
            ['2021-09', 'Typeform Volvo', '4', '1', '0.25'],
            ['2021-10', 'Phone', '4', '0', '0.0'],
            ['2021-10', 'Typeform Volvo', '17', '1', '0.058823529411764705'],
            ['2021-10', 'Email', '1', '0', '0.0'],
            ['2021-11', 'Typeform Volvo', '40', '13', '0.325'],
            ['2021-11', 'Phone', '2', '0', '0.0'],
            ['2021-11', 'Form Volvo Employee FR', '6', '6', '1.0'],
            ['2021-12', 'Typeform Volvo', '68', '38', '0.5588235294117647'],
            ['2021-12', 'Phone', '3', '1', '0.3333333333333333'],
            ['2021-12', 'Other', '1', '1', '1.0'],
            ['2021-12', 'Email', '1', '0', '0.0'],
            ['2022-01', 'Typeform Volvo', '50', '28', '0.56'],
            ['2022-01', 'Email', '1', '1', '1.0'],
            ['2022-01', 'Volvo dealership Form', '14', '8', '0.5714285714285714'],
            ['2022-01', 'Phone', '2', '2', '1.0'],
            ['2022-01', 'Form Volvo Employee FR', '2', '2', '1.0'],
            ['2022-02', 'Typeform Volvo', '50', '22', '0.44'],
            ['2022-02', 'Volvo dealership Form', '32', '14', '0.4375'],
            ['2022-02', 'Phone', '1', '1', '1.0'],
            ['2022-02', 'Direct', '1', '0', '0.0'],
            ['2022-03', 'Volvo dealership Form', '90', '35', '0.3888888888888889'],
            ['2022-03', 'Typeform Volvo', '84', '33', '0.39285714285714285'],
            ['2022-03', 'Direct', '3', '1', '0.3333333333333333'],
            ['2022-03', 'Zeplug', '2', '1', '0.5'],
            ['2022-03', 'Phone', '3', '1', '0.3333333333333333'],
            ['2022-04', 'Volvo dealership Form', '82', '40', '0.4878048780487805'],
            ['2022-04', 'Typeform Volvo', '69', '35', '0.5072463768115942'],
            ['2022-04', 'Website', '1', '0', '0.0'],
            ['2022-04', 'Email', '4', '3', '0.75'],
            ['2022-05', 'Typeform Volvo', '65', '40', '0.6153846153846154'],
            ['2022-05', 'Volvo dealership Form', '89', '27', '0.30337078651685395'],
            ['2022-05', 'Direct', '1', '1', '1.0'],
            ['2022-06', 'Volvo dealership Form', '128', '35', '0.2734375'],
            ['2022-06', 'Typeform Volvo', '60', '37', '0.6166666666666667'],
            ['2022-06', 'Phone', '5', '4', '0.8'],
            ['2022-06', 'Direct', '1', '0', '0.0'],
            ['2022-07', 'Typeform Volvo', '54', '35', '0.6481481481481481'],
            ['2022-07', 'Volvo dealership Form', '54', '26', '0.48148148148148145'],
            ['2022-07', 'Email', '1', '1', '1.0'],
            ['2022-07', 'Phone', '3', '2', '0.6666666666666666'],
            ['2022-07', 'Direct', '1', '1', '1.0'],
            ['2022-07', 'Zeplug', '1', '0', '0.0'],
            ['2022-08', 'Volvo dealership Form', '37', '21', '0.5675675675675675'],
            ['2022-08', 'Typeform Volvo', '36', '19', '0.5277777777777778'],
            ['2022-08', 'Phone', '2', '1', '0.5'],
            ['2022-08', 'Zeplug', '1', '1', '1.0'],
            ['2022-09', 'Volvo dealership Form', '75', '42', '0.56'],
            ['2022-09', 'Typeform Volvo', '40', '14', '0.35'],
            ['2022-09', 'Email', '3', '3', '1.0'],
            ['2022-10', 'Volvo dealership Form', '63', '28', '0.4444444444444444'],
            ['2022-10', 'Typeform Volvo', '40', '12', '0.3'],
            ['2022-10', 'Direct', '2', '1', '0.5'],
            ['2022-10', 'Email', '2', '0', '0.0'],
            ['2022-10', 'Phone', '1', '0', '0.0'],
            ['2022-11', 'Typeform Volvo', '42', '13', '0.30952380952380953'],
            ['2022-11', 'Volvo dealership Form', '43', '18', '0.4186046511627907'],
            ['2022-11', 'Email', '1', '1', '1.0'],
            ['2022-12', 'Typeform Volvo', '35', '19', '0.5428571428571428'],
            ['2022-12', 'Volvo dealership Form', '43', '22', '0.5116279069767442'],
            ['2022-12', 'Email', '2', '2', '1.0'],
            ['2023-01', 'Typeform Volvo', '37', '16', '0.43243243243243246'],
            ['2023-01', 'Volvo dealership Form', '33', '10', '0.30303030303030304'],
            ['2023-01', 'Direct', '1', '1', '1.0'],
            ['2023-01', 'Volkswagen Form', '1', '1', '1.0'],
            ['2023-02', 'Volvo dealership Form', '23', '11', '0.4782608695652174'],
            ['2023-02', 'Typeform Volvo', '21', '7', '0.3333333333333333'],
            ['2023-02', 'Zeplug', '1', '1', '1.0'],
            ['2023-03', 'Volvo dealership Form', '33', '20', '0.6060606060606061'],
            ['2023-03', 'Typeform Volvo', '26', '6', '0.23076923076923078'],
            ['2023-03', 'Email', '1', '1', '1.0'],
            ['2023-03', 'Direct', '1', '0', '0.0'],
            ['2023-04', 'Direct', '1', '0', '0.0'],
            ['2023-04', 'Typeform Volvo', '25', '9', '0.36'],
            ['2023-04', 'Volvo dealership Form', '21', '7', '0.3333333333333333'],
            ['2023-04', 'Email', '1', '1', '1.0'],
            ['2023-04', 'Phone', '1', '1', '1.0'],
            ['2023-04', 'Zeplug', '1', '1', '1.0'],
            ['2023-05', 'Volvo dealership Form', '14', '1', '0.07142857142857142'],
            ['2023-05', 'Zeplug', '1', '1', '1.0'],
            ['2023-05', 'Typeform Volvo', '2', '2', '1.0'],
            ['2023-05', 'Phone', '1', '0', '0.0'],
            ['2023-05', 'Email', '1', '1', '1.0'],
            ['2023-06', 'Volvo dealership Form', '27', '9', '0.3333333333333333'],
            ['2023-06', 'Typeform Volvo', '8', '6', '0.75'],
            ['2023-06', 'Email', '1', '1', '1.0'],
            ['2023-07', 'Volvo dealership Form', '18', '10', '0.5555555555555556'],
            ['2023-07', 'Typeform Volvo', '2', '2', '1.0'],
            ['2023-07', 'Email', '3', '0', '0.0'],
            ['2023-08', 'Volvo dealership Form', '13', '4', '0.3076923076923077'],
            ['2023-08', 'Typeform Volvo', '8', '6', '0.75'],
            ['2023-09', 'Volvo dealership Form', '32', '12', '0.375'],
            ['2023-09', 'Typeform Volvo', '10', '8', '0.8'],
            ['2023-09', 'Email', '1', '1', '1.0'],
            ['2023-10', 'Typeform Volvo', '8', '7', '0.875'],
            ['2023-10', 'Volvo dealership Form', '34', '17', '0.5'],
            ['2023-11', 'Volvo dealership Form', '23', '14', '0.6086956521739131'],
            ['2023-11', 'Typeform Volvo', '5', '5', '1.0'],
            ['2023-12', 'Volvo dealership Form', '1', '1', '1.0'],
            ['2023-12', 'Typeform Volvo', '1', '1', '1.0'],
        ],
    },
};
