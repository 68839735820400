export const block3lMock = {
    statement_id: '01ee9b2e-fd69-13cf-a14b-0a5dfed31fe5',
    status: {
        state: 'SUCCEEDED',
    },
    manifest: {
        format: 'JSON_ARRAY',
        schema: {
            column_count: 4,
            columns: [
                {
                    name: 'project_type',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 0,
                },
                {
                    name: 'total_opp',
                    type_text: 'BIGINT',
                    type_name: 'LONG',
                    position: 1,
                },
                {
                    name: 'won_opp',
                    type_text: 'BIGINT',
                    type_name: 'LONG',
                    position: 2,
                },
                {
                    name: 'win_rate',
                    type_text: 'DOUBLE',
                    type_name: 'DOUBLE',
                    position: 3,
                },
            ],
        },
        total_chunk_count: 1,
        chunks: [
            {
                chunk_index: 0,
                row_offset: 0,
                row_count: 3,
            },
        ],
        total_row_count: 3,
        truncated: false,
    },
    result: {
        chunk_index: 0,
        row_offset: 0,
        row_count: 3,
        data_array: [
            ['Company', '206', '77', '0.3737864077669903'],
            ['Multi Family Home', '34', '14', '0.4117647058823529'],
            ['Single Home', '916', '393', '0.42903930131004364'],
        ],
    },
};
