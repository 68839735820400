import { Box, Checkbox as JoyCheckbox, Typography, checkboxClasses } from '@mui/joy';
import { useCallback, useState } from 'react';
import CheckedIcon from '../../atoms/icons/CheckIcon';
import { theme } from '../../theme';

interface InputProps {
    label: string;
    disabled?: boolean;
    onChange: Function;
    initiallyChecked?: boolean;
}

export default function Checkbox({ label, onChange, initiallyChecked, ...props }: InputProps) {
    const [checked, setChecked] = useState<boolean>(!!initiallyChecked);

    const handleChange = useCallback(() => {
        setChecked(!checked);
        onChange();
    }, [checked, onChange]);

    return (
        <Box minHeight="18px">
            <JoyCheckbox
                checked={checked}
                checkedIcon={<CheckedIcon />}
                label={<Typography level={'body-md'}>{label}</Typography>}
                onChange={handleChange}
                sx={{
                    minHeight: '18px',
                    '--Checkbox-size': '16px',
                    maxWidth: 'calc(100% - 5px)',
                    [`& .${checkboxClasses.checkbox}`]: {
                        borderColor: theme.palette.grey[200],
                    },
                }}
                {...props}
            />
        </Box>
    );
}
