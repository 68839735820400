export const block4jMock = {
    statement_id: '01ee9b2f-067a-1e90-bdcb-1a1f51764975',
    status: {
        state: 'SUCCEEDED',
    },
    manifest: {
        format: 'JSON_ARRAY',
        schema: {
            column_count: 4,
            columns: [
                {
                    name: 'country_name',
                    type_text: 'STRING',
                    type_name: 'STRING',
                    position: 0,
                },
                {
                    name: 'total_leads',
                    type_text: 'BIGINT',
                    type_name: 'LONG',
                    position: 1,
                },
                {
                    name: 'qualified_leads',
                    type_text: 'BIGINT',
                    type_name: 'LONG',
                    position: 2,
                },
                {
                    name: 'percentage_qualified',
                    type_text: 'DOUBLE',
                    type_name: 'DOUBLE',
                    position: 3,
                },
            ],
        },
        total_chunk_count: 1,
        chunks: [
            {
                chunk_index: 0,
                row_offset: 0,
                row_count: 2,
            },
        ],
        total_row_count: 2,
        truncated: false,
    },
    result: {
        chunk_index: 0,
        row_offset: 0,
        row_count: 2,
        data_array: [
            ['Belgium', '3', '0', '0.0'],
            ['France', '2033', '926', '0.45548450565666504'],
        ],
    },
};
