import { removeYearsToToday } from 'src/utils/removeDatesToToday';
import { ActionExtractor, createAction } from '../action';

// Actions
export const filtersActions = {
    setCountries: (
        countries: Array<string>,
        page: 'contributorPage' | 'overviewPage',
        tab?: 'status' | 'channel' | 'project_type' | 'country_name' | null,
    ) => createAction('setCountries', { countries, page, tab }),
    setTypes: (
        types: Array<string>,
        page: 'contributorPage' | 'overviewPage',
        tab?: 'status' | 'channel' | 'project_type' | 'country_name' | null,
    ) => createAction('setTypes', { types, page, tab }),
    setContributors: (
        contributors: Array<string>,
        page: 'contributorPage' | 'overviewPage',
        tab?: 'status' | 'channel' | 'project_type' | 'country_name' | null,
    ) => createAction('setContributors', { contributors, page, tab }),
    setDates: (
        dates: Array<Date>,
        page: 'contributorPage' | 'overviewPage',
        tab?: 'status' | 'channel' | 'project_type' | 'country_name' | null,
    ) => createAction('setDates', { dates, page, tab }),
    setDatesRadio: (
        datesRadio: number,
        page: 'contributorPage' | 'overviewPage',
        tab?: 'status' | 'channel' | 'project_type' | 'country_name' | null,
    ) => createAction('setDatesRadio', { datesRadio, page, tab }),
    setExportDates: (exportDates: Array<Date>) => createAction('setExportDates', { exportDates }),
    setExportDatesRadio: (exportDatesRadio: number) => createAction('setExportDatesRadio', { exportDatesRadio }),
    setTab: (tab: 'status' | 'channel' | 'project_type' | 'country_name') => createAction('setTab', { tab }),
    setCurrentPage: (page: string) => createAction('setCurrentPage', { page }),
};

export type FiltersAction = ActionExtractor<typeof filtersActions>;

// State
export type FiltersState = {
    overviewPage: FiltersByTab;
    contributorPage: GroupOfFilters;
    exportDates: Array<Date>;
    exportDatesRadio: number;
    tab: 'status' | 'channel' | 'project_type' | 'country_name';
    currentPage: string;
    error: unknown;
};

export type FiltersByTab = {
    status: GroupOfFilters;
    channel: GroupOfFilters;
    project_type: GroupOfFilters;
    country_name: GroupOfFilters;
};

export type GroupOfFilters = {
    countries: Array<string>;
    types: Array<string>;
    contributors: Array<string>;
    dates: Array<Date>;
    datesRadio: number;
};

export const filtersInitialState: FiltersState = {
    overviewPage: {
        status: {
            countries: [],
            types: [],
            contributors: [],
            dates: [new Date(removeYearsToToday(0), 0, 1), new Date()],
            datesRadio: 1,
        },
        channel: {
            countries: [],
            types: [],
            contributors: [],
            dates: [new Date(removeYearsToToday(0), 0, 1), new Date()],
            datesRadio: 1,
        },
        project_type: {
            countries: [],
            types: [],
            contributors: [],
            dates: [new Date(removeYearsToToday(0), 0, 1), new Date()],
            datesRadio: 1,
        },
        country_name: {
            countries: [],
            types: [],
            contributors: [],
            dates: [new Date(removeYearsToToday(0), 0, 1), new Date()],
            datesRadio: 1,
        },
    },
    contributorPage: {
        countries: [],
        types: [],
        contributors: [],
        dates: [new Date(removeYearsToToday(0), 0, 1), new Date()],
        datesRadio: 1,
    },
    exportDates: [new Date(removeYearsToToday(0), 0, 1), new Date()],
    exportDatesRadio: 1,
    tab: 'status',
    currentPage: 'status',
    error: undefined,
};

// Reducer
export type FiltersReducer = (state: FiltersState, action: FiltersAction) => Promise<FiltersState>;
export const filtersReducer =
    () =>
    async (state: FiltersState, action: FiltersAction): Promise<FiltersState> => {
        switch (action.type) {
            case 'setCountries': {
                const newState = state;
                if (action.payload.tab && action.payload.page === 'overviewPage') {
                    newState[action.payload.page][action.payload.tab].countries = action.payload.countries;
                } else if (action.payload.page === 'contributorPage') {
                    newState[action.payload.page].countries = action.payload.countries;
                }
                return newState;
            }
            case 'setTypes': {
                const newState = state;
                if (action.payload.tab && action.payload.page === 'overviewPage') {
                    newState[action.payload.page][action.payload.tab].types = action.payload.types;
                } else if (action.payload.page === 'contributorPage') {
                    newState[action.payload.page].types = action.payload.types;
                }
                return newState;
            }
            case 'setContributors': {
                const newState = state;
                if (action.payload.tab && action.payload.page === 'overviewPage') {
                    newState[action.payload.page][action.payload.tab].contributors = action.payload.contributors;
                } else if (action.payload.page === 'contributorPage') {
                    newState[action.payload.page].contributors = action.payload.contributors;
                }
                return newState;
            }
            case 'setDatesRadio': {
                const newState = state;
                if (action.payload.tab && action.payload.page === 'overviewPage') {
                    newState[action.payload.page][action.payload.tab].datesRadio = action.payload.datesRadio;
                } else if (action.payload.page === 'contributorPage') {
                    newState[action.payload.page].datesRadio = action.payload.datesRadio;
                }
                return newState;
            }
            case 'setDates': {
                const newState = state;
                if (action.payload.tab && action.payload.page === 'overviewPage') {
                    newState[action.payload.page][action.payload.tab].dates = action.payload.dates;
                } else if (action.payload.page === 'contributorPage') {
                    newState[action.payload.page].dates = action.payload.dates;
                }
                return newState;
            }
            case 'setExportDatesRadio': {
                return {
                    ...state,
                    exportDatesRadio: action.payload.exportDatesRadio,
                };
            }
            case 'setExportDates': {
                return {
                    ...state,
                    exportDates: action.payload.exportDates,
                };
            }
            case 'setTab': {
                return {
                    ...state,
                    tab: action.payload.tab,
                };
            }
            case 'setCurrentPage': {
                return {
                    ...state,
                    currentPage: action.payload.page,
                };
            }
        }
    };
