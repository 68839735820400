import { BackendResponse } from 'src/providers/data-source-backend';

export const sortByMonth = (a: string, b: string): number => {
    const orderMonth = [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december',
    ];
    const findIdxYear = (entry: string) => parseInt(entry.split(' ')[1] ?? 0);
    const findIdxMonth = (entry: string) => orderMonth.findIndex((val) => (entry ?? '').toLowerCase().includes(val));
    const aIdxYear = findIdxYear(a);
    const bIdxYear = findIdxYear(b);
    if (aIdxYear !== bIdxYear) {
        return aIdxYear - bIdxYear;
    }
    const aIdx = findIdxMonth(a);
    const bIdx = findIdxMonth(b);
    return aIdx - bIdx;
};

export const positionOf = (data: BackendResponse, colName: string): number =>
    data.manifest.schema.columns.find((col) => col.name === colName)?.position ?? -1;

export const allValuesDeduplicatedOf = (
    data: BackendResponse,
    colName: string,
    sortAxis?: (a: string, b: string) => number,
) => {
    const position = positionOf(data, colName);
    const allValues = data.result.data_array.map((row) => row[position]!).filter((val) => val !== null);
    const deduplicated = Array.from(new Set(allValues).values());
    return deduplicated.sort(sortAxis);
};

export const countValue = (data: BackendResponse, colName: string, colValue: string): number => {
    const countPosition = positionOf(data, colName);
    return data.result.data_array.filter((row) => row[countPosition] === colValue).length;
};

export const labelWithCount = (data: BackendResponse, colName: string) => (colValue: string) =>
    `${colValue} (${countValue(data, colName, colValue)})`;

export const extractCountValue = (
    data: BackendResponse,
    colName: string,
    colValue: string,
    colNameForValue: string,
): number => {
    const countPosition = positionOf(data, colName);
    const valuePosition = positionOf(data, colNameForValue);
    return parseInt(
        (data.result.data_array.find((row) => row[countPosition] === colValue) ?? [])[valuePosition] ?? '0',
    );
};

export const labelWithExtractCount =
    (data: BackendResponse, colName: string, colNameForValue: string) => (colValue: string) =>
        `${colValue} (${extractCountValue(data, colName, colValue, colNameForValue)})`;

export const equals = (data: BackendResponse, colName: string, value: string) => (row: (string | null)[]) => {
    const position = positionOf(data, colName);
    return row[position] === value;
};

export const notEquals = (data: BackendResponse, colName: string, value: string) => (row: (string | null)[]) => {
    const position = positionOf(data, colName);
    return row[position] !== value;
};

// Aggregations

export const count = (data: (string | null)[][]): number => data.length;

export const sum =
    (data: BackendResponse, colName: string) =>
    (filteredData: (string | null)[][]): number => {
        const position = positionOf(data, colName);
        const allValues = filteredData.map((row) => row[position]!).filter((val) => val !== null && val !== undefined);
        return allValues.reduce((acc, val) => acc + parseFloat(val), 0);
    };

export const avg =
    (data: BackendResponse, colName: string) =>
    (filteredData: (string | null)[][]): number => {
        const sumValue = sum(data, colName)(filteredData);
        const count = filteredData.filter((val) => val !== null && val !== undefined).length;
        return count > 0 ? sumValue / count : 0;
    };

export const avgAndPercent =
    (data: BackendResponse, colName: string) =>
    (filteredData: (string | null)[][]): number => {
        const sumValue = sum(data, colName)(filteredData);
        const count = filteredData.filter((val) => val !== null && val !== undefined).length;
        return (count > 0 ? sumValue / count : 0) * 100;
    };

export const sumAndPercent = (data: BackendResponse, colName: string) => {
    const sumInst = sum(data, colName);
    return (filteredData: (string | null)[][]): number => sumInst(filteredData) * 100;
};
