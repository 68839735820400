import { Stack, Box, Input, Button } from '@mui/joy';
import Checkbox from './Checkbox';
import { ChangeEvent, useCallback, useContext, useMemo, useState } from 'react';
import { filtersActions } from 'src/core/filters/service';
import { FiltersContext } from 'src/ui/contexts/filters-context';
import { useLocation } from 'react-router-dom';
import { ValuesResponse } from './Select';

interface SelectDefaultProps {
    values: ValuesResponse;
    type: 'countries' | 'types' | 'contributors';
}

export default function SelectDefault({ values, type }: SelectDefaultProps) {
    const { dispatch, filtersState } = useContext(FiltersContext)!;
    const [filteredValues, setFilteredValues] = useState(values);
    const location = useLocation();
    const [clearAllToggle, setClearAllToggle] = useState(false);

    const page = -1 !== location.pathname.indexOf('/contributor') ? 'contributorPage' : 'overviewPage';
    const filtersOfThisPageByType = useMemo(() => {
        return page === 'contributorPage' ? filtersState[page][type] : filtersState[page][filtersState.tab][type];
    }, [filtersState, page, type]);

    const handleChange = useCallback(
        async (value: string) => {
            const newValues =
                page === 'contributorPage' ? filtersState[page][type] : filtersState[page][filtersState.tab][type];
            const valueIndex = newValues.findIndex((e) => e === value);
            if (-1 !== valueIndex) {
                newValues.splice(valueIndex, 1);
            } else {
                newValues.push(value);
            }

            switch (type) {
                case 'countries':
                    await dispatch(
                        filtersActions.setCountries(
                            newValues,
                            page,
                            page === 'contributorPage' ? null : filtersState.tab,
                        ),
                    );
                    break;
                case 'types':
                    await dispatch(
                        filtersActions.setTypes(newValues, page, page === 'contributorPage' ? null : filtersState.tab),
                    );
                    break;
                case 'contributors':
                    await dispatch(
                        filtersActions.setContributors(
                            newValues,
                            page,
                            page === 'contributorPage' ? null : filtersState.tab,
                        ),
                    );
                    break;
            }
        },
        [dispatch, filtersState, type, page],
    );

    const handleSearch = useCallback(
        (event: ChangeEvent) => {
            const target = event.target as HTMLInputElement;
            const newValues = values.filter((value) => {
                return -1 !== value.label.toLowerCase().indexOf(target.value.toLowerCase().trim());
            });
            setFilteredValues(newValues);
        },
        [values],
    );

    const handleClearAll = useCallback(async () => {
        await setClearAllToggle(true);
        switch (type) {
            case 'countries':
                await dispatch(filtersActions.setCountries([], page, filtersState.tab));
                setClearAllToggle(false);
                break;
            case 'types':
                await dispatch(filtersActions.setTypes([], page, filtersState.tab));
                setClearAllToggle(false);
                break;
            case 'contributors':
                await dispatch(filtersActions.setContributors([], page, filtersState.tab));
                setClearAllToggle(false);
                break;
        }
    }, [page, filtersState.tab, dispatch, type]);

    return (
        <Stack>
            <Input
                color="grey"
                variant="soft"
                sx={{ marginBottom: '15px', borderRadius: '30px' }}
                placeholder="Search"
                onChange={handleSearch}
            />
            <Stack sx={{ maxHeight: '250px', overflowY: 'scroll' }}>
                {filteredValues.map((value) => (
                    <Box sx={{ marginBottom: '16px' }} key={value.label}>
                        <Checkbox
                            key={clearAllToggle + value.label}
                            initiallyChecked={filtersOfThisPageByType.includes(value.label)}
                            label={value.label}
                            onChange={() => handleChange(value.label)}
                        />
                    </Box>
                ))}
            </Stack>
            <Button
                disabled={filtersOfThisPageByType.length === 0}
                variant="solid"
                size="sm"
                onClick={handleClearAll}
                sx={{ marginTop: '15px' }}
                loading={clearAllToggle}
            >
                Clear all
            </Button>
        </Stack>
    );
}
