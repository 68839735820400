import React, { useState } from 'react';
import { Stack, Typography } from '@mui/joy';
import { theme } from '../theme';
import ChargeGuruLogoWhite from '../atoms/icons/ChargeGuruLogoWhite';
import contributor from '../atoms/icons/contributor_white.png';
import contributorSelected from '../atoms/icons/contributor_green.png';
import exportData from '../atoms/icons/export_note_white.png';
import overview from '../atoms/icons/overview_white.png';
import overviewSelected from '../atoms/icons/overview_green.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExportModal } from '../molecules/ExportModal';

export function SideBar() {
    const location = useLocation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    return (
        <Stack
            height="100vh"
            width="110px"
            sx={{ background: theme.palette.secondary[500] }}
            padding="30px 15px"
            position="sticky"
            left="0"
            top="0"
            justifyContent="space-between"
            zIndex={2}
        >
            <Stack>
                <ChargeGuruLogoWhite />
                <Stack
                    sx={{ marginTop: '40px', cursor: 'pointer' }}
                    onClick={() => {
                        navigate('/overview');
                    }}
                    alignItems="center"
                >
                    {-1 === location.pathname.indexOf('/overview') ? (
                        <img src={overview} alt="overview-menu" style={{ width: '32px', height: '32px' }} />
                    ) : (
                        <img src={overviewSelected} alt="overview-menu" style={{ width: '32px', height: '32px' }} />
                    )}
                    <Typography
                        textAlign="center"
                        sx={{
                            color:
                                -1 === location.pathname.indexOf('/overview')
                                    ? theme.palette.neutral[50]
                                    : theme.palette.primary[500],
                        }}
                        level="title-lg"
                    >
                        Global overview
                    </Typography>
                </Stack>
                <Stack
                    sx={{ marginTop: '40px', cursor: 'pointer' }}
                    onClick={() => {
                        navigate('/contributor');
                    }}
                    alignItems="center"
                >
                    {-1 === location.pathname.indexOf('/contributor') ? (
                        <img src={contributor} alt="contributor-menu" style={{ width: '32px', height: '32px' }} />
                    ) : (
                        <img
                            src={contributorSelected}
                            alt="contributor-menu"
                            style={{ width: '32px', height: '32px' }}
                        />
                    )}
                    <Typography
                        textAlign="center"
                        sx={{
                            color:
                                -1 === location.pathname.indexOf('/contributor')
                                    ? theme.palette.neutral[50]
                                    : theme.palette.primary[500],
                        }}
                        level="title-lg"
                    >
                        Business contributor
                    </Typography>
                </Stack>
            </Stack>
            <Stack sx={{ cursor: 'pointer' }} alignItems="center" onClick={() => setOpen(true)}>
                <img src={exportData} alt="export-menu" style={{ width: '32px', height: '32px' }} />
                <Typography textAlign="center" sx={{ color: theme.palette.neutral[50] }} level="title-lg">
                    Export data
                </Typography>
            </Stack>
            <ExportModal open={open} setOpen={setOpen} />
        </Stack>
    );
}
